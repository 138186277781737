/* tslint:disable */
/* eslint:disable */
import 'isomorphic-fetch';

export type ThenArg<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any[]) => Promise<infer U>
  ? U
  : T;

type QueryParameters = {
  [key: string]:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | boolean[]
    | undefined;
};

export type ViewTypeEnum = 'admin' | 'public' | 'autocomplete';
export type Error = {
  description?: string;

  name?: string;

  payload?: {};

  statusCode?: number;

  userinfo?: string;
};

class ApiError {
  public message: string;
  public details: Error | null = null;

  constructor(message: string) {
    this.message = message;
  }
}

/**
 *
 * @class API
 * @param {(string)} [domainOrOptions] - The project domain.
 */
class API {
  protected baseUrl: string = '';
  protected token: string = '';
  protected apiKey: string | null = null;

  serializeQueryParams(parameters: QueryParameters) {
    return Object.keys(parameters)
      .reduce((acc: string[], p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  protected transformParameter(value: any, transformOperation?: string) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  public setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public setToken(token: string) {
    this.token = token;
  }

  public setApiKey(apiKey: string) {
    this.apiKey = apiKey;
  }

  protected appendAuthHeaders(headerParams: Headers) {
    const headers = new Headers(headerParams);
    if (this.token) {
      headers.append('Authorization', `Bearer ${this.token}`);
    }
    if (this.apiKey) {
      headers.append('api-key', this.apiKey);
    }
    return headers;
  }

  private async request(
    method: string,
    url: string,
    body: any,
    headers: Headers = new Headers(),
    queryParameters: QueryParameters = {},
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    if (headers.get('Content-Type') === 'multipart/form-data') {
      const form = new FormData();
      for (let k in body) {
        form.append(k, body[k]);
      }
      body = form;
    } else if (
      headers.get('Content-Type') === 'application/json' &&
      body &&
      Object.keys(body).length > 0
    ) {
      body = JSON.stringify(body);
    } else {
      body = undefined;
    }

    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    const response = await fetch(urlWithParams, { method, headers, body });
    if (response.ok) {
      const responseContentType =
        (response.headers && response.headers.get('Content-Type')) || '';
      if (responseContentType.includes('application/json')) {
        return response.json();
      } else if (responseContentType.includes('application/pdf')) {
        return response.blob();
      } else if (responseContentType.includes('text/plain')) {
        return response.text();
      }
      return {};
    } else {
      const err = new ApiError(response.statusText);
      err.details = await response.json();
      throw err;
    }
  }

  /**
   * Create order document
   * @method
   * @name API#generateAdminOrderDocument
   */
  generateAdminOrderDocument(
    publicId: string,
    document: 'faktura' | 'predfaktura' | 'dobropis' | 'karat_faktura',
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{}> {
    let path = '/admin/orders/{public_id}/documents/{document}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{public_id}', publicId.toString());

    path = path.replace('{document}', document.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update content data
   * @method
   * @name API#contentBuilderUpload
   */
  contentBuilderUpload(
    contentId: number,
    parameters: {
      path?: string;
    } = {},
    form: {
      file?: {};
    },
  ): Promise<{
    author?: {
      author_id?: number;

      image?: string;

      name?: string;

      url?: string;
    };

    created_date?: string;

    created_user_id?: number;

    documents?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    downloads?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    draft?: boolean;

    hint?: string;

    home?: boolean;

    id?: number;

    is_top?: boolean;

    json_content?: {};

    lang_id?: string;

    lang_version_id?: number;

    last_update?: string;

    name?: string;

    next_article?: {
      annotation?: string;

      id?: string;

      image?: string;

      name?: string;

      url?: string;
    };

    ordering?: number;

    original_id?: string;

    photogallery?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    products?: Array<{
      attribs?: Array<{
        attrib_code?: string;

        attrib_grid?: number;

        attrib_id?: number;

        attrib_ismulti?: number;

        attrib_list?: number;

        attrib_name?: string;

        attrib_pbl?: number;

        attrib_sort?: number;

        attrib_type?: string;

        attrib_uid?: string;

        attrib_unit?: string;

        attrib_value?: string;

        custom_value?: string;

        group_id?: number;

        group_name?: string;

        product_id?: number;

        translations?: Array<{
          attrib_id?: number;

          attrib_name?: string;

          attrib_unit?: string;

          lang_id?: string;
        }>;

        type_id?: number;

        value_groups?: Array<{
          attrib_id?: number;

          group_id?: number;

          group_name?: string;

          group_sort?: number;
        }>;

        value_id?: number;

        values?: Array<{
          amount?: number;

          attrib_id?: number;

          attrib_pict?: string;

          attrib_value?: string;

          group_id?: number;

          istop?: boolean;

          searchfilter?: number;

          translations?: Array<{
            attrib_url?: string;

            attrib_value?: string;

            lang_id?: string;

            secondary_name?: string;

            value_id?: number;
          }>;

          value_id?: number;

          value_sort?: number;
        }>;
      }>;

      brand?: {
        brand_descr?: string;

        brand_id?: number;

        brand_image?: string;

        brand_name?: string;

        brand_provider?: string;

        brand_top?: boolean;

        brand_weburl?: string;

        catalog_id?: number;

        translations?: Array<{
          brand_descr?: string;

          brand_id?: number;

          brand_name?: string;

          brand_weburl?: string;

          lang_id?: string;
        }>;

        url?: string;
      };

      brand_id?: number;

      category_text?: string;

      created_at?: string;

      created_datetime?: string;

      description?: string;

      disable_discount?: boolean;

      disable_solo_order?: boolean;

      ean?: string;

      goods?: Array<{
        availability?: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id?: number;

            avail_name?: string;

            lang?: string;
          }>;
        };

        availability_date?: string;

        availability_id?: number;

        buy_price?: number;

        currency?: {
          id?: string;

          name?: string;
        };

        currency_id?: string;

        customer_prices?: {};

        discount_pcn?: number;

        ean?: string;

        final_price?: number;

        from_group_sale?: boolean;

        from_sale?: boolean;

        giftcard?: {
          apply_per_unit?: boolean;

          card_number?: string;

          card_status?: string;

          club_user_id?: number;

          created_date?: string;

          created_user_id?: number;

          date_used?: string;

          date_validity?: string;

          discount?: number;

          discount_currency?: string;

          freeshipping?: boolean;

          freeshippingeu?: boolean;

          id?: number;

          idx?: number;

          is_strict_discount?: boolean;

          lang?: string;

          multicard?: boolean;

          order_id?: number;

          price?: number;

          price_currency?: string;

          price_validity?: number;

          sale?: number;

          sale_count?: number;

          valid_product_ids?: Array<number>;

          valid_products_count?: number;
        };

        good_id?: number;

        group_price?: {
          oldprice?: number;

          sale_percentage?: number;

          unitprice?: number;
        };

        group_prices?: Array<{}>;

        idoklad_id?: string;

        imagePath?: string;

        internal_code?: string;

        is_on_stock?: boolean;

        karat_id?: string;

        lang?: string;

        oldprice?: number;

        on_stock_count?: number;

        order_number?: string;

        order_quantity_limit?: number;

        point_good?: boolean;

        points_amount?: number;

        price_without_vat?: number;

        price_without_vat_sale?: number;

        priceinfo?: string;

        product_id?: number;

        product_name?: string;

        product_note?: string;

        product_type?: number;

        purchaseprice?: number;

        qtyDiscounts?: {};

        sale?: boolean;

        sale_percentage?: number;

        sale_valid?: string;

        unit_price_sale?: number;

        unitprice?: number;

        unitprice_sale?: number;

        units?: string;

        valid_from?: string;

        valid_to?: string;

        vat_rate?: number;

        web_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        web_order_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        weight?: number;
      }>;

      heureka?: number;

      heureka_id?: number;

      internal_code?: number;

      is_favorite?: boolean;

      is_new?: boolean;

      is_popular?: boolean;

      is_recommended?: boolean;

      is_sale?: boolean;

      isactive?: number;

      isnew_valid?: string;

      issale_buy3_pay2?: number;

      issaleout?: boolean;

      issaleout_since?: string;

      issaleout_valid?: string;

      istop?: boolean;

      locked?: number;

      main_good?: {
        availability?: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id?: number;

            avail_name?: string;

            lang?: string;
          }>;
        };

        availability_date?: string;

        availability_id?: number;

        buy_price?: number;

        currency?: {
          id?: string;

          name?: string;
        };

        currency_id?: string;

        customer_prices?: {};

        discount_pcn?: number;

        ean?: string;

        final_price?: number;

        from_group_sale?: boolean;

        from_sale?: boolean;

        giftcard?: {
          apply_per_unit?: boolean;

          card_number?: string;

          card_status?: string;

          club_user_id?: number;

          created_date?: string;

          created_user_id?: number;

          date_used?: string;

          date_validity?: string;

          discount?: number;

          discount_currency?: string;

          freeshipping?: boolean;

          freeshippingeu?: boolean;

          id?: number;

          idx?: number;

          is_strict_discount?: boolean;

          lang?: string;

          multicard?: boolean;

          order_id?: number;

          price?: number;

          price_currency?: string;

          price_validity?: number;

          sale?: number;

          sale_count?: number;

          valid_product_ids?: Array<number>;

          valid_products_count?: number;
        };

        good_id?: number;

        group_price?: {
          oldprice?: number;

          sale_percentage?: number;

          unitprice?: number;
        };

        group_prices?: Array<{}>;

        idoklad_id?: string;

        imagePath?: string;

        internal_code?: string;

        is_on_stock?: boolean;

        karat_id?: string;

        lang?: string;

        oldprice?: number;

        on_stock_count?: number;

        order_number?: string;

        order_quantity_limit?: number;

        point_good?: boolean;

        points_amount?: number;

        price_without_vat?: number;

        price_without_vat_sale?: number;

        priceinfo?: string;

        product_id?: number;

        product_name?: string;

        product_note?: string;

        product_type?: number;

        purchaseprice?: number;

        qtyDiscounts?: {};

        sale?: boolean;

        sale_percentage?: number;

        sale_valid?: string;

        unit_price_sale?: number;

        unitprice?: number;

        unitprice_sale?: number;

        units?: string;

        valid_from?: string;

        valid_to?: string;

        vat_rate?: number;

        web_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        web_order_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        weight?: number;
      };

      max_price?: number;

      min_delivery_priority?: number;

      min_payment_priority?: number;

      min_price?: number;

      name?: string;

      name_url_encoded?: string;

      notshow?: number;

      ordered_count?: number;

      picture?: string;

      picture_hash?: string;

      picture_media_script?: string;

      plu?: string;

      product_fts?: string;

      product_id?: number;

      product_weight?: number;

      rating?: number;

      rating_users?: number;

      slogan?: string;

      type?: {
        catalog_id?: number;

        is_hidden?: number;

        store_minqty?: string;

        type_descr?: string;

        type_id?: number;

        type_inherits?: number;

        type_name?: string;
      };

      url?: string;

      valid_from?: string;

      valid_to?: string;
    }>;

    publish?: number;

    rss?: boolean;

    sf_form?: string;

    sf_stylesheet?: string;

    sf_xmlcontent?: string;

    sitemap_item_id?: number;

    tags?: Array<{
      id?: number;

      name?: string;
    }>;

    url?: string;

    videos?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      ordering?: number;

      video_description?: string;

      video_height?: number;

      video_id?: number;

      video_name?: string;

      video_url?: string;

      video_width?: number;

      webshow?: number;
    }>;

    canonical_url?: string;

    lang_versions?: Array<{
      lang_id?: string;

      url?: string;
    }>;
  }> {
    let path = '/contentbuilder/{content_id}/upload';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{content_id}', contentId.toString());

    if (typeof parameters['path'] !== 'undefined') {
      queryParameters['path'] = parameters['path'];
    }

    queryParameters['path'] = this.transformParameter(queryParameters['path']);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      form,
      headers,
      queryParameters,
    );
  }
  /**
   * Update content data
   * @method
   * @name API#contentBuilderMediaBrowser
   */
  contentBuilderMediaBrowser(
    parameters: {
      path?: string;
      filter?: string;
    } = {},
  ): Promise<{
    author?: {
      author_id?: number;

      image?: string;

      name?: string;

      url?: string;
    };

    created_date?: string;

    created_user_id?: number;

    documents?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    downloads?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    draft?: boolean;

    hint?: string;

    home?: boolean;

    id?: number;

    is_top?: boolean;

    json_content?: {};

    lang_id?: string;

    lang_version_id?: number;

    last_update?: string;

    name?: string;

    next_article?: {
      annotation?: string;

      id?: string;

      image?: string;

      name?: string;

      url?: string;
    };

    ordering?: number;

    original_id?: string;

    photogallery?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    products?: Array<{
      attribs?: Array<{
        attrib_code?: string;

        attrib_grid?: number;

        attrib_id?: number;

        attrib_ismulti?: number;

        attrib_list?: number;

        attrib_name?: string;

        attrib_pbl?: number;

        attrib_sort?: number;

        attrib_type?: string;

        attrib_uid?: string;

        attrib_unit?: string;

        attrib_value?: string;

        custom_value?: string;

        group_id?: number;

        group_name?: string;

        product_id?: number;

        translations?: Array<{
          attrib_id?: number;

          attrib_name?: string;

          attrib_unit?: string;

          lang_id?: string;
        }>;

        type_id?: number;

        value_groups?: Array<{
          attrib_id?: number;

          group_id?: number;

          group_name?: string;

          group_sort?: number;
        }>;

        value_id?: number;

        values?: Array<{
          amount?: number;

          attrib_id?: number;

          attrib_pict?: string;

          attrib_value?: string;

          group_id?: number;

          istop?: boolean;

          searchfilter?: number;

          translations?: Array<{
            attrib_url?: string;

            attrib_value?: string;

            lang_id?: string;

            secondary_name?: string;

            value_id?: number;
          }>;

          value_id?: number;

          value_sort?: number;
        }>;
      }>;

      brand?: {
        brand_descr?: string;

        brand_id?: number;

        brand_image?: string;

        brand_name?: string;

        brand_provider?: string;

        brand_top?: boolean;

        brand_weburl?: string;

        catalog_id?: number;

        translations?: Array<{
          brand_descr?: string;

          brand_id?: number;

          brand_name?: string;

          brand_weburl?: string;

          lang_id?: string;
        }>;

        url?: string;
      };

      brand_id?: number;

      category_text?: string;

      created_at?: string;

      created_datetime?: string;

      description?: string;

      disable_discount?: boolean;

      disable_solo_order?: boolean;

      ean?: string;

      goods?: Array<{
        availability?: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id?: number;

            avail_name?: string;

            lang?: string;
          }>;
        };

        availability_date?: string;

        availability_id?: number;

        buy_price?: number;

        currency?: {
          id?: string;

          name?: string;
        };

        currency_id?: string;

        customer_prices?: {};

        discount_pcn?: number;

        ean?: string;

        final_price?: number;

        from_group_sale?: boolean;

        from_sale?: boolean;

        giftcard?: {
          apply_per_unit?: boolean;

          card_number?: string;

          card_status?: string;

          club_user_id?: number;

          created_date?: string;

          created_user_id?: number;

          date_used?: string;

          date_validity?: string;

          discount?: number;

          discount_currency?: string;

          freeshipping?: boolean;

          freeshippingeu?: boolean;

          id?: number;

          idx?: number;

          is_strict_discount?: boolean;

          lang?: string;

          multicard?: boolean;

          order_id?: number;

          price?: number;

          price_currency?: string;

          price_validity?: number;

          sale?: number;

          sale_count?: number;

          valid_product_ids?: Array<number>;

          valid_products_count?: number;
        };

        good_id?: number;

        group_price?: {
          oldprice?: number;

          sale_percentage?: number;

          unitprice?: number;
        };

        group_prices?: Array<{}>;

        idoklad_id?: string;

        imagePath?: string;

        internal_code?: string;

        is_on_stock?: boolean;

        karat_id?: string;

        lang?: string;

        oldprice?: number;

        on_stock_count?: number;

        order_number?: string;

        order_quantity_limit?: number;

        point_good?: boolean;

        points_amount?: number;

        price_without_vat?: number;

        price_without_vat_sale?: number;

        priceinfo?: string;

        product_id?: number;

        product_name?: string;

        product_note?: string;

        product_type?: number;

        purchaseprice?: number;

        qtyDiscounts?: {};

        sale?: boolean;

        sale_percentage?: number;

        sale_valid?: string;

        unit_price_sale?: number;

        unitprice?: number;

        unitprice_sale?: number;

        units?: string;

        valid_from?: string;

        valid_to?: string;

        vat_rate?: number;

        web_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        web_order_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        weight?: number;
      }>;

      heureka?: number;

      heureka_id?: number;

      internal_code?: number;

      is_favorite?: boolean;

      is_new?: boolean;

      is_popular?: boolean;

      is_recommended?: boolean;

      is_sale?: boolean;

      isactive?: number;

      isnew_valid?: string;

      issale_buy3_pay2?: number;

      issaleout?: boolean;

      issaleout_since?: string;

      issaleout_valid?: string;

      istop?: boolean;

      locked?: number;

      main_good?: {
        availability?: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id?: number;

            avail_name?: string;

            lang?: string;
          }>;
        };

        availability_date?: string;

        availability_id?: number;

        buy_price?: number;

        currency?: {
          id?: string;

          name?: string;
        };

        currency_id?: string;

        customer_prices?: {};

        discount_pcn?: number;

        ean?: string;

        final_price?: number;

        from_group_sale?: boolean;

        from_sale?: boolean;

        giftcard?: {
          apply_per_unit?: boolean;

          card_number?: string;

          card_status?: string;

          club_user_id?: number;

          created_date?: string;

          created_user_id?: number;

          date_used?: string;

          date_validity?: string;

          discount?: number;

          discount_currency?: string;

          freeshipping?: boolean;

          freeshippingeu?: boolean;

          id?: number;

          idx?: number;

          is_strict_discount?: boolean;

          lang?: string;

          multicard?: boolean;

          order_id?: number;

          price?: number;

          price_currency?: string;

          price_validity?: number;

          sale?: number;

          sale_count?: number;

          valid_product_ids?: Array<number>;

          valid_products_count?: number;
        };

        good_id?: number;

        group_price?: {
          oldprice?: number;

          sale_percentage?: number;

          unitprice?: number;
        };

        group_prices?: Array<{}>;

        idoklad_id?: string;

        imagePath?: string;

        internal_code?: string;

        is_on_stock?: boolean;

        karat_id?: string;

        lang?: string;

        oldprice?: number;

        on_stock_count?: number;

        order_number?: string;

        order_quantity_limit?: number;

        point_good?: boolean;

        points_amount?: number;

        price_without_vat?: number;

        price_without_vat_sale?: number;

        priceinfo?: string;

        product_id?: number;

        product_name?: string;

        product_note?: string;

        product_type?: number;

        purchaseprice?: number;

        qtyDiscounts?: {};

        sale?: boolean;

        sale_percentage?: number;

        sale_valid?: string;

        unit_price_sale?: number;

        unitprice?: number;

        unitprice_sale?: number;

        units?: string;

        valid_from?: string;

        valid_to?: string;

        vat_rate?: number;

        web_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        web_order_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        weight?: number;
      };

      max_price?: number;

      min_delivery_priority?: number;

      min_payment_priority?: number;

      min_price?: number;

      name?: string;

      name_url_encoded?: string;

      notshow?: number;

      ordered_count?: number;

      picture?: string;

      picture_hash?: string;

      picture_media_script?: string;

      plu?: string;

      product_fts?: string;

      product_id?: number;

      product_weight?: number;

      rating?: number;

      rating_users?: number;

      slogan?: string;

      type?: {
        catalog_id?: number;

        is_hidden?: number;

        store_minqty?: string;

        type_descr?: string;

        type_id?: number;

        type_inherits?: number;

        type_name?: string;
      };

      url?: string;

      valid_from?: string;

      valid_to?: string;
    }>;

    publish?: number;

    rss?: boolean;

    sf_form?: string;

    sf_stylesheet?: string;

    sf_xmlcontent?: string;

    sitemap_item_id?: number;

    tags?: Array<{
      id?: number;

      name?: string;
    }>;

    url?: string;

    videos?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      ordering?: number;

      video_description?: string;

      video_height?: number;

      video_id?: number;

      video_name?: string;

      video_url?: string;

      video_width?: number;

      webshow?: number;
    }>;

    canonical_url?: string;

    lang_versions?: Array<{
      lang_id?: string;

      url?: string;
    }>;
  }> {
    let path = '/contentbuilder/mediabrowser';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['path'] !== 'undefined') {
      queryParameters['path'] = parameters['path'];
    }

    queryParameters['path'] = this.transformParameter(queryParameters['path']);

    if (typeof parameters['filter'] !== 'undefined') {
      queryParameters['filter'] = parameters['filter'];
    }

    queryParameters['filter'] = this.transformParameter(
      queryParameters['filter'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update content data
   * @method
   * @name API#contentBuilderMesiaBrowserCreatePath
   */
  contentBuilderMesiaBrowserCreatePath(
    parameters: {} = {},
    body: {
      path?: string;
    },
  ): Promise<{
    author?: {
      author_id?: number;

      image?: string;

      name?: string;

      url?: string;
    };

    created_date?: string;

    created_user_id?: number;

    documents?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    downloads?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    draft?: boolean;

    hint?: string;

    home?: boolean;

    id?: number;

    is_top?: boolean;

    json_content?: {};

    lang_id?: string;

    lang_version_id?: number;

    last_update?: string;

    name?: string;

    next_article?: {
      annotation?: string;

      id?: string;

      image?: string;

      name?: string;

      url?: string;
    };

    ordering?: number;

    original_id?: string;

    photogallery?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    products?: Array<{
      attribs?: Array<{
        attrib_code?: string;

        attrib_grid?: number;

        attrib_id?: number;

        attrib_ismulti?: number;

        attrib_list?: number;

        attrib_name?: string;

        attrib_pbl?: number;

        attrib_sort?: number;

        attrib_type?: string;

        attrib_uid?: string;

        attrib_unit?: string;

        attrib_value?: string;

        custom_value?: string;

        group_id?: number;

        group_name?: string;

        product_id?: number;

        translations?: Array<{
          attrib_id?: number;

          attrib_name?: string;

          attrib_unit?: string;

          lang_id?: string;
        }>;

        type_id?: number;

        value_groups?: Array<{
          attrib_id?: number;

          group_id?: number;

          group_name?: string;

          group_sort?: number;
        }>;

        value_id?: number;

        values?: Array<{
          amount?: number;

          attrib_id?: number;

          attrib_pict?: string;

          attrib_value?: string;

          group_id?: number;

          istop?: boolean;

          searchfilter?: number;

          translations?: Array<{
            attrib_url?: string;

            attrib_value?: string;

            lang_id?: string;

            secondary_name?: string;

            value_id?: number;
          }>;

          value_id?: number;

          value_sort?: number;
        }>;
      }>;

      brand?: {
        brand_descr?: string;

        brand_id?: number;

        brand_image?: string;

        brand_name?: string;

        brand_provider?: string;

        brand_top?: boolean;

        brand_weburl?: string;

        catalog_id?: number;

        translations?: Array<{
          brand_descr?: string;

          brand_id?: number;

          brand_name?: string;

          brand_weburl?: string;

          lang_id?: string;
        }>;

        url?: string;
      };

      brand_id?: number;

      category_text?: string;

      created_at?: string;

      created_datetime?: string;

      description?: string;

      disable_discount?: boolean;

      disable_solo_order?: boolean;

      ean?: string;

      goods?: Array<{
        availability?: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id?: number;

            avail_name?: string;

            lang?: string;
          }>;
        };

        availability_date?: string;

        availability_id?: number;

        buy_price?: number;

        currency?: {
          id?: string;

          name?: string;
        };

        currency_id?: string;

        customer_prices?: {};

        discount_pcn?: number;

        ean?: string;

        final_price?: number;

        from_group_sale?: boolean;

        from_sale?: boolean;

        giftcard?: {
          apply_per_unit?: boolean;

          card_number?: string;

          card_status?: string;

          club_user_id?: number;

          created_date?: string;

          created_user_id?: number;

          date_used?: string;

          date_validity?: string;

          discount?: number;

          discount_currency?: string;

          freeshipping?: boolean;

          freeshippingeu?: boolean;

          id?: number;

          idx?: number;

          is_strict_discount?: boolean;

          lang?: string;

          multicard?: boolean;

          order_id?: number;

          price?: number;

          price_currency?: string;

          price_validity?: number;

          sale?: number;

          sale_count?: number;

          valid_product_ids?: Array<number>;

          valid_products_count?: number;
        };

        good_id?: number;

        group_price?: {
          oldprice?: number;

          sale_percentage?: number;

          unitprice?: number;
        };

        group_prices?: Array<{}>;

        idoklad_id?: string;

        imagePath?: string;

        internal_code?: string;

        is_on_stock?: boolean;

        karat_id?: string;

        lang?: string;

        oldprice?: number;

        on_stock_count?: number;

        order_number?: string;

        order_quantity_limit?: number;

        point_good?: boolean;

        points_amount?: number;

        price_without_vat?: number;

        price_without_vat_sale?: number;

        priceinfo?: string;

        product_id?: number;

        product_name?: string;

        product_note?: string;

        product_type?: number;

        purchaseprice?: number;

        qtyDiscounts?: {};

        sale?: boolean;

        sale_percentage?: number;

        sale_valid?: string;

        unit_price_sale?: number;

        unitprice?: number;

        unitprice_sale?: number;

        units?: string;

        valid_from?: string;

        valid_to?: string;

        vat_rate?: number;

        web_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        web_order_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        weight?: number;
      }>;

      heureka?: number;

      heureka_id?: number;

      internal_code?: number;

      is_favorite?: boolean;

      is_new?: boolean;

      is_popular?: boolean;

      is_recommended?: boolean;

      is_sale?: boolean;

      isactive?: number;

      isnew_valid?: string;

      issale_buy3_pay2?: number;

      issaleout?: boolean;

      issaleout_since?: string;

      issaleout_valid?: string;

      istop?: boolean;

      locked?: number;

      main_good?: {
        availability?: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id?: number;

            avail_name?: string;

            lang?: string;
          }>;
        };

        availability_date?: string;

        availability_id?: number;

        buy_price?: number;

        currency?: {
          id?: string;

          name?: string;
        };

        currency_id?: string;

        customer_prices?: {};

        discount_pcn?: number;

        ean?: string;

        final_price?: number;

        from_group_sale?: boolean;

        from_sale?: boolean;

        giftcard?: {
          apply_per_unit?: boolean;

          card_number?: string;

          card_status?: string;

          club_user_id?: number;

          created_date?: string;

          created_user_id?: number;

          date_used?: string;

          date_validity?: string;

          discount?: number;

          discount_currency?: string;

          freeshipping?: boolean;

          freeshippingeu?: boolean;

          id?: number;

          idx?: number;

          is_strict_discount?: boolean;

          lang?: string;

          multicard?: boolean;

          order_id?: number;

          price?: number;

          price_currency?: string;

          price_validity?: number;

          sale?: number;

          sale_count?: number;

          valid_product_ids?: Array<number>;

          valid_products_count?: number;
        };

        good_id?: number;

        group_price?: {
          oldprice?: number;

          sale_percentage?: number;

          unitprice?: number;
        };

        group_prices?: Array<{}>;

        idoklad_id?: string;

        imagePath?: string;

        internal_code?: string;

        is_on_stock?: boolean;

        karat_id?: string;

        lang?: string;

        oldprice?: number;

        on_stock_count?: number;

        order_number?: string;

        order_quantity_limit?: number;

        point_good?: boolean;

        points_amount?: number;

        price_without_vat?: number;

        price_without_vat_sale?: number;

        priceinfo?: string;

        product_id?: number;

        product_name?: string;

        product_note?: string;

        product_type?: number;

        purchaseprice?: number;

        qtyDiscounts?: {};

        sale?: boolean;

        sale_percentage?: number;

        sale_valid?: string;

        unit_price_sale?: number;

        unitprice?: number;

        unitprice_sale?: number;

        units?: string;

        valid_from?: string;

        valid_to?: string;

        vat_rate?: number;

        web_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        web_order_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        weight?: number;
      };

      max_price?: number;

      min_delivery_priority?: number;

      min_payment_priority?: number;

      min_price?: number;

      name?: string;

      name_url_encoded?: string;

      notshow?: number;

      ordered_count?: number;

      picture?: string;

      picture_hash?: string;

      picture_media_script?: string;

      plu?: string;

      product_fts?: string;

      product_id?: number;

      product_weight?: number;

      rating?: number;

      rating_users?: number;

      slogan?: string;

      type?: {
        catalog_id?: number;

        is_hidden?: number;

        store_minqty?: string;

        type_descr?: string;

        type_id?: number;

        type_inherits?: number;

        type_name?: string;
      };

      url?: string;

      valid_from?: string;

      valid_to?: string;
    }>;

    publish?: number;

    rss?: boolean;

    sf_form?: string;

    sf_stylesheet?: string;

    sf_xmlcontent?: string;

    sitemap_item_id?: number;

    tags?: Array<{
      id?: number;

      name?: string;
    }>;

    url?: string;

    videos?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      ordering?: number;

      video_description?: string;

      video_height?: number;

      video_id?: number;

      video_name?: string;

      video_url?: string;

      video_width?: number;

      webshow?: number;
    }>;

    canonical_url?: string;

    lang_versions?: Array<{
      lang_id?: string;

      url?: string;
    }>;
  }> {
    let path = '/contentbuilder/mediabrowser/path';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Update content data
   * @method
   * @name API#contentBuilderMesiaBrowserDeletePath
   */
  contentBuilderMesiaBrowserDeletePath(
    parameters: {
      path?: string;
    } = {},
  ): Promise<{
    author?: {
      author_id?: number;

      image?: string;

      name?: string;

      url?: string;
    };

    created_date?: string;

    created_user_id?: number;

    documents?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    downloads?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    draft?: boolean;

    hint?: string;

    home?: boolean;

    id?: number;

    is_top?: boolean;

    json_content?: {};

    lang_id?: string;

    lang_version_id?: number;

    last_update?: string;

    name?: string;

    next_article?: {
      annotation?: string;

      id?: string;

      image?: string;

      name?: string;

      url?: string;
    };

    ordering?: number;

    original_id?: string;

    photogallery?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    products?: Array<{
      attribs?: Array<{
        attrib_code?: string;

        attrib_grid?: number;

        attrib_id?: number;

        attrib_ismulti?: number;

        attrib_list?: number;

        attrib_name?: string;

        attrib_pbl?: number;

        attrib_sort?: number;

        attrib_type?: string;

        attrib_uid?: string;

        attrib_unit?: string;

        attrib_value?: string;

        custom_value?: string;

        group_id?: number;

        group_name?: string;

        product_id?: number;

        translations?: Array<{
          attrib_id?: number;

          attrib_name?: string;

          attrib_unit?: string;

          lang_id?: string;
        }>;

        type_id?: number;

        value_groups?: Array<{
          attrib_id?: number;

          group_id?: number;

          group_name?: string;

          group_sort?: number;
        }>;

        value_id?: number;

        values?: Array<{
          amount?: number;

          attrib_id?: number;

          attrib_pict?: string;

          attrib_value?: string;

          group_id?: number;

          istop?: boolean;

          searchfilter?: number;

          translations?: Array<{
            attrib_url?: string;

            attrib_value?: string;

            lang_id?: string;

            secondary_name?: string;

            value_id?: number;
          }>;

          value_id?: number;

          value_sort?: number;
        }>;
      }>;

      brand?: {
        brand_descr?: string;

        brand_id?: number;

        brand_image?: string;

        brand_name?: string;

        brand_provider?: string;

        brand_top?: boolean;

        brand_weburl?: string;

        catalog_id?: number;

        translations?: Array<{
          brand_descr?: string;

          brand_id?: number;

          brand_name?: string;

          brand_weburl?: string;

          lang_id?: string;
        }>;

        url?: string;
      };

      brand_id?: number;

      category_text?: string;

      created_at?: string;

      created_datetime?: string;

      description?: string;

      disable_discount?: boolean;

      disable_solo_order?: boolean;

      ean?: string;

      goods?: Array<{
        availability?: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id?: number;

            avail_name?: string;

            lang?: string;
          }>;
        };

        availability_date?: string;

        availability_id?: number;

        buy_price?: number;

        currency?: {
          id?: string;

          name?: string;
        };

        currency_id?: string;

        customer_prices?: {};

        discount_pcn?: number;

        ean?: string;

        final_price?: number;

        from_group_sale?: boolean;

        from_sale?: boolean;

        giftcard?: {
          apply_per_unit?: boolean;

          card_number?: string;

          card_status?: string;

          club_user_id?: number;

          created_date?: string;

          created_user_id?: number;

          date_used?: string;

          date_validity?: string;

          discount?: number;

          discount_currency?: string;

          freeshipping?: boolean;

          freeshippingeu?: boolean;

          id?: number;

          idx?: number;

          is_strict_discount?: boolean;

          lang?: string;

          multicard?: boolean;

          order_id?: number;

          price?: number;

          price_currency?: string;

          price_validity?: number;

          sale?: number;

          sale_count?: number;

          valid_product_ids?: Array<number>;

          valid_products_count?: number;
        };

        good_id?: number;

        group_price?: {
          oldprice?: number;

          sale_percentage?: number;

          unitprice?: number;
        };

        group_prices?: Array<{}>;

        idoklad_id?: string;

        imagePath?: string;

        internal_code?: string;

        is_on_stock?: boolean;

        karat_id?: string;

        lang?: string;

        oldprice?: number;

        on_stock_count?: number;

        order_number?: string;

        order_quantity_limit?: number;

        point_good?: boolean;

        points_amount?: number;

        price_without_vat?: number;

        price_without_vat_sale?: number;

        priceinfo?: string;

        product_id?: number;

        product_name?: string;

        product_note?: string;

        product_type?: number;

        purchaseprice?: number;

        qtyDiscounts?: {};

        sale?: boolean;

        sale_percentage?: number;

        sale_valid?: string;

        unit_price_sale?: number;

        unitprice?: number;

        unitprice_sale?: number;

        units?: string;

        valid_from?: string;

        valid_to?: string;

        vat_rate?: number;

        web_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        web_order_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        weight?: number;
      }>;

      heureka?: number;

      heureka_id?: number;

      internal_code?: number;

      is_favorite?: boolean;

      is_new?: boolean;

      is_popular?: boolean;

      is_recommended?: boolean;

      is_sale?: boolean;

      isactive?: number;

      isnew_valid?: string;

      issale_buy3_pay2?: number;

      issaleout?: boolean;

      issaleout_since?: string;

      issaleout_valid?: string;

      istop?: boolean;

      locked?: number;

      main_good?: {
        availability?: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id?: number;

            avail_name?: string;

            lang?: string;
          }>;
        };

        availability_date?: string;

        availability_id?: number;

        buy_price?: number;

        currency?: {
          id?: string;

          name?: string;
        };

        currency_id?: string;

        customer_prices?: {};

        discount_pcn?: number;

        ean?: string;

        final_price?: number;

        from_group_sale?: boolean;

        from_sale?: boolean;

        giftcard?: {
          apply_per_unit?: boolean;

          card_number?: string;

          card_status?: string;

          club_user_id?: number;

          created_date?: string;

          created_user_id?: number;

          date_used?: string;

          date_validity?: string;

          discount?: number;

          discount_currency?: string;

          freeshipping?: boolean;

          freeshippingeu?: boolean;

          id?: number;

          idx?: number;

          is_strict_discount?: boolean;

          lang?: string;

          multicard?: boolean;

          order_id?: number;

          price?: number;

          price_currency?: string;

          price_validity?: number;

          sale?: number;

          sale_count?: number;

          valid_product_ids?: Array<number>;

          valid_products_count?: number;
        };

        good_id?: number;

        group_price?: {
          oldprice?: number;

          sale_percentage?: number;

          unitprice?: number;
        };

        group_prices?: Array<{}>;

        idoklad_id?: string;

        imagePath?: string;

        internal_code?: string;

        is_on_stock?: boolean;

        karat_id?: string;

        lang?: string;

        oldprice?: number;

        on_stock_count?: number;

        order_number?: string;

        order_quantity_limit?: number;

        point_good?: boolean;

        points_amount?: number;

        price_without_vat?: number;

        price_without_vat_sale?: number;

        priceinfo?: string;

        product_id?: number;

        product_name?: string;

        product_note?: string;

        product_type?: number;

        purchaseprice?: number;

        qtyDiscounts?: {};

        sale?: boolean;

        sale_percentage?: number;

        sale_valid?: string;

        unit_price_sale?: number;

        unitprice?: number;

        unitprice_sale?: number;

        units?: string;

        valid_from?: string;

        valid_to?: string;

        vat_rate?: number;

        web_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        web_order_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        weight?: number;
      };

      max_price?: number;

      min_delivery_priority?: number;

      min_payment_priority?: number;

      min_price?: number;

      name?: string;

      name_url_encoded?: string;

      notshow?: number;

      ordered_count?: number;

      picture?: string;

      picture_hash?: string;

      picture_media_script?: string;

      plu?: string;

      product_fts?: string;

      product_id?: number;

      product_weight?: number;

      rating?: number;

      rating_users?: number;

      slogan?: string;

      type?: {
        catalog_id?: number;

        is_hidden?: number;

        store_minqty?: string;

        type_descr?: string;

        type_id?: number;

        type_inherits?: number;

        type_name?: string;
      };

      url?: string;

      valid_from?: string;

      valid_to?: string;
    }>;

    publish?: number;

    rss?: boolean;

    sf_form?: string;

    sf_stylesheet?: string;

    sf_xmlcontent?: string;

    sitemap_item_id?: number;

    tags?: Array<{
      id?: number;

      name?: string;
    }>;

    url?: string;

    videos?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      ordering?: number;

      video_description?: string;

      video_height?: number;

      video_id?: number;

      video_name?: string;

      video_url?: string;

      video_width?: number;

      webshow?: number;
    }>;

    canonical_url?: string;

    lang_versions?: Array<{
      lang_id?: string;

      url?: string;
    }>;
  }> {
    let path = '/contentbuilder/mediabrowser/path';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['path'] !== 'undefined') {
      queryParameters['path'] = parameters['path'];
    }

    queryParameters['path'] = this.transformParameter(queryParameters['path']);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update content data
   * @method
   * @name API#contentBuilderMesiaBrowserDeleteFile
   */
  contentBuilderMesiaBrowserDeleteFile(parameters: {
    contentId: number;
    path?: string;
  }): Promise<{
    author?: {
      author_id?: number;

      image?: string;

      name?: string;

      url?: string;
    };

    created_date?: string;

    created_user_id?: number;

    documents?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    downloads?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    draft?: boolean;

    hint?: string;

    home?: boolean;

    id?: number;

    is_top?: boolean;

    json_content?: {};

    lang_id?: string;

    lang_version_id?: number;

    last_update?: string;

    name?: string;

    next_article?: {
      annotation?: string;

      id?: string;

      image?: string;

      name?: string;

      url?: string;
    };

    ordering?: number;

    original_id?: string;

    photogallery?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    products?: Array<{
      attribs?: Array<{
        attrib_code?: string;

        attrib_grid?: number;

        attrib_id?: number;

        attrib_ismulti?: number;

        attrib_list?: number;

        attrib_name?: string;

        attrib_pbl?: number;

        attrib_sort?: number;

        attrib_type?: string;

        attrib_uid?: string;

        attrib_unit?: string;

        attrib_value?: string;

        custom_value?: string;

        group_id?: number;

        group_name?: string;

        product_id?: number;

        translations?: Array<{
          attrib_id?: number;

          attrib_name?: string;

          attrib_unit?: string;

          lang_id?: string;
        }>;

        type_id?: number;

        value_groups?: Array<{
          attrib_id?: number;

          group_id?: number;

          group_name?: string;

          group_sort?: number;
        }>;

        value_id?: number;

        values?: Array<{
          amount?: number;

          attrib_id?: number;

          attrib_pict?: string;

          attrib_value?: string;

          group_id?: number;

          istop?: boolean;

          searchfilter?: number;

          translations?: Array<{
            attrib_url?: string;

            attrib_value?: string;

            lang_id?: string;

            secondary_name?: string;

            value_id?: number;
          }>;

          value_id?: number;

          value_sort?: number;
        }>;
      }>;

      brand?: {
        brand_descr?: string;

        brand_id?: number;

        brand_image?: string;

        brand_name?: string;

        brand_provider?: string;

        brand_top?: boolean;

        brand_weburl?: string;

        catalog_id?: number;

        translations?: Array<{
          brand_descr?: string;

          brand_id?: number;

          brand_name?: string;

          brand_weburl?: string;

          lang_id?: string;
        }>;

        url?: string;
      };

      brand_id?: number;

      category_text?: string;

      created_at?: string;

      created_datetime?: string;

      description?: string;

      disable_discount?: boolean;

      disable_solo_order?: boolean;

      ean?: string;

      goods?: Array<{
        availability?: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id?: number;

            avail_name?: string;

            lang?: string;
          }>;
        };

        availability_date?: string;

        availability_id?: number;

        buy_price?: number;

        currency?: {
          id?: string;

          name?: string;
        };

        currency_id?: string;

        customer_prices?: {};

        discount_pcn?: number;

        ean?: string;

        final_price?: number;

        from_group_sale?: boolean;

        from_sale?: boolean;

        giftcard?: {
          apply_per_unit?: boolean;

          card_number?: string;

          card_status?: string;

          club_user_id?: number;

          created_date?: string;

          created_user_id?: number;

          date_used?: string;

          date_validity?: string;

          discount?: number;

          discount_currency?: string;

          freeshipping?: boolean;

          freeshippingeu?: boolean;

          id?: number;

          idx?: number;

          is_strict_discount?: boolean;

          lang?: string;

          multicard?: boolean;

          order_id?: number;

          price?: number;

          price_currency?: string;

          price_validity?: number;

          sale?: number;

          sale_count?: number;

          valid_product_ids?: Array<number>;

          valid_products_count?: number;
        };

        good_id?: number;

        group_price?: {
          oldprice?: number;

          sale_percentage?: number;

          unitprice?: number;
        };

        group_prices?: Array<{}>;

        idoklad_id?: string;

        imagePath?: string;

        internal_code?: string;

        is_on_stock?: boolean;

        karat_id?: string;

        lang?: string;

        oldprice?: number;

        on_stock_count?: number;

        order_number?: string;

        order_quantity_limit?: number;

        point_good?: boolean;

        points_amount?: number;

        price_without_vat?: number;

        price_without_vat_sale?: number;

        priceinfo?: string;

        product_id?: number;

        product_name?: string;

        product_note?: string;

        product_type?: number;

        purchaseprice?: number;

        qtyDiscounts?: {};

        sale?: boolean;

        sale_percentage?: number;

        sale_valid?: string;

        unit_price_sale?: number;

        unitprice?: number;

        unitprice_sale?: number;

        units?: string;

        valid_from?: string;

        valid_to?: string;

        vat_rate?: number;

        web_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        web_order_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        weight?: number;
      }>;

      heureka?: number;

      heureka_id?: number;

      internal_code?: number;

      is_favorite?: boolean;

      is_new?: boolean;

      is_popular?: boolean;

      is_recommended?: boolean;

      is_sale?: boolean;

      isactive?: number;

      isnew_valid?: string;

      issale_buy3_pay2?: number;

      issaleout?: boolean;

      issaleout_since?: string;

      issaleout_valid?: string;

      istop?: boolean;

      locked?: number;

      main_good?: {
        availability?: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id?: number;

            avail_name?: string;

            lang?: string;
          }>;
        };

        availability_date?: string;

        availability_id?: number;

        buy_price?: number;

        currency?: {
          id?: string;

          name?: string;
        };

        currency_id?: string;

        customer_prices?: {};

        discount_pcn?: number;

        ean?: string;

        final_price?: number;

        from_group_sale?: boolean;

        from_sale?: boolean;

        giftcard?: {
          apply_per_unit?: boolean;

          card_number?: string;

          card_status?: string;

          club_user_id?: number;

          created_date?: string;

          created_user_id?: number;

          date_used?: string;

          date_validity?: string;

          discount?: number;

          discount_currency?: string;

          freeshipping?: boolean;

          freeshippingeu?: boolean;

          id?: number;

          idx?: number;

          is_strict_discount?: boolean;

          lang?: string;

          multicard?: boolean;

          order_id?: number;

          price?: number;

          price_currency?: string;

          price_validity?: number;

          sale?: number;

          sale_count?: number;

          valid_product_ids?: Array<number>;

          valid_products_count?: number;
        };

        good_id?: number;

        group_price?: {
          oldprice?: number;

          sale_percentage?: number;

          unitprice?: number;
        };

        group_prices?: Array<{}>;

        idoklad_id?: string;

        imagePath?: string;

        internal_code?: string;

        is_on_stock?: boolean;

        karat_id?: string;

        lang?: string;

        oldprice?: number;

        on_stock_count?: number;

        order_number?: string;

        order_quantity_limit?: number;

        point_good?: boolean;

        points_amount?: number;

        price_without_vat?: number;

        price_without_vat_sale?: number;

        priceinfo?: string;

        product_id?: number;

        product_name?: string;

        product_note?: string;

        product_type?: number;

        purchaseprice?: number;

        qtyDiscounts?: {};

        sale?: boolean;

        sale_percentage?: number;

        sale_valid?: string;

        unit_price_sale?: number;

        unitprice?: number;

        unitprice_sale?: number;

        units?: string;

        valid_from?: string;

        valid_to?: string;

        vat_rate?: number;

        web_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        web_order_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        weight?: number;
      };

      max_price?: number;

      min_delivery_priority?: number;

      min_payment_priority?: number;

      min_price?: number;

      name?: string;

      name_url_encoded?: string;

      notshow?: number;

      ordered_count?: number;

      picture?: string;

      picture_hash?: string;

      picture_media_script?: string;

      plu?: string;

      product_fts?: string;

      product_id?: number;

      product_weight?: number;

      rating?: number;

      rating_users?: number;

      slogan?: string;

      type?: {
        catalog_id?: number;

        is_hidden?: number;

        store_minqty?: string;

        type_descr?: string;

        type_id?: number;

        type_inherits?: number;

        type_name?: string;
      };

      url?: string;

      valid_from?: string;

      valid_to?: string;
    }>;

    publish?: number;

    rss?: boolean;

    sf_form?: string;

    sf_stylesheet?: string;

    sf_xmlcontent?: string;

    sitemap_item_id?: number;

    tags?: Array<{
      id?: number;

      name?: string;
    }>;

    url?: string;

    videos?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      ordering?: number;

      video_description?: string;

      video_height?: number;

      video_id?: number;

      video_name?: string;

      video_url?: string;

      video_width?: number;

      webshow?: number;
    }>;

    canonical_url?: string;

    lang_versions?: Array<{
      lang_id?: string;

      url?: string;
    }>;
  }> {
    let path = '/contentbuilder/mediabrowser/file';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['contentId'] !== 'undefined') {
      queryParameters['content_id'] = parameters['contentId'];
    }

    queryParameters['content_id'] = this.transformParameter(
      queryParameters['content_id'],
    );

    if (typeof parameters['contentId'] === 'undefined') {
      throw new Error('Missing required parameter: contentId');
    }

    if (typeof parameters['path'] !== 'undefined') {
      queryParameters['path'] = parameters['path'];
    }

    queryParameters['path'] = this.transformParameter(queryParameters['path']);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update content data
   * @method
   * @name API#updateContent
   */
  updateContent(
    contentId: number,
    parameters: {
      contentType?: string;
    } = {},
    body: {
      is_top: number;
    },
  ): Promise<{
    author?: {
      author_id?: number;

      image?: string;

      name?: string;

      url?: string;
    };

    created_date?: string;

    created_user_id?: number;

    documents?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    downloads?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    draft?: boolean;

    hint?: string;

    home?: boolean;

    id?: number;

    is_top?: boolean;

    json_content?: {};

    lang_id?: string;

    lang_version_id?: number;

    last_update?: string;

    name?: string;

    next_article?: {
      annotation?: string;

      id?: string;

      image?: string;

      name?: string;

      url?: string;
    };

    ordering?: number;

    original_id?: string;

    photogallery?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    products?: Array<{
      attribs?: Array<{
        attrib_code?: string;

        attrib_grid?: number;

        attrib_id?: number;

        attrib_ismulti?: number;

        attrib_list?: number;

        attrib_name?: string;

        attrib_pbl?: number;

        attrib_sort?: number;

        attrib_type?: string;

        attrib_uid?: string;

        attrib_unit?: string;

        attrib_value?: string;

        custom_value?: string;

        group_id?: number;

        group_name?: string;

        product_id?: number;

        translations?: Array<{
          attrib_id?: number;

          attrib_name?: string;

          attrib_unit?: string;

          lang_id?: string;
        }>;

        type_id?: number;

        value_groups?: Array<{
          attrib_id?: number;

          group_id?: number;

          group_name?: string;

          group_sort?: number;
        }>;

        value_id?: number;

        values?: Array<{
          amount?: number;

          attrib_id?: number;

          attrib_pict?: string;

          attrib_value?: string;

          group_id?: number;

          istop?: boolean;

          searchfilter?: number;

          translations?: Array<{
            attrib_url?: string;

            attrib_value?: string;

            lang_id?: string;

            secondary_name?: string;

            value_id?: number;
          }>;

          value_id?: number;

          value_sort?: number;
        }>;
      }>;

      brand?: {
        brand_descr?: string;

        brand_id?: number;

        brand_image?: string;

        brand_name?: string;

        brand_provider?: string;

        brand_top?: boolean;

        brand_weburl?: string;

        catalog_id?: number;

        translations?: Array<{
          brand_descr?: string;

          brand_id?: number;

          brand_name?: string;

          brand_weburl?: string;

          lang_id?: string;
        }>;

        url?: string;
      };

      brand_id?: number;

      category_text?: string;

      created_at?: string;

      created_datetime?: string;

      description?: string;

      disable_discount?: boolean;

      disable_solo_order?: boolean;

      ean?: string;

      goods?: Array<{
        availability?: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id?: number;

            avail_name?: string;

            lang?: string;
          }>;
        };

        availability_date?: string;

        availability_id?: number;

        buy_price?: number;

        currency?: {
          id?: string;

          name?: string;
        };

        currency_id?: string;

        customer_prices?: {};

        discount_pcn?: number;

        ean?: string;

        final_price?: number;

        from_group_sale?: boolean;

        from_sale?: boolean;

        giftcard?: {
          apply_per_unit?: boolean;

          card_number?: string;

          card_status?: string;

          club_user_id?: number;

          created_date?: string;

          created_user_id?: number;

          date_used?: string;

          date_validity?: string;

          discount?: number;

          discount_currency?: string;

          freeshipping?: boolean;

          freeshippingeu?: boolean;

          id?: number;

          idx?: number;

          is_strict_discount?: boolean;

          lang?: string;

          multicard?: boolean;

          order_id?: number;

          price?: number;

          price_currency?: string;

          price_validity?: number;

          sale?: number;

          sale_count?: number;

          valid_product_ids?: Array<number>;

          valid_products_count?: number;
        };

        good_id?: number;

        group_price?: {
          oldprice?: number;

          sale_percentage?: number;

          unitprice?: number;
        };

        group_prices?: Array<{}>;

        idoklad_id?: string;

        imagePath?: string;

        internal_code?: string;

        is_on_stock?: boolean;

        karat_id?: string;

        lang?: string;

        oldprice?: number;

        on_stock_count?: number;

        order_number?: string;

        order_quantity_limit?: number;

        point_good?: boolean;

        points_amount?: number;

        price_without_vat?: number;

        price_without_vat_sale?: number;

        priceinfo?: string;

        product_id?: number;

        product_name?: string;

        product_note?: string;

        product_type?: number;

        purchaseprice?: number;

        qtyDiscounts?: {};

        sale?: boolean;

        sale_percentage?: number;

        sale_valid?: string;

        unit_price_sale?: number;

        unitprice?: number;

        unitprice_sale?: number;

        units?: string;

        valid_from?: string;

        valid_to?: string;

        vat_rate?: number;

        web_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        web_order_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        weight?: number;
      }>;

      heureka?: number;

      heureka_id?: number;

      internal_code?: number;

      is_favorite?: boolean;

      is_new?: boolean;

      is_popular?: boolean;

      is_recommended?: boolean;

      is_sale?: boolean;

      isactive?: number;

      isnew_valid?: string;

      issale_buy3_pay2?: number;

      issaleout?: boolean;

      issaleout_since?: string;

      issaleout_valid?: string;

      istop?: boolean;

      locked?: number;

      main_good?: {
        availability?: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id?: number;

            avail_name?: string;

            lang?: string;
          }>;
        };

        availability_date?: string;

        availability_id?: number;

        buy_price?: number;

        currency?: {
          id?: string;

          name?: string;
        };

        currency_id?: string;

        customer_prices?: {};

        discount_pcn?: number;

        ean?: string;

        final_price?: number;

        from_group_sale?: boolean;

        from_sale?: boolean;

        giftcard?: {
          apply_per_unit?: boolean;

          card_number?: string;

          card_status?: string;

          club_user_id?: number;

          created_date?: string;

          created_user_id?: number;

          date_used?: string;

          date_validity?: string;

          discount?: number;

          discount_currency?: string;

          freeshipping?: boolean;

          freeshippingeu?: boolean;

          id?: number;

          idx?: number;

          is_strict_discount?: boolean;

          lang?: string;

          multicard?: boolean;

          order_id?: number;

          price?: number;

          price_currency?: string;

          price_validity?: number;

          sale?: number;

          sale_count?: number;

          valid_product_ids?: Array<number>;

          valid_products_count?: number;
        };

        good_id?: number;

        group_price?: {
          oldprice?: number;

          sale_percentage?: number;

          unitprice?: number;
        };

        group_prices?: Array<{}>;

        idoklad_id?: string;

        imagePath?: string;

        internal_code?: string;

        is_on_stock?: boolean;

        karat_id?: string;

        lang?: string;

        oldprice?: number;

        on_stock_count?: number;

        order_number?: string;

        order_quantity_limit?: number;

        point_good?: boolean;

        points_amount?: number;

        price_without_vat?: number;

        price_without_vat_sale?: number;

        priceinfo?: string;

        product_id?: number;

        product_name?: string;

        product_note?: string;

        product_type?: number;

        purchaseprice?: number;

        qtyDiscounts?: {};

        sale?: boolean;

        sale_percentage?: number;

        sale_valid?: string;

        unit_price_sale?: number;

        unitprice?: number;

        unitprice_sale?: number;

        units?: string;

        valid_from?: string;

        valid_to?: string;

        vat_rate?: number;

        web_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        web_order_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        weight?: number;
      };

      max_price?: number;

      min_delivery_priority?: number;

      min_payment_priority?: number;

      min_price?: number;

      name?: string;

      name_url_encoded?: string;

      notshow?: number;

      ordered_count?: number;

      picture?: string;

      picture_hash?: string;

      picture_media_script?: string;

      plu?: string;

      product_fts?: string;

      product_id?: number;

      product_weight?: number;

      rating?: number;

      rating_users?: number;

      slogan?: string;

      type?: {
        catalog_id?: number;

        is_hidden?: number;

        store_minqty?: string;

        type_descr?: string;

        type_id?: number;

        type_inherits?: number;

        type_name?: string;
      };

      url?: string;

      valid_from?: string;

      valid_to?: string;
    }>;

    publish?: number;

    rss?: boolean;

    sf_form?: string;

    sf_stylesheet?: string;

    sf_xmlcontent?: string;

    sitemap_item_id?: number;

    tags?: Array<{
      id?: number;

      name?: string;
    }>;

    url?: string;

    videos?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      ordering?: number;

      video_description?: string;

      video_height?: number;

      video_id?: number;

      video_name?: string;

      video_url?: string;

      video_width?: number;

      webshow?: number;
    }>;

    canonical_url?: string;

    lang_versions?: Array<{
      lang_id?: string;

      url?: string;
    }>;
  }> {
    let path = '/contents/{content_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{content_id}', contentId.toString());

    if (typeof parameters['contentType'] !== 'undefined') {
      queryParameters['content_type'] = parameters['contentType'];
    }

    queryParameters['content_type'] = this.transformParameter(
      queryParameters['content_type'],
    );

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Load content data
   * @method
   * @name API#loadContent
   */
  loadContent(
    contentId: number,
    parameters: {} = {},
  ): Promise<{
    author?: {
      author_id?: number;

      image?: string;

      name?: string;

      url?: string;
    };

    created_date?: string;

    created_user_id?: number;

    documents?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    downloads?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    draft?: boolean;

    hint?: string;

    home?: boolean;

    id?: number;

    is_top?: boolean;

    json_content?: {};

    lang_id?: string;

    lang_version_id?: number;

    last_update?: string;

    name?: string;

    next_article?: {
      annotation?: string;

      id?: string;

      image?: string;

      name?: string;

      url?: string;
    };

    ordering?: number;

    original_id?: string;

    photogallery?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    products?: Array<{
      attribs?: Array<{
        attrib_code?: string;

        attrib_grid?: number;

        attrib_id?: number;

        attrib_ismulti?: number;

        attrib_list?: number;

        attrib_name?: string;

        attrib_pbl?: number;

        attrib_sort?: number;

        attrib_type?: string;

        attrib_uid?: string;

        attrib_unit?: string;

        attrib_value?: string;

        custom_value?: string;

        group_id?: number;

        group_name?: string;

        product_id?: number;

        translations?: Array<{
          attrib_id?: number;

          attrib_name?: string;

          attrib_unit?: string;

          lang_id?: string;
        }>;

        type_id?: number;

        value_groups?: Array<{
          attrib_id?: number;

          group_id?: number;

          group_name?: string;

          group_sort?: number;
        }>;

        value_id?: number;

        values?: Array<{
          amount?: number;

          attrib_id?: number;

          attrib_pict?: string;

          attrib_value?: string;

          group_id?: number;

          istop?: boolean;

          searchfilter?: number;

          translations?: Array<{
            attrib_url?: string;

            attrib_value?: string;

            lang_id?: string;

            secondary_name?: string;

            value_id?: number;
          }>;

          value_id?: number;

          value_sort?: number;
        }>;
      }>;

      brand?: {
        brand_descr?: string;

        brand_id?: number;

        brand_image?: string;

        brand_name?: string;

        brand_provider?: string;

        brand_top?: boolean;

        brand_weburl?: string;

        catalog_id?: number;

        translations?: Array<{
          brand_descr?: string;

          brand_id?: number;

          brand_name?: string;

          brand_weburl?: string;

          lang_id?: string;
        }>;

        url?: string;
      };

      brand_id?: number;

      category_text?: string;

      created_at?: string;

      created_datetime?: string;

      description?: string;

      disable_discount?: boolean;

      disable_solo_order?: boolean;

      ean?: string;

      goods?: Array<{
        availability?: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id?: number;

            avail_name?: string;

            lang?: string;
          }>;
        };

        availability_date?: string;

        availability_id?: number;

        buy_price?: number;

        currency?: {
          id?: string;

          name?: string;
        };

        currency_id?: string;

        customer_prices?: {};

        discount_pcn?: number;

        ean?: string;

        final_price?: number;

        from_group_sale?: boolean;

        from_sale?: boolean;

        giftcard?: {
          apply_per_unit?: boolean;

          card_number?: string;

          card_status?: string;

          club_user_id?: number;

          created_date?: string;

          created_user_id?: number;

          date_used?: string;

          date_validity?: string;

          discount?: number;

          discount_currency?: string;

          freeshipping?: boolean;

          freeshippingeu?: boolean;

          id?: number;

          idx?: number;

          is_strict_discount?: boolean;

          lang?: string;

          multicard?: boolean;

          order_id?: number;

          price?: number;

          price_currency?: string;

          price_validity?: number;

          sale?: number;

          sale_count?: number;

          valid_product_ids?: Array<number>;

          valid_products_count?: number;
        };

        good_id?: number;

        group_price?: {
          oldprice?: number;

          sale_percentage?: number;

          unitprice?: number;
        };

        group_prices?: Array<{}>;

        idoklad_id?: string;

        imagePath?: string;

        internal_code?: string;

        is_on_stock?: boolean;

        karat_id?: string;

        lang?: string;

        oldprice?: number;

        on_stock_count?: number;

        order_number?: string;

        order_quantity_limit?: number;

        point_good?: boolean;

        points_amount?: number;

        price_without_vat?: number;

        price_without_vat_sale?: number;

        priceinfo?: string;

        product_id?: number;

        product_name?: string;

        product_note?: string;

        product_type?: number;

        purchaseprice?: number;

        qtyDiscounts?: {};

        sale?: boolean;

        sale_percentage?: number;

        sale_valid?: string;

        unit_price_sale?: number;

        unitprice?: number;

        unitprice_sale?: number;

        units?: string;

        valid_from?: string;

        valid_to?: string;

        vat_rate?: number;

        web_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        web_order_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        weight?: number;
      }>;

      heureka?: number;

      heureka_id?: number;

      internal_code?: number;

      is_favorite?: boolean;

      is_new?: boolean;

      is_popular?: boolean;

      is_recommended?: boolean;

      is_sale?: boolean;

      isactive?: number;

      isnew_valid?: string;

      issale_buy3_pay2?: number;

      issaleout?: boolean;

      issaleout_since?: string;

      issaleout_valid?: string;

      istop?: boolean;

      locked?: number;

      main_good?: {
        availability?: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id?: number;

            avail_name?: string;

            lang?: string;
          }>;
        };

        availability_date?: string;

        availability_id?: number;

        buy_price?: number;

        currency?: {
          id?: string;

          name?: string;
        };

        currency_id?: string;

        customer_prices?: {};

        discount_pcn?: number;

        ean?: string;

        final_price?: number;

        from_group_sale?: boolean;

        from_sale?: boolean;

        giftcard?: {
          apply_per_unit?: boolean;

          card_number?: string;

          card_status?: string;

          club_user_id?: number;

          created_date?: string;

          created_user_id?: number;

          date_used?: string;

          date_validity?: string;

          discount?: number;

          discount_currency?: string;

          freeshipping?: boolean;

          freeshippingeu?: boolean;

          id?: number;

          idx?: number;

          is_strict_discount?: boolean;

          lang?: string;

          multicard?: boolean;

          order_id?: number;

          price?: number;

          price_currency?: string;

          price_validity?: number;

          sale?: number;

          sale_count?: number;

          valid_product_ids?: Array<number>;

          valid_products_count?: number;
        };

        good_id?: number;

        group_price?: {
          oldprice?: number;

          sale_percentage?: number;

          unitprice?: number;
        };

        group_prices?: Array<{}>;

        idoklad_id?: string;

        imagePath?: string;

        internal_code?: string;

        is_on_stock?: boolean;

        karat_id?: string;

        lang?: string;

        oldprice?: number;

        on_stock_count?: number;

        order_number?: string;

        order_quantity_limit?: number;

        point_good?: boolean;

        points_amount?: number;

        price_without_vat?: number;

        price_without_vat_sale?: number;

        priceinfo?: string;

        product_id?: number;

        product_name?: string;

        product_note?: string;

        product_type?: number;

        purchaseprice?: number;

        qtyDiscounts?: {};

        sale?: boolean;

        sale_percentage?: number;

        sale_valid?: string;

        unit_price_sale?: number;

        unitprice?: number;

        unitprice_sale?: number;

        units?: string;

        valid_from?: string;

        valid_to?: string;

        vat_rate?: number;

        web_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        web_order_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        weight?: number;
      };

      max_price?: number;

      min_delivery_priority?: number;

      min_payment_priority?: number;

      min_price?: number;

      name?: string;

      name_url_encoded?: string;

      notshow?: number;

      ordered_count?: number;

      picture?: string;

      picture_hash?: string;

      picture_media_script?: string;

      plu?: string;

      product_fts?: string;

      product_id?: number;

      product_weight?: number;

      rating?: number;

      rating_users?: number;

      slogan?: string;

      type?: {
        catalog_id?: number;

        is_hidden?: number;

        store_minqty?: string;

        type_descr?: string;

        type_id?: number;

        type_inherits?: number;

        type_name?: string;
      };

      url?: string;

      valid_from?: string;

      valid_to?: string;
    }>;

    publish?: number;

    rss?: boolean;

    sf_form?: string;

    sf_stylesheet?: string;

    sf_xmlcontent?: string;

    sitemap_item_id?: number;

    tags?: Array<{
      id?: number;

      name?: string;
    }>;

    url?: string;

    videos?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      ordering?: number;

      video_description?: string;

      video_height?: number;

      video_id?: number;

      video_name?: string;

      video_url?: string;

      video_width?: number;

      webshow?: number;
    }>;

    canonical_url?: string;

    lang_versions?: Array<{
      lang_id?: string;

      url?: string;
    }>;
  }> {
    let path = '/contents/{content_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{content_id}', contentId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Save content version
   * @method
   * @name API#saveContentVersion
   */
  saveContentVersion(
    contentId: number,
    parameters: {} = {},
    body: {
      web_content_version_id?: number;

      author_id?: number;

      name: string;

      sf_xmlcontent: string;

      json_content: string;

      is_draft?: boolean;

      version_number?: number;
    },
  ): Promise<{}> {
    let path = '/contents/{content_id}/version';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{content_id}', contentId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Set content to specific version
   * @method
   * @name API#setContentVersion
   */
  setContentVersion(
    contentId: number,
    versionId: number,
    parameters: {
      contentType?: string;
    } = {},
  ): Promise<{}> {
    let path = '/contents/{content_id}/version/{version_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{content_id}', contentId.toString());

    path = path.replace('{version_id}', versionId.toString());

    if (typeof parameters['contentType'] !== 'undefined') {
      queryParameters['content_type'] = parameters['contentType'];
    }

    queryParameters['content_type'] = this.transformParameter(
      queryParameters['content_type'],
    );

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Copy content versions to another content
   * @method
   * @name API#copyContentVersions
   */
  copyContentVersions(
    contentId: number,
    finalContentId: number,
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/contents/{content_id}/version/copy/{final_content_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{content_id}', contentId.toString());

    path = path.replace('{final_content_id}', finalContentId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Add article to most read
   * @method
   * @name API#addArticleToMostRead
   */
  addArticleToMostRead(
    contentId: number,
    parameters: {} = {},
    body: {
      date_type: string;

      ordering: number;
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    author?: {
      author_id?: number;

      image?: string;

      name?: string;

      url?: string;
    };

    created_date?: string;

    created_user_id?: number;

    documents?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    downloads?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    draft?: boolean;

    hint?: string;

    home?: boolean;

    id?: number;

    is_top?: boolean;

    json_content?: {};

    lang_id?: string;

    lang_version_id?: number;

    last_update?: string;

    name?: string;

    next_article?: {
      annotation?: string;

      id?: string;

      image?: string;

      name?: string;

      url?: string;
    };

    ordering?: number;

    original_id?: string;

    photogallery?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    products?: Array<{
      attribs?: Array<{
        attrib_code?: string;

        attrib_grid?: number;

        attrib_id?: number;

        attrib_ismulti?: number;

        attrib_list?: number;

        attrib_name?: string;

        attrib_pbl?: number;

        attrib_sort?: number;

        attrib_type?: string;

        attrib_uid?: string;

        attrib_unit?: string;

        attrib_value?: string;

        custom_value?: string;

        group_id?: number;

        group_name?: string;

        product_id?: number;

        translations?: Array<{
          attrib_id?: number;

          attrib_name?: string;

          attrib_unit?: string;

          lang_id?: string;
        }>;

        type_id?: number;

        value_groups?: Array<{
          attrib_id?: number;

          group_id?: number;

          group_name?: string;

          group_sort?: number;
        }>;

        value_id?: number;

        values?: Array<{
          amount?: number;

          attrib_id?: number;

          attrib_pict?: string;

          attrib_value?: string;

          group_id?: number;

          istop?: boolean;

          searchfilter?: number;

          translations?: Array<{
            attrib_url?: string;

            attrib_value?: string;

            lang_id?: string;

            secondary_name?: string;

            value_id?: number;
          }>;

          value_id?: number;

          value_sort?: number;
        }>;
      }>;

      brand?: {
        brand_descr?: string;

        brand_id?: number;

        brand_image?: string;

        brand_name?: string;

        brand_provider?: string;

        brand_top?: boolean;

        brand_weburl?: string;

        catalog_id?: number;

        translations?: Array<{
          brand_descr?: string;

          brand_id?: number;

          brand_name?: string;

          brand_weburl?: string;

          lang_id?: string;
        }>;

        url?: string;
      };

      brand_id?: number;

      category_text?: string;

      created_at?: string;

      created_datetime?: string;

      description?: string;

      disable_discount?: boolean;

      disable_solo_order?: boolean;

      ean?: string;

      goods?: Array<{
        availability?: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id?: number;

            avail_name?: string;

            lang?: string;
          }>;
        };

        availability_date?: string;

        availability_id?: number;

        buy_price?: number;

        currency?: {
          id?: string;

          name?: string;
        };

        currency_id?: string;

        customer_prices?: {};

        discount_pcn?: number;

        ean?: string;

        final_price?: number;

        from_group_sale?: boolean;

        from_sale?: boolean;

        giftcard?: {
          apply_per_unit?: boolean;

          card_number?: string;

          card_status?: string;

          club_user_id?: number;

          created_date?: string;

          created_user_id?: number;

          date_used?: string;

          date_validity?: string;

          discount?: number;

          discount_currency?: string;

          freeshipping?: boolean;

          freeshippingeu?: boolean;

          id?: number;

          idx?: number;

          is_strict_discount?: boolean;

          lang?: string;

          multicard?: boolean;

          order_id?: number;

          price?: number;

          price_currency?: string;

          price_validity?: number;

          sale?: number;

          sale_count?: number;

          valid_product_ids?: Array<number>;

          valid_products_count?: number;
        };

        good_id?: number;

        group_price?: {
          oldprice?: number;

          sale_percentage?: number;

          unitprice?: number;
        };

        group_prices?: Array<{}>;

        idoklad_id?: string;

        imagePath?: string;

        internal_code?: string;

        is_on_stock?: boolean;

        karat_id?: string;

        lang?: string;

        oldprice?: number;

        on_stock_count?: number;

        order_number?: string;

        order_quantity_limit?: number;

        point_good?: boolean;

        points_amount?: number;

        price_without_vat?: number;

        price_without_vat_sale?: number;

        priceinfo?: string;

        product_id?: number;

        product_name?: string;

        product_note?: string;

        product_type?: number;

        purchaseprice?: number;

        qtyDiscounts?: {};

        sale?: boolean;

        sale_percentage?: number;

        sale_valid?: string;

        unit_price_sale?: number;

        unitprice?: number;

        unitprice_sale?: number;

        units?: string;

        valid_from?: string;

        valid_to?: string;

        vat_rate?: number;

        web_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        web_order_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        weight?: number;
      }>;

      heureka?: number;

      heureka_id?: number;

      internal_code?: number;

      is_favorite?: boolean;

      is_new?: boolean;

      is_popular?: boolean;

      is_recommended?: boolean;

      is_sale?: boolean;

      isactive?: number;

      isnew_valid?: string;

      issale_buy3_pay2?: number;

      issaleout?: boolean;

      issaleout_since?: string;

      issaleout_valid?: string;

      istop?: boolean;

      locked?: number;

      main_good?: {
        availability?: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id?: number;

            avail_name?: string;

            lang?: string;
          }>;
        };

        availability_date?: string;

        availability_id?: number;

        buy_price?: number;

        currency?: {
          id?: string;

          name?: string;
        };

        currency_id?: string;

        customer_prices?: {};

        discount_pcn?: number;

        ean?: string;

        final_price?: number;

        from_group_sale?: boolean;

        from_sale?: boolean;

        giftcard?: {
          apply_per_unit?: boolean;

          card_number?: string;

          card_status?: string;

          club_user_id?: number;

          created_date?: string;

          created_user_id?: number;

          date_used?: string;

          date_validity?: string;

          discount?: number;

          discount_currency?: string;

          freeshipping?: boolean;

          freeshippingeu?: boolean;

          id?: number;

          idx?: number;

          is_strict_discount?: boolean;

          lang?: string;

          multicard?: boolean;

          order_id?: number;

          price?: number;

          price_currency?: string;

          price_validity?: number;

          sale?: number;

          sale_count?: number;

          valid_product_ids?: Array<number>;

          valid_products_count?: number;
        };

        good_id?: number;

        group_price?: {
          oldprice?: number;

          sale_percentage?: number;

          unitprice?: number;
        };

        group_prices?: Array<{}>;

        idoklad_id?: string;

        imagePath?: string;

        internal_code?: string;

        is_on_stock?: boolean;

        karat_id?: string;

        lang?: string;

        oldprice?: number;

        on_stock_count?: number;

        order_number?: string;

        order_quantity_limit?: number;

        point_good?: boolean;

        points_amount?: number;

        price_without_vat?: number;

        price_without_vat_sale?: number;

        priceinfo?: string;

        product_id?: number;

        product_name?: string;

        product_note?: string;

        product_type?: number;

        purchaseprice?: number;

        qtyDiscounts?: {};

        sale?: boolean;

        sale_percentage?: number;

        sale_valid?: string;

        unit_price_sale?: number;

        unitprice?: number;

        unitprice_sale?: number;

        units?: string;

        valid_from?: string;

        valid_to?: string;

        vat_rate?: number;

        web_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        web_order_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        weight?: number;
      };

      max_price?: number;

      min_delivery_priority?: number;

      min_payment_priority?: number;

      min_price?: number;

      name?: string;

      name_url_encoded?: string;

      notshow?: number;

      ordered_count?: number;

      picture?: string;

      picture_hash?: string;

      picture_media_script?: string;

      plu?: string;

      product_fts?: string;

      product_id?: number;

      product_weight?: number;

      rating?: number;

      rating_users?: number;

      slogan?: string;

      type?: {
        catalog_id?: number;

        is_hidden?: number;

        store_minqty?: string;

        type_descr?: string;

        type_id?: number;

        type_inherits?: number;

        type_name?: string;
      };

      url?: string;

      valid_from?: string;

      valid_to?: string;
    }>;

    publish?: number;

    rss?: boolean;

    sf_form?: string;

    sf_stylesheet?: string;

    sf_xmlcontent?: string;

    sitemap_item_id?: number;

    tags?: Array<{
      id?: number;

      name?: string;
    }>;

    url?: string;

    videos?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      ordering?: number;

      video_description?: string;

      video_height?: number;

      video_id?: number;

      video_name?: string;

      video_url?: string;

      video_width?: number;

      webshow?: number;
    }>;

    canonical_url?: string;

    lang_versions?: Array<{
      lang_id?: string;

      url?: string;
    }>;
  }> {
    let path = '/contents/{content_id}/most-read';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{content_id}', contentId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Remove article from most read
   * @method
   * @name API#removeArticleFromMostRead
   */
  removeArticleFromMostRead(
    contentId: number,
    dateType: 'week' | 'month' | 'year',
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{}> {
    let path = '/contents/{content_id}/most-read/{date_type}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{content_id}', contentId.toString());

    path = path.replace('{date_type}', dateType.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Upload video
   * @method
   * @name API#uploadVideo
   */
  uploadVideo(
    contentId: number,
    parameters: {} = {},
    form: {
      file?: {};
    },
  ): Promise<{}> {
    let path = '/contents/{content_id}/video-upload';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{content_id}', contentId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      form,
      headers,
      queryParameters,
    );
  }
  /**
   * Loads content versions or specific version
   * @method
   * @name API#loadVersions
   */
  loadVersions(
    parameters: {
      limit?: number;
      offset?: number;
      versionId?: string;
      contentId?: string;
      contentType?: string;
      isDraft?: 0 | 1;
      webContentLangId?: string;
      onlyAssigned?: number;
    } = {},
  ): Promise<{}> {
    let path = '/contents/versions';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 20;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['versionId'] !== 'undefined') {
      queryParameters['version_id'] = parameters['versionId'];
    }

    queryParameters['version_id'] = this.transformParameter(
      queryParameters['version_id'],
    );

    if (typeof parameters['contentId'] !== 'undefined') {
      queryParameters['content_id'] = parameters['contentId'];
    }

    queryParameters['content_id'] = this.transformParameter(
      queryParameters['content_id'],
    );

    if (typeof parameters['contentType'] !== 'undefined') {
      queryParameters['content_type'] = parameters['contentType'];
    }

    queryParameters['content_type'] = this.transformParameter(
      queryParameters['content_type'],
    );

    if (typeof parameters['isDraft'] !== 'undefined') {
      queryParameters['is_draft'] = parameters['isDraft'];
    }

    queryParameters['is_draft'] = this.transformParameter(
      queryParameters['is_draft'],
    );

    if (typeof parameters['webContentLangId'] !== 'undefined') {
      queryParameters['web_content_lang_id'] = parameters['webContentLangId'];
    }

    queryParameters['web_content_lang_id'] = this.transformParameter(
      queryParameters['web_content_lang_id'],
    );

    if (typeof parameters['onlyAssigned'] !== 'undefined') {
      queryParameters['only_assigned'] = parameters['onlyAssigned'];
    }

    queryParameters['only_assigned'] = this.transformParameter(
      queryParameters['only_assigned'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Delete video
   * @method
   * @name API#deleteVideo
   */
  deleteVideo(parameters: { fileName: string }): Promise<{}> {
    let path = '/contents/video-delete';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['fileName'] !== 'undefined') {
      queryParameters['file_name'] = parameters['fileName'];
    }

    queryParameters['file_name'] = this.transformParameter(
      queryParameters['file_name'],
    );

    if (typeof parameters['fileName'] === 'undefined') {
      throw new Error('Missing required parameter: fileName');
    }

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * returns basic info about articles
   * @method
   * @name API#loadArticles
   */
  loadArticles(
    parameters: {
      limit?: number;
      offset?: number;
      sitemapId?: number;
      isPublished?: '0' | '1';
      tagId?: number;
      tagExcludeId?: number;
      sitemapUniqueId?: string;
      excludeSitemapUniqueId?: Array<string>;
      isTop?: '0' | '1';
      identificator?: string;
      sfForm?: string;
      query?: string;
      brandName?: string;
      onlyActual?: '0' | '1';
      sort?: 'date' | 'sorting' | 'created_date';
      sortDir?: 'asc' | 'desc';
      withoutStats?: '0' | '1';
      withoutJsonContent?: '0' | '1';
      replaceCurrentLangTags?: '0' | '1';
      hasProductsFromCategoryId?: number;
      sitemapOrProductCategory?: boolean;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    articles?: Array<{}>;

    total?: number;
  }> {
    let path = '/articles';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['sitemapId'] !== 'undefined') {
      queryParameters['sitemap_id'] = parameters['sitemapId'];
    }

    queryParameters['sitemap_id'] = this.transformParameter(
      queryParameters['sitemap_id'],
    );

    if (typeof parameters['isPublished'] !== 'undefined') {
      queryParameters['is_published'] = parameters['isPublished'];
    }

    queryParameters['is_published'] = this.transformParameter(
      queryParameters['is_published'],
    );

    if (typeof parameters['tagId'] !== 'undefined') {
      queryParameters['tag_id'] = parameters['tagId'];
    }

    queryParameters['tag_id'] = this.transformParameter(
      queryParameters['tag_id'],
    );

    if (typeof parameters['tagExcludeId'] !== 'undefined') {
      queryParameters['tag_exclude_id'] = parameters['tagExcludeId'];
    }

    queryParameters['tag_exclude_id'] = this.transformParameter(
      queryParameters['tag_exclude_id'],
    );

    if (typeof parameters['sitemapUniqueId'] !== 'undefined') {
      queryParameters['sitemap_unique_id'] = parameters['sitemapUniqueId'];
    }

    queryParameters['sitemap_unique_id'] = this.transformParameter(
      queryParameters['sitemap_unique_id'],
    );

    if (typeof parameters['excludeSitemapUniqueId'] !== 'undefined') {
      queryParameters['exclude_sitemap_unique_id'] =
        parameters['excludeSitemapUniqueId'];
    }

    queryParameters['exclude_sitemap_unique_id'] = this.transformParameter(
      queryParameters['exclude_sitemap_unique_id'],
    );

    if (typeof parameters['isTop'] !== 'undefined') {
      queryParameters['is_top'] = parameters['isTop'];
    }

    queryParameters['is_top'] = this.transformParameter(
      queryParameters['is_top'],
    );

    if (typeof parameters['identificator'] !== 'undefined') {
      queryParameters['identificator'] = parameters['identificator'];
    }

    queryParameters['identificator'] = this.transformParameter(
      queryParameters['identificator'],
    );

    if (typeof parameters['sfForm'] !== 'undefined') {
      queryParameters['sf_form'] = parameters['sfForm'];
    }

    queryParameters['sf_form'] = this.transformParameter(
      queryParameters['sf_form'],
    );

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    if (typeof parameters['brandName'] !== 'undefined') {
      queryParameters['brand_name'] = parameters['brandName'];
    }

    queryParameters['brand_name'] = this.transformParameter(
      queryParameters['brand_name'],
    );

    if (typeof parameters['onlyActual'] !== 'undefined') {
      queryParameters['only_actual'] = parameters['onlyActual'];
    }

    queryParameters['only_actual'] = this.transformParameter(
      queryParameters['only_actual'],
    );

    queryParameters['sort'] = 'date';

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    queryParameters['sort_dir'] = 'desc';

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    if (typeof parameters['withoutStats'] !== 'undefined') {
      queryParameters['without_stats'] = parameters['withoutStats'];
    }

    queryParameters['without_stats'] = this.transformParameter(
      queryParameters['without_stats'],
    );

    if (typeof parameters['withoutJsonContent'] !== 'undefined') {
      queryParameters['without_json_content'] =
        parameters['withoutJsonContent'];
    }

    queryParameters['without_json_content'] = this.transformParameter(
      queryParameters['without_json_content'],
    );

    if (typeof parameters['replaceCurrentLangTags'] !== 'undefined') {
      queryParameters['replace_current_lang_tags'] =
        parameters['replaceCurrentLangTags'];
    }

    queryParameters['replace_current_lang_tags'] = this.transformParameter(
      queryParameters['replace_current_lang_tags'],
    );

    if (typeof parameters['hasProductsFromCategoryId'] !== 'undefined') {
      queryParameters['has_products_from_category_id'] =
        parameters['hasProductsFromCategoryId'];
    }

    queryParameters['has_products_from_category_id'] = this.transformParameter(
      queryParameters['has_products_from_category_id'],
    );

    if (typeof parameters['sitemapOrProductCategory'] !== 'undefined') {
      queryParameters['sitemap_or_product_category'] =
        parameters['sitemapOrProductCategory'];
    }

    queryParameters['sitemap_or_product_category'] = this.transformParameter(
      queryParameters['sitemap_or_product_category'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns object with all info about article
   * @method
   * @name API#loadArticleDetail
   */
  loadArticleDetail(
    articleId: number,
    parameters: {
      withNextArticle?: '0' | '1';
      withHiddenTags?: '0' | '1';
      withLangVersions?: '0' | '1';
      productCategoryId?: string;
      replaceCurrentLangTags?: '0' | '1';
      withCanonicalDomains?: '0' | '1';
      withCanonicalUrl?: '0' | '1';
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    author?: {
      author_id?: number;

      image?: string;

      name?: string;

      url?: string;
    };

    created_date?: string;

    created_user_id?: number;

    documents?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    downloads?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    draft?: boolean;

    hint?: string;

    home?: boolean;

    id?: number;

    is_top?: boolean;

    json_content?: {};

    lang_id?: string;

    lang_version_id?: number;

    last_update?: string;

    name?: string;

    next_article?: {
      annotation?: string;

      id?: string;

      image?: string;

      name?: string;

      url?: string;
    };

    ordering?: number;

    original_id?: string;

    photogallery?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      document_description?: string;

      document_id?: number;

      document_name?: string;

      document_path?: string;

      document_type?: string;

      privatedoc?: boolean;
    }>;

    products?: Array<{
      attribs?: Array<{
        attrib_code?: string;

        attrib_grid?: number;

        attrib_id?: number;

        attrib_ismulti?: number;

        attrib_list?: number;

        attrib_name?: string;

        attrib_pbl?: number;

        attrib_sort?: number;

        attrib_type?: string;

        attrib_uid?: string;

        attrib_unit?: string;

        attrib_value?: string;

        custom_value?: string;

        group_id?: number;

        group_name?: string;

        product_id?: number;

        translations?: Array<{
          attrib_id?: number;

          attrib_name?: string;

          attrib_unit?: string;

          lang_id?: string;
        }>;

        type_id?: number;

        value_groups?: Array<{
          attrib_id?: number;

          group_id?: number;

          group_name?: string;

          group_sort?: number;
        }>;

        value_id?: number;

        values?: Array<{
          amount?: number;

          attrib_id?: number;

          attrib_pict?: string;

          attrib_value?: string;

          group_id?: number;

          istop?: boolean;

          searchfilter?: number;

          translations?: Array<{
            attrib_url?: string;

            attrib_value?: string;

            lang_id?: string;

            secondary_name?: string;

            value_id?: number;
          }>;

          value_id?: number;

          value_sort?: number;
        }>;
      }>;

      brand?: {
        brand_descr?: string;

        brand_id?: number;

        brand_image?: string;

        brand_name?: string;

        brand_provider?: string;

        brand_top?: boolean;

        brand_weburl?: string;

        catalog_id?: number;

        translations?: Array<{
          brand_descr?: string;

          brand_id?: number;

          brand_name?: string;

          brand_weburl?: string;

          lang_id?: string;
        }>;

        url?: string;
      };

      brand_id?: number;

      category_text?: string;

      created_at?: string;

      created_datetime?: string;

      description?: string;

      disable_discount?: boolean;

      disable_solo_order?: boolean;

      ean?: string;

      goods?: Array<{
        availability?: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id?: number;

            avail_name?: string;

            lang?: string;
          }>;
        };

        availability_date?: string;

        availability_id?: number;

        buy_price?: number;

        currency?: {
          id?: string;

          name?: string;
        };

        currency_id?: string;

        customer_prices?: {};

        discount_pcn?: number;

        ean?: string;

        final_price?: number;

        from_group_sale?: boolean;

        from_sale?: boolean;

        giftcard?: {
          apply_per_unit?: boolean;

          card_number?: string;

          card_status?: string;

          club_user_id?: number;

          created_date?: string;

          created_user_id?: number;

          date_used?: string;

          date_validity?: string;

          discount?: number;

          discount_currency?: string;

          freeshipping?: boolean;

          freeshippingeu?: boolean;

          id?: number;

          idx?: number;

          is_strict_discount?: boolean;

          lang?: string;

          multicard?: boolean;

          order_id?: number;

          price?: number;

          price_currency?: string;

          price_validity?: number;

          sale?: number;

          sale_count?: number;

          valid_product_ids?: Array<number>;

          valid_products_count?: number;
        };

        good_id?: number;

        group_price?: {
          oldprice?: number;

          sale_percentage?: number;

          unitprice?: number;
        };

        group_prices?: Array<{}>;

        idoklad_id?: string;

        imagePath?: string;

        internal_code?: string;

        is_on_stock?: boolean;

        karat_id?: string;

        lang?: string;

        oldprice?: number;

        on_stock_count?: number;

        order_number?: string;

        order_quantity_limit?: number;

        point_good?: boolean;

        points_amount?: number;

        price_without_vat?: number;

        price_without_vat_sale?: number;

        priceinfo?: string;

        product_id?: number;

        product_name?: string;

        product_note?: string;

        product_type?: number;

        purchaseprice?: number;

        qtyDiscounts?: {};

        sale?: boolean;

        sale_percentage?: number;

        sale_valid?: string;

        unit_price_sale?: number;

        unitprice?: number;

        unitprice_sale?: number;

        units?: string;

        valid_from?: string;

        valid_to?: string;

        vat_rate?: number;

        web_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        web_order_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        weight?: number;
      }>;

      heureka?: number;

      heureka_id?: number;

      internal_code?: number;

      is_favorite?: boolean;

      is_new?: boolean;

      is_popular?: boolean;

      is_recommended?: boolean;

      is_sale?: boolean;

      isactive?: number;

      isnew_valid?: string;

      issale_buy3_pay2?: number;

      issaleout?: boolean;

      issaleout_since?: string;

      issaleout_valid?: string;

      istop?: boolean;

      locked?: number;

      main_good?: {
        availability?: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id?: number;

            avail_name?: string;

            lang?: string;
          }>;
        };

        availability_date?: string;

        availability_id?: number;

        buy_price?: number;

        currency?: {
          id?: string;

          name?: string;
        };

        currency_id?: string;

        customer_prices?: {};

        discount_pcn?: number;

        ean?: string;

        final_price?: number;

        from_group_sale?: boolean;

        from_sale?: boolean;

        giftcard?: {
          apply_per_unit?: boolean;

          card_number?: string;

          card_status?: string;

          club_user_id?: number;

          created_date?: string;

          created_user_id?: number;

          date_used?: string;

          date_validity?: string;

          discount?: number;

          discount_currency?: string;

          freeshipping?: boolean;

          freeshippingeu?: boolean;

          id?: number;

          idx?: number;

          is_strict_discount?: boolean;

          lang?: string;

          multicard?: boolean;

          order_id?: number;

          price?: number;

          price_currency?: string;

          price_validity?: number;

          sale?: number;

          sale_count?: number;

          valid_product_ids?: Array<number>;

          valid_products_count?: number;
        };

        good_id?: number;

        group_price?: {
          oldprice?: number;

          sale_percentage?: number;

          unitprice?: number;
        };

        group_prices?: Array<{}>;

        idoklad_id?: string;

        imagePath?: string;

        internal_code?: string;

        is_on_stock?: boolean;

        karat_id?: string;

        lang?: string;

        oldprice?: number;

        on_stock_count?: number;

        order_number?: string;

        order_quantity_limit?: number;

        point_good?: boolean;

        points_amount?: number;

        price_without_vat?: number;

        price_without_vat_sale?: number;

        priceinfo?: string;

        product_id?: number;

        product_name?: string;

        product_note?: string;

        product_type?: number;

        purchaseprice?: number;

        qtyDiscounts?: {};

        sale?: boolean;

        sale_percentage?: number;

        sale_valid?: string;

        unit_price_sale?: number;

        unitprice?: number;

        unitprice_sale?: number;

        units?: string;

        valid_from?: string;

        valid_to?: string;

        vat_rate?: number;

        web_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        web_order_attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        weight?: number;
      };

      max_price?: number;

      min_delivery_priority?: number;

      min_payment_priority?: number;

      min_price?: number;

      name?: string;

      name_url_encoded?: string;

      notshow?: number;

      ordered_count?: number;

      picture?: string;

      picture_hash?: string;

      picture_media_script?: string;

      plu?: string;

      product_fts?: string;

      product_id?: number;

      product_weight?: number;

      rating?: number;

      rating_users?: number;

      slogan?: string;

      type?: {
        catalog_id?: number;

        is_hidden?: number;

        store_minqty?: string;

        type_descr?: string;

        type_id?: number;

        type_inherits?: number;

        type_name?: string;
      };

      url?: string;

      valid_from?: string;

      valid_to?: string;
    }>;

    publish?: number;

    rss?: boolean;

    sf_form?: string;

    sf_stylesheet?: string;

    sf_xmlcontent?: string;

    sitemap_item_id?: number;

    tags?: Array<{
      id?: number;

      name?: string;
    }>;

    url?: string;

    videos?: Array<{
      content_id?: number;

      created_date?: string;

      created_user?: number;

      ordering?: number;

      video_description?: string;

      video_height?: number;

      video_id?: number;

      video_name?: string;

      video_url?: string;

      video_width?: number;

      webshow?: number;
    }>;

    canonical_url?: string;

    lang_versions?: Array<{
      lang_id?: string;

      url?: string;
    }>;
  }> {
    let path = '/articles/{article_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{article_id}', articleId.toString());

    if (typeof parameters['withNextArticle'] !== 'undefined') {
      queryParameters['with_next_article'] = parameters['withNextArticle'];
    }

    queryParameters['with_next_article'] = this.transformParameter(
      queryParameters['with_next_article'],
    );

    if (typeof parameters['withHiddenTags'] !== 'undefined') {
      queryParameters['with_hidden_tags'] = parameters['withHiddenTags'];
    }

    queryParameters['with_hidden_tags'] = this.transformParameter(
      queryParameters['with_hidden_tags'],
    );

    if (typeof parameters['withLangVersions'] !== 'undefined') {
      queryParameters['with_lang_versions'] = parameters['withLangVersions'];
    }

    queryParameters['with_lang_versions'] = this.transformParameter(
      queryParameters['with_lang_versions'],
    );

    if (typeof parameters['productCategoryId'] !== 'undefined') {
      queryParameters['product_category_id'] = parameters['productCategoryId'];
    }

    queryParameters['product_category_id'] = this.transformParameter(
      queryParameters['product_category_id'],
    );

    if (typeof parameters['replaceCurrentLangTags'] !== 'undefined') {
      queryParameters['replace_current_lang_tags'] =
        parameters['replaceCurrentLangTags'];
    }

    queryParameters['replace_current_lang_tags'] = this.transformParameter(
      queryParameters['replace_current_lang_tags'],
    );

    if (typeof parameters['withCanonicalDomains'] !== 'undefined') {
      queryParameters['with_canonical_domains'] =
        parameters['withCanonicalDomains'];
    }

    queryParameters['with_canonical_domains'] = this.transformParameter(
      queryParameters['with_canonical_domains'],
    );

    if (typeof parameters['withCanonicalUrl'] !== 'undefined') {
      queryParameters['with_canonical_url'] = parameters['withCanonicalUrl'];
    }

    queryParameters['with_canonical_url'] = this.transformParameter(
      queryParameters['with_canonical_url'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>, Samo Sepesi <sepesi@bart.sk>

**Main usage**: Creates new language version of article available for translation

**DESCRIPTION**

Copy articles between two language versions. Source langugage version is determined by CONTENT_ID of article.
Language version of article is full copy of source article. Connections between language version is maintained by
lang_version_id. Endpoint is called after creation of article in admin than administrator can create copy with button.
**!!!WARNING!!! If dest. version already exists it will be first deleted than created again.**

    * @method
    * @name API#copyArticle
    */
  copyArticle(
    articleId: number,
    langId: string,
    parameters: {} = {},
    body: {
      sitemap_id: number;

      name: string;

      url: string;

      is_draft: boolean;
    },
  ): Promise<{}> {
    let path = '/articles/{article_id}/copy/{lang_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{article_id}', articleId.toString());

    path = path.replace('{lang_id}', langId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Link tag from article
   * @method
   * @name API#linkTagToArticle
   */
  linkTagToArticle(
    articleId: number,
    tagId: number,
    parameters: {} = {},
  ): Promise<{
    status?: string;
  }> {
    let path = '/articles/{article_id}/tags/{tag_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{article_id}', articleId.toString());

    path = path.replace('{tag_id}', tagId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Unlink tag from article
   * @method
   * @name API#unlinkTagFromArticle
   */
  unlinkTagFromArticle(
    articleId: number,
    tagId: number,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/articles/{article_id}/tags/{tag_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{article_id}', articleId.toString());

    path = path.replace('{tag_id}', tagId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Change or add article's author
   * @method
   * @name API#changeArticleAuthor
   */
  changeArticleAuthor(
    articleId: number,
    authorId: number,
    parameters: {} = {},
  ): Promise<{
    status?: string;
  }> {
    let path = '/articles/{article_id}/authors/{author_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{article_id}', articleId.toString());

    path = path.replace('{author_id}', authorId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Remove author from article
   * @method
   * @name API#removeAuthorFromArticle
   */
  removeAuthorFromArticle(
    articleId: number,
    authorId: number,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/articles/{article_id}/authors/{author_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{article_id}', articleId.toString());

    path = path.replace('{author_id}', authorId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Moves MULTIPLE articles from one section to another sections

**DESCRIPTION**

 Move articles from one section to another sections. Actual version DOES NOT throw any exception or warning
if moving articles between sitemaps with different language versions.

    * @method
    * @name API#moveBulk
    */
  moveBulk(
    parameters: {} = {},
    body: {
      sitemap_id: Array<number>;

      content_ids: Array<number>;
    },
  ): Promise<{}> {
    let path = '/articles/bulk/move';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * returns basic info about authors
   * @method
   * @name API#loadAuthors
   */
  loadAuthors(
    parameters: {
      limit?: number;
      offset?: number;
      query?: string;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    articles?: Array<{
      author_id?: number;

      image?: string;

      name?: string;

      url?: string;
    }>;
  }> {
    let path = '/authors';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create new author
   * @method
   * @name API#createAuthor
   */
  createAuthor(
    parameters: {} = {},
    body: {
      admin_id?: number;

      name: string;

      url: string;

      image: string;

      json_content: {};
    },
  ): Promise<{
    status?: string;
  }> {
    let path = '/authors';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Returns object with all info about author
   * @method
   * @name API#loadAuthorDetail
   */
  loadAuthorDetail(
    authorId: number,
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    admin_id?: number;

    articles?: Array<{
      author?: {
        author_id?: number;

        image?: {};

        name?: string;

        url?: string;
      };

      id?: number;

      image?: string;

      is_top?: boolean;

      name?: string;

      tags?: Array<{
        id?: number;

        name?: string;
      }>;

      url?: string;
    }>;

    author_id?: number;

    created_at?: string;

    email?: string;

    image?: {};

    json_content?: {};

    name?: string;

    phone?: string;

    total_articles?: number;

    url?: string;

    xml_content?: {};
  }> {
    let path = '/authors/{author_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{author_id}', authorId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update authors's details
   * @method
   * @name API#updateAuthor
   */
  updateAuthor(
    authorId: number,
    parameters: {} = {},
    body: {
      admin_id?: string;

      name?: string;

      url?: string;

      image?: string;

      xml_content?: {};

      json_content?: {};
    },
  ): Promise<{
    admin_id?: number;

    articles?: Array<{
      author?: {
        author_id?: number;

        image?: {};

        name?: string;

        url?: string;
      };

      id?: number;

      image?: string;

      is_top?: boolean;

      name?: string;

      tags?: Array<{
        id?: number;

        name?: string;
      }>;

      url?: string;
    }>;

    author_id?: number;

    created_at?: string;

    email?: string;

    image?: {};

    json_content?: {};

    name?: string;

    phone?: string;

    total_articles?: number;

    url?: string;

    xml_content?: {};
  }> {
    let path = '/authors/{author_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{author_id}', authorId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Delete author
   * @method
   * @name API#deleteAuthor
   */
  deleteAuthor(authorId: number, parameters: {} = {}): Promise<void> {
    let path = '/authors/{author_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{author_id}', authorId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Uploads author image
   * @method
   * @name API#uploadAuthorImage
   */
  uploadAuthorImage(
    authorId: {},
    parameters: {} = {},
    form: {
      file?: {};
    },
  ): Promise<{
    path: string;
  }> {
    let path = '/authors/{author_id}/image-upload';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{author_id}', authorId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      form,
      headers,
      queryParameters,
    );
  }
  /**
   * Returns connected products
   * @method
   * @name API#loadWebContentProductsConnections
   */
  loadWebContentProductsConnections(
    webContentId: number,
    parameters: {
      limit?: number;
      offset?: number;
      onstock?: '0' | '1';
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    products: Array<{
      name?: string;

      picture?: string;

      product_id?: number;

      type_id?: number;

      url?: string;
    }>;
  }> {
    let path = '/web_content/product_connections/{web_content_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{web_content_id}', webContentId.toString());

    if (typeof parameters['onstock'] !== 'undefined') {
      queryParameters['onstock'] = parameters['onstock'];
    }

    queryParameters['onstock'] = this.transformParameter(
      queryParameters['onstock'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns content connections
   * @method
   * @name API#loadContentConnections
   */
  loadContentConnections(
    webContentId: number,
    parameters: {
      limit?: number;
      offset?: number;
      connectionType?: string;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    total: number;

    web_content_connections: Array<{
      annot?: string;

      author?: {
        author_id?: number;

        image?: string;

        name?: string;

        url?: string;
      };

      id?: number;

      image?: string;

      is_top?: boolean;

      last_update?: string;

      name?: string;

      tags?: Array<{
        id?: number;

        name?: string;
      }>;

      url?: string;
    }>;
  }> {
    let path = '/web_content/connections/{web_content_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{web_content_id}', webContentId.toString());

    if (typeof parameters['connectionType'] !== 'undefined') {
      queryParameters['connection_type'] = parameters['connectionType'];
    }

    queryParameters['connection_type'] = this.transformParameter(
      queryParameters['connection_type'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create connection between two web contents
   * @method
   * @name API#createConnection
   */
  createConnection(
    webContentId: number,
    connectedId: number,
    connectionType: string,
    parameters: {} = {},
  ): Promise<{}> {
    let path =
      '/web_content/connections/{web_content_id}/{connected_id}/{connection_type}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{web_content_id}', webContentId.toString());

    path = path.replace('{connected_id}', connectedId.toString());

    path = path.replace('{connection_type}', connectionType.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Delete connection between two web contents
   * @method
   * @name API#deleteConnection
   */
  deleteConnection(
    webContentId: number,
    connectedId: number,
    connectionType: string,
    parameters: {} = {},
  ): Promise<{}> {
    let path =
      '/web_content/connections/{web_content_id}/{connected_id}/{connection_type}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{web_content_id}', webContentId.toString());

    path = path.replace('{connected_id}', connectedId.toString());

    path = path.replace('{connection_type}', connectionType.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * You can specify category ID to get availabilities only for products in
specific category

    * @method
    * @name API#loadAvailabilities
    */
  loadAvailabilities(
    parameters: {
      categoryId?: number;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    availabilities?: Array<{
      avail_id?: number;

      avail_uniqid?: string;

      order_available?: number;

      translations?: Array<{
        avail_id: number;

        lang: string;

        avail_name: string;
      }>;
    }>;
  }> {
    let path = '/availabilities';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['categoryId'] !== 'undefined') {
      queryParameters['category_id'] = parameters['categoryId'];
    }

    queryParameters['category_id'] = this.transformParameter(
      queryParameters['category_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns object with all info about article
   * @method
   * @name API#loadBanners
   */
  loadBanners(
    parameters: {
      sitemapId?: string;
      position?: string;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    banners: Array<{
      id: number;

      sitemap_id: number | null;

      b_position: string;

      b_name: string;

      b_description: string;

      b_type: string;

      b_path: string;

      b_url: string | null;

      b_sort: number | null;

      created: string;

      lang: string;

      device: string;
    }>;
  }> {
    let path = '/banners';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['sitemapId'] !== 'undefined') {
      queryParameters['sitemap_id'] = parameters['sitemapId'];
    }

    queryParameters['sitemap_id'] = this.transformParameter(
      queryParameters['sitemap_id'],
    );

    if (typeof parameters['position'] !== 'undefined') {
      queryParameters['position'] = parameters['position'];
    }

    queryParameters['position'] = this.transformParameter(
      queryParameters['position'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * You can specify category ID to get brands only for products in specific
category

    * @method
    * @name API#loadBrands
    */
  loadBrands(
    parameters: {
      categoryId?: string;
      productType?: string;
      sort?: string;
      sortDir?: string;
      onlyTop?: '0' | '1';
      name?: string;
      url?: string;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    brands: Array<{
      brand_id: number;

      catalog_id: number;

      brand_name: string;

      brand_descr: string;

      brand_weburl: string;

      brand_image: string | null;

      brand_provider: string;

      url: string;

      brand_top: boolean;

      filter_attribs?: Array<{
        attrib_id?: number;

        attrib_value_id?: number;
      }>;

      translations: Array<{
        brand_id: number;

        lang_id: string | null;

        brand_name: string | null;

        brand_descr: string | null;

        brand_weburl: string | null;
      }>;
    }>;
  }> {
    let path = '/brands';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['categoryId'] !== 'undefined') {
      queryParameters['category_id'] = parameters['categoryId'];
    }

    queryParameters['category_id'] = this.transformParameter(
      queryParameters['category_id'],
    );

    if (typeof parameters['productType'] !== 'undefined') {
      queryParameters['product_type'] = parameters['productType'];
    }

    queryParameters['product_type'] = this.transformParameter(
      queryParameters['product_type'],
    );

    queryParameters['sort'] = 'brand_name';

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    queryParameters['sort_dir'] = 'asc';

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    if (typeof parameters['onlyTop'] !== 'undefined') {
      queryParameters['only_top'] = parameters['onlyTop'];
    }

    queryParameters['only_top'] = this.transformParameter(
      queryParameters['only_top'],
    );

    if (typeof parameters['name'] !== 'undefined') {
      queryParameters['name'] = parameters['name'];
    }

    queryParameters['name'] = this.transformParameter(queryParameters['name']);

    if (typeof parameters['url'] !== 'undefined') {
      queryParameters['url'] = parameters['url'];
    }

    queryParameters['url'] = this.transformParameter(queryParameters['url']);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Loads brand detail object
   * @method
   * @name API#loadBrandDetail
   */
  loadBrandDetail(
    brandId: string,
    parameters: {
      withBrandFilters?: boolean;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    brand_id: number;

    catalog_id: number;

    brand_name: string;

    brand_descr: string;

    brand_weburl: string;

    brand_image: string | null;

    brand_provider: string;

    url: string;

    brand_top: boolean;

    filter_attribs?: Array<{
      attrib_id?: number;

      attrib_value_id?: number;
    }>;

    translations: Array<{
      brand_id: number;

      lang_id: string | null;

      brand_name: string | null;

      brand_descr: string | null;

      brand_weburl: string | null;
    }>;
  }> {
    let path = '/brands/{brand_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{brand_id}', brandId.toString());

    if (typeof parameters['withBrandFilters'] !== 'undefined') {
      queryParameters['with_brand_filters'] = parameters['withBrandFilters'];
    }

    queryParameters['with_brand_filters'] = this.transformParameter(
      queryParameters['with_brand_filters'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns array of category objects
   * @method
   * @name API#loadBrandCategories
   */
  loadBrandCategories(
    brandId: string,
    parameters: {
      categoryTopParentId?: number;
      limit?: number;
      offset?: number;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    catalog_category?: Array<{
      category_id: number | null;

      catalog_id: number;

      lang_id: string;

      category_name: string;

      category_descr: string;

      cms_content_id: number | null;

      webshow: number;

      category_sort: number;

      image: string | null;

      category_number: string | null;

      url: string;

      searchtype: string | null;

      layout_style: string | null;

      parent_number: string | null;

      consultant_id: string | null;

      layout_id: string | null;

      tree_left: number;

      tree_right: number;

      tree_level: number;

      weburl: string | null;

      last_update: string | null;

      istop: number;

      type: 'CLASSIC' | 'WITH_SUBCATEGORIES';

      heureka_id: string | null;

      content?: Array<{
        id: string | number;

        created_user_id: string | null;

        sitemap_item_id: string | null;

        name: string | null;

        created_date: string;

        ordering: number | null;

        sf_form: string;

        sf_stylesheet: string;

        sf_xmlcontent: string | null;

        draft: number;

        hint: string | null;

        url: string | null;

        last_update: string;

        rss: number;

        home: number;

        publish: number;

        json_content: string | null;

        documents?: Array<{
          document_id?: number;

          content_id?: number;

          document_name?: string | null;

          document_description?: string | null;

          document_path?: string | null;

          document_type?: string | null;

          created_date?: string | null;

          created_user?: number | null;

          privatedoc?: number | null;
        }>;

        downloads?: Array<{
          document_id?: number;

          content_id?: number;

          document_name?: string | null;

          document_description?: string | null;

          document_path?: string | null;

          document_type?: string | null;

          created_date?: string | null;

          created_user?: number | null;

          privatedoc?: number | null;
        }>;

        photogallery?: Array<{
          document_id?: number;

          content_id?: number;

          document_name?: string | null;

          document_description?: string | null;

          document_path?: string | null;

          document_type?: string | null;

          created_date?: string | null;

          created_user?: number | null;

          privatedoc?: number | null;
        }>;

        videos?: Array<{
          video_id?: number;

          content_id?: number;

          video_name?: string | null;

          video_description?: string | null;

          video_url?: string | null;

          ordering?: number | null;

          created_date?: string | null;

          created_user?: number | null;

          webshow?: number | null;

          video_width?: number | null;

          video_height?: number | null;
        }>;

        products?: Array<{
          availability_date: string | null;

          is_in_wishlist: boolean;

          product_id: number;

          name: string;

          slogan: string | null;

          is_sale: 0 | 1;

          issaleout: 0 | 1;

          is_new: 0 | 1;

          is_top: 0 | 1;

          plu: string | null;

          ean: string | null;

          url: string;

          description: string;

          created_at: string;

          rating: string;

          rating_users: number;

          unpacking_cost?: number;

          picture: string | null;

          brand: {};

          name_url_encoded: string;

          qtyDiscounts?: {
            discount_pcn?: number;

            qty_from?: number;

            product_id?: number;

            lang?: string;

            final_price?: number;
          };

          goods: Array<{
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            final_price_without_vat?: number | null;

            customer_discount?: number | null;

            b2b_discount?: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            sum_price?: number | null;

            sum_price_without_vat?: number | null;

            sum_unpacking_cost?: number | null;

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            discount_pcn?: number;

            color?: Array<{}>;

            length?: number;

            width?: number;

            height?: number;
          }>;

          main_good: {
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            final_price_without_vat?: number | null;

            customer_discount?: number | null;

            b2b_discount?: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            sum_price?: number | null;

            sum_price_without_vat?: number | null;

            sum_unpacking_cost?: number | null;

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            discount_pcn?: number;

            color?: Array<{}>;

            length?: number;

            width?: number;

            height?: number;
          };

          orderWithoutStock?: boolean;

          products_in_set?: Array<{
            good_id?: number;

            package_good_id?: number;

            package_quantity?: number;

            package_price?: number;

            product_id?: number;

            package_product_id?: number;

            color?: string;

            pages?: string;

            capacity?: string;
          }>;

          street?: string;

          city?: string;

          zip?: string;

          parent_categories: Array<{}>;

          assembling?: boolean;

          prelisting?: boolean;

          productPackages?: Array<{
            id?: number;

            productId?: number;

            storeQuantity?: number;

            fullQuantity?: number;

            type?: number;
          }>;

          lang_versions?: Array<{
            lang_id?: string;

            url?: string;
          }>;
        }>;

        next_article?: {
          name?: string;

          url?: string;

          image?: string;

          annotation?: string;

          id?: number;
        };

        tags?: Array<{
          tag_id: string;

          tag_name?: string;
        }>;

        lang_versions?: Array<{}>;
      }>;

      category_name_alias: string | null;

      lang_versions?: Array<{
        lang_id?: string;

        url?: string;
      }>;
    }>;
  }> {
    let path = '/brands/{brand_id}/categories';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{brand_id}', brandId.toString());

    if (typeof parameters['categoryTopParentId'] !== 'undefined') {
      queryParameters['category_top_parent_id'] =
        parameters['categoryTopParentId'];
    }

    queryParameters['category_top_parent_id'] = this.transformParameter(
      queryParameters['category_top_parent_id'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Info about general free delivery price
   * @method
   * @name API#getGeneralFreeDeliveryInfo
   */
  getGeneralFreeDeliveryInfo(
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
      xCurrency?: string;
    },
  ): Promise<{
    price?: number;

    currency?: string;
  }> {
    let path = '/cart/free-delivery';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (extraHeaders && typeof extraHeaders['xCurrency'] !== 'undefined') {
      headers.append('X-Currency', extraHeaders['xCurrency']!);
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns new empty cart
   * @method
   * @name API#createCart
   */
  createCart(
    parameters: {
      domainId?: string;
      createLocation?: string;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
      xCurrency?: string;
    },
  ): Promise<{
    id: string;
  }> {
    let path = '/cart';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (extraHeaders && typeof extraHeaders['xCurrency'] !== 'undefined') {
      headers.append('X-Currency', extraHeaders['xCurrency']!);
    }

    if (typeof parameters['domainId'] !== 'undefined') {
      queryParameters['domain_id'] = parameters['domainId'];
    }

    queryParameters['domain_id'] = this.transformParameter(
      queryParameters['domain_id'],
    );

    if (typeof parameters['createLocation'] !== 'undefined') {
      queryParameters['create_location'] = parameters['createLocation'];
    }

    queryParameters['create_location'] = this.transformParameter(
      queryParameters['create_location'],
    );

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns products, total count and price and other information about cart
   * @method
   * @name API#getCart
   */
  getCart(
    cartId: string,
    parameters: {
      vatGroups?: 0 | 1;
    } = {},
  ): Promise<{
    id: string;

    total_items_count: number;

    total_items_price: number;

    total_items_price_without_vat: number;

    total_items_price_before_giftcards: number;

    total_items_price_without_vat_before_giftcards: number;

    total_giftcards_price: number;

    total_price: number;

    total_price_without_vat?: number;

    price_rounding_value?: number;

    currency: string;

    discount: number;

    discount_save: number;

    discount_reg: number;

    assigned_points: number;

    consumed_points: number;

    items: Array<{
      count: number;

      unpacking_cost?: number;

      good: {
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        final_price_without_vat?: number | null;

        customer_discount?: number | null;

        b2b_discount?: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        sum_price?: number | null;

        sum_price_without_vat?: number | null;

        sum_unpacking_cost?: number | null;

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        discount_pcn?: number;

        color?: Array<{}>;

        length?: number;

        width?: number;

        height?: number;
      };

      product: {
        id: number;

        ean: string | null;

        name: string;

        plu: string | null;

        url: string;

        picture: string | null;

        name_url_encoded: string;

        productPackages?: Array<{
          id?: number;

          productId?: number;

          storeQuantity?: number;

          fullQuantity?: number;

          type?: number;
        }>;
      };

      idx: number;

      sum_price?: number;

      sum_price_without_vat?: number;

      sum_points: number;

      store: {
        store_id?: number;

        store_name?: string;

        store_phone?: string;

        store_email?: string;

        store_infourl?: string;

        store_priority?: number;

        store_minqty?: number;

        store_type?: 'STORE' | 'SHOP';
      };

      availability: {
        avail_id?: number;

        avail_uniqid?: string;

        order_available?: number;

        translations?: Array<{
          avail_id: number;

          lang: string;

          avail_name: string;
        }>;
      };

      product_note?: string | null;
    }>;

    giftcards: Array<{
      id: number;

      order_id: number;

      card_number: string;

      date_validity: string;

      date_validity_from: string;

      price: number;

      price_currency: string;

      price_validity: number;

      valid_products_count: number;

      discount: number;

      discount_currency: string;

      is_strict_discount: 0 | 1;

      card_status: string;

      date_used: string;

      club_user_id: number;

      created_date: string;

      created_user_id: number;

      multicard: 0 | 1;

      sale: number;

      sale_count: number;

      apply_per_unit: 0 | 1;

      lang: string;

      valid_product_ids?: Array<number>;

      count_use?: number;

      sales?: Array<{}>;
    }>;

    pointcards: Array<{
      id: number;

      name: string;

      points: number;

      discount: number;

      count: number;
    }>;

    sales: Array<{
      id: number | null;

      name: string;

      type: 'PRODUCTS' | 'CART';

      buy_count: number;

      buy_price: number;

      buy_price_currency: number;

      sale_count: number;

      sale_discount: number;

      sale_unitprice: number;

      sale_currency: string;

      sale_name: string;

      sale_choose_only: boolean;

      priority: number;

      valid_from: string | null;

      valid_to: string | null;

      members_only: boolean;

      combine_possible: boolean;

      is_strict_discount: boolean;

      transport_price: number;

      gift: string | null;

      lang: string;

      more_info_link: string;

      products: Array<{
        id_product?: number;

        id_sale?: number;

        buy?: boolean;

        sale?: boolean;

        name?: string;
      }>;
    }>;

    delivery: {
      delivery_id: string;

      delivery_name: string;

      delivery_descr: string | null;

      multistore: 0 | 1;

      delivery_sort: number | null;

      delivery_priority: number;

      min_weight: number | null;

      max_weight: number | null;

      price: {
        currency_id: string;

        price: number | null;
      };

      translations: Array<{
        lang_id: string;

        delivery_id: string;

        delivery_name: string | null;

        delivery_descr: string | null;
      }>;

      payments: Array<{
        payment_id: string;

        payment_name: string;

        payment_descr: string;

        payment_priority: number;

        payment_sort: number | null;

        price: {
          currency_id: string;

          price: number | null;
        };

        translations: Array<{
          lang_id: string;

          payment_id: string;

          payment_name: string | null;

          payment_descr: string | null;
        }>;
      }>;
    };

    lang: string;

    user_id?: number | null;

    quatro?: {
      quatro_status: string | null;

      quatro_kod: string | null;

      quatro_url: string | null;

      quatro_oz: string | null;
    };

    shop?: {
      shop_id?: number;

      shop_name?: string;

      shop_address?: {
        person?: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      shop_description?: string;

      shop_openhours?: string;

      gps_latitude?: number;

      gps_longitude?: number;

      email?: string;

      phone?: string;

      shop_type?: string;
    };

    posta?: {
      id: number;

      name: string;

      address: {
        person?: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      gps_latitude: number;

      gps_longitude: number;

      xml_details?: string;
    };

    vat_rate?: number;

    group_discount?: number;

    individual_discount?: number;

    discount_amount?: number;

    problems?: Array<{
      type?: string;

      readable?: string;

      payload?: {};

      step?: number;
    }>;

    card_number?: string;

    zasielkovna_id: string;

    dpd_id?: string;

    dhl_parcel_shop_code?: string;

    chosen_gift?: {};

    note?: string;

    vat_groups?: {
      group_value?: {
        price?: number;

        price_without_vat?: number;

        vat_rate?: number;
      };
    };

    has_known_ico?: boolean;
  }> {
    let path = '/cart/{cart_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{cart_id}', cartId.toString());

    if (typeof parameters['vatGroups'] !== 'undefined') {
      queryParameters['vat_groups'] = parameters['vatGroups'];
    }

    queryParameters['vat_groups'] = this.transformParameter(
      queryParameters['vat_groups'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Set type of delivery and payment, billing information, ...
   * @method
   * @name API#updateCart
   */
  updateCart(
    cartId: string,
    parameters: {
      vatGroups?: 0 | 1;
    } = {},
    body: {
      delivery?: {};

      payment?: {};

      delivery_address?: {
        person: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street?: string;

        street_number?: string;

        zip?: string;

        city?: string;

        country?: string;

        country_code?: string;

        country_id?: string;

        company?: string;

        delivery_address_id?: number;
      };

      billing_address: {
        person: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street?: string;

        street_number?: string;

        zip?: string;

        city?: string;

        country?: string;

        country_code?: string;

        country_id?: string;

        company?: string;

        delivery_address_id?: number;
      };

      company: {
        name: string | null;

        ico: string | null;

        dic: string | null;

        ic_dph: string | null;
      };

      billing_company: boolean;

      use_delivery_address: boolean;

      terms_accept: boolean;

      newsletter_accept: boolean;

      heureka: boolean;

      heureka_refuse?: boolean;

      note: string;

      cart_label?: string;

      step: number;

      zasielkovna_id?: string;

      dpd_id?: string;

      consumed_points: number;

      chosen_gift?: {};

      vat_payer?: boolean;
    },
  ): Promise<{
    id: string;

    total_items_count: number;

    total_items_price: number;

    total_items_price_without_vat: number;

    total_items_price_before_giftcards: number;

    total_items_price_without_vat_before_giftcards: number;

    total_giftcards_price: number;

    total_price: number;

    total_price_without_vat?: number;

    price_rounding_value?: number;

    currency: string;

    discount: number;

    discount_save: number;

    discount_reg: number;

    assigned_points: number;

    consumed_points: number;

    items: Array<{
      count: number;

      unpacking_cost?: number;

      good: {
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        final_price_without_vat?: number | null;

        customer_discount?: number | null;

        b2b_discount?: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        sum_price?: number | null;

        sum_price_without_vat?: number | null;

        sum_unpacking_cost?: number | null;

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        discount_pcn?: number;

        color?: Array<{}>;

        length?: number;

        width?: number;

        height?: number;
      };

      product: {
        id: number;

        ean: string | null;

        name: string;

        plu: string | null;

        url: string;

        picture: string | null;

        name_url_encoded: string;

        productPackages?: Array<{
          id?: number;

          productId?: number;

          storeQuantity?: number;

          fullQuantity?: number;

          type?: number;
        }>;
      };

      idx: number;

      sum_price?: number;

      sum_price_without_vat?: number;

      sum_points: number;

      store: {
        store_id?: number;

        store_name?: string;

        store_phone?: string;

        store_email?: string;

        store_infourl?: string;

        store_priority?: number;

        store_minqty?: number;

        store_type?: 'STORE' | 'SHOP';
      };

      availability: {
        avail_id?: number;

        avail_uniqid?: string;

        order_available?: number;

        translations?: Array<{
          avail_id: number;

          lang: string;

          avail_name: string;
        }>;
      };

      product_note?: string | null;
    }>;

    giftcards: Array<{
      id: number;

      order_id: number;

      card_number: string;

      date_validity: string;

      date_validity_from: string;

      price: number;

      price_currency: string;

      price_validity: number;

      valid_products_count: number;

      discount: number;

      discount_currency: string;

      is_strict_discount: 0 | 1;

      card_status: string;

      date_used: string;

      club_user_id: number;

      created_date: string;

      created_user_id: number;

      multicard: 0 | 1;

      sale: number;

      sale_count: number;

      apply_per_unit: 0 | 1;

      lang: string;

      valid_product_ids?: Array<number>;

      count_use?: number;

      sales?: Array<{}>;
    }>;

    pointcards: Array<{
      id: number;

      name: string;

      points: number;

      discount: number;

      count: number;
    }>;

    sales: Array<{
      id: number | null;

      name: string;

      type: 'PRODUCTS' | 'CART';

      buy_count: number;

      buy_price: number;

      buy_price_currency: number;

      sale_count: number;

      sale_discount: number;

      sale_unitprice: number;

      sale_currency: string;

      sale_name: string;

      sale_choose_only: boolean;

      priority: number;

      valid_from: string | null;

      valid_to: string | null;

      members_only: boolean;

      combine_possible: boolean;

      is_strict_discount: boolean;

      transport_price: number;

      gift: string | null;

      lang: string;

      more_info_link: string;

      products: Array<{
        id_product?: number;

        id_sale?: number;

        buy?: boolean;

        sale?: boolean;

        name?: string;
      }>;
    }>;

    delivery: {
      delivery_id: string;

      delivery_name: string;

      delivery_descr: string | null;

      multistore: 0 | 1;

      delivery_sort: number | null;

      delivery_priority: number;

      min_weight: number | null;

      max_weight: number | null;

      price: {
        currency_id: string;

        price: number | null;
      };

      translations: Array<{
        lang_id: string;

        delivery_id: string;

        delivery_name: string | null;

        delivery_descr: string | null;
      }>;

      payments: Array<{
        payment_id: string;

        payment_name: string;

        payment_descr: string;

        payment_priority: number;

        payment_sort: number | null;

        price: {
          currency_id: string;

          price: number | null;
        };

        translations: Array<{
          lang_id: string;

          payment_id: string;

          payment_name: string | null;

          payment_descr: string | null;
        }>;
      }>;
    };

    lang: string;

    user_id?: number | null;

    quatro?: {
      quatro_status: string | null;

      quatro_kod: string | null;

      quatro_url: string | null;

      quatro_oz: string | null;
    };

    shop?: {
      shop_id?: number;

      shop_name?: string;

      shop_address?: {
        person?: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      shop_description?: string;

      shop_openhours?: string;

      gps_latitude?: number;

      gps_longitude?: number;

      email?: string;

      phone?: string;

      shop_type?: string;
    };

    posta?: {
      id: number;

      name: string;

      address: {
        person?: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      gps_latitude: number;

      gps_longitude: number;

      xml_details?: string;
    };

    vat_rate?: number;

    group_discount?: number;

    individual_discount?: number;

    discount_amount?: number;

    problems?: Array<{
      type?: string;

      readable?: string;

      payload?: {};

      step?: number;
    }>;

    card_number?: string;

    zasielkovna_id: string;

    dpd_id?: string;

    dhl_parcel_shop_code?: string;

    chosen_gift?: {};

    note?: string;

    vat_groups?: {
      group_value?: {
        price?: number;

        price_without_vat?: number;

        vat_rate?: number;
      };
    };

    has_known_ico?: boolean;
  }> {
    let path = '/cart/{cart_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{cart_id}', cartId.toString());

    if (typeof parameters['vatGroups'] !== 'undefined') {
      queryParameters['vat_groups'] = parameters['vatGroups'];
    }

    queryParameters['vat_groups'] = this.transformParameter(
      queryParameters['vat_groups'],
    );

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Applies giftcard/pointcard/card discount on items in cart
   * @method
   * @name API#addCard
   */
  addCard(
    cartId: string,
    parameters: {
      vatGroups?: 0 | 1;
    } = {},
    body: {
      card_number?: string;

      card_type: 'GIFTCARD' | 'POINTCARD' | 'CARD';

      pointcards?: Array<{
        count: number;

        id: string;
      }>;
    },
  ): Promise<{
    id: string;

    total_items_count: number;

    total_items_price: number;

    total_items_price_without_vat: number;

    total_items_price_before_giftcards: number;

    total_items_price_without_vat_before_giftcards: number;

    total_giftcards_price: number;

    total_price: number;

    total_price_without_vat?: number;

    price_rounding_value?: number;

    currency: string;

    discount: number;

    discount_save: number;

    discount_reg: number;

    assigned_points: number;

    consumed_points: number;

    items: Array<{
      count: number;

      unpacking_cost?: number;

      good: {
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        final_price_without_vat?: number | null;

        customer_discount?: number | null;

        b2b_discount?: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        sum_price?: number | null;

        sum_price_without_vat?: number | null;

        sum_unpacking_cost?: number | null;

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        discount_pcn?: number;

        color?: Array<{}>;

        length?: number;

        width?: number;

        height?: number;
      };

      product: {
        id: number;

        ean: string | null;

        name: string;

        plu: string | null;

        url: string;

        picture: string | null;

        name_url_encoded: string;

        productPackages?: Array<{
          id?: number;

          productId?: number;

          storeQuantity?: number;

          fullQuantity?: number;

          type?: number;
        }>;
      };

      idx: number;

      sum_price?: number;

      sum_price_without_vat?: number;

      sum_points: number;

      store: {
        store_id?: number;

        store_name?: string;

        store_phone?: string;

        store_email?: string;

        store_infourl?: string;

        store_priority?: number;

        store_minqty?: number;

        store_type?: 'STORE' | 'SHOP';
      };

      availability: {
        avail_id?: number;

        avail_uniqid?: string;

        order_available?: number;

        translations?: Array<{
          avail_id: number;

          lang: string;

          avail_name: string;
        }>;
      };

      product_note?: string | null;
    }>;

    giftcards: Array<{
      id: number;

      order_id: number;

      card_number: string;

      date_validity: string;

      date_validity_from: string;

      price: number;

      price_currency: string;

      price_validity: number;

      valid_products_count: number;

      discount: number;

      discount_currency: string;

      is_strict_discount: 0 | 1;

      card_status: string;

      date_used: string;

      club_user_id: number;

      created_date: string;

      created_user_id: number;

      multicard: 0 | 1;

      sale: number;

      sale_count: number;

      apply_per_unit: 0 | 1;

      lang: string;

      valid_product_ids?: Array<number>;

      count_use?: number;

      sales?: Array<{}>;
    }>;

    pointcards: Array<{
      id: number;

      name: string;

      points: number;

      discount: number;

      count: number;
    }>;

    sales: Array<{
      id: number | null;

      name: string;

      type: 'PRODUCTS' | 'CART';

      buy_count: number;

      buy_price: number;

      buy_price_currency: number;

      sale_count: number;

      sale_discount: number;

      sale_unitprice: number;

      sale_currency: string;

      sale_name: string;

      sale_choose_only: boolean;

      priority: number;

      valid_from: string | null;

      valid_to: string | null;

      members_only: boolean;

      combine_possible: boolean;

      is_strict_discount: boolean;

      transport_price: number;

      gift: string | null;

      lang: string;

      more_info_link: string;

      products: Array<{
        id_product?: number;

        id_sale?: number;

        buy?: boolean;

        sale?: boolean;

        name?: string;
      }>;
    }>;

    delivery: {
      delivery_id: string;

      delivery_name: string;

      delivery_descr: string | null;

      multistore: 0 | 1;

      delivery_sort: number | null;

      delivery_priority: number;

      min_weight: number | null;

      max_weight: number | null;

      price: {
        currency_id: string;

        price: number | null;
      };

      translations: Array<{
        lang_id: string;

        delivery_id: string;

        delivery_name: string | null;

        delivery_descr: string | null;
      }>;

      payments: Array<{
        payment_id: string;

        payment_name: string;

        payment_descr: string;

        payment_priority: number;

        payment_sort: number | null;

        price: {
          currency_id: string;

          price: number | null;
        };

        translations: Array<{
          lang_id: string;

          payment_id: string;

          payment_name: string | null;

          payment_descr: string | null;
        }>;
      }>;
    };

    lang: string;

    user_id?: number | null;

    quatro?: {
      quatro_status: string | null;

      quatro_kod: string | null;

      quatro_url: string | null;

      quatro_oz: string | null;
    };

    shop?: {
      shop_id?: number;

      shop_name?: string;

      shop_address?: {
        person?: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      shop_description?: string;

      shop_openhours?: string;

      gps_latitude?: number;

      gps_longitude?: number;

      email?: string;

      phone?: string;

      shop_type?: string;
    };

    posta?: {
      id: number;

      name: string;

      address: {
        person?: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      gps_latitude: number;

      gps_longitude: number;

      xml_details?: string;
    };

    vat_rate?: number;

    group_discount?: number;

    individual_discount?: number;

    discount_amount?: number;

    problems?: Array<{
      type?: string;

      readable?: string;

      payload?: {};

      step?: number;
    }>;

    card_number?: string;

    zasielkovna_id: string;

    dpd_id?: string;

    dhl_parcel_shop_code?: string;

    chosen_gift?: {};

    note?: string;

    vat_groups?: {
      group_value?: {
        price?: number;

        price_without_vat?: number;

        vat_rate?: number;
      };
    };

    has_known_ico?: boolean;
  }> {
    let path = '/cart/{cart_id}/apply-card';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{cart_id}', cartId.toString());

    if (typeof parameters['vatGroups'] !== 'undefined') {
      queryParameters['vat_groups'] = parameters['vatGroups'];
    }

    queryParameters['vat_groups'] = this.transformParameter(
      queryParameters['vat_groups'],
    );

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Returns delivery and payment methods
   * @method
   * @name API#getDeliveryPaymentInfo
   */
  getDeliveryPaymentInfo(
    cartId: string,
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
      xCurrency?: string;
    },
  ): Promise<{
    delivery_id: string;

    delivery_name: string;

    delivery_descr: string | null;

    multistore: 0 | 1;

    delivery_sort: number | null;

    delivery_priority: number;

    min_weight: number | null;

    max_weight: number | null;

    price: {
      currency_id: string;

      price: number | null;
    };

    translations: Array<{
      lang_id: string;

      delivery_id: string;

      delivery_name: string | null;

      delivery_descr: string | null;
    }>;

    payments: Array<{
      payment_id: string;

      payment_name: string;

      payment_descr: string;

      payment_priority: number;

      payment_sort: number | null;

      price: {
        currency_id: string;

        price: number | null;
      };

      translations: Array<{
        lang_id: string;

        payment_id: string;

        payment_name: string | null;

        payment_descr: string | null;
      }>;
    }>;
  }> {
    let path = '/cart/{cart_id}/delivery-payment';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (extraHeaders && typeof extraHeaders['xCurrency'] !== 'undefined') {
      headers.append('X-Currency', extraHeaders['xCurrency']!);
    }

    path = path.replace('{cart_id}', cartId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Info about remaining price needed to get free delivery
   * @method
   * @name API#getFreeDeliveryInfo
   */
  getFreeDeliveryInfo(
    cartId: string,
    parameters: {} = {},
  ): Promise<{
    cart_price?: number;

    currency?: string;

    remaining_price?: number;
  }> {
    let path = '/cart/{cart_id}/free-delivery';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{cart_id}', cartId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns gifts tied to this concrete cart
   * @method
   * @name API#loadCartGifts
   */
  loadCartGifts(
    cartId: string,
    parameters: {
      sortDir?: string;
    } = {},
  ): Promise<{
    cart_gifts: Array<{
      product_id: string;

      product_name?: string;

      picture?: string;

      price_from: number;

      claimable: boolean;

      price_unlock: number;
    }>;
  }> {
    let path = '/cart/{cart_id}/gifts';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{cart_id}', cartId.toString());

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns total count of products and price of cart
   * @method
   * @name API#getCartInfo
   */
  getCartInfo(
    cartId: string,
    parameters: {} = {},
  ): Promise<{
    amount?: number;

    currency?: string;

    price?: number;

    step?: number;
  }> {
    let path = '/cart/{cart_id}/info';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{cart_id}', cartId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Remove item on given index from cart and returns updated cart
   * @method
   * @name API#deleteCartItem
   */
  deleteCartItem(
    cartId: string,
    productId: number,
    goodId: number,
    parameters: {
      isDemand?: 0 | 1;
      vatGroups?: 0 | 1;
    } = {},
  ): Promise<{
    id: string;

    total_items_count: number;

    total_items_price: number;

    total_items_price_without_vat: number;

    total_items_price_before_giftcards: number;

    total_items_price_without_vat_before_giftcards: number;

    total_giftcards_price: number;

    total_price: number;

    total_price_without_vat?: number;

    price_rounding_value?: number;

    currency: string;

    discount: number;

    discount_save: number;

    discount_reg: number;

    assigned_points: number;

    consumed_points: number;

    items: Array<{
      count: number;

      unpacking_cost?: number;

      good: {
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        final_price_without_vat?: number | null;

        customer_discount?: number | null;

        b2b_discount?: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        sum_price?: number | null;

        sum_price_without_vat?: number | null;

        sum_unpacking_cost?: number | null;

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        discount_pcn?: number;

        color?: Array<{}>;

        length?: number;

        width?: number;

        height?: number;
      };

      product: {
        id: number;

        ean: string | null;

        name: string;

        plu: string | null;

        url: string;

        picture: string | null;

        name_url_encoded: string;

        productPackages?: Array<{
          id?: number;

          productId?: number;

          storeQuantity?: number;

          fullQuantity?: number;

          type?: number;
        }>;
      };

      idx: number;

      sum_price?: number;

      sum_price_without_vat?: number;

      sum_points: number;

      store: {
        store_id?: number;

        store_name?: string;

        store_phone?: string;

        store_email?: string;

        store_infourl?: string;

        store_priority?: number;

        store_minqty?: number;

        store_type?: 'STORE' | 'SHOP';
      };

      availability: {
        avail_id?: number;

        avail_uniqid?: string;

        order_available?: number;

        translations?: Array<{
          avail_id: number;

          lang: string;

          avail_name: string;
        }>;
      };

      product_note?: string | null;
    }>;

    giftcards: Array<{
      id: number;

      order_id: number;

      card_number: string;

      date_validity: string;

      date_validity_from: string;

      price: number;

      price_currency: string;

      price_validity: number;

      valid_products_count: number;

      discount: number;

      discount_currency: string;

      is_strict_discount: 0 | 1;

      card_status: string;

      date_used: string;

      club_user_id: number;

      created_date: string;

      created_user_id: number;

      multicard: 0 | 1;

      sale: number;

      sale_count: number;

      apply_per_unit: 0 | 1;

      lang: string;

      valid_product_ids?: Array<number>;

      count_use?: number;

      sales?: Array<{}>;
    }>;

    pointcards: Array<{
      id: number;

      name: string;

      points: number;

      discount: number;

      count: number;
    }>;

    sales: Array<{
      id: number | null;

      name: string;

      type: 'PRODUCTS' | 'CART';

      buy_count: number;

      buy_price: number;

      buy_price_currency: number;

      sale_count: number;

      sale_discount: number;

      sale_unitprice: number;

      sale_currency: string;

      sale_name: string;

      sale_choose_only: boolean;

      priority: number;

      valid_from: string | null;

      valid_to: string | null;

      members_only: boolean;

      combine_possible: boolean;

      is_strict_discount: boolean;

      transport_price: number;

      gift: string | null;

      lang: string;

      more_info_link: string;

      products: Array<{
        id_product?: number;

        id_sale?: number;

        buy?: boolean;

        sale?: boolean;

        name?: string;
      }>;
    }>;

    delivery: {
      delivery_id: string;

      delivery_name: string;

      delivery_descr: string | null;

      multistore: 0 | 1;

      delivery_sort: number | null;

      delivery_priority: number;

      min_weight: number | null;

      max_weight: number | null;

      price: {
        currency_id: string;

        price: number | null;
      };

      translations: Array<{
        lang_id: string;

        delivery_id: string;

        delivery_name: string | null;

        delivery_descr: string | null;
      }>;

      payments: Array<{
        payment_id: string;

        payment_name: string;

        payment_descr: string;

        payment_priority: number;

        payment_sort: number | null;

        price: {
          currency_id: string;

          price: number | null;
        };

        translations: Array<{
          lang_id: string;

          payment_id: string;

          payment_name: string | null;

          payment_descr: string | null;
        }>;
      }>;
    };

    lang: string;

    user_id?: number | null;

    quatro?: {
      quatro_status: string | null;

      quatro_kod: string | null;

      quatro_url: string | null;

      quatro_oz: string | null;
    };

    shop?: {
      shop_id?: number;

      shop_name?: string;

      shop_address?: {
        person?: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      shop_description?: string;

      shop_openhours?: string;

      gps_latitude?: number;

      gps_longitude?: number;

      email?: string;

      phone?: string;

      shop_type?: string;
    };

    posta?: {
      id: number;

      name: string;

      address: {
        person?: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      gps_latitude: number;

      gps_longitude: number;

      xml_details?: string;
    };

    vat_rate?: number;

    group_discount?: number;

    individual_discount?: number;

    discount_amount?: number;

    problems?: Array<{
      type?: string;

      readable?: string;

      payload?: {};

      step?: number;
    }>;

    card_number?: string;

    zasielkovna_id: string;

    dpd_id?: string;

    dhl_parcel_shop_code?: string;

    chosen_gift?: {};

    note?: string;

    vat_groups?: {
      group_value?: {
        price?: number;

        price_without_vat?: number;

        vat_rate?: number;
      };
    };

    has_known_ico?: boolean;
  }> {
    let path = '/cart/{cart_id}/items/{product_id}/{good_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{cart_id}', cartId.toString());

    path = path.replace('{product_id}', productId.toString());

    path = path.replace('{good_id}', goodId.toString());

    if (typeof parameters['isDemand'] !== 'undefined') {
      queryParameters['is_demand'] = parameters['isDemand'];
    }

    queryParameters['is_demand'] = this.transformParameter(
      queryParameters['is_demand'],
    );

    if (typeof parameters['vatGroups'] !== 'undefined') {
      queryParameters['vat_groups'] = parameters['vatGroups'];
    }

    queryParameters['vat_groups'] = this.transformParameter(
      queryParameters['vat_groups'],
    );

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Add item to cart or update count
   * @method
   * @name API#addCartItem
   */
  addCartItem(
    cartId: string,
    goodId: number,
    productId: number,
    parameters: {
      count?: number;
      currencyId?: string;
      isDemand?: 0 | 1;
      vatGroups?: 0 | 1;
    } = {},
    body: {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    id: string;

    total_items_count: number;

    total_items_price: number;

    total_items_price_without_vat: number;

    total_items_price_before_giftcards: number;

    total_items_price_without_vat_before_giftcards: number;

    total_giftcards_price: number;

    total_price: number;

    total_price_without_vat?: number;

    price_rounding_value?: number;

    currency: string;

    discount: number;

    discount_save: number;

    discount_reg: number;

    assigned_points: number;

    consumed_points: number;

    items: Array<{
      count: number;

      unpacking_cost?: number;

      good: {
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        final_price_without_vat?: number | null;

        customer_discount?: number | null;

        b2b_discount?: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        sum_price?: number | null;

        sum_price_without_vat?: number | null;

        sum_unpacking_cost?: number | null;

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        discount_pcn?: number;

        color?: Array<{}>;

        length?: number;

        width?: number;

        height?: number;
      };

      product: {
        id: number;

        ean: string | null;

        name: string;

        plu: string | null;

        url: string;

        picture: string | null;

        name_url_encoded: string;

        productPackages?: Array<{
          id?: number;

          productId?: number;

          storeQuantity?: number;

          fullQuantity?: number;

          type?: number;
        }>;
      };

      idx: number;

      sum_price?: number;

      sum_price_without_vat?: number;

      sum_points: number;

      store: {
        store_id?: number;

        store_name?: string;

        store_phone?: string;

        store_email?: string;

        store_infourl?: string;

        store_priority?: number;

        store_minqty?: number;

        store_type?: 'STORE' | 'SHOP';
      };

      availability: {
        avail_id?: number;

        avail_uniqid?: string;

        order_available?: number;

        translations?: Array<{
          avail_id: number;

          lang: string;

          avail_name: string;
        }>;
      };

      product_note?: string | null;
    }>;

    giftcards: Array<{
      id: number;

      order_id: number;

      card_number: string;

      date_validity: string;

      date_validity_from: string;

      price: number;

      price_currency: string;

      price_validity: number;

      valid_products_count: number;

      discount: number;

      discount_currency: string;

      is_strict_discount: 0 | 1;

      card_status: string;

      date_used: string;

      club_user_id: number;

      created_date: string;

      created_user_id: number;

      multicard: 0 | 1;

      sale: number;

      sale_count: number;

      apply_per_unit: 0 | 1;

      lang: string;

      valid_product_ids?: Array<number>;

      count_use?: number;

      sales?: Array<{}>;
    }>;

    pointcards: Array<{
      id: number;

      name: string;

      points: number;

      discount: number;

      count: number;
    }>;

    sales: Array<{
      id: number | null;

      name: string;

      type: 'PRODUCTS' | 'CART';

      buy_count: number;

      buy_price: number;

      buy_price_currency: number;

      sale_count: number;

      sale_discount: number;

      sale_unitprice: number;

      sale_currency: string;

      sale_name: string;

      sale_choose_only: boolean;

      priority: number;

      valid_from: string | null;

      valid_to: string | null;

      members_only: boolean;

      combine_possible: boolean;

      is_strict_discount: boolean;

      transport_price: number;

      gift: string | null;

      lang: string;

      more_info_link: string;

      products: Array<{
        id_product?: number;

        id_sale?: number;

        buy?: boolean;

        sale?: boolean;

        name?: string;
      }>;
    }>;

    delivery: {
      delivery_id: string;

      delivery_name: string;

      delivery_descr: string | null;

      multistore: 0 | 1;

      delivery_sort: number | null;

      delivery_priority: number;

      min_weight: number | null;

      max_weight: number | null;

      price: {
        currency_id: string;

        price: number | null;
      };

      translations: Array<{
        lang_id: string;

        delivery_id: string;

        delivery_name: string | null;

        delivery_descr: string | null;
      }>;

      payments: Array<{
        payment_id: string;

        payment_name: string;

        payment_descr: string;

        payment_priority: number;

        payment_sort: number | null;

        price: {
          currency_id: string;

          price: number | null;
        };

        translations: Array<{
          lang_id: string;

          payment_id: string;

          payment_name: string | null;

          payment_descr: string | null;
        }>;
      }>;
    };

    lang: string;

    user_id?: number | null;

    quatro?: {
      quatro_status: string | null;

      quatro_kod: string | null;

      quatro_url: string | null;

      quatro_oz: string | null;
    };

    shop?: {
      shop_id?: number;

      shop_name?: string;

      shop_address?: {
        person?: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      shop_description?: string;

      shop_openhours?: string;

      gps_latitude?: number;

      gps_longitude?: number;

      email?: string;

      phone?: string;

      shop_type?: string;
    };

    posta?: {
      id: number;

      name: string;

      address: {
        person?: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      gps_latitude: number;

      gps_longitude: number;

      xml_details?: string;
    };

    vat_rate?: number;

    group_discount?: number;

    individual_discount?: number;

    discount_amount?: number;

    problems?: Array<{
      type?: string;

      readable?: string;

      payload?: {};

      step?: number;
    }>;

    card_number?: string;

    zasielkovna_id: string;

    dpd_id?: string;

    dhl_parcel_shop_code?: string;

    chosen_gift?: {};

    note?: string;

    vat_groups?: {
      group_value?: {
        price?: number;

        price_without_vat?: number;

        vat_rate?: number;
      };
    };

    has_known_ico?: boolean;
  }> {
    let path = '/cart/{cart_id}/items/{product_id}/{good_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{cart_id}', cartId.toString());

    path = path.replace('{good_id}', goodId.toString());

    path = path.replace('{product_id}', productId.toString());

    if (typeof parameters['count'] !== 'undefined') {
      queryParameters['count'] = parameters['count'];
    }

    queryParameters['count'] = this.transformParameter(
      queryParameters['count'],
    );

    if (typeof parameters['currencyId'] !== 'undefined') {
      queryParameters['currency_id'] = parameters['currencyId'];
    }

    queryParameters['currency_id'] = this.transformParameter(
      queryParameters['currency_id'],
    );

    if (typeof parameters['isDemand'] !== 'undefined') {
      queryParameters['is_demand'] = parameters['isDemand'];
    }

    queryParameters['is_demand'] = this.transformParameter(
      queryParameters['is_demand'],
    );

    if (typeof parameters['vatGroups'] !== 'undefined') {
      queryParameters['vat_groups'] = parameters['vatGroups'];
    }

    queryParameters['vat_groups'] = this.transformParameter(
      queryParameters['vat_groups'],
    );

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Set item in cart free if possible
   * @method
   * @name API#setCartItemFree
   */
  setCartItemFree(
    cartId: string,
    goodId: number,
    productId: number,
    parameters: {
      vatGroups?: 0 | 1;
      currencyId?: string;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    id: string;

    total_items_count: number;

    total_items_price: number;

    total_items_price_without_vat: number;

    total_items_price_before_giftcards: number;

    total_items_price_without_vat_before_giftcards: number;

    total_giftcards_price: number;

    total_price: number;

    total_price_without_vat?: number;

    price_rounding_value?: number;

    currency: string;

    discount: number;

    discount_save: number;

    discount_reg: number;

    assigned_points: number;

    consumed_points: number;

    items: Array<{
      count: number;

      unpacking_cost?: number;

      good: {
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        final_price_without_vat?: number | null;

        customer_discount?: number | null;

        b2b_discount?: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        sum_price?: number | null;

        sum_price_without_vat?: number | null;

        sum_unpacking_cost?: number | null;

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        discount_pcn?: number;

        color?: Array<{}>;

        length?: number;

        width?: number;

        height?: number;
      };

      product: {
        id: number;

        ean: string | null;

        name: string;

        plu: string | null;

        url: string;

        picture: string | null;

        name_url_encoded: string;

        productPackages?: Array<{
          id?: number;

          productId?: number;

          storeQuantity?: number;

          fullQuantity?: number;

          type?: number;
        }>;
      };

      idx: number;

      sum_price?: number;

      sum_price_without_vat?: number;

      sum_points: number;

      store: {
        store_id?: number;

        store_name?: string;

        store_phone?: string;

        store_email?: string;

        store_infourl?: string;

        store_priority?: number;

        store_minqty?: number;

        store_type?: 'STORE' | 'SHOP';
      };

      availability: {
        avail_id?: number;

        avail_uniqid?: string;

        order_available?: number;

        translations?: Array<{
          avail_id: number;

          lang: string;

          avail_name: string;
        }>;
      };

      product_note?: string | null;
    }>;

    giftcards: Array<{
      id: number;

      order_id: number;

      card_number: string;

      date_validity: string;

      date_validity_from: string;

      price: number;

      price_currency: string;

      price_validity: number;

      valid_products_count: number;

      discount: number;

      discount_currency: string;

      is_strict_discount: 0 | 1;

      card_status: string;

      date_used: string;

      club_user_id: number;

      created_date: string;

      created_user_id: number;

      multicard: 0 | 1;

      sale: number;

      sale_count: number;

      apply_per_unit: 0 | 1;

      lang: string;

      valid_product_ids?: Array<number>;

      count_use?: number;

      sales?: Array<{}>;
    }>;

    pointcards: Array<{
      id: number;

      name: string;

      points: number;

      discount: number;

      count: number;
    }>;

    sales: Array<{
      id: number | null;

      name: string;

      type: 'PRODUCTS' | 'CART';

      buy_count: number;

      buy_price: number;

      buy_price_currency: number;

      sale_count: number;

      sale_discount: number;

      sale_unitprice: number;

      sale_currency: string;

      sale_name: string;

      sale_choose_only: boolean;

      priority: number;

      valid_from: string | null;

      valid_to: string | null;

      members_only: boolean;

      combine_possible: boolean;

      is_strict_discount: boolean;

      transport_price: number;

      gift: string | null;

      lang: string;

      more_info_link: string;

      products: Array<{
        id_product?: number;

        id_sale?: number;

        buy?: boolean;

        sale?: boolean;

        name?: string;
      }>;
    }>;

    delivery: {
      delivery_id: string;

      delivery_name: string;

      delivery_descr: string | null;

      multistore: 0 | 1;

      delivery_sort: number | null;

      delivery_priority: number;

      min_weight: number | null;

      max_weight: number | null;

      price: {
        currency_id: string;

        price: number | null;
      };

      translations: Array<{
        lang_id: string;

        delivery_id: string;

        delivery_name: string | null;

        delivery_descr: string | null;
      }>;

      payments: Array<{
        payment_id: string;

        payment_name: string;

        payment_descr: string;

        payment_priority: number;

        payment_sort: number | null;

        price: {
          currency_id: string;

          price: number | null;
        };

        translations: Array<{
          lang_id: string;

          payment_id: string;

          payment_name: string | null;

          payment_descr: string | null;
        }>;
      }>;
    };

    lang: string;

    user_id?: number | null;

    quatro?: {
      quatro_status: string | null;

      quatro_kod: string | null;

      quatro_url: string | null;

      quatro_oz: string | null;
    };

    shop?: {
      shop_id?: number;

      shop_name?: string;

      shop_address?: {
        person?: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      shop_description?: string;

      shop_openhours?: string;

      gps_latitude?: number;

      gps_longitude?: number;

      email?: string;

      phone?: string;

      shop_type?: string;
    };

    posta?: {
      id: number;

      name: string;

      address: {
        person?: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      gps_latitude: number;

      gps_longitude: number;

      xml_details?: string;
    };

    vat_rate?: number;

    group_discount?: number;

    individual_discount?: number;

    discount_amount?: number;

    problems?: Array<{
      type?: string;

      readable?: string;

      payload?: {};

      step?: number;
    }>;

    card_number?: string;

    zasielkovna_id: string;

    dpd_id?: string;

    dhl_parcel_shop_code?: string;

    chosen_gift?: {};

    note?: string;

    vat_groups?: {
      group_value?: {
        price?: number;

        price_without_vat?: number;

        vat_rate?: number;
      };
    };

    has_known_ico?: boolean;
  }> {
    let path = '/cart/{cart_id}/items/{product_id}/{good_id}/setfree';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{cart_id}', cartId.toString());

    path = path.replace('{good_id}', goodId.toString());

    path = path.replace('{product_id}', productId.toString());

    if (typeof parameters['vatGroups'] !== 'undefined') {
      queryParameters['vat_groups'] = parameters['vatGroups'];
    }

    queryParameters['vat_groups'] = this.transformParameter(
      queryParameters['vat_groups'],
    );

    if (typeof parameters['currencyId'] !== 'undefined') {
      queryParameters['currency_id'] = parameters['currencyId'];
    }

    queryParameters['currency_id'] = this.transformParameter(
      queryParameters['currency_id'],
    );

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Set custom note for cart
   * @method
   * @name API#addNote
   */
  addNote(
    cartId: string,
    parameters: {
      vatGroups?: 0 | 1;
    } = {},
    body: {
      note: string;
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    id: string;

    total_items_count: number;

    total_items_price: number;

    total_items_price_without_vat: number;

    total_items_price_before_giftcards: number;

    total_items_price_without_vat_before_giftcards: number;

    total_giftcards_price: number;

    total_price: number;

    total_price_without_vat?: number;

    price_rounding_value?: number;

    currency: string;

    discount: number;

    discount_save: number;

    discount_reg: number;

    assigned_points: number;

    consumed_points: number;

    items: Array<{
      count: number;

      unpacking_cost?: number;

      good: {
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        final_price_without_vat?: number | null;

        customer_discount?: number | null;

        b2b_discount?: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        sum_price?: number | null;

        sum_price_without_vat?: number | null;

        sum_unpacking_cost?: number | null;

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        discount_pcn?: number;

        color?: Array<{}>;

        length?: number;

        width?: number;

        height?: number;
      };

      product: {
        id: number;

        ean: string | null;

        name: string;

        plu: string | null;

        url: string;

        picture: string | null;

        name_url_encoded: string;

        productPackages?: Array<{
          id?: number;

          productId?: number;

          storeQuantity?: number;

          fullQuantity?: number;

          type?: number;
        }>;
      };

      idx: number;

      sum_price?: number;

      sum_price_without_vat?: number;

      sum_points: number;

      store: {
        store_id?: number;

        store_name?: string;

        store_phone?: string;

        store_email?: string;

        store_infourl?: string;

        store_priority?: number;

        store_minqty?: number;

        store_type?: 'STORE' | 'SHOP';
      };

      availability: {
        avail_id?: number;

        avail_uniqid?: string;

        order_available?: number;

        translations?: Array<{
          avail_id: number;

          lang: string;

          avail_name: string;
        }>;
      };

      product_note?: string | null;
    }>;

    giftcards: Array<{
      id: number;

      order_id: number;

      card_number: string;

      date_validity: string;

      date_validity_from: string;

      price: number;

      price_currency: string;

      price_validity: number;

      valid_products_count: number;

      discount: number;

      discount_currency: string;

      is_strict_discount: 0 | 1;

      card_status: string;

      date_used: string;

      club_user_id: number;

      created_date: string;

      created_user_id: number;

      multicard: 0 | 1;

      sale: number;

      sale_count: number;

      apply_per_unit: 0 | 1;

      lang: string;

      valid_product_ids?: Array<number>;

      count_use?: number;

      sales?: Array<{}>;
    }>;

    pointcards: Array<{
      id: number;

      name: string;

      points: number;

      discount: number;

      count: number;
    }>;

    sales: Array<{
      id: number | null;

      name: string;

      type: 'PRODUCTS' | 'CART';

      buy_count: number;

      buy_price: number;

      buy_price_currency: number;

      sale_count: number;

      sale_discount: number;

      sale_unitprice: number;

      sale_currency: string;

      sale_name: string;

      sale_choose_only: boolean;

      priority: number;

      valid_from: string | null;

      valid_to: string | null;

      members_only: boolean;

      combine_possible: boolean;

      is_strict_discount: boolean;

      transport_price: number;

      gift: string | null;

      lang: string;

      more_info_link: string;

      products: Array<{
        id_product?: number;

        id_sale?: number;

        buy?: boolean;

        sale?: boolean;

        name?: string;
      }>;
    }>;

    delivery: {
      delivery_id: string;

      delivery_name: string;

      delivery_descr: string | null;

      multistore: 0 | 1;

      delivery_sort: number | null;

      delivery_priority: number;

      min_weight: number | null;

      max_weight: number | null;

      price: {
        currency_id: string;

        price: number | null;
      };

      translations: Array<{
        lang_id: string;

        delivery_id: string;

        delivery_name: string | null;

        delivery_descr: string | null;
      }>;

      payments: Array<{
        payment_id: string;

        payment_name: string;

        payment_descr: string;

        payment_priority: number;

        payment_sort: number | null;

        price: {
          currency_id: string;

          price: number | null;
        };

        translations: Array<{
          lang_id: string;

          payment_id: string;

          payment_name: string | null;

          payment_descr: string | null;
        }>;
      }>;
    };

    lang: string;

    user_id?: number | null;

    quatro?: {
      quatro_status: string | null;

      quatro_kod: string | null;

      quatro_url: string | null;

      quatro_oz: string | null;
    };

    shop?: {
      shop_id?: number;

      shop_name?: string;

      shop_address?: {
        person?: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      shop_description?: string;

      shop_openhours?: string;

      gps_latitude?: number;

      gps_longitude?: number;

      email?: string;

      phone?: string;

      shop_type?: string;
    };

    posta?: {
      id: number;

      name: string;

      address: {
        person?: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      gps_latitude: number;

      gps_longitude: number;

      xml_details?: string;
    };

    vat_rate?: number;

    group_discount?: number;

    individual_discount?: number;

    discount_amount?: number;

    problems?: Array<{
      type?: string;

      readable?: string;

      payload?: {};

      step?: number;
    }>;

    card_number?: string;

    zasielkovna_id: string;

    dpd_id?: string;

    dhl_parcel_shop_code?: string;

    chosen_gift?: {};

    note?: string;

    vat_groups?: {
      group_value?: {
        price?: number;

        price_without_vat?: number;

        vat_rate?: number;
      };
    };

    has_known_ico?: boolean;
  }> {
    let path = '/cart/{cart_id}/note';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{cart_id}', cartId.toString());

    if (typeof parameters['vatGroups'] !== 'undefined') {
      queryParameters['vat_groups'] = parameters['vatGroups'];
    }

    queryParameters['vat_groups'] = this.transformParameter(
      queryParameters['vat_groups'],
    );

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Set custom note for given item in cart
   * @method
   * @name API#addItemNote
   */
  addItemNote(
    cartId: string,
    goodId: number,
    parameters: {
      vatGroups?: 0 | 1;
    } = {},
    body: {
      note: string;
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    id: string;

    total_items_count: number;

    total_items_price: number;

    total_items_price_without_vat: number;

    total_items_price_before_giftcards: number;

    total_items_price_without_vat_before_giftcards: number;

    total_giftcards_price: number;

    total_price: number;

    total_price_without_vat?: number;

    price_rounding_value?: number;

    currency: string;

    discount: number;

    discount_save: number;

    discount_reg: number;

    assigned_points: number;

    consumed_points: number;

    items: Array<{
      count: number;

      unpacking_cost?: number;

      good: {
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        final_price_without_vat?: number | null;

        customer_discount?: number | null;

        b2b_discount?: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        sum_price?: number | null;

        sum_price_without_vat?: number | null;

        sum_unpacking_cost?: number | null;

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        discount_pcn?: number;

        color?: Array<{}>;

        length?: number;

        width?: number;

        height?: number;
      };

      product: {
        id: number;

        ean: string | null;

        name: string;

        plu: string | null;

        url: string;

        picture: string | null;

        name_url_encoded: string;

        productPackages?: Array<{
          id?: number;

          productId?: number;

          storeQuantity?: number;

          fullQuantity?: number;

          type?: number;
        }>;
      };

      idx: number;

      sum_price?: number;

      sum_price_without_vat?: number;

      sum_points: number;

      store: {
        store_id?: number;

        store_name?: string;

        store_phone?: string;

        store_email?: string;

        store_infourl?: string;

        store_priority?: number;

        store_minqty?: number;

        store_type?: 'STORE' | 'SHOP';
      };

      availability: {
        avail_id?: number;

        avail_uniqid?: string;

        order_available?: number;

        translations?: Array<{
          avail_id: number;

          lang: string;

          avail_name: string;
        }>;
      };

      product_note?: string | null;
    }>;

    giftcards: Array<{
      id: number;

      order_id: number;

      card_number: string;

      date_validity: string;

      date_validity_from: string;

      price: number;

      price_currency: string;

      price_validity: number;

      valid_products_count: number;

      discount: number;

      discount_currency: string;

      is_strict_discount: 0 | 1;

      card_status: string;

      date_used: string;

      club_user_id: number;

      created_date: string;

      created_user_id: number;

      multicard: 0 | 1;

      sale: number;

      sale_count: number;

      apply_per_unit: 0 | 1;

      lang: string;

      valid_product_ids?: Array<number>;

      count_use?: number;

      sales?: Array<{}>;
    }>;

    pointcards: Array<{
      id: number;

      name: string;

      points: number;

      discount: number;

      count: number;
    }>;

    sales: Array<{
      id: number | null;

      name: string;

      type: 'PRODUCTS' | 'CART';

      buy_count: number;

      buy_price: number;

      buy_price_currency: number;

      sale_count: number;

      sale_discount: number;

      sale_unitprice: number;

      sale_currency: string;

      sale_name: string;

      sale_choose_only: boolean;

      priority: number;

      valid_from: string | null;

      valid_to: string | null;

      members_only: boolean;

      combine_possible: boolean;

      is_strict_discount: boolean;

      transport_price: number;

      gift: string | null;

      lang: string;

      more_info_link: string;

      products: Array<{
        id_product?: number;

        id_sale?: number;

        buy?: boolean;

        sale?: boolean;

        name?: string;
      }>;
    }>;

    delivery: {
      delivery_id: string;

      delivery_name: string;

      delivery_descr: string | null;

      multistore: 0 | 1;

      delivery_sort: number | null;

      delivery_priority: number;

      min_weight: number | null;

      max_weight: number | null;

      price: {
        currency_id: string;

        price: number | null;
      };

      translations: Array<{
        lang_id: string;

        delivery_id: string;

        delivery_name: string | null;

        delivery_descr: string | null;
      }>;

      payments: Array<{
        payment_id: string;

        payment_name: string;

        payment_descr: string;

        payment_priority: number;

        payment_sort: number | null;

        price: {
          currency_id: string;

          price: number | null;
        };

        translations: Array<{
          lang_id: string;

          payment_id: string;

          payment_name: string | null;

          payment_descr: string | null;
        }>;
      }>;
    };

    lang: string;

    user_id?: number | null;

    quatro?: {
      quatro_status: string | null;

      quatro_kod: string | null;

      quatro_url: string | null;

      quatro_oz: string | null;
    };

    shop?: {
      shop_id?: number;

      shop_name?: string;

      shop_address?: {
        person?: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      shop_description?: string;

      shop_openhours?: string;

      gps_latitude?: number;

      gps_longitude?: number;

      email?: string;

      phone?: string;

      shop_type?: string;
    };

    posta?: {
      id: number;

      name: string;

      address: {
        person?: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      gps_latitude: number;

      gps_longitude: number;

      xml_details?: string;
    };

    vat_rate?: number;

    group_discount?: number;

    individual_discount?: number;

    discount_amount?: number;

    problems?: Array<{
      type?: string;

      readable?: string;

      payload?: {};

      step?: number;
    }>;

    card_number?: string;

    zasielkovna_id: string;

    dpd_id?: string;

    dhl_parcel_shop_code?: string;

    chosen_gift?: {};

    note?: string;

    vat_groups?: {
      group_value?: {
        price?: number;

        price_without_vat?: number;

        vat_rate?: number;
      };
    };

    has_known_ico?: boolean;
  }> {
    let path = '/cart/{cart_id}/note/{good_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{cart_id}', cartId.toString());

    path = path.replace('{good_id}', goodId.toString());

    if (typeof parameters['vatGroups'] !== 'undefined') {
      queryParameters['vat_groups'] = parameters['vatGroups'];
    }

    queryParameters['vat_groups'] = this.transformParameter(
      queryParameters['vat_groups'],
    );

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Remove giftcard/pointcard/card from cart
   * @method
   * @name API#removeCard
   */
  removeCard(
    cartId: string,
    cardType: 'giftcard' | 'pointcard' | 'card',
    cardNumber: string,
    parameters: {
      vatGroups?: 0 | 1;
    } = {},
  ): Promise<{
    id: string;

    total_items_count: number;

    total_items_price: number;

    total_items_price_without_vat: number;

    total_items_price_before_giftcards: number;

    total_items_price_without_vat_before_giftcards: number;

    total_giftcards_price: number;

    total_price: number;

    total_price_without_vat?: number;

    price_rounding_value?: number;

    currency: string;

    discount: number;

    discount_save: number;

    discount_reg: number;

    assigned_points: number;

    consumed_points: number;

    items: Array<{
      count: number;

      unpacking_cost?: number;

      good: {
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        final_price_without_vat?: number | null;

        customer_discount?: number | null;

        b2b_discount?: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        sum_price?: number | null;

        sum_price_without_vat?: number | null;

        sum_unpacking_cost?: number | null;

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        discount_pcn?: number;

        color?: Array<{}>;

        length?: number;

        width?: number;

        height?: number;
      };

      product: {
        id: number;

        ean: string | null;

        name: string;

        plu: string | null;

        url: string;

        picture: string | null;

        name_url_encoded: string;

        productPackages?: Array<{
          id?: number;

          productId?: number;

          storeQuantity?: number;

          fullQuantity?: number;

          type?: number;
        }>;
      };

      idx: number;

      sum_price?: number;

      sum_price_without_vat?: number;

      sum_points: number;

      store: {
        store_id?: number;

        store_name?: string;

        store_phone?: string;

        store_email?: string;

        store_infourl?: string;

        store_priority?: number;

        store_minqty?: number;

        store_type?: 'STORE' | 'SHOP';
      };

      availability: {
        avail_id?: number;

        avail_uniqid?: string;

        order_available?: number;

        translations?: Array<{
          avail_id: number;

          lang: string;

          avail_name: string;
        }>;
      };

      product_note?: string | null;
    }>;

    giftcards: Array<{
      id: number;

      order_id: number;

      card_number: string;

      date_validity: string;

      date_validity_from: string;

      price: number;

      price_currency: string;

      price_validity: number;

      valid_products_count: number;

      discount: number;

      discount_currency: string;

      is_strict_discount: 0 | 1;

      card_status: string;

      date_used: string;

      club_user_id: number;

      created_date: string;

      created_user_id: number;

      multicard: 0 | 1;

      sale: number;

      sale_count: number;

      apply_per_unit: 0 | 1;

      lang: string;

      valid_product_ids?: Array<number>;

      count_use?: number;

      sales?: Array<{}>;
    }>;

    pointcards: Array<{
      id: number;

      name: string;

      points: number;

      discount: number;

      count: number;
    }>;

    sales: Array<{
      id: number | null;

      name: string;

      type: 'PRODUCTS' | 'CART';

      buy_count: number;

      buy_price: number;

      buy_price_currency: number;

      sale_count: number;

      sale_discount: number;

      sale_unitprice: number;

      sale_currency: string;

      sale_name: string;

      sale_choose_only: boolean;

      priority: number;

      valid_from: string | null;

      valid_to: string | null;

      members_only: boolean;

      combine_possible: boolean;

      is_strict_discount: boolean;

      transport_price: number;

      gift: string | null;

      lang: string;

      more_info_link: string;

      products: Array<{
        id_product?: number;

        id_sale?: number;

        buy?: boolean;

        sale?: boolean;

        name?: string;
      }>;
    }>;

    delivery: {
      delivery_id: string;

      delivery_name: string;

      delivery_descr: string | null;

      multistore: 0 | 1;

      delivery_sort: number | null;

      delivery_priority: number;

      min_weight: number | null;

      max_weight: number | null;

      price: {
        currency_id: string;

        price: number | null;
      };

      translations: Array<{
        lang_id: string;

        delivery_id: string;

        delivery_name: string | null;

        delivery_descr: string | null;
      }>;

      payments: Array<{
        payment_id: string;

        payment_name: string;

        payment_descr: string;

        payment_priority: number;

        payment_sort: number | null;

        price: {
          currency_id: string;

          price: number | null;
        };

        translations: Array<{
          lang_id: string;

          payment_id: string;

          payment_name: string | null;

          payment_descr: string | null;
        }>;
      }>;
    };

    lang: string;

    user_id?: number | null;

    quatro?: {
      quatro_status: string | null;

      quatro_kod: string | null;

      quatro_url: string | null;

      quatro_oz: string | null;
    };

    shop?: {
      shop_id?: number;

      shop_name?: string;

      shop_address?: {
        person?: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      shop_description?: string;

      shop_openhours?: string;

      gps_latitude?: number;

      gps_longitude?: number;

      email?: string;

      phone?: string;

      shop_type?: string;
    };

    posta?: {
      id: number;

      name: string;

      address: {
        person?: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      gps_latitude: number;

      gps_longitude: number;

      xml_details?: string;
    };

    vat_rate?: number;

    group_discount?: number;

    individual_discount?: number;

    discount_amount?: number;

    problems?: Array<{
      type?: string;

      readable?: string;

      payload?: {};

      step?: number;
    }>;

    card_number?: string;

    zasielkovna_id: string;

    dpd_id?: string;

    dhl_parcel_shop_code?: string;

    chosen_gift?: {};

    note?: string;

    vat_groups?: {
      group_value?: {
        price?: number;

        price_without_vat?: number;

        vat_rate?: number;
      };
    };

    has_known_ico?: boolean;
  }> {
    let path = '/cart/{cart_id}/remove-card/{card_type}/{card_number}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{cart_id}', cartId.toString());

    path = path.replace('{card_type}', cardType.toString());

    path = path.replace('{card_number}', cardNumber.toString());

    if (typeof parameters['vatGroups'] !== 'undefined') {
      queryParameters['vat_groups'] = parameters['vatGroups'];
    }

    queryParameters['vat_groups'] = this.transformParameter(
      queryParameters['vat_groups'],
    );

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * returns import id if successful
   * @method
   * @name API#importCart
   */
  importCart(
    parameters: {} = {},
    form: {
      file: {};
    },
  ): Promise<{
    importId: string;
  }> {
    let path = '/cart/import';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      form,
      headers,
      queryParameters,
    );
  }
  /**
   * returns current import cart status
   * @method
   * @name API#loadCartImportStatus
   */
  loadCartImportStatus(
    importId: string,
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
      xCurrency?: string;
    },
  ): Promise<{
    importId: number;

    importStatus: string;

    cartId?: string;

    notImported?: Array<{}>;
  }> {
    let path = '/cart/import/{import_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (extraHeaders && typeof extraHeaders['xCurrency'] !== 'undefined') {
      headers.append('X-Currency', extraHeaders['xCurrency']!);
    }

    path = path.replace('{import_id}', importId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * change import status
   * @method
   * @name API#changeImportStatus
   */
  changeImportStatus(
    importId: string,
    parameters: {} = {},
    body: {
      status: string;
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
      xCurrency?: string;
    },
  ): Promise<{
    importId?: number;

    importStatus?: string;

    cartId?: string;
  }> {
    let path = '/cart/import/{import_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (extraHeaders && typeof extraHeaders['xCurrency'] !== 'undefined') {
      headers.append('X-Currency', extraHeaders['xCurrency']!);
    }

    path = path.replace('{import_id}', importId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * returns zoneId by zip
   * @method
   * @name API#findZoneByZipCode
   */
  findZoneByZipCode(parameters: { zip: string }): Promise<{
    zoneId: number;
  }> {
    let path = '/cart/zone';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['zip'] !== 'undefined') {
      queryParameters['zip'] = parameters['zip'];
    }

    queryParameters['zip'] = this.transformParameter(queryParameters['zip']);

    if (typeof parameters['zip'] === 'undefined') {
      throw new Error('Missing required parameter: zip');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns array of category objects
   * @method
   * @name API#loadCategories
   */
  loadCategories(
    parameters: {
      showTop?: '0' | '1';
      withContent?: '0' | '1';
      webShow?: '0' | '1';
      q?: string;
      isFavorite?: '0' | '1';
      categoryTopParentId?: string;
      withPublish?: '0' | '1';
      publishLang?: string;
      hasNoRedirect?: '0' | '1';
      publishUrl?: string;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{}> {
    let path = '/categories';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['showTop'] !== 'undefined') {
      queryParameters['show_top'] = parameters['showTop'];
    }

    queryParameters['show_top'] = this.transformParameter(
      queryParameters['show_top'],
    );

    if (typeof parameters['withContent'] !== 'undefined') {
      queryParameters['with_content'] = parameters['withContent'];
    }

    queryParameters['with_content'] = this.transformParameter(
      queryParameters['with_content'],
    );

    queryParameters['web_show'] = '1';

    if (typeof parameters['webShow'] !== 'undefined') {
      queryParameters['web_show'] = parameters['webShow'];
    }

    queryParameters['web_show'] = this.transformParameter(
      queryParameters['web_show'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['isFavorite'] !== 'undefined') {
      queryParameters['is_favorite'] = parameters['isFavorite'];
    }

    queryParameters['is_favorite'] = this.transformParameter(
      queryParameters['is_favorite'],
    );

    if (typeof parameters['categoryTopParentId'] !== 'undefined') {
      queryParameters['category_top_parent_id'] =
        parameters['categoryTopParentId'];
    }

    queryParameters['category_top_parent_id'] = this.transformParameter(
      queryParameters['category_top_parent_id'],
    );

    if (typeof parameters['withPublish'] !== 'undefined') {
      queryParameters['with_publish'] = parameters['withPublish'];
    }

    queryParameters['with_publish'] = this.transformParameter(
      queryParameters['with_publish'],
    );

    if (typeof parameters['publishLang'] !== 'undefined') {
      queryParameters['publish_lang'] = parameters['publishLang'];
    }

    queryParameters['publish_lang'] = this.transformParameter(
      queryParameters['publish_lang'],
    );

    if (typeof parameters['hasNoRedirect'] !== 'undefined') {
      queryParameters['has_no_redirect'] = parameters['hasNoRedirect'];
    }

    queryParameters['has_no_redirect'] = this.transformParameter(
      queryParameters['has_no_redirect'],
    );

    if (typeof parameters['publishUrl'] !== 'undefined') {
      queryParameters['publish_url'] = parameters['publishUrl'];
    }

    queryParameters['publish_url'] = this.transformParameter(
      queryParameters['publish_url'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns full category tree as an object
   * @method
   * @name API#loadTree
   */
  loadTree(
    parameters: {
      totalCounts?: '0' | '1';
      onlyVisible?: '0' | '1';
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<
    Array<{
      searchtype: string;

      category_name: string;

      category_descr: string;

      url: string;

      weburl: string;

      heureka_id: string | null;

      image: string | null;

      category_id: number;

      counts?: number;

      attribs?: Array<{
        attrib_id: number;

        type_id: number;

        attrib_uid: string | null;

        attrib_type: string | null;

        group_id: number | null;

        attrib_name: string;

        attrib_ismulti: number;

        attrib_sort: number;

        attrib_pbl: number;

        attrib_unit: string;

        attrib_list: number;

        attrib_code: string | null;

        attrib_grid: number;

        min?: number;

        max?: number;

        translations: Array<{
          attrib_id: number;

          lang_id: string;

          attrib_name: string;

          attrib_unit: string | null;
        }>;

        values: Array<{
          amount: number;

          value_id: number;

          attrib_id: number;

          attrib_value: string;

          attrib_pict: string | null;

          group_id: number | null;

          istop: boolean;

          searchfilter: number;

          value_sort: number | null;

          translations: Array<{
            value_id: number;

            lang_id: string;

            attrib_value: string;

            attrib_link: string;
          }>;
        }>;
      }>;

      children: Array<{}>;

      publish?: Array<{}>;
    }>
  > {
    let path = '/categories/tree';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    queryParameters['total_counts'] = '1';

    if (typeof parameters['totalCounts'] !== 'undefined') {
      queryParameters['total_counts'] = parameters['totalCounts'];
    }

    queryParameters['total_counts'] = this.transformParameter(
      queryParameters['total_counts'],
    );

    if (typeof parameters['onlyVisible'] !== 'undefined') {
      queryParameters['only_visible'] = parameters['onlyVisible'];
    }

    queryParameters['only_visible'] = this.transformParameter(
      queryParameters['only_visible'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns a category
   * @method
   * @name API#loadCategory
   */
  loadCategory(
    categoryId: number,
    parameters: {
      parameters?: Array<string>;
      attribValuesSorter?: 'NAME' | 'LANG_ORDER_NUMBER_SORT' | 'VALUE_SORT';
      withPublish?: '0' | '1';
      withAllLangs?: '0' | '1';
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    category_id: number | null;

    catalog_id: number;

    lang_id: string;

    category_name: string;

    category_descr: string;

    cms_content_id: number | null;

    webshow: number;

    category_sort: number;

    image: string | null;

    category_number: string | null;

    url: string;

    searchtype: string | null;

    layout_style: string | null;

    parent_number: string | null;

    consultant_id: string | null;

    layout_id: string | null;

    tree_left: number;

    tree_right: number;

    tree_level: number;

    weburl: string | null;

    last_update: string | null;

    istop: number;

    type: 'CLASSIC' | 'WITH_SUBCATEGORIES';

    heureka_id: string | null;

    content?: Array<{
      id: string | number;

      created_user_id: string | null;

      sitemap_item_id: string | null;

      name: string | null;

      created_date: string;

      ordering: number | null;

      sf_form: string;

      sf_stylesheet: string;

      sf_xmlcontent: string | null;

      draft: number;

      hint: string | null;

      url: string | null;

      last_update: string;

      rss: number;

      home: number;

      publish: number;

      json_content: string | null;

      documents?: Array<{
        document_id?: number;

        content_id?: number;

        document_name?: string | null;

        document_description?: string | null;

        document_path?: string | null;

        document_type?: string | null;

        created_date?: string | null;

        created_user?: number | null;

        privatedoc?: number | null;
      }>;

      downloads?: Array<{
        document_id?: number;

        content_id?: number;

        document_name?: string | null;

        document_description?: string | null;

        document_path?: string | null;

        document_type?: string | null;

        created_date?: string | null;

        created_user?: number | null;

        privatedoc?: number | null;
      }>;

      photogallery?: Array<{
        document_id?: number;

        content_id?: number;

        document_name?: string | null;

        document_description?: string | null;

        document_path?: string | null;

        document_type?: string | null;

        created_date?: string | null;

        created_user?: number | null;

        privatedoc?: number | null;
      }>;

      videos?: Array<{
        video_id?: number;

        content_id?: number;

        video_name?: string | null;

        video_description?: string | null;

        video_url?: string | null;

        ordering?: number | null;

        created_date?: string | null;

        created_user?: number | null;

        webshow?: number | null;

        video_width?: number | null;

        video_height?: number | null;
      }>;

      products?: Array<{
        availability_date: string | null;

        is_in_wishlist: boolean;

        product_id: number;

        name: string;

        slogan: string | null;

        is_sale: 0 | 1;

        issaleout: 0 | 1;

        is_new: 0 | 1;

        is_top: 0 | 1;

        plu: string | null;

        ean: string | null;

        url: string;

        description: string;

        created_at: string;

        rating: string;

        rating_users: number;

        unpacking_cost?: number;

        picture: string | null;

        brand: {};

        name_url_encoded: string;

        qtyDiscounts?: {
          discount_pcn?: number;

          qty_from?: number;

          product_id?: number;

          lang?: string;

          final_price?: number;
        };

        goods: Array<{
          priceinfo: string;

          valid_from?: string;

          valid_to?: string;

          imagePath: string;

          final_price: number | null;

          final_price_without_vat?: number | null;

          customer_discount?: number | null;

          b2b_discount?: number | null;

          unitprice_sale: number | null;

          sale: boolean | null;

          good_id: number;

          product_id: number;

          currency_id: string;

          unitprice: number;

          vat_rate: number;

          availability_id: number | null;

          oldprice: number | null;

          units: string | null;

          order_number: string | null;

          ean: string | null;

          internal_code: string | null;

          on_stock_count: number;

          is_on_stock: 0 | 1;

          point_good: 0 | 1;

          points_amount: number | null;

          sale_percentage: number | null;

          price_without_vat: number | null;

          price_without_vat_sale: number | null;

          currency: {
            id: string;

            name: string;
          };

          availability: {
            avail_id?: number;

            avail_uniqid?: string;

            order_available?: number;

            translations?: Array<{
              avail_id: number;

              lang: string;

              avail_name: string;
            }>;
          };

          sum_price?: number | null;

          sum_price_without_vat?: number | null;

          sum_unpacking_cost?: number | null;

          group_price?: {
            unitprice: number;

            oldprice: number;

            sale_percentage: number;
          };

          discount_pcn?: number;

          color?: Array<{}>;

          length?: number;

          width?: number;

          height?: number;
        }>;

        main_good: {
          priceinfo: string;

          valid_from?: string;

          valid_to?: string;

          imagePath: string;

          final_price: number | null;

          final_price_without_vat?: number | null;

          customer_discount?: number | null;

          b2b_discount?: number | null;

          unitprice_sale: number | null;

          sale: boolean | null;

          good_id: number;

          product_id: number;

          currency_id: string;

          unitprice: number;

          vat_rate: number;

          availability_id: number | null;

          oldprice: number | null;

          units: string | null;

          order_number: string | null;

          ean: string | null;

          internal_code: string | null;

          on_stock_count: number;

          is_on_stock: 0 | 1;

          point_good: 0 | 1;

          points_amount: number | null;

          sale_percentage: number | null;

          price_without_vat: number | null;

          price_without_vat_sale: number | null;

          currency: {
            id: string;

            name: string;
          };

          availability: {
            avail_id?: number;

            avail_uniqid?: string;

            order_available?: number;

            translations?: Array<{
              avail_id: number;

              lang: string;

              avail_name: string;
            }>;
          };

          sum_price?: number | null;

          sum_price_without_vat?: number | null;

          sum_unpacking_cost?: number | null;

          group_price?: {
            unitprice: number;

            oldprice: number;

            sale_percentage: number;
          };

          discount_pcn?: number;

          color?: Array<{}>;

          length?: number;

          width?: number;

          height?: number;
        };

        orderWithoutStock?: boolean;

        products_in_set?: Array<{
          good_id?: number;

          package_good_id?: number;

          package_quantity?: number;

          package_price?: number;

          product_id?: number;

          package_product_id?: number;

          color?: string;

          pages?: string;

          capacity?: string;
        }>;

        street?: string;

        city?: string;

        zip?: string;

        parent_categories: Array<{}>;

        assembling?: boolean;

        prelisting?: boolean;

        productPackages?: Array<{
          id?: number;

          productId?: number;

          storeQuantity?: number;

          fullQuantity?: number;

          type?: number;
        }>;

        lang_versions?: Array<{
          lang_id?: string;

          url?: string;
        }>;
      }>;

      next_article?: {
        name?: string;

        url?: string;

        image?: string;

        annotation?: string;

        id?: number;
      };

      tags?: Array<{
        tag_id: string;

        tag_name?: string;
      }>;

      lang_versions?: Array<{}>;
    }>;

    category_name_alias: string | null;

    lang_versions?: Array<{
      lang_id?: string;

      url?: string;
    }>;
  }> {
    let path = '/categories/{category_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{category_id}', categoryId.toString());

    if (typeof parameters['parameters'] !== 'undefined') {
      queryParameters['parameters'] = parameters['parameters'];
    }

    queryParameters['parameters'] = this.transformParameter(
      queryParameters['parameters'],
    );

    if (typeof parameters['attribValuesSorter'] !== 'undefined') {
      queryParameters['attrib_values_sorter'] =
        parameters['attribValuesSorter'];
    }

    queryParameters['attrib_values_sorter'] = this.transformParameter(
      queryParameters['attrib_values_sorter'],
    );

    if (typeof parameters['withPublish'] !== 'undefined') {
      queryParameters['with_publish'] = parameters['withPublish'];
    }

    queryParameters['with_publish'] = this.transformParameter(
      queryParameters['with_publish'],
    );

    if (typeof parameters['withAllLangs'] !== 'undefined') {
      queryParameters['with_all_langs'] = parameters['withAllLangs'];
    }

    queryParameters['with_all_langs'] = this.transformParameter(
      queryParameters['with_all_langs'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns array of category objects
   * @method
   * @name API#loadRelatedCategories
   */
  loadRelatedCategories(
    categoryId: number,
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<
    Array<{
      category_id: number | null;

      catalog_id: number;

      lang_id: string;

      category_name: string;

      category_descr: string;

      cms_content_id: number | null;

      webshow: number;

      category_sort: number;

      image: string | null;

      category_number: string | null;

      url: string;

      searchtype: string | null;

      layout_style: string | null;

      parent_number: string | null;

      consultant_id: string | null;

      layout_id: string | null;

      tree_left: number;

      tree_right: number;

      tree_level: number;

      weburl: string | null;

      last_update: string | null;

      istop: number;

      type: 'CLASSIC' | 'WITH_SUBCATEGORIES';

      heureka_id: string | null;

      content?: Array<{
        id: string | number;

        created_user_id: string | null;

        sitemap_item_id: string | null;

        name: string | null;

        created_date: string;

        ordering: number | null;

        sf_form: string;

        sf_stylesheet: string;

        sf_xmlcontent: string | null;

        draft: number;

        hint: string | null;

        url: string | null;

        last_update: string;

        rss: number;

        home: number;

        publish: number;

        json_content: string | null;

        documents?: Array<{
          document_id?: number;

          content_id?: number;

          document_name?: string | null;

          document_description?: string | null;

          document_path?: string | null;

          document_type?: string | null;

          created_date?: string | null;

          created_user?: number | null;

          privatedoc?: number | null;
        }>;

        downloads?: Array<{
          document_id?: number;

          content_id?: number;

          document_name?: string | null;

          document_description?: string | null;

          document_path?: string | null;

          document_type?: string | null;

          created_date?: string | null;

          created_user?: number | null;

          privatedoc?: number | null;
        }>;

        photogallery?: Array<{
          document_id?: number;

          content_id?: number;

          document_name?: string | null;

          document_description?: string | null;

          document_path?: string | null;

          document_type?: string | null;

          created_date?: string | null;

          created_user?: number | null;

          privatedoc?: number | null;
        }>;

        videos?: Array<{
          video_id?: number;

          content_id?: number;

          video_name?: string | null;

          video_description?: string | null;

          video_url?: string | null;

          ordering?: number | null;

          created_date?: string | null;

          created_user?: number | null;

          webshow?: number | null;

          video_width?: number | null;

          video_height?: number | null;
        }>;

        products?: Array<{
          availability_date: string | null;

          is_in_wishlist: boolean;

          product_id: number;

          name: string;

          slogan: string | null;

          is_sale: 0 | 1;

          issaleout: 0 | 1;

          is_new: 0 | 1;

          is_top: 0 | 1;

          plu: string | null;

          ean: string | null;

          url: string;

          description: string;

          created_at: string;

          rating: string;

          rating_users: number;

          unpacking_cost?: number;

          picture: string | null;

          brand: {};

          name_url_encoded: string;

          qtyDiscounts?: {
            discount_pcn?: number;

            qty_from?: number;

            product_id?: number;

            lang?: string;

            final_price?: number;
          };

          goods: Array<{
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            final_price_without_vat?: number | null;

            customer_discount?: number | null;

            b2b_discount?: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            sum_price?: number | null;

            sum_price_without_vat?: number | null;

            sum_unpacking_cost?: number | null;

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            discount_pcn?: number;

            color?: Array<{}>;

            length?: number;

            width?: number;

            height?: number;
          }>;

          main_good: {
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            final_price_without_vat?: number | null;

            customer_discount?: number | null;

            b2b_discount?: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            sum_price?: number | null;

            sum_price_without_vat?: number | null;

            sum_unpacking_cost?: number | null;

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            discount_pcn?: number;

            color?: Array<{}>;

            length?: number;

            width?: number;

            height?: number;
          };

          orderWithoutStock?: boolean;

          products_in_set?: Array<{
            good_id?: number;

            package_good_id?: number;

            package_quantity?: number;

            package_price?: number;

            product_id?: number;

            package_product_id?: number;

            color?: string;

            pages?: string;

            capacity?: string;
          }>;

          street?: string;

          city?: string;

          zip?: string;

          parent_categories: Array<{}>;

          assembling?: boolean;

          prelisting?: boolean;

          productPackages?: Array<{
            id?: number;

            productId?: number;

            storeQuantity?: number;

            fullQuantity?: number;

            type?: number;
          }>;

          lang_versions?: Array<{
            lang_id?: string;

            url?: string;
          }>;
        }>;

        next_article?: {
          name?: string;

          url?: string;

          image?: string;

          annotation?: string;

          id?: number;
        };

        tags?: Array<{
          tag_id: string;

          tag_name?: string;
        }>;

        lang_versions?: Array<{}>;
      }>;

      category_name_alias: string | null;

      lang_versions?: Array<{
        lang_id?: string;

        url?: string;
      }>;
    }>
  > {
    let path = '/categories/{category_id}/related';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{category_id}', categoryId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns full category tree as an object
   * @method
   * @name API#loadSubTree
   */
  loadSubTree(
    categoryId: number,
    parameters: {
      withAttribs?: '0' | '1';
      withProductCounts?: '0' | '1';
      totalCounts?: '0' | '1';
      withPublish?: '0' | '1';
      onlyVisible?: '0' | '1';
      withContent?: '0' | '1';
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<
    Array<{
      searchtype: string;

      category_name: string;

      category_descr: string;

      url: string;

      weburl: string;

      heureka_id: string | null;

      image: string | null;

      category_id: number;

      counts?: number;

      attribs?: Array<{
        attrib_id: number;

        type_id: number;

        attrib_uid: string | null;

        attrib_type: string | null;

        group_id: number | null;

        attrib_name: string;

        attrib_ismulti: number;

        attrib_sort: number;

        attrib_pbl: number;

        attrib_unit: string;

        attrib_list: number;

        attrib_code: string | null;

        attrib_grid: number;

        min?: number;

        max?: number;

        translations: Array<{
          attrib_id: number;

          lang_id: string;

          attrib_name: string;

          attrib_unit: string | null;
        }>;

        values: Array<{
          amount: number;

          value_id: number;

          attrib_id: number;

          attrib_value: string;

          attrib_pict: string | null;

          group_id: number | null;

          istop: boolean;

          searchfilter: number;

          value_sort: number | null;

          translations: Array<{
            value_id: number;

            lang_id: string;

            attrib_value: string;

            attrib_link: string;
          }>;
        }>;
      }>;

      children: Array<{}>;

      publish?: Array<{}>;
    }>
  > {
    let path = '/categories/{category_id}/tree';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    queryParameters['with_attribs'] = '1';

    if (typeof parameters['withAttribs'] !== 'undefined') {
      queryParameters['with_attribs'] = parameters['withAttribs'];
    }

    queryParameters['with_attribs'] = this.transformParameter(
      queryParameters['with_attribs'],
    );

    queryParameters['with_product_counts'] = '1';

    if (typeof parameters['withProductCounts'] !== 'undefined') {
      queryParameters['with_product_counts'] = parameters['withProductCounts'];
    }

    queryParameters['with_product_counts'] = this.transformParameter(
      queryParameters['with_product_counts'],
    );

    queryParameters['total_counts'] = '1';

    if (typeof parameters['totalCounts'] !== 'undefined') {
      queryParameters['total_counts'] = parameters['totalCounts'];
    }

    queryParameters['total_counts'] = this.transformParameter(
      queryParameters['total_counts'],
    );

    path = path.replace('{category_id}', categoryId.toString());

    if (typeof parameters['withPublish'] !== 'undefined') {
      queryParameters['with_publish'] = parameters['withPublish'];
    }

    queryParameters['with_publish'] = this.transformParameter(
      queryParameters['with_publish'],
    );

    if (typeof parameters['onlyVisible'] !== 'undefined') {
      queryParameters['only_visible'] = parameters['onlyVisible'];
    }

    queryParameters['only_visible'] = this.transformParameter(
      queryParameters['only_visible'],
    );

    if (typeof parameters['withContent'] !== 'undefined') {
      queryParameters['with_content'] = parameters['withContent'];
    }

    queryParameters['with_content'] = this.transformParameter(
      queryParameters['with_content'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Clears redis cache
   * @method
   * @name API#clearCache
   */
  clearCache(parameters: {} = {}): Promise<void> {
    let path = '/clear-cache';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns list of customers
   * @method
   * @name API#searchCustomers
   */
  searchCustomers(
    parameters: {
      limit?: number;
      name?: string;
      sort?: string;
      sortDir?: string;
      credibility?: '0' | '1';
      offset?: number;
    } = {},
  ): Promise<{
    customers: Array<{
      id: number;

      meno: string;

      priezvisko: string;

      firma: string | null;

      ico: string;

      dic: string;

      icdph: string;

      platba_dph: number;

      ulica: string;

      cislo: string;

      mesto: string;

      psc: string;

      country: string;

      telefon: string;

      email: string;

      login: string;

      ip: string;

      host: string;

      registrovany: string;

      stav: string;

      poznamka: string;

      nedoveryhodny: number;

      interna_poznamka: string;

      has_ip_access: number;

      individualna_zlava: number;

      lang: string;

      activatecode: string;

      activate_datetime: string | null;

      guid: string | null;

      login_hash: string;

      uniqid: string;

      card_number: string;

      reg_discount: number;

      delivery_addresses: {
        id_user?: number;

        id?: number;

        name: string;

        surname: string;

        delivery_street: string;

        delivery_number: string;

        delivery_zip: string;

        delivery_city: string;

        delivery_phone: string;

        country: string;

        isDefault?: boolean;

        company?: string;

        delivery_email?: string;

        delivery_phone_prefix?: string;
      };

      autoregister: number | null;

      facebook_user_id: string;

      google_user_id: string;

      passwd: string;

      passwd_salt: string;

      trust_points?: number | null;

      highest_trust_points_reached?: number | null;
    }>;
  }> {
    let path = '/customers';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['name'] !== 'undefined') {
      queryParameters['name'] = parameters['name'];
    }

    queryParameters['name'] = this.transformParameter(queryParameters['name']);

    queryParameters['sort'] = 'date';

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    queryParameters['sort_dir'] = 'desc';

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    if (typeof parameters['credibility'] !== 'undefined') {
      queryParameters['credibility'] = parameters['credibility'];
    }

    queryParameters['credibility'] = this.transformParameter(
      queryParameters['credibility'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Activate customer account
   * @method
   * @name API#activateUser
   */
  activateUser(parameters: { activationCode: string }): Promise<void> {
    let path = '/customers/activate';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['activationCode'] !== 'undefined') {
      queryParameters['activation_code'] = parameters['activationCode'];
    }

    queryParameters['activation_code'] = this.transformParameter(
      queryParameters['activation_code'],
    );

    if (typeof parameters['activationCode'] === 'undefined') {
      throw new Error('Missing required parameter: activationCode');
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * Create access token to enable reset user's password (token is valid 15
minutes)

    * @method
    * @name API#lostPassword
    */
  lostPassword(
    parameters: {} = {},
    body: {
      email: string;

      domain_id?: string;

      lang?: string;
    },
  ): Promise<void> {
    let path = '/customers/lost-password';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
    * Save new user's password and password salt into Db and remove access
token for password reset

    * @method
    * @name API#newPassword
    */
  newPassword(
    parameters: {},
    body: {
      new_password: string;

      lang?: string;

      domain_id?: string;
    },
    extraHeaders?: {
      xLostPasswordToken: string;
    },
  ): Promise<void> {
    let path = '/customers/new-password';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xLostPasswordToken'] !== 'undefined'
    ) {
      headers.append(
        'x-lost-password-token',
        extraHeaders['xLostPasswordToken']!,
      );
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Get customer delivery address
   * @method
   * @name API#getDeliveryAddresses
   */
  getDeliveryAddresses(
    customerId: number,
    parameters: {
      addressId?: number;
      limit?: number;
      sort?: string;
      sortDir?: string;
    } = {},
  ): Promise<
    Array<{
      country?: string;

      created_at?: string;

      delivery_city?: string;

      delivery_number?: string;

      delivery_phone?: string;

      delivery_street?: string;

      delivery_zip?: string;

      id?: number;

      id_user?: number;

      isDefault?: boolean;

      name?: string;

      surname?: string;
    }>
  > {
    let path = '/customers/{customer_id}/delivery-address';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{customer_id}', customerId.toString());

    if (typeof parameters['addressId'] !== 'undefined') {
      queryParameters['address_id'] = parameters['addressId'];
    }

    queryParameters['address_id'] = this.transformParameter(
      queryParameters['address_id'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['sort'] = 'created_at';

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    queryParameters['sort_dir'] = 'asc';

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create new customer's delivery address
   * @method
   * @name API#createNewDeliveryAddress
   */
  createNewDeliveryAddress(
    customerId: number,
    parameters: {} = {},
    body: {
      id_user?: number;

      id?: number;

      name: string;

      surname: string;

      delivery_street: string;

      delivery_number: string;

      delivery_zip: string;

      delivery_city: string;

      delivery_phone: string;

      country: string;

      isDefault?: boolean;

      company?: string;

      delivery_email?: string;

      delivery_phone_prefix?: string;
    },
  ): Promise<
    Array<{
      id_user?: number;

      id?: number;

      name: string;

      surname: string;

      delivery_street: string;

      delivery_number: string;

      delivery_zip: string;

      delivery_city: string;

      delivery_phone: string;

      country: string;

      isDefault?: boolean;

      company?: string;

      delivery_email?: string;

      delivery_phone_prefix?: string;
    }>
  > {
    let path = '/customers/{customer_id}/delivery-address';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{customer_id}', customerId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Get customer billing address
   * @method
   * @name API#getBillingAddresses
   */
  getBillingAddresses(
    customerId: number,
    parameters: {
      addressId?: number;
      limit?: number;
      sort?: string;
      sortDir?: string;
    } = {},
  ): Promise<
    Array<{
      id: number;

      id_user: number;

      name: string;

      surname: string;

      billing_street: string;

      billing_number: string;

      billing_zip: string;

      billing_city: string;

      billing_phone: string;

      country: string;
    }>
  > {
    let path = '/customers/{customer_id}/billing-address';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{customer_id}', customerId.toString());

    if (typeof parameters['addressId'] !== 'undefined') {
      queryParameters['address_id'] = parameters['addressId'];
    }

    queryParameters['address_id'] = this.transformParameter(
      queryParameters['address_id'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['sort'] = 'created_at';

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    queryParameters['sort_dir'] = 'asc';

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create new customer's billing address
   * @method
   * @name API#createNewBillingAddress
   */
  createNewBillingAddress(
    customerId: number,
    parameters: {} = {},
    body: {
      id: number;

      id_user: number;

      name: string;

      surname: string;

      billing_street: string;

      billing_number: string;

      billing_zip: string;

      billing_city: string;

      billing_phone: string;

      country: string;
    },
  ): Promise<
    Array<{
      id: number;

      id_user: number;

      name: string;

      surname: string;

      billing_street: string;

      billing_number: string;

      billing_zip: string;

      billing_city: string;

      billing_phone: string;

      country: string;
    }>
  > {
    let path = '/customers/{customer_id}/billing-address';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{customerId}', customerId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Get user info by user ID
   * @method
   * @name API#loadDashboard
   */
  loadDashboard(customerId: number, parameters: {} = {}): Promise<void> {
    let path = '/customers/{customer_id}/dashboard';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{customer_id}', customerId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Loads products in customer orders
   * @method
   * @name API#loadOrderedProducts
   */
  loadOrderedProducts(
    customerId: number,
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
      xCurrency?: string;
    },
  ): Promise<void> {
    let path = '/customers/{customer_id}/ordered-products';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (extraHeaders && typeof extraHeaders['xCurrency'] !== 'undefined') {
      headers.append('X-Currency', extraHeaders['xCurrency']!);
    }

    path = path.replace('{customer_id}', customerId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns array with basic informations about product
   * @method
   * @name API#loadFavoriteProducts
   */
  loadFavoriteProducts(
    customerId: number,
    parameters: {
      limit?: number;
      offset?: number;
      sort?: string;
      sortDir?: string;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    total: number;

    offset: number;

    price_min: number | null;

    price_max: number | null;

    limit: number;

    products: Array<{
      availability_date: string | null;

      is_in_wishlist: boolean;

      product_id: number;

      name: string;

      slogan: string | null;

      is_sale: 0 | 1;

      issaleout: 0 | 1;

      is_new: 0 | 1;

      is_top: 0 | 1;

      plu: string | null;

      ean: string | null;

      url: string;

      description: string;

      created_at: string;

      rating: string;

      rating_users: number;

      unpacking_cost?: number;

      picture: string | null;

      brand: {};

      name_url_encoded: string;

      qtyDiscounts?: {
        discount_pcn?: number;

        qty_from?: number;

        product_id?: number;

        lang?: string;

        final_price?: number;
      };

      goods: Array<{
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        final_price_without_vat?: number | null;

        customer_discount?: number | null;

        b2b_discount?: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        sum_price?: number | null;

        sum_price_without_vat?: number | null;

        sum_unpacking_cost?: number | null;

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        discount_pcn?: number;

        color?: Array<{}>;

        length?: number;

        width?: number;

        height?: number;
      }>;

      main_good: {
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        final_price_without_vat?: number | null;

        customer_discount?: number | null;

        b2b_discount?: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        sum_price?: number | null;

        sum_price_without_vat?: number | null;

        sum_unpacking_cost?: number | null;

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        discount_pcn?: number;

        color?: Array<{}>;

        length?: number;

        width?: number;

        height?: number;
      };

      orderWithoutStock?: boolean;

      products_in_set?: Array<{
        good_id?: number;

        package_good_id?: number;

        package_quantity?: number;

        package_price?: number;

        product_id?: number;

        package_product_id?: number;

        color?: string;

        pages?: string;

        capacity?: string;
      }>;

      street?: string;

      city?: string;

      zip?: string;

      parent_categories: Array<{}>;

      assembling?: boolean;

      prelisting?: boolean;

      productPackages?: Array<{
        id?: number;

        productId?: number;

        storeQuantity?: number;

        fullQuantity?: number;

        type?: number;
      }>;

      lang_versions?: Array<{
        lang_id?: string;

        url?: string;
      }>;
    }>;
  }> {
    let path = '/customers/{customer_id}/favorite-products';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    path = path.replace('{customer_id}', customerId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Unfavorite product (as customer)
   * @method
   * @name API#unfavoriteProduct
   */
  unfavoriteProduct(
    customerId: number,
    productId: number,
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/customers/{customer_id}/favorite-products/{product_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{customer_id}', customerId.toString());

    path = path.replace('{product_id}', productId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Favorite product (as customer)
   * @method
   * @name API#favoriteProduct
   */
  favoriteProduct(
    customerId: number,
    productId: number,
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/customers/{customer_id}/favorite-products/{product_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{customer_id}', customerId.toString());

    path = path.replace('{product_id}', productId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Generates customer's product xml file
   * @method
   * @name API#generateCustomerProductXml
   */
  generateCustomerProductXml(
    customerId: number,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/customers/{customer_id}/generate-xml';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{customer_id}', customerId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get orders by user ID
   * @method
   * @name API#getOrders
   */
  getOrders(
    customerId: number,
    parameters: {
      onlyActive?: '0' | '1';
      limit?: number;
      offset?: number;
      filterType?: 'DEMAND' | 'ORDERS' | 'BOTH';
      domainId?: string;
    } = {},
  ): Promise<{
    orders: Array<{
      _id: number;

      order_id: number;

      order_nr: string;

      order_status: string;

      order_status_color: string;

      order_status_readable: string;

      order_type: string;

      order_tprice: number;

      order_tpricecurrency: string;

      created_datetime: string;

      order_cancelled_datetime: string | null;

      order_paid_datetime: string | null;

      order_ship_datetime: string | null;

      order_comments: string;

      shipnr: string | null;

      comments: string | null;

      order_lang: string;

      parent_order: number | null;

      vs: string;

      ac: string | null;

      credit_note: number;

      credit_note_number: number | null;

      credit_note_amount: number | null;

      credit_note_delivery: number;

      delivery_note_number: number | null;

      delivery_note_created_date: string | null;

      reservation_date: string | null;

      partner_id: number | null;

      oo_shop_id: number | null;

      oo_shop_name: string | null;

      oo_shop_address: string | null;

      consumed_points: number;

      assigned_points: number;

      giftcards: number;

      club_user_id: number | null;

      invoice_with_vat: number;

      invoice_number: number | null;

      invoice_created_date: string | null;

      invoice_maturity_date: string | null;

      invoice_delivery_date: string | null;

      public_id: string;

      error_message: string | null;

      person: {
        name: string | null;

        surname: string | null;

        phone: string | null;

        email: string | null;

        phonePrefix?: string | null;
      };

      address: {
        person?: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      payment: {
        payment_type: string;

        payment_typename: string;

        payment_price: number;

        payment_pricecurrency: string;

        delivery_price: number;

        delivery_pricecurrency: string;

        order_tprice: number;

        order_tpricecurrency: string;

        vat_rate: number;

        discount: number;

        individual_discount: number;

        group_discount: number;

        discount_amount: number;

        paid_amount: number | null;

        paid_amount_from_wallet: number | null;

        paid_amount_from_wallet_returned?: number | null;

        card_number: string | null;
      };

      delivery: {
        person: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        delivery_firm: string | null;

        delivery_addr_street: string;

        delivery_addr_street_number: string;

        delivery_addr_city: string;

        delivery_addr_zip: string;

        delivery_addr_country: string;

        delivery_addr_country_code: string | null;

        delivery_type: string;

        delivery_typename: string;

        delivery_price: number;

        delivery_pricecurrency: string;

        posta_id: number | null;

        posta_name: string | null;

        posta_address: string | null;
      };

      quatro: {
        quatro_status: string | null;

        quatro_kod: string | null;

        quatro_url: string | null;

        quatro_oz: string | null;
      };

      company: {
        name: string | null;

        ico: string | null;

        dic: string | null;

        ic_dph: string | null;
      };

      items?: Array<{
        order_id: number;

        item_idx: number;

        ordernr: number;

        product_name: string;

        units: string | null;

        unitprice: number;

        vat_rate: number;

        quantity: number;

        credit_note_quantity: number | null;

        totalpoints: number;

        totalprice: number;

        currency: string;

        product_id: number;

        good_id: number;

        skladom: number | null;

        date_delivery: string | null;

        from_store_id: string | null;

        product_note: string | null;

        ean: string | null;

        product_picture: string | null;

        product_url: string | null;

        good_details?: string;

        good_details_json: {
          productName: string;

          productNote: string | null;

          units: string | null;

          ordernr: number;

          ean: string | null;

          internalcode: string | null;

          unitprice: {
            price: number;

            currency: string;

            excludeVat: number;

            vatRate: string;
          };

          onstock: number;

          onstockCount: string;

          orderAvailable: number;

          points: number;

          id: string;

          productId: string;

          productTypeId: string;

          hasSales: boolean;

          isFromSale: boolean;

          isFromGroupSale: boolean;

          isPointGood: number;

          pointAmount: number;

          availabilityId: string;

          availability: {
            name: string;

            uniqueid: string;

            id: string;

            lang: string;

            order_available: string;
          };

          attribs: Array<{
            name: string;

            unit: string;

            htmlUnit: string;

            value: string | null;

            customValue: string;

            groupName: string | null;

            attribId: string;

            valueId: number | null;

            valueGroupId: number | null;

            groupId: number | null;

            orderDepend: number;

            hexadecimal: string | null;

            attrib_ordernr: number;

            default_for_cart: number | null;
          }>;

          attribsByAttribId: {
            groupId: number;

            groupName: string;

            attribs: Array<{
              name: string;

              unit: string;

              htmlUnit: string;

              value: string | null;

              customValue: string;

              groupName: string | null;

              attribId: string;

              valueId: number | null;

              valueGroupId: number | null;

              groupId: number | null;

              orderDepend: number;

              hexadecimal: string | null;

              attrib_ordernr: number;

              default_for_cart: number | null;
            }>;
          };
        };

        is_meta_item?: boolean;
      }>;

      documents?: {
        faktura?: string;

        predfaktura?: string;

        dobropis?: string;
      };

      showPayment?: boolean;

      use_delivery_address?: boolean;

      chosen_gift_name?: string;

      chosen_gift_id?: string;

      sale_explanation?: string;
    }>;
  }> {
    let path = '/customers/{customer_id}/orders';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{customer_id}', customerId.toString());

    if (typeof parameters['onlyActive'] !== 'undefined') {
      queryParameters['only_active'] = parameters['onlyActive'];
    }

    queryParameters['only_active'] = this.transformParameter(
      queryParameters['only_active'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['filter_type'] = 'ORDERS';

    if (typeof parameters['filterType'] !== 'undefined') {
      queryParameters['filter_type'] = parameters['filterType'];
    }

    queryParameters['filter_type'] = this.transformParameter(
      queryParameters['filter_type'],
    );

    if (typeof parameters['domainId'] !== 'undefined') {
      queryParameters['domain_id'] = parameters['domainId'];
    }

    queryParameters['domain_id'] = this.transformParameter(
      queryParameters['domain_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns array of transaction objects
   * @method
   * @name API#loadTransactions
   */
  loadTransactions(
    customerId: number,
    parameters: {
      dateStart?: string;
      dateEnd?: string;
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    current?: number;

    plus?: number;

    minus?: number;

    transactions?: Array<{
      id: number;

      id_user: number;

      id_order: number | null;

      amount: number;

      created_date: string;

      note: string;
    }>;
  }> {
    let path = '/customers/{customer_id}/points';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['dateStart'] !== 'undefined') {
      queryParameters['date_start'] = parameters['dateStart'];
    }

    queryParameters['date_start'] = this.transformParameter(
      queryParameters['date_start'],
    );

    if (typeof parameters['dateEnd'] !== 'undefined') {
      queryParameters['date_end'] = parameters['dateEnd'];
    }

    queryParameters['date_end'] = this.transformParameter(
      queryParameters['date_end'],
    );

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{customer_id}', customerId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Set custom order number to given good for a customer
   * @method
   * @name API#updateCustomOrderNumber
   */
  updateCustomOrderNumber(
    customerId: number,
    goodId: number,
    parameters: {} = {},
    body: {
      orderNr: string;
    },
  ): Promise<void> {
    let path = '/customers/{customer_id}/order-nr/{good_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{customer_id}', customerId.toString());

    path = path.replace('{good_id}', goodId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Get customer's details
   * @method
   * @name API#getCustomerDetails
   */
  getCustomerDetails(
    id: number,
    parameters: {
      withParent?: string;
    } = {},
  ): Promise<{
    id: number;

    meno: string;

    priezvisko: string;

    firma: string;

    ico: string;

    dic: string;

    icdph: string;

    platca_dph: 0 | 1;

    ulica: string;

    cislo: string;

    mesto: string;

    psc: string;

    country: string;

    telefon: string;

    phonePrefix?: string;

    email: string;

    login: string;

    ip: string;

    host: string;

    registrovany: string;

    stav: string;

    poznamka: string;

    nedoveryhodny: boolean;

    interna_poznamka: string;

    has_ip_access: boolean;

    individualna_zlava: number;

    lang: string;

    activatecode: string;

    activate_datetime: string;

    guid: string;

    login_hash: string;

    uniqid: string;

    card_number: string;

    reg_discount: boolean;

    facebook_user_id: string;

    google_user_id: string;

    trust_points?: number;

    highest_trust_points_reached?: number;

    history_points?: {};

    price_vat_display_type?: number;

    price_retail_wholesale_display_type?: number;

    newsletter_accept: boolean;
  }> {
    let path = '/customers/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    if (typeof parameters['withParent'] !== 'undefined') {
      queryParameters['with_parent'] = parameters['withParent'];
    }

    queryParameters['with_parent'] = this.transformParameter(
      queryParameters['with_parent'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update customer's details
   * @method
   * @name API#updateCustomerDetails
   */
  updateCustomerDetails(
    id: number,
    parameters: {} = {},
    body: {
      id: number;

      meno: string;

      priezvisko: string;

      firma: string;

      ico: string;

      dic: string;

      icdph: string;

      platca_dph: 0 | 1;

      ulica: string;

      cislo: string;

      mesto: string;

      psc: string;

      country: string;

      telefon: string;

      phonePrefix?: string;

      email: string;

      login: string;

      ip: string;

      host: string;

      registrovany: string;

      stav: string;

      poznamka: string;

      nedoveryhodny: boolean;

      interna_poznamka: string;

      has_ip_access: boolean;

      individualna_zlava: number;

      lang: string;

      activatecode: string;

      activate_datetime: string;

      guid: string;

      login_hash: string;

      uniqid: string;

      card_number: string;

      reg_discount: boolean;

      facebook_user_id: string;

      google_user_id: string;

      trust_points?: number;

      highest_trust_points_reached?: number;

      history_points?: {};

      price_vat_display_type?: number;

      price_retail_wholesale_display_type?: number;

      newsletter_accept: boolean;
    },
  ): Promise<void> {
    let path = '/customers/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Update customer's settings
   * @method
   * @name API#updateCustomerSettings
   */
  updateCustomerSettings(
    id: number,
    parameters: {} = {},
    body: {
      newsletter_accept?: boolean;
    },
  ): Promise<void> {
    let path = '/customers/{id}/settings';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Delete request customer
   * @method
   * @name API#deleteRequestCustomer
   */
  deleteRequestCustomer(id: number, parameters: {} = {}): Promise<void> {
    let path = '/customers/{id}/delete-request';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get customer's additional info
   * @method
   * @name API#loadAdditionalInfo
   */
  loadAdditionalInfo(id: number, parameters: {} = {}): Promise<{}> {
    let path = '/customers/{id}/additional-info';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get customer's ordered products
   * @method
   * @name API#loadi6OrderedProducts
   */
  loadi6OrderedProducts(
    id: number,
    parameters: {
      limit?: number;
      offset?: number;
      filterFrom?: string;
      filterTo?: string;
      categoryId?: string;
      brandId?: string;
      userOuterId?: string;
    } = {},
  ): Promise<{}> {
    let path = '/customers/{id}/order-items';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{id}', id.toString());

    if (typeof parameters['filterFrom'] !== 'undefined') {
      queryParameters['filter_from'] = parameters['filterFrom'];
    }

    queryParameters['filter_from'] = this.transformParameter(
      queryParameters['filter_from'],
    );

    if (typeof parameters['filterTo'] !== 'undefined') {
      queryParameters['filter_to'] = parameters['filterTo'];
    }

    queryParameters['filter_to'] = this.transformParameter(
      queryParameters['filter_to'],
    );

    if (typeof parameters['categoryId'] !== 'undefined') {
      queryParameters['category_id'] = parameters['categoryId'];
    }

    queryParameters['category_id'] = this.transformParameter(
      queryParameters['category_id'],
    );

    if (typeof parameters['brandId'] !== 'undefined') {
      queryParameters['brand_id'] = parameters['brandId'];
    }

    queryParameters['brand_id'] = this.transformParameter(
      queryParameters['brand_id'],
    );

    if (typeof parameters['userOuterId'] !== 'undefined') {
      queryParameters['user_outer_id'] = parameters['userOuterId'];
    }

    queryParameters['user_outer_id'] = this.transformParameter(
      queryParameters['user_outer_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get customer's backorders
   * @method
   * @name API#loadi6Backorders
   */
  loadi6Backorders(
    id: number,
    parameters: {
      limit?: number;
      offset?: number;
      categoryId?: string;
      brandId?: string;
      reserved?: '0' | '1';
      userOuterId?: string;
    } = {},
  ): Promise<{}> {
    let path = '/customers/{id}/backorders';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{id}', id.toString());

    if (typeof parameters['categoryId'] !== 'undefined') {
      queryParameters['category_id'] = parameters['categoryId'];
    }

    queryParameters['category_id'] = this.transformParameter(
      queryParameters['category_id'],
    );

    if (typeof parameters['brandId'] !== 'undefined') {
      queryParameters['brand_id'] = parameters['brandId'];
    }

    queryParameters['brand_id'] = this.transformParameter(
      queryParameters['brand_id'],
    );

    if (typeof parameters['reserved'] !== 'undefined') {
      queryParameters['reserved'] = parameters['reserved'];
    }

    queryParameters['reserved'] = this.transformParameter(
      queryParameters['reserved'],
    );

    if (typeof parameters['userOuterId'] !== 'undefined') {
      queryParameters['user_outer_id'] = parameters['userOuterId'];
    }

    queryParameters['user_outer_id'] = this.transformParameter(
      queryParameters['user_outer_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get orders by user ID
   * @method
   * @name API#geti6Orders
   */
  geti6Orders(
    customerId: number,
    parameters: {
      onlyActive?: '0' | '1';
      limit?: number;
      offset?: number;
      filterType?: 'DEMAND' | 'ORDERS' | 'BOTH';
      userOuterId?: string;
      filterFrom?: string;
      filterTo?: string;
    } = {},
  ): Promise<{
    orders: Array<{
      _id: number;

      order_id: number;

      order_nr: string;

      order_status: string;

      order_status_color: string;

      order_status_readable: string;

      order_type: string;

      order_tprice: number;

      order_tpricecurrency: string;

      created_datetime: string;

      order_cancelled_datetime: string | null;

      order_paid_datetime: string | null;

      order_ship_datetime: string | null;

      order_comments: string;

      shipnr: string | null;

      comments: string | null;

      order_lang: string;

      parent_order: number | null;

      vs: string;

      ac: string | null;

      credit_note: number;

      credit_note_number: number | null;

      credit_note_amount: number | null;

      credit_note_delivery: number;

      delivery_note_number: number | null;

      delivery_note_created_date: string | null;

      reservation_date: string | null;

      partner_id: number | null;

      oo_shop_id: number | null;

      oo_shop_name: string | null;

      oo_shop_address: string | null;

      consumed_points: number;

      assigned_points: number;

      giftcards: number;

      club_user_id: number | null;

      invoice_with_vat: number;

      invoice_number: number | null;

      invoice_created_date: string | null;

      invoice_maturity_date: string | null;

      invoice_delivery_date: string | null;

      public_id: string;

      error_message: string | null;

      person: {
        name: string | null;

        surname: string | null;

        phone: string | null;

        email: string | null;

        phonePrefix?: string | null;
      };

      address: {
        person?: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      payment: {
        payment_type: string;

        payment_typename: string;

        payment_price: number;

        payment_pricecurrency: string;

        delivery_price: number;

        delivery_pricecurrency: string;

        order_tprice: number;

        order_tpricecurrency: string;

        vat_rate: number;

        discount: number;

        individual_discount: number;

        group_discount: number;

        discount_amount: number;

        paid_amount: number | null;

        paid_amount_from_wallet: number | null;

        paid_amount_from_wallet_returned?: number | null;

        card_number: string | null;
      };

      delivery: {
        person: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        delivery_firm: string | null;

        delivery_addr_street: string;

        delivery_addr_street_number: string;

        delivery_addr_city: string;

        delivery_addr_zip: string;

        delivery_addr_country: string;

        delivery_addr_country_code: string | null;

        delivery_type: string;

        delivery_typename: string;

        delivery_price: number;

        delivery_pricecurrency: string;

        posta_id: number | null;

        posta_name: string | null;

        posta_address: string | null;
      };

      quatro: {
        quatro_status: string | null;

        quatro_kod: string | null;

        quatro_url: string | null;

        quatro_oz: string | null;
      };

      company: {
        name: string | null;

        ico: string | null;

        dic: string | null;

        ic_dph: string | null;
      };

      items?: Array<{
        order_id: number;

        item_idx: number;

        ordernr: number;

        product_name: string;

        units: string | null;

        unitprice: number;

        vat_rate: number;

        quantity: number;

        credit_note_quantity: number | null;

        totalpoints: number;

        totalprice: number;

        currency: string;

        product_id: number;

        good_id: number;

        skladom: number | null;

        date_delivery: string | null;

        from_store_id: string | null;

        product_note: string | null;

        ean: string | null;

        product_picture: string | null;

        product_url: string | null;

        good_details?: string;

        good_details_json: {
          productName: string;

          productNote: string | null;

          units: string | null;

          ordernr: number;

          ean: string | null;

          internalcode: string | null;

          unitprice: {
            price: number;

            currency: string;

            excludeVat: number;

            vatRate: string;
          };

          onstock: number;

          onstockCount: string;

          orderAvailable: number;

          points: number;

          id: string;

          productId: string;

          productTypeId: string;

          hasSales: boolean;

          isFromSale: boolean;

          isFromGroupSale: boolean;

          isPointGood: number;

          pointAmount: number;

          availabilityId: string;

          availability: {
            name: string;

            uniqueid: string;

            id: string;

            lang: string;

            order_available: string;
          };

          attribs: Array<{
            name: string;

            unit: string;

            htmlUnit: string;

            value: string | null;

            customValue: string;

            groupName: string | null;

            attribId: string;

            valueId: number | null;

            valueGroupId: number | null;

            groupId: number | null;

            orderDepend: number;

            hexadecimal: string | null;

            attrib_ordernr: number;

            default_for_cart: number | null;
          }>;

          attribsByAttribId: {
            groupId: number;

            groupName: string;

            attribs: Array<{
              name: string;

              unit: string;

              htmlUnit: string;

              value: string | null;

              customValue: string;

              groupName: string | null;

              attribId: string;

              valueId: number | null;

              valueGroupId: number | null;

              groupId: number | null;

              orderDepend: number;

              hexadecimal: string | null;

              attrib_ordernr: number;

              default_for_cart: number | null;
            }>;
          };
        };

        is_meta_item?: boolean;
      }>;

      documents?: {
        faktura?: string;

        predfaktura?: string;

        dobropis?: string;
      };

      showPayment?: boolean;

      use_delivery_address?: boolean;

      chosen_gift_name?: string;

      chosen_gift_id?: string;

      sale_explanation?: string;
    }>;
  }> {
    let path = '/customers/{customer_id}/i6-orders';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{customer_id}', customerId.toString());

    if (typeof parameters['onlyActive'] !== 'undefined') {
      queryParameters['only_active'] = parameters['onlyActive'];
    }

    queryParameters['only_active'] = this.transformParameter(
      queryParameters['only_active'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['filter_type'] = 'ORDERS';

    if (typeof parameters['filterType'] !== 'undefined') {
      queryParameters['filter_type'] = parameters['filterType'];
    }

    queryParameters['filter_type'] = this.transformParameter(
      queryParameters['filter_type'],
    );

    if (typeof parameters['userOuterId'] !== 'undefined') {
      queryParameters['user_outer_id'] = parameters['userOuterId'];
    }

    queryParameters['user_outer_id'] = this.transformParameter(
      queryParameters['user_outer_id'],
    );

    if (typeof parameters['filterFrom'] !== 'undefined') {
      queryParameters['filter_from'] = parameters['filterFrom'];
    }

    queryParameters['filter_from'] = this.transformParameter(
      queryParameters['filter_from'],
    );

    if (typeof parameters['filterTo'] !== 'undefined') {
      queryParameters['filter_to'] = parameters['filterTo'];
    }

    queryParameters['filter_to'] = this.transformParameter(
      queryParameters['filter_to'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get order by order public id
   * @method
   * @name API#geti6Order
   */
  geti6Order(
    customerId: number,
    id: string,
    parameters: {} = {},
  ): Promise<{
    _id: number;

    order_id: number;

    order_nr: string;

    order_status: string;

    order_status_color: string;

    order_status_readable: string;

    order_type: string;

    order_tprice: number;

    order_tpricecurrency: string;

    created_datetime: string;

    order_cancelled_datetime: string | null;

    order_paid_datetime: string | null;

    order_ship_datetime: string | null;

    order_comments: string;

    shipnr: string | null;

    comments: string | null;

    order_lang: string;

    parent_order: number | null;

    vs: string;

    ac: string | null;

    credit_note: number;

    credit_note_number: number | null;

    credit_note_amount: number | null;

    credit_note_delivery: number;

    delivery_note_number: number | null;

    delivery_note_created_date: string | null;

    reservation_date: string | null;

    partner_id: number | null;

    oo_shop_id: number | null;

    oo_shop_name: string | null;

    oo_shop_address: string | null;

    consumed_points: number;

    assigned_points: number;

    giftcards: number;

    club_user_id: number | null;

    invoice_with_vat: number;

    invoice_number: number | null;

    invoice_created_date: string | null;

    invoice_maturity_date: string | null;

    invoice_delivery_date: string | null;

    public_id: string;

    error_message: string | null;

    person: {
      name: string | null;

      surname: string | null;

      phone: string | null;

      email: string | null;

      phonePrefix?: string | null;
    };

    address: {
      person?: {
        name: string | null;

        surname: string | null;

        phone: string | null;

        email: string | null;

        phonePrefix?: string | null;
      };

      street: string;

      street_number?: string;

      zip: string;

      city: string;

      country: string;

      country_code: string;

      country_id: string;

      company?: string;

      delivery_address_id?: number;
    };

    payment: {
      payment_type: string;

      payment_typename: string;

      payment_price: number;

      payment_pricecurrency: string;

      delivery_price: number;

      delivery_pricecurrency: string;

      order_tprice: number;

      order_tpricecurrency: string;

      vat_rate: number;

      discount: number;

      individual_discount: number;

      group_discount: number;

      discount_amount: number;

      paid_amount: number | null;

      paid_amount_from_wallet: number | null;

      paid_amount_from_wallet_returned?: number | null;

      card_number: string | null;
    };

    delivery: {
      person: {
        name: string | null;

        surname: string | null;

        phone: string | null;

        email: string | null;

        phonePrefix?: string | null;
      };

      delivery_firm: string | null;

      delivery_addr_street: string;

      delivery_addr_street_number: string;

      delivery_addr_city: string;

      delivery_addr_zip: string;

      delivery_addr_country: string;

      delivery_addr_country_code: string | null;

      delivery_type: string;

      delivery_typename: string;

      delivery_price: number;

      delivery_pricecurrency: string;

      posta_id: number | null;

      posta_name: string | null;

      posta_address: string | null;
    };

    quatro: {
      quatro_status: string | null;

      quatro_kod: string | null;

      quatro_url: string | null;

      quatro_oz: string | null;
    };

    company: {
      name: string | null;

      ico: string | null;

      dic: string | null;

      ic_dph: string | null;
    };

    items?: Array<{
      order_id: number;

      item_idx: number;

      ordernr: number;

      product_name: string;

      units: string | null;

      unitprice: number;

      vat_rate: number;

      quantity: number;

      credit_note_quantity: number | null;

      totalpoints: number;

      totalprice: number;

      currency: string;

      product_id: number;

      good_id: number;

      skladom: number | null;

      date_delivery: string | null;

      from_store_id: string | null;

      product_note: string | null;

      ean: string | null;

      product_picture: string | null;

      product_url: string | null;

      good_details?: string;

      good_details_json: {
        productName: string;

        productNote: string | null;

        units: string | null;

        ordernr: number;

        ean: string | null;

        internalcode: string | null;

        unitprice: {
          price: number;

          currency: string;

          excludeVat: number;

          vatRate: string;
        };

        onstock: number;

        onstockCount: string;

        orderAvailable: number;

        points: number;

        id: string;

        productId: string;

        productTypeId: string;

        hasSales: boolean;

        isFromSale: boolean;

        isFromGroupSale: boolean;

        isPointGood: number;

        pointAmount: number;

        availabilityId: string;

        availability: {
          name: string;

          uniqueid: string;

          id: string;

          lang: string;

          order_available: string;
        };

        attribs: Array<{
          name: string;

          unit: string;

          htmlUnit: string;

          value: string | null;

          customValue: string;

          groupName: string | null;

          attribId: string;

          valueId: number | null;

          valueGroupId: number | null;

          groupId: number | null;

          orderDepend: number;

          hexadecimal: string | null;

          attrib_ordernr: number;

          default_for_cart: number | null;
        }>;

        attribsByAttribId: {
          groupId: number;

          groupName: string;

          attribs: Array<{
            name: string;

            unit: string;

            htmlUnit: string;

            value: string | null;

            customValue: string;

            groupName: string | null;

            attribId: string;

            valueId: number | null;

            valueGroupId: number | null;

            groupId: number | null;

            orderDepend: number;

            hexadecimal: string | null;

            attrib_ordernr: number;

            default_for_cart: number | null;
          }>;
        };
      };

      is_meta_item?: boolean;
    }>;

    documents?: {
      faktura?: string;

      predfaktura?: string;

      dobropis?: string;
    };

    showPayment?: boolean;

    use_delivery_address?: boolean;

    chosen_gift_name?: string;

    chosen_gift_id?: string;

    sale_explanation?: string;
  }> {
    let path = '/customers/{customer_id}/i6-orders/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{customer_id}', customerId.toString());

    path = path.replace('{id}', id.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Load i6 customer's documents
   * @method
   * @name API#loadi6Documents
   */
  loadi6Documents(
    id: number,
    parameters: {
      limit?: number;
      offset?: number;
      filterFrom?: string;
      filterTo?: string;
      type?: 'INVOICE' | 'PREINVOICE' | 'CREDIT';
      paid?: '0' | '1';
      userOuterId?: string;
    } = {},
  ): Promise<{}> {
    let path = '/customers/{id}/i6-documents';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['filterFrom'] !== 'undefined') {
      queryParameters['filter_from'] = parameters['filterFrom'];
    }

    queryParameters['filter_from'] = this.transformParameter(
      queryParameters['filter_from'],
    );

    if (typeof parameters['filterTo'] !== 'undefined') {
      queryParameters['filter_to'] = parameters['filterTo'];
    }

    queryParameters['filter_to'] = this.transformParameter(
      queryParameters['filter_to'],
    );

    path = path.replace('{id}', id.toString());

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['paid'] !== 'undefined') {
      queryParameters['paid'] = parameters['paid'];
    }

    queryParameters['paid'] = this.transformParameter(queryParameters['paid']);

    if (typeof parameters['userOuterId'] !== 'undefined') {
      queryParameters['user_outer_id'] = parameters['userOuterId'];
    }

    queryParameters['user_outer_id'] = this.transformParameter(
      queryParameters['user_outer_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Load customer's documents
   * @method
   * @name API#loadi6DocumentPdf
   */
  loadi6DocumentPdf(
    id: number,
    orderId: number,
    documentType: 'INVOICE' | 'PREINVOICE' | 'CREDIT',
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/customers/{id}/i6-document/{order_id}/{document_type}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    path = path.replace('{order_id}', orderId.toString());

    path = path.replace('{document_type}', documentType.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Authorized customer can through this endpoint change his password
   * @method
   * @name API#changePassword
   */
  changePassword(
    customerId: number,
    parameters: {} = {},
    body: {
      current_password: string;

      new_password: string;

      password_check: string;

      lang?: string;

      domain_id?: string;
    },
    extraHeaders?: {
      xApiKey?: string;
    },
  ): Promise<void> {
    let path = '/customers/{customer_id}/change-password';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (extraHeaders && typeof extraHeaders['xApiKey'] !== 'undefined') {
      headers.append('X-API-Key', extraHeaders['xApiKey']!);
    }

    path = path.replace('{customer_id}', customerId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Get user info by user ID
   * @method
   * @name API#loadManagerI6InvoicesData
   */
  loadManagerI6InvoicesData(parameters: {
    limit?: number;
    offset?: number;
    query?: string;
    customerId: number;
    customerAddressId?: number;
    dateFrom?: string;
    dateTo?: string;
    sort?: string;
    sortDirection?: 'asc' | 'desc';
  }): Promise<void> {
    let path = '/manager/i6-inovices-items';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    if (typeof parameters['customerId'] !== 'undefined') {
      queryParameters['customer_id'] = parameters['customerId'];
    }

    queryParameters['customer_id'] = this.transformParameter(
      queryParameters['customer_id'],
    );

    if (typeof parameters['customerId'] === 'undefined') {
      throw new Error('Missing required parameter: customerId');
    }

    if (typeof parameters['customerAddressId'] !== 'undefined') {
      queryParameters['customer_address_id'] = parameters['customerAddressId'];
    }

    queryParameters['customer_address_id'] = this.transformParameter(
      queryParameters['customer_address_id'],
    );

    if (typeof parameters['dateFrom'] !== 'undefined') {
      queryParameters['date_from'] = parameters['dateFrom'];
    }

    queryParameters['date_from'] = this.transformParameter(
      queryParameters['date_from'],
    );

    if (typeof parameters['dateTo'] !== 'undefined') {
      queryParameters['date_to'] = parameters['dateTo'];
    }

    queryParameters['date_to'] = this.transformParameter(
      queryParameters['date_to'],
    );

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    if (typeof parameters['sortDirection'] !== 'undefined') {
      queryParameters['sort_direction'] = parameters['sortDirection'];
    }

    queryParameters['sort_direction'] = this.transformParameter(
      queryParameters['sort_direction'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Authorized customer can through this endpoint change his password
   * @method
   * @name API#adminChangePassword
   */
  adminChangePassword(
    customerId: string,
    parameters: {} = {},
    body: {
      new_password: string;
    },
    extraHeaders?: {
      xApiKey?: string;
    },
  ): Promise<void> {
    let path = '/admin/customers/{customer_id}/change-password';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (extraHeaders && typeof extraHeaders['xApiKey'] !== 'undefined') {
      headers.append('X-API-Key', extraHeaders['xApiKey']!);
    }

    path = path.replace('{customer_id}', customerId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Load user groups
   * @method
   * @name API#loadUserGroups
   */
  loadUserGroups(
    parameters: {
      ids?: Array<number>;
      query?: string;
      excludeIds?: Array<number>;
    } = {},
    extraHeaders?: {
      xApiKey?: string;
    },
  ): Promise<{
    items?: Array<{
      id: number;

      title: string;

      type: string;
    }>;
  }> {
    let path = '/admin/customers/user_groups';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (extraHeaders && typeof extraHeaders['xApiKey'] !== 'undefined') {
      headers.append('X-API-Key', extraHeaders['xApiKey']!);
    }

    if (typeof parameters['ids'] !== 'undefined') {
      queryParameters['ids'] = parameters['ids'];
    }

    queryParameters['ids'] = this.transformParameter(queryParameters['ids']);

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    if (typeof parameters['excludeIds'] !== 'undefined') {
      queryParameters['exclude_ids'] = parameters['excludeIds'];
    }

    queryParameters['exclude_ids'] = this.transformParameter(
      queryParameters['exclude_ids'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Delete particular customer's delivery address
   * @method
   * @name API#deleteDeliveryAddress
   */
  deleteDeliveryAddress(
    id: number,
    deliveryAddressId: number,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/customers/{id}/delivery-address/{delivery_address_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    path = path.replace('{delivery_address_id}', deliveryAddressId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update particular customer's delivery address
   * @method
   * @name API#updateDeliveryAddress
   */
  updateDeliveryAddress(
    id: number,
    deliveryAddressId: number,
    parameters: {} = {},
    body: {
      id_user?: number;

      id?: number;

      name: string;

      surname: string;

      delivery_street: string;

      delivery_number: string;

      delivery_zip: string;

      delivery_city: string;

      delivery_phone: string;

      country: string;

      isDefault?: boolean;

      company?: string;

      delivery_email?: string;

      delivery_phone_prefix?: string;
    },
  ): Promise<void> {
    let path = '/customers/{id}/delivery-address/{delivery_address_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    path = path.replace('{delivery_address_id}', deliveryAddressId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Delete particular customer's billing address
   * @method
   * @name API#deleteBillingAddress
   */
  deleteBillingAddress(
    id: number,
    billingAddressId: number,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/customers/{id}/billing-address/{billing_address_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    path = path.replace('{billing_address_id}', billingAddressId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update particular customer's billing address
   * @method
   * @name API#updateBillingAddress
   */
  updateBillingAddress(
    id: number,
    billingAddressId: number,
    parameters: {} = {},
    body: {
      id: number;

      id_user: number;

      name: string;

      surname: string;

      billing_street: string;

      billing_number: string;

      billing_zip: string;

      billing_city: string;

      billing_phone: string;

      country: string;
    },
  ): Promise<void> {
    let path = '/customers/{id}/billing-address/{billing_address_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    path = path.replace('{billing_address_id}', billingAddressId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Load child users for customer

    * @method
    * @name API#loadChildUsers
    */
  loadChildUsers(
    customerId: number,
    parameters: {
      columns?: string;
      onlyActive?: '0' | '1';
    } = {},
  ): Promise<{
    customers?: Array<{
      activate_datetime?: string;

      activatecode?: string;

      autoregister?: boolean;

      card_number?: string;

      cislo?: string;

      country?: string;

      delivery_addresses?: {
        country?: string;

        created_at?: string;

        delivery_city?: string;

        delivery_number?: string;

        delivery_phone?: string;

        delivery_street?: string;

        delivery_zip?: string;

        id?: number;

        id_user?: number;

        name?: string;

        surname?: string;
      };

      dic?: string;

      email?: string;

      facebook_user_id?: string;

      firma?: string;

      google_user_id?: string;

      groupDetail?: {
        group_discount?: number;

        group_id?: number;

        vo?: boolean;
      };

      guid?: string;

      has_ip_access?: boolean;

      highest_trust_points_reached?: number;

      history_points?: {};

      host?: string;

      icdph?: string;

      ico?: string;

      id?: number;

      individualna_zlava?: number;

      interna_poznamka?: string;

      ip?: string;

      karat_id?: string;

      lang?: string;

      login?: string;

      login_hash?: string;

      meno?: string;

      mesto?: string;

      nedoveryhodny?: boolean;

      parentUserId?: number;

      passwd?: string;

      passwd_salt?: string;

      permissions?: number;

      platca_dph?: boolean;

      poznamka?: string;

      priezvisko?: string;

      psc?: string;

      reg_discount?: boolean;

      registrovany?: string;

      shouldChangePassword?: boolean;

      stav?: string;

      telefon?: string;

      trust_points?: number;

      ulica?: string;

      uniqid?: string;
    }>;
  }> {
    let path = '/customers/{customer_id}/child';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['columns'] !== 'undefined') {
      queryParameters['columns'] = parameters['columns'];
    }

    queryParameters['columns'] = this.transformParameter(
      queryParameters['columns'],
    );

    path = path.replace('{customer_id}', customerId.toString());

    queryParameters['only_active'] = '0';

    if (typeof parameters['onlyActive'] !== 'undefined') {
      queryParameters['only_active'] = parameters['onlyActive'];
    }

    queryParameters['only_active'] = this.transformParameter(
      queryParameters['only_active'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Creates new customer as child customer

**DESCRIPTION**

In basic and current implementation of this method new user is created with property: **shouldChangePassword**.
New user after his first log in recieved e-mail with change password link and instructions to change password.

    * @method
    * @name API#createChildUser
    */
  createChildUser(
    customerId: number,
    parameters: {} = {},
    body: {
      email: string;

      password: string;

      name: string;

      surname: string;
    },
  ): Promise<void> {
    let path = '/customers/{customer_id}/child';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{customer_id}', customerId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Load child and siblings users for customer

    * @method
    * @name API#loadChildSiblingsUsers
    */
  loadChildSiblingsUsers(
    customerId: number,
    parameters: {
      columns?: string;
      onlyActive?: '0' | '1';
    } = {},
  ): Promise<{
    customers?: Array<{
      activate_datetime?: string;

      activatecode?: string;

      autoregister?: boolean;

      card_number?: string;

      cislo?: string;

      country?: string;

      delivery_addresses?: {
        country?: string;

        created_at?: string;

        delivery_city?: string;

        delivery_number?: string;

        delivery_phone?: string;

        delivery_street?: string;

        delivery_zip?: string;

        id?: number;

        id_user?: number;

        name?: string;

        surname?: string;
      };

      dic?: string;

      email?: string;

      facebook_user_id?: string;

      firma?: string;

      google_user_id?: string;

      groupDetail?: {
        group_discount?: number;

        group_id?: number;

        vo?: boolean;
      };

      guid?: string;

      has_ip_access?: boolean;

      highest_trust_points_reached?: number;

      history_points?: {};

      host?: string;

      icdph?: string;

      ico?: string;

      id?: number;

      individualna_zlava?: number;

      interna_poznamka?: string;

      ip?: string;

      karat_id?: string;

      lang?: string;

      login?: string;

      login_hash?: string;

      meno?: string;

      mesto?: string;

      nedoveryhodny?: boolean;

      parentUserId?: number;

      passwd?: string;

      passwd_salt?: string;

      permissions?: number;

      platca_dph?: boolean;

      poznamka?: string;

      priezvisko?: string;

      psc?: string;

      reg_discount?: boolean;

      registrovany?: string;

      shouldChangePassword?: boolean;

      stav?: string;

      telefon?: string;

      trust_points?: number;

      ulica?: string;

      uniqid?: string;
    }>;
  }> {
    let path = '/customers/{customer_id}/child-siblings';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['columns'] !== 'undefined') {
      queryParameters['columns'] = parameters['columns'];
    }

    queryParameters['columns'] = this.transformParameter(
      queryParameters['columns'],
    );

    path = path.replace('{customer_id}', customerId.toString());

    queryParameters['only_active'] = '0';

    if (typeof parameters['onlyActive'] !== 'undefined') {
      queryParameters['only_active'] = parameters['onlyActive'];
    }

    queryParameters['only_active'] = this.transformParameter(
      queryParameters['only_active'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Deletes child users for concrete customer

**DESCRIPTION**

Possible problems:

- User you want to delete is not your child user
- Parent user has not sufficient permissions for deleting child users. Look at: ClubUserPermissionsService.ts

    * @method
    * @name API#deleteChildUser
    */
  deleteChildUser(
    customerId: number,
    childId: number,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/customers/{customer_id}/child/{child_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{customer_id}', customerId.toString());

    path = path.replace('{child_id}', childId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Loads related users for customer who is for example MANAGER

    * @method
    * @name API#loadCustomerRelations
    */
  loadCustomerRelations(
    customerId: number,
    parameters: {
      limit?: number;
      offset?: number;
      onlyParents?: '0' | '1';
      relation?: string;
      query?: string;
    } = {},
  ): Promise<void> {
    let path = '/customers/{customer_id}/relations';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{customer_id}', customerId.toString());

    if (typeof parameters['onlyParents'] !== 'undefined') {
      queryParameters['only_parents'] = parameters['onlyParents'];
    }

    queryParameters['only_parents'] = this.transformParameter(
      queryParameters['only_parents'],
    );

    if (typeof parameters['relation'] !== 'undefined') {
      queryParameters['relation'] = parameters['relation'];
    }

    queryParameters['relation'] = this.transformParameter(
      queryParameters['relation'],
    );

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update customer's price display
   * @method
   * @name API#updatePriceDisplay
   */
  updatePriceDisplay(
    id: number,
    parameters: {} = {},
    body: {
      priceRetailWholesaleDisplayType?: number;

      priceVatDisplayType?: number;
    },
  ): Promise<void> {
    let path = '/customers/{id}/price-display';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Returns object with all info about ebook
   * @method
   * @name API#loadEbookDetail
   */
  loadEbookDetail(
    ebookId: number,
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    author?: {
      author_id?: number;

      image?: string;

      name?: string;

      url?: string;
    };

    connected_ebooks?: {};

    created_date?: string;

    downloadLink?: {};

    downloadSize?: string;

    id?: number;

    image?: string;

    json_content?: {};

    name?: string;

    recommendations?: {};

    sf_form?: {};

    tags?: Array<{
      id?: number;

      name?: string;
    }>;

    url?: string;
  }> {
    let path = '/ebook/{ebook_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{ebook_id}', ebookId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Updates category in ElasticSearch
   * @method
   * @name API#updateElasticCategory
   */
  updateElasticCategory(
    categoryId: number,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/elastic/category/{category_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{category_id}', categoryId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Updates category in ElasticSearch
   * @method
   * @name API#loadElasticCategory
   */
  loadElasticCategory(
    categoryId: number,
    parameters: {
      sort?: string;
      sortDir?: 'desc' | 'asc';
      limit?: number;
      min?: number;
      max?: number;
      offset?: number;
      onlyAvailable?: '0' | '1';
      saleout?: '0' | '1';
      new?: '0' | '1';
      sale?: '0' | '1';
      langId: string;
      onlySubcategories: '0' | '1';
      activeAttribs?: string;
      age?: string;
      q?: string;
      onlyWithParent?: '0' | '1';
      onlyWithoutParent?: boolean;
      projectType?: string;
    },
  ): Promise<void> {
    let path = '/elastic/category/{category_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['sort'] = 'default';

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    queryParameters['sort_dir'] = 'desc';

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['min'] !== 'undefined') {
      queryParameters['min'] = parameters['min'];
    }

    queryParameters['min'] = this.transformParameter(queryParameters['min']);

    if (typeof parameters['max'] !== 'undefined') {
      queryParameters['max'] = parameters['max'];
    }

    queryParameters['max'] = this.transformParameter(queryParameters['max']);

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['only_available'] = '0';

    if (typeof parameters['onlyAvailable'] !== 'undefined') {
      queryParameters['only_available'] = parameters['onlyAvailable'];
    }

    queryParameters['only_available'] = this.transformParameter(
      queryParameters['only_available'],
    );

    queryParameters['saleout'] = '0';

    if (typeof parameters['saleout'] !== 'undefined') {
      queryParameters['saleout'] = parameters['saleout'];
    }

    queryParameters['saleout'] = this.transformParameter(
      queryParameters['saleout'],
    );

    queryParameters['new'] = '0';

    if (typeof parameters['new'] !== 'undefined') {
      queryParameters['new'] = parameters['new'];
    }

    queryParameters['new'] = this.transformParameter(queryParameters['new']);

    queryParameters['sale'] = '0';

    if (typeof parameters['sale'] !== 'undefined') {
      queryParameters['sale'] = parameters['sale'];
    }

    queryParameters['sale'] = this.transformParameter(queryParameters['sale']);

    path = path.replace('{category_id}', categoryId.toString());

    if (typeof parameters['langId'] !== 'undefined') {
      queryParameters['lang_id'] = parameters['langId'];
    }

    queryParameters['lang_id'] = this.transformParameter(
      queryParameters['lang_id'],
    );

    if (typeof parameters['langId'] === 'undefined') {
      throw new Error('Missing required parameter: langId');
    }

    queryParameters['only_subcategories'] = '0';

    if (typeof parameters['onlySubcategories'] !== 'undefined') {
      queryParameters['only_subcategories'] = parameters['onlySubcategories'];
    }

    queryParameters['only_subcategories'] = this.transformParameter(
      queryParameters['only_subcategories'],
    );

    if (typeof parameters['onlySubcategories'] === 'undefined') {
      throw new Error('Missing required parameter: onlySubcategories');
    }

    if (typeof parameters['activeAttribs'] !== 'undefined') {
      queryParameters['active_attribs'] = parameters['activeAttribs'];
    }

    queryParameters['active_attribs'] = this.transformParameter(
      queryParameters['active_attribs'],
    );

    if (typeof parameters['age'] !== 'undefined') {
      queryParameters['age'] = parameters['age'];
    }

    queryParameters['age'] = this.transformParameter(queryParameters['age']);

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters['only_with_parent'] = '0';

    if (typeof parameters['onlyWithParent'] !== 'undefined') {
      queryParameters['only_with_parent'] = parameters['onlyWithParent'];
    }

    queryParameters['only_with_parent'] = this.transformParameter(
      queryParameters['only_with_parent'],
    );

    if (typeof parameters['onlyWithoutParent'] !== 'undefined') {
      queryParameters['only_without_parent'] = parameters['onlyWithoutParent'];
    }

    queryParameters['only_without_parent'] = this.transformParameter(
      queryParameters['only_without_parent'],
    );

    if (typeof parameters['projectType'] !== 'undefined') {
      queryParameters['project_type'] = parameters['projectType'];
    }

    queryParameters['project_type'] = this.transformParameter(
      queryParameters['project_type'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Updates category in ElasticSearch
   * @method
   * @name API#loadElasticCategoryProducts
   */
  loadElasticCategoryProducts(
    categoryId: number,
    parameters: {
      sort?: string;
      sortDir?: 'desc' | 'asc';
      limit?: number;
      min?: number;
      max?: number;
      offset?: number;
      onlyAvailable?: '0' | '1';
      saleout?: '0' | '1';
      langId: string;
      onlySubcategories: '0' | '1';
      activeAttribs?: string;
      activeRangeAttribs?: string;
      age?: string;
      new?: '0' | '1';
      sale?: '0' | '1';
      hasGift?: '0' | '1';
      q?: string;
      domainId?: string;
      brandId?: string;
      withVat?: '0' | '1';
      voc?: '0' | '1';
      tagId?: string;
      onlyWithParent?: '0' | '1';
      onlyWithoutParent?: '0' | '1';
      productListId?: string;
      freeDelivery?: number;
      cartId?: string;
      projectType?: string;
    },
  ): Promise<void> {
    let path = '/elastic/category/{category_id}/products';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['sort'] = 'default';

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    queryParameters['sort_dir'] = 'desc';

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['min'] !== 'undefined') {
      queryParameters['min'] = parameters['min'];
    }

    queryParameters['min'] = this.transformParameter(queryParameters['min']);

    if (typeof parameters['max'] !== 'undefined') {
      queryParameters['max'] = parameters['max'];
    }

    queryParameters['max'] = this.transformParameter(queryParameters['max']);

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['only_available'] = '0';

    if (typeof parameters['onlyAvailable'] !== 'undefined') {
      queryParameters['only_available'] = parameters['onlyAvailable'];
    }

    queryParameters['only_available'] = this.transformParameter(
      queryParameters['only_available'],
    );

    queryParameters['saleout'] = '0';

    if (typeof parameters['saleout'] !== 'undefined') {
      queryParameters['saleout'] = parameters['saleout'];
    }

    queryParameters['saleout'] = this.transformParameter(
      queryParameters['saleout'],
    );

    path = path.replace('{category_id}', categoryId.toString());

    if (typeof parameters['langId'] !== 'undefined') {
      queryParameters['lang_id'] = parameters['langId'];
    }

    queryParameters['lang_id'] = this.transformParameter(
      queryParameters['lang_id'],
    );

    if (typeof parameters['langId'] === 'undefined') {
      throw new Error('Missing required parameter: langId');
    }

    queryParameters['only_subcategories'] = '0';

    if (typeof parameters['onlySubcategories'] !== 'undefined') {
      queryParameters['only_subcategories'] = parameters['onlySubcategories'];
    }

    queryParameters['only_subcategories'] = this.transformParameter(
      queryParameters['only_subcategories'],
    );

    if (typeof parameters['onlySubcategories'] === 'undefined') {
      throw new Error('Missing required parameter: onlySubcategories');
    }

    if (typeof parameters['activeAttribs'] !== 'undefined') {
      queryParameters['active_attribs'] = parameters['activeAttribs'];
    }

    queryParameters['active_attribs'] = this.transformParameter(
      queryParameters['active_attribs'],
    );

    if (typeof parameters['activeRangeAttribs'] !== 'undefined') {
      queryParameters['active_range_attribs'] =
        parameters['activeRangeAttribs'];
    }

    queryParameters['active_range_attribs'] = this.transformParameter(
      queryParameters['active_range_attribs'],
    );

    if (typeof parameters['age'] !== 'undefined') {
      queryParameters['age'] = parameters['age'];
    }

    queryParameters['age'] = this.transformParameter(queryParameters['age']);

    queryParameters['new'] = '0';

    if (typeof parameters['new'] !== 'undefined') {
      queryParameters['new'] = parameters['new'];
    }

    queryParameters['new'] = this.transformParameter(queryParameters['new']);

    queryParameters['sale'] = '0';

    if (typeof parameters['sale'] !== 'undefined') {
      queryParameters['sale'] = parameters['sale'];
    }

    queryParameters['sale'] = this.transformParameter(queryParameters['sale']);

    queryParameters['has_gift'] = '0';

    if (typeof parameters['hasGift'] !== 'undefined') {
      queryParameters['has_gift'] = parameters['hasGift'];
    }

    queryParameters['has_gift'] = this.transformParameter(
      queryParameters['has_gift'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['domainId'] !== 'undefined') {
      queryParameters['domain_id'] = parameters['domainId'];
    }

    queryParameters['domain_id'] = this.transformParameter(
      queryParameters['domain_id'],
    );

    if (typeof parameters['brandId'] !== 'undefined') {
      queryParameters['brand_id'] = parameters['brandId'];
    }

    queryParameters['brand_id'] = this.transformParameter(
      queryParameters['brand_id'],
    );

    queryParameters['with_vat'] = '1';

    if (typeof parameters['withVat'] !== 'undefined') {
      queryParameters['with_vat'] = parameters['withVat'];
    }

    queryParameters['with_vat'] = this.transformParameter(
      queryParameters['with_vat'],
    );

    queryParameters['voc'] = '0';

    if (typeof parameters['voc'] !== 'undefined') {
      queryParameters['voc'] = parameters['voc'];
    }

    queryParameters['voc'] = this.transformParameter(queryParameters['voc']);

    if (typeof parameters['tagId'] !== 'undefined') {
      queryParameters['tag_id'] = parameters['tagId'];
    }

    queryParameters['tag_id'] = this.transformParameter(
      queryParameters['tag_id'],
    );

    queryParameters['only_with_parent'] = '0';

    if (typeof parameters['onlyWithParent'] !== 'undefined') {
      queryParameters['only_with_parent'] = parameters['onlyWithParent'];
    }

    queryParameters['only_with_parent'] = this.transformParameter(
      queryParameters['only_with_parent'],
    );

    queryParameters['only_without_parent'] = '0';

    if (typeof parameters['onlyWithoutParent'] !== 'undefined') {
      queryParameters['only_without_parent'] = parameters['onlyWithoutParent'];
    }

    queryParameters['only_without_parent'] = this.transformParameter(
      queryParameters['only_without_parent'],
    );

    if (typeof parameters['productListId'] !== 'undefined') {
      queryParameters['product_list_id'] = parameters['productListId'];
    }

    queryParameters['product_list_id'] = this.transformParameter(
      queryParameters['product_list_id'],
    );

    if (typeof parameters['freeDelivery'] !== 'undefined') {
      queryParameters['free_delivery'] = parameters['freeDelivery'];
    }

    queryParameters['free_delivery'] = this.transformParameter(
      queryParameters['free_delivery'],
    );

    if (typeof parameters['cartId'] !== 'undefined') {
      queryParameters['cart_id'] = parameters['cartId'];
    }

    queryParameters['cart_id'] = this.transformParameter(
      queryParameters['cart_id'],
    );

    if (typeof parameters['projectType'] !== 'undefined') {
      queryParameters['project_type'] = parameters['projectType'];
    }

    queryParameters['project_type'] = this.transformParameter(
      queryParameters['project_type'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Updates product in ElasticSearch
   * @method
   * @name API#updateElasticProduct
   */
  updateElasticProduct(productId: number, parameters: {} = {}): Promise<void> {
    let path = '/elastic/products/{product_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Loads articles from ElasticSearch
   * @method
   * @name API#loadElasticArticles
   */
  loadElasticArticles(parameters: {
    sort?: string;
    sortDir?: 'desc' | 'asc';
    limit?: number;
    offset?: number;
    langId: string;
    q?: string;
    domainId?: string;
    productsDomainId?: number;
  }): Promise<void> {
    let path = '/elastic/articles';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['sort'] = 'default';

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    queryParameters['sort_dir'] = 'desc';

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['langId'] !== 'undefined') {
      queryParameters['lang_id'] = parameters['langId'];
    }

    queryParameters['lang_id'] = this.transformParameter(
      queryParameters['lang_id'],
    );

    if (typeof parameters['langId'] === 'undefined') {
      throw new Error('Missing required parameter: langId');
    }

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['domainId'] !== 'undefined') {
      queryParameters['domain_id'] = parameters['domainId'];
    }

    queryParameters['domain_id'] = this.transformParameter(
      queryParameters['domain_id'],
    );

    if (typeof parameters['productsDomainId'] !== 'undefined') {
      queryParameters['products_domain_id'] = parameters['productsDomainId'];
    }

    queryParameters['products_domain_id'] = this.transformParameter(
      queryParameters['products_domain_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * List of eshop gifts
   * @method
   * @name API#loadEshopGifts
   */
  loadEshopGifts(
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    gifts?: Array<{
      product_id: string;

      product_name?: string;

      product_url?: string;

      picture?: string;

      price_from: number;

      available?: 0 | 1;
    }>;
  }> {
    let path = '/eshop-gifts';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Creates gift in the database
   * @method
   * @name API#createGift
   */
  createGift(
    parameters: {} = {},
    body: {
      product_id: number;

      price_from: number;

      available: 0 | 1;
    },
  ): Promise<{
    available?: '0' | '1';

    picture?: string;

    price_from: number;

    product_id: string;

    product_name?: string;
  }> {
    let path = '/eshop-gifts';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Deletes gift in the database
   * @method
   * @name API#deleteGift
   */
  deleteGift(
    productId: number,
    parameters: {} = {},
  ): Promise<{
    status?: string;
  }> {
    let path = '/eshop-gifts/{product_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Load gift detail
   * @method
   * @name API#loadGiftDetail
   */
  loadGiftDetail(
    productId: number,
    parameters: {} = {},
  ): Promise<{
    product_id: string;

    product_name?: string;

    product_url?: string;

    picture?: string;

    price_from: number;

    available?: 0 | 1;
  }> {
    let path = '/eshop-gifts/{product_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Updates gift price_from in the database
   * @method
   * @name API#updateGift
   */
  updateGift(
    productId: number,
    parameters: {} = {},
    body: {
      available?: '0' | '1';

      price_from?: number;
    },
  ): Promise<{
    product_id: string;

    product_name?: string;

    product_url?: string;

    picture?: string;

    price_from: number;

    available?: 0 | 1;
  }> {
    let path = '/eshop-gifts/{product_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * List of eshop gift cards
   * @method
   * @name API#loadEshopGiftCards
   */
  loadEshopGiftCards(parameters: {
    cardNr: string;
    withSales?: number;
    limit?: number;
    offset?: number;
  }): Promise<{
    giftcards?: Array<{
      id: number;

      order_id: number;

      card_number: string;

      date_validity: string;

      date_validity_from: string;

      price: number;

      price_currency: string;

      price_validity: number;

      valid_products_count: number;

      discount: number;

      discount_currency: string;

      is_strict_discount: 0 | 1;

      card_status: string;

      date_used: string;

      club_user_id: number;

      created_date: string;

      created_user_id: number;

      multicard: 0 | 1;

      sale: number;

      sale_count: number;

      apply_per_unit: 0 | 1;

      lang: string;

      valid_product_ids?: Array<number>;

      count_use?: number;

      sales?: Array<{}>;
    }>;

    limit?: number;

    offset?: number;

    total?: number;
  }> {
    let path = '/eshop-gift-cards';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['cardNr'] !== 'undefined') {
      queryParameters['card_nr'] = parameters['cardNr'];
    }

    queryParameters['card_nr'] = this.transformParameter(
      queryParameters['card_nr'],
    );

    if (typeof parameters['cardNr'] === 'undefined') {
      throw new Error('Missing required parameter: cardNr');
    }

    if (typeof parameters['withSales'] !== 'undefined') {
      queryParameters['with_sales'] = parameters['withSales'];
    }

    queryParameters['with_sales'] = this.transformParameter(
      queryParameters['with_sales'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Prepare cart from giftcard
   * @method
   * @name API#giftCardPrepareCart
   */
  giftCardPrepareCart(
    cardNr: string,
    cartId: string,
    parameters: {} = {},
  ): Promise<{
    id: string;

    total_items_count: number;

    total_items_price: number;

    total_items_price_without_vat: number;

    total_items_price_before_giftcards: number;

    total_items_price_without_vat_before_giftcards: number;

    total_giftcards_price: number;

    total_price: number;

    total_price_without_vat?: number;

    price_rounding_value?: number;

    currency: string;

    discount: number;

    discount_save: number;

    discount_reg: number;

    assigned_points: number;

    consumed_points: number;

    items: Array<{
      count: number;

      unpacking_cost?: number;

      good: {
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        final_price_without_vat?: number | null;

        customer_discount?: number | null;

        b2b_discount?: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        sum_price?: number | null;

        sum_price_without_vat?: number | null;

        sum_unpacking_cost?: number | null;

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        discount_pcn?: number;

        color?: Array<{}>;

        length?: number;

        width?: number;

        height?: number;
      };

      product: {
        id: number;

        ean: string | null;

        name: string;

        plu: string | null;

        url: string;

        picture: string | null;

        name_url_encoded: string;

        productPackages?: Array<{
          id?: number;

          productId?: number;

          storeQuantity?: number;

          fullQuantity?: number;

          type?: number;
        }>;
      };

      idx: number;

      sum_price?: number;

      sum_price_without_vat?: number;

      sum_points: number;

      store: {
        store_id?: number;

        store_name?: string;

        store_phone?: string;

        store_email?: string;

        store_infourl?: string;

        store_priority?: number;

        store_minqty?: number;

        store_type?: 'STORE' | 'SHOP';
      };

      availability: {
        avail_id?: number;

        avail_uniqid?: string;

        order_available?: number;

        translations?: Array<{
          avail_id: number;

          lang: string;

          avail_name: string;
        }>;
      };

      product_note?: string | null;
    }>;

    giftcards: Array<{
      id: number;

      order_id: number;

      card_number: string;

      date_validity: string;

      date_validity_from: string;

      price: number;

      price_currency: string;

      price_validity: number;

      valid_products_count: number;

      discount: number;

      discount_currency: string;

      is_strict_discount: 0 | 1;

      card_status: string;

      date_used: string;

      club_user_id: number;

      created_date: string;

      created_user_id: number;

      multicard: 0 | 1;

      sale: number;

      sale_count: number;

      apply_per_unit: 0 | 1;

      lang: string;

      valid_product_ids?: Array<number>;

      count_use?: number;

      sales?: Array<{}>;
    }>;

    pointcards: Array<{
      id: number;

      name: string;

      points: number;

      discount: number;

      count: number;
    }>;

    sales: Array<{
      id: number | null;

      name: string;

      type: 'PRODUCTS' | 'CART';

      buy_count: number;

      buy_price: number;

      buy_price_currency: number;

      sale_count: number;

      sale_discount: number;

      sale_unitprice: number;

      sale_currency: string;

      sale_name: string;

      sale_choose_only: boolean;

      priority: number;

      valid_from: string | null;

      valid_to: string | null;

      members_only: boolean;

      combine_possible: boolean;

      is_strict_discount: boolean;

      transport_price: number;

      gift: string | null;

      lang: string;

      more_info_link: string;

      products: Array<{
        id_product?: number;

        id_sale?: number;

        buy?: boolean;

        sale?: boolean;

        name?: string;
      }>;
    }>;

    delivery: {
      delivery_id: string;

      delivery_name: string;

      delivery_descr: string | null;

      multistore: 0 | 1;

      delivery_sort: number | null;

      delivery_priority: number;

      min_weight: number | null;

      max_weight: number | null;

      price: {
        currency_id: string;

        price: number | null;
      };

      translations: Array<{
        lang_id: string;

        delivery_id: string;

        delivery_name: string | null;

        delivery_descr: string | null;
      }>;

      payments: Array<{
        payment_id: string;

        payment_name: string;

        payment_descr: string;

        payment_priority: number;

        payment_sort: number | null;

        price: {
          currency_id: string;

          price: number | null;
        };

        translations: Array<{
          lang_id: string;

          payment_id: string;

          payment_name: string | null;

          payment_descr: string | null;
        }>;
      }>;
    };

    lang: string;

    user_id?: number | null;

    quatro?: {
      quatro_status: string | null;

      quatro_kod: string | null;

      quatro_url: string | null;

      quatro_oz: string | null;
    };

    shop?: {
      shop_id?: number;

      shop_name?: string;

      shop_address?: {
        person?: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      shop_description?: string;

      shop_openhours?: string;

      gps_latitude?: number;

      gps_longitude?: number;

      email?: string;

      phone?: string;

      shop_type?: string;
    };

    posta?: {
      id: number;

      name: string;

      address: {
        person?: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      gps_latitude: number;

      gps_longitude: number;

      xml_details?: string;
    };

    vat_rate?: number;

    group_discount?: number;

    individual_discount?: number;

    discount_amount?: number;

    problems?: Array<{
      type?: string;

      readable?: string;

      payload?: {};

      step?: number;
    }>;

    card_number?: string;

    zasielkovna_id: string;

    dpd_id?: string;

    dhl_parcel_shop_code?: string;

    chosen_gift?: {};

    note?: string;

    vat_groups?: {
      group_value?: {
        price?: number;

        price_without_vat?: number;

        vat_rate?: number;
      };
    };

    has_known_ico?: boolean;
  }> {
    let path = '/eshop-gift-cards/{card_nr}/prepare-cart/{cart_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{card_nr}', cardNr.toString());

    path = path.replace('{cart_id}', cartId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create order for contents
   * @method
   * @name API#giftCardCreateOrder
   */
  giftCardCreateOrder(
    cartId: string,
    parameters: {} = {},
    body: {
      delivery?: {};

      payment?: {};

      delivery_address?: {
        person: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street?: string;

        street_number?: string;

        zip?: string;

        city?: string;

        country?: string;

        country_code?: string;

        country_id?: string;

        company?: string;

        delivery_address_id?: number;
      };

      billing_address: {
        person: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street?: string;

        street_number?: string;

        zip?: string;

        city?: string;

        country?: string;

        country_code?: string;

        country_id?: string;

        company?: string;

        delivery_address_id?: number;
      };

      company: {
        name: string | null;

        ico: string | null;

        dic: string | null;

        ic_dph: string | null;
      };

      billing_company: boolean;

      use_delivery_address: boolean;

      terms_accept: boolean;

      newsletter_accept: boolean;

      heureka: boolean;

      heureka_refuse?: boolean;

      note: string;

      cart_label?: string;

      step: number;

      zasielkovna_id?: string;

      dpd_id?: string;

      consumed_points: number;

      chosen_gift?: {};

      vat_payer?: boolean;
    },
  ): Promise<{
    orderPublicId: string;
  }> {
    let path = '/eshop-gift-cards/{cart_id}/order';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{cart_id}', cartId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * List of eshop gift cards groups
   * @method
   * @name API#loadGiftcardsOuterGroups
   */
  loadGiftcardsOuterGroups(
    parameters: {
      query?: string;
      viewType?: 'admin' | 'public' | 'autocomplete';
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{}> {
    let path = '/eshop-gift-cards/outer-groups';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    if (typeof parameters['viewType'] !== 'undefined') {
      queryParameters['view_type'] = parameters['viewType'];
    }

    queryParameters['view_type'] = this.transformParameter(
      queryParameters['view_type'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create giftcards group
   * @method
   * @name API#createGiftcardOuterGroup
   */
  createGiftcardOuterGroup(
    parameters: {} = {},
    body: {
      name: string;

      outer_id: string;
    },
  ): Promise<{}> {
    let path = '/eshop-gift-cards/outer-groups';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Bulk update giftcards groups
   * @method
   * @name API#bulkUpdateGiftcardOuterGroup
   */
  bulkUpdateGiftcardOuterGroup(
    parameters: {} = {},
    body: {
      bulk_data: Array<{
        id: number;

        name: string;

        outer_id: string;
      }>;
    },
  ): Promise<{}> {
    let path = '/eshop-gift-cards/outer-groups';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Delete giftcards group
   * @method
   * @name API#deleteGiftcardOuterGroup
   */
  deleteGiftcardOuterGroup(groupId: number, parameters: {} = {}): Promise<{}> {
    let path = '/eshop-gift-cards/outer-groups/{group_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{group_id}', groupId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Delete giftcards group
   * @method
   * @name API#updateGiftcardOuterGroup
   */
  updateGiftcardOuterGroup(
    groupId: number,
    parameters: {} = {},
    body: {
      name: string;

      outer_id: string;
    },
  ): Promise<{}> {
    let path = '/eshop-gift-cards/outer-groups/{group_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{group_id}', groupId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#loadExpeditionFreeDays
   */
  loadExpeditionFreeDays(
    parameters: {
      marketId?: string;
    } = {},
  ): Promise<void> {
    let path = '/expedition-free-days';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['marketId'] !== 'undefined') {
      queryParameters['market_id'] = parameters['marketId'];
    }

    queryParameters['market_id'] = this.transformParameter(
      queryParameters['market_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#createExpeditionFreeDay
   */
  createExpeditionFreeDay(
    parameters: {} = {},
    body: {
      description: string;

      free_date: string;

      id: number;

      market_id: string;
    },
  ): Promise<void> {
    let path = '/expedition-free-days';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#updateExpeditionFreeDay
   */
  updateExpeditionFreeDay(
    id: string,
    parameters: {} = {},
    body: {
      id: number | null;

      free_date: string;

      description: string;

      market_id: string;
    },
  ): Promise<void> {
    let path = '/expedition-free-days/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#deleteExpeditionFreeDay
   */
  deleteExpeditionFreeDay(id: string, parameters: {} = {}): Promise<void> {
    let path = '/expedition-free-days/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#loadExpeditionHours
   */
  loadExpeditionHours(
    parameters: {
      marketId?: string;
    } = {},
  ): Promise<void> {
    let path = '/expedition-hours';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['marketId'] !== 'undefined') {
      queryParameters['market_id'] = parameters['marketId'];
    }

    queryParameters['market_id'] = this.transformParameter(
      queryParameters['market_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#createExpeditionHour
   */
  createExpeditionHour(
    parameters: {} = {},
    body: {
      day: number;

      id: number;

      market_id: string;

      time_from: string;

      time_to: string;
    },
  ): Promise<void> {
    let path = '/expedition-hours';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#updateExpeditionHour
   */
  updateExpeditionHour(
    id: string,
    parameters: {} = {},
    body: {
      id: number | null;

      day: number;

      time_from: string;

      time_to: string;

      market_id: string;
    },
  ): Promise<void> {
    let path = '/expedition-hours/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Recieve a file from the server
   * @method
   * @name API#processFile
   */
  processFile(parameters: { query: string }): Promise<{}> {
    let path = '/file';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    if (typeof parameters['query'] === 'undefined') {
      throw new Error('Missing required parameter: query');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Heureka eshop reviews
   * @method
   * @name API#loadHeurekaEshopReviews
   */
  loadHeurekaEshopReviews(
    parameters: {
      limit?: number;
      offset?: number;
      totalRatingMore?: number;
    } = {},
  ): Promise<{
    reviews?: Array<{
      rating_id: string;

      first_imported_at?: string;

      last_imported_at?: string;

      source?: string;

      unix_timestamp?: string;

      total_rating?: number;

      recommends?: number;

      delivery_time?: number;

      transport_quality?: number;

      communication?: number;

      summary?: string;

      order_id?: string;
    }>;
  }> {
    let path = '/heureka/eshop-reviews';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['totalRatingMore'] !== 'undefined') {
      queryParameters['total_rating_more'] = parameters['totalRatingMore'];
    }

    queryParameters['total_rating_more'] = this.transformParameter(
      queryParameters['total_rating_more'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Recieve an image from the server
   * @method
   * @name API#processImage
   */
  processImage(parameters: {
    query: string;
    webp?: '0' | '1';
    quality?: number;
    type?: 'cover' | 'contain' | 'fill' | 'inside' | 'outside' | 'normal';
    width?: number;
    height?: number;
    color?: string;
  }): Promise<{}> {
    let path = '/image';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    if (typeof parameters['query'] === 'undefined') {
      throw new Error('Missing required parameter: query');
    }

    queryParameters['webp'] = '0';

    if (typeof parameters['webp'] !== 'undefined') {
      queryParameters['webp'] = parameters['webp'];
    }

    queryParameters['webp'] = this.transformParameter(queryParameters['webp']);

    if (typeof parameters['quality'] !== 'undefined') {
      queryParameters['quality'] = parameters['quality'];
    }

    queryParameters['quality'] = this.transformParameter(
      queryParameters['quality'],
    );

    queryParameters['type'] = 'contain';

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['width'] !== 'undefined') {
      queryParameters['width'] = parameters['width'];
    }

    queryParameters['width'] = this.transformParameter(
      queryParameters['width'],
    );

    if (typeof parameters['height'] !== 'undefined') {
      queryParameters['height'] = parameters['height'];
    }

    queryParameters['height'] = this.transformParameter(
      queryParameters['height'],
    );

    queryParameters['color'] = 'white';

    if (typeof parameters['color'] !== 'undefined') {
      queryParameters['color'] = parameters['color'];
    }

    queryParameters['color'] = this.transformParameter(
      queryParameters['color'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Recieve an image from the server
   * @method
   * @name API#processImageHead
   */
  processImageHead(parameters: {
    query: string;
    webp?: '0' | '1';
    type?: 'cover' | 'contain' | 'fill' | 'inside' | 'outside' | 'normal';
    width?: number;
    height?: number;
    color?: string;
  }): Promise<{}> {
    let path = '/image';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    if (typeof parameters['query'] === 'undefined') {
      throw new Error('Missing required parameter: query');
    }

    queryParameters['webp'] = '0';

    if (typeof parameters['webp'] !== 'undefined') {
      queryParameters['webp'] = parameters['webp'];
    }

    queryParameters['webp'] = this.transformParameter(queryParameters['webp']);

    queryParameters['type'] = 'contain';

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['width'] !== 'undefined') {
      queryParameters['width'] = parameters['width'];
    }

    queryParameters['width'] = this.transformParameter(
      queryParameters['width'],
    );

    if (typeof parameters['height'] !== 'undefined') {
      queryParameters['height'] = parameters['height'];
    }

    queryParameters['height'] = this.transformParameter(
      queryParameters['height'],
    );

    queryParameters['color'] = 'white';

    if (typeof parameters['color'] !== 'undefined') {
      queryParameters['color'] = parameters['color'];
    }

    queryParameters['color'] = this.transformParameter(
      queryParameters['color'],
    );

    return this.request(
      'HEAD',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Recieve an image from the server
   * @method
   * @name API#processProductImage
   */
  processProductImage(
    productId: number,
    parameters: {
      webp?: '0' | '1';
      type?: 'cover' | 'contain' | 'fill' | 'inside' | 'outside' | 'normal';
      width?: number;
      height?: number;
      color?: string;
    } = {},
  ): Promise<{}> {
    let path = '/image-product/{product_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    queryParameters['webp'] = '0';

    if (typeof parameters['webp'] !== 'undefined') {
      queryParameters['webp'] = parameters['webp'];
    }

    queryParameters['webp'] = this.transformParameter(queryParameters['webp']);

    queryParameters['type'] = 'contain';

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['width'] !== 'undefined') {
      queryParameters['width'] = parameters['width'];
    }

    queryParameters['width'] = this.transformParameter(
      queryParameters['width'],
    );

    if (typeof parameters['height'] !== 'undefined') {
      queryParameters['height'] = parameters['height'];
    }

    queryParameters['height'] = this.transformParameter(
      queryParameters['height'],
    );

    queryParameters['color'] = 'white';

    if (typeof parameters['color'] !== 'undefined') {
      queryParameters['color'] = parameters['color'];
    }

    queryParameters['color'] = this.transformParameter(
      queryParameters['color'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Recieve an image from the server
   * @method
   * @name API#processImageWithDescritptiveUrl
   */
  processImageWithDescritptiveUrl(
    entityType: 'product' | 'category' | 'attrib_value',
    slug: string,
    parameters: {
      query: string;
      webp?: '0' | '1';
      entityId?: string;
      type?: 'cover' | 'contain' | 'fill' | 'inside' | 'outside' | 'normal';
      width?: number;
      height?: number;
      color?: string;
    },
  ): Promise<{}> {
    let path = '/image/{entity_type}/{slug}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{entity_type}', entityType.toString());

    path = path.replace('{slug}', slug.toString());

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    if (typeof parameters['query'] === 'undefined') {
      throw new Error('Missing required parameter: query');
    }

    queryParameters['webp'] = '0';

    if (typeof parameters['webp'] !== 'undefined') {
      queryParameters['webp'] = parameters['webp'];
    }

    queryParameters['webp'] = this.transformParameter(queryParameters['webp']);

    if (typeof parameters['entityId'] !== 'undefined') {
      queryParameters['entity_id'] = parameters['entityId'];
    }

    queryParameters['entity_id'] = this.transformParameter(
      queryParameters['entity_id'],
    );

    queryParameters['type'] = 'contain';

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['width'] !== 'undefined') {
      queryParameters['width'] = parameters['width'];
    }

    queryParameters['width'] = this.transformParameter(
      queryParameters['width'],
    );

    if (typeof parameters['height'] !== 'undefined') {
      queryParameters['height'] = parameters['height'];
    }

    queryParameters['height'] = this.transformParameter(
      queryParameters['height'],
    );

    queryParameters['color'] = 'white';

    if (typeof parameters['color'] !== 'undefined') {
      queryParameters['color'] = parameters['color'];
    }

    queryParameters['color'] = this.transformParameter(
      queryParameters['color'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Recieve an image from the server
   * @method
   * @name API#imageWithouQuery
   */
  imageWithouQuery(
    entityType: 'product' | 'category' | 'attrib_value',
    sizeId: 'base' | 'thumbnail' | 'small' | 'default',
    slug: string,
    imageFormat: 'webp' | 'jpeg' | 'png',
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/image/{entity_type}/{size_id}/{slug}.{image_format}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{entity_type}', entityType.toString());

    path = path.replace('{size_id}', sizeId.toString());

    path = path.replace('{slug}', slug.toString());

    path = path.replace('{image_format}', imageFormat.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Recieve an image from the server
   * @method
   * @name API#imageWithoutQueryDocument
   */
  imageWithoutQueryDocument(
    entityType: 'product' | 'category' | 'attrib_value',
    sizeId: 'base' | 'thumbnail' | 'small',
    slug: string,
    documentNumber: number,
    imageFormat: 'webp' | 'jpeg' | 'png',
    parameters: {} = {},
  ): Promise<{}> {
    let path =
      '/image/{entity_type}/{size_id}/{slug}/{document_number}.{image_format}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{entity_type}', entityType.toString());

    path = path.replace('{size_id}', sizeId.toString());

    path = path.replace('{slug}', slug.toString());

    path = path.replace('{document_number}', documentNumber.toString());

    path = path.replace('{image_format}', imageFormat.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * save product impression
   * @method
   * @name API#saveImpressionProduct
   */
  saveImpressionProduct(
    productId: string,
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
      xOriginalUserAgent?: string;
    },
  ): Promise<{}> {
    let path = '/impressions/product/{product_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (
      extraHeaders &&
      typeof extraHeaders['xOriginalUserAgent'] !== 'undefined'
    ) {
      headers.append(
        'x-original-user-agent',
        extraHeaders['xOriginalUserAgent']!,
      );
    }

    path = path.replace('{product_id}', productId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * save article impression
   * @method
   * @name API#saveImpressionArticle
   */
  saveImpressionArticle(
    articleId: string,
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
      xOriginalUserAgent?: string;
    },
  ): Promise<{}> {
    let path = '/impressions/article/{article_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (
      extraHeaders &&
      typeof extraHeaders['xOriginalUserAgent'] !== 'undefined'
    ) {
      headers.append(
        'x-original-user-agent',
        extraHeaders['xOriginalUserAgent']!,
      );
    }

    path = path.replace('{article_id}', articleId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * returns basic info about most read articles. This route returns list of most read articles composed from impressions table and manual web_content_most_read submissions
   * @method
   * @name API#loadMostReadArticles
   */
  loadMostReadArticles(
    parameters: {
      limit?: number;
      offset?: number;
      type?: 'week' | 'month' | 'year';
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    articles?: Array<{
      author?: {
        author_id?: number;

        image?: string;

        name?: string;

        url?: string;
      };

      created_date?: string;

      created_user_id?: number;

      documents?: Array<{
        content_id?: number;

        created_date?: string;

        created_user?: number;

        document_description?: string;

        document_id?: number;

        document_name?: string;

        document_path?: string;

        document_type?: string;

        privatedoc?: boolean;
      }>;

      downloads?: Array<{
        content_id?: number;

        created_date?: string;

        created_user?: number;

        document_description?: string;

        document_id?: number;

        document_name?: string;

        document_path?: string;

        document_type?: string;

        privatedoc?: boolean;
      }>;

      draft?: boolean;

      hint?: string;

      home?: boolean;

      id?: number;

      is_top?: boolean;

      json_content?: {};

      lang_id?: string;

      lang_version_id?: number;

      last_update?: string;

      name?: string;

      next_article?: {
        annotation?: string;

        id?: string;

        image?: string;

        name?: string;

        url?: string;
      };

      ordering?: number;

      original_id?: string;

      photogallery?: Array<{
        content_id?: number;

        created_date?: string;

        created_user?: number;

        document_description?: string;

        document_id?: number;

        document_name?: string;

        document_path?: string;

        document_type?: string;

        privatedoc?: boolean;
      }>;

      products?: Array<{
        attribs?: Array<{
          attrib_code?: string;

          attrib_grid?: number;

          attrib_id?: number;

          attrib_ismulti?: number;

          attrib_list?: number;

          attrib_name?: string;

          attrib_pbl?: number;

          attrib_sort?: number;

          attrib_type?: string;

          attrib_uid?: string;

          attrib_unit?: string;

          attrib_value?: string;

          custom_value?: string;

          group_id?: number;

          group_name?: string;

          product_id?: number;

          translations?: Array<{
            attrib_id?: number;

            attrib_name?: string;

            attrib_unit?: string;

            lang_id?: string;
          }>;

          type_id?: number;

          value_groups?: Array<{
            attrib_id?: number;

            group_id?: number;

            group_name?: string;

            group_sort?: number;
          }>;

          value_id?: number;

          values?: Array<{
            amount?: number;

            attrib_id?: number;

            attrib_pict?: string;

            attrib_value?: string;

            group_id?: number;

            istop?: boolean;

            searchfilter?: number;

            translations?: Array<{
              attrib_url?: string;

              attrib_value?: string;

              lang_id?: string;

              secondary_name?: string;

              value_id?: number;
            }>;

            value_id?: number;

            value_sort?: number;
          }>;
        }>;

        brand?: {
          brand_descr?: string;

          brand_id?: number;

          brand_image?: string;

          brand_name?: string;

          brand_provider?: string;

          brand_top?: boolean;

          brand_weburl?: string;

          catalog_id?: number;

          translations?: Array<{
            brand_descr?: string;

            brand_id?: number;

            brand_name?: string;

            brand_weburl?: string;

            lang_id?: string;
          }>;

          url?: string;
        };

        brand_id?: number;

        category_text?: string;

        created_at?: string;

        created_datetime?: string;

        description?: string;

        disable_discount?: boolean;

        disable_solo_order?: boolean;

        ean?: string;

        goods?: Array<{
          availability?: {
            avail_id?: number;

            avail_uniqid?: string;

            order_available?: number;

            translations?: Array<{
              avail_id?: number;

              avail_name?: string;

              lang?: string;
            }>;
          };

          availability_date?: string;

          availability_id?: number;

          buy_price?: number;

          currency?: {
            id?: string;

            name?: string;
          };

          currency_id?: string;

          customer_prices?: {};

          discount_pcn?: number;

          ean?: string;

          final_price?: number;

          from_group_sale?: boolean;

          from_sale?: boolean;

          giftcard?: {
            apply_per_unit?: boolean;

            card_number?: string;

            card_status?: string;

            club_user_id?: number;

            created_date?: string;

            created_user_id?: number;

            date_used?: string;

            date_validity?: string;

            discount?: number;

            discount_currency?: string;

            freeshipping?: boolean;

            freeshippingeu?: boolean;

            id?: number;

            idx?: number;

            is_strict_discount?: boolean;

            lang?: string;

            multicard?: boolean;

            order_id?: number;

            price?: number;

            price_currency?: string;

            price_validity?: number;

            sale?: number;

            sale_count?: number;

            valid_product_ids?: Array<number>;

            valid_products_count?: number;
          };

          good_id?: number;

          group_price?: {
            oldprice?: number;

            sale_percentage?: number;

            unitprice?: number;
          };

          group_prices?: Array<{}>;

          idoklad_id?: string;

          imagePath?: string;

          internal_code?: string;

          is_on_stock?: boolean;

          karat_id?: string;

          lang?: string;

          oldprice?: number;

          on_stock_count?: number;

          order_number?: string;

          order_quantity_limit?: number;

          point_good?: boolean;

          points_amount?: number;

          price_without_vat?: number;

          price_without_vat_sale?: number;

          priceinfo?: string;

          product_id?: number;

          product_name?: string;

          product_note?: string;

          product_type?: number;

          purchaseprice?: number;

          qtyDiscounts?: {};

          sale?: boolean;

          sale_percentage?: number;

          sale_valid?: string;

          unit_price_sale?: number;

          unitprice?: number;

          unitprice_sale?: number;

          units?: string;

          valid_from?: string;

          valid_to?: string;

          vat_rate?: number;

          web_attribs?: Array<{
            attrib_code?: string;

            attrib_grid?: number;

            attrib_id?: number;

            attrib_ismulti?: number;

            attrib_list?: number;

            attrib_name?: string;

            attrib_pbl?: number;

            attrib_sort?: number;

            attrib_type?: string;

            attrib_uid?: string;

            attrib_unit?: string;

            attrib_value?: string;

            custom_value?: string;

            group_id?: number;

            group_name?: string;

            product_id?: number;

            translations?: Array<{
              attrib_id?: number;

              attrib_name?: string;

              attrib_unit?: string;

              lang_id?: string;
            }>;

            type_id?: number;

            value_groups?: Array<{
              attrib_id?: number;

              group_id?: number;

              group_name?: string;

              group_sort?: number;
            }>;

            value_id?: number;

            values?: Array<{
              amount?: number;

              attrib_id?: number;

              attrib_pict?: string;

              attrib_value?: string;

              group_id?: number;

              istop?: boolean;

              searchfilter?: number;

              translations?: Array<{
                attrib_url?: string;

                attrib_value?: string;

                lang_id?: string;

                secondary_name?: string;

                value_id?: number;
              }>;

              value_id?: number;

              value_sort?: number;
            }>;
          }>;

          web_order_attribs?: Array<{
            attrib_code?: string;

            attrib_grid?: number;

            attrib_id?: number;

            attrib_ismulti?: number;

            attrib_list?: number;

            attrib_name?: string;

            attrib_pbl?: number;

            attrib_sort?: number;

            attrib_type?: string;

            attrib_uid?: string;

            attrib_unit?: string;

            attrib_value?: string;

            custom_value?: string;

            group_id?: number;

            group_name?: string;

            product_id?: number;

            translations?: Array<{
              attrib_id?: number;

              attrib_name?: string;

              attrib_unit?: string;

              lang_id?: string;
            }>;

            type_id?: number;

            value_groups?: Array<{
              attrib_id?: number;

              group_id?: number;

              group_name?: string;

              group_sort?: number;
            }>;

            value_id?: number;

            values?: Array<{
              amount?: number;

              attrib_id?: number;

              attrib_pict?: string;

              attrib_value?: string;

              group_id?: number;

              istop?: boolean;

              searchfilter?: number;

              translations?: Array<{
                attrib_url?: string;

                attrib_value?: string;

                lang_id?: string;

                secondary_name?: string;

                value_id?: number;
              }>;

              value_id?: number;

              value_sort?: number;
            }>;
          }>;

          weight?: number;
        }>;

        heureka?: number;

        heureka_id?: number;

        internal_code?: number;

        is_favorite?: boolean;

        is_new?: boolean;

        is_popular?: boolean;

        is_recommended?: boolean;

        is_sale?: boolean;

        isactive?: number;

        isnew_valid?: string;

        issale_buy3_pay2?: number;

        issaleout?: boolean;

        issaleout_since?: string;

        issaleout_valid?: string;

        istop?: boolean;

        locked?: number;

        main_good?: {
          availability?: {
            avail_id?: number;

            avail_uniqid?: string;

            order_available?: number;

            translations?: Array<{
              avail_id?: number;

              avail_name?: string;

              lang?: string;
            }>;
          };

          availability_date?: string;

          availability_id?: number;

          buy_price?: number;

          currency?: {
            id?: string;

            name?: string;
          };

          currency_id?: string;

          customer_prices?: {};

          discount_pcn?: number;

          ean?: string;

          final_price?: number;

          from_group_sale?: boolean;

          from_sale?: boolean;

          giftcard?: {
            apply_per_unit?: boolean;

            card_number?: string;

            card_status?: string;

            club_user_id?: number;

            created_date?: string;

            created_user_id?: number;

            date_used?: string;

            date_validity?: string;

            discount?: number;

            discount_currency?: string;

            freeshipping?: boolean;

            freeshippingeu?: boolean;

            id?: number;

            idx?: number;

            is_strict_discount?: boolean;

            lang?: string;

            multicard?: boolean;

            order_id?: number;

            price?: number;

            price_currency?: string;

            price_validity?: number;

            sale?: number;

            sale_count?: number;

            valid_product_ids?: Array<number>;

            valid_products_count?: number;
          };

          good_id?: number;

          group_price?: {
            oldprice?: number;

            sale_percentage?: number;

            unitprice?: number;
          };

          group_prices?: Array<{}>;

          idoklad_id?: string;

          imagePath?: string;

          internal_code?: string;

          is_on_stock?: boolean;

          karat_id?: string;

          lang?: string;

          oldprice?: number;

          on_stock_count?: number;

          order_number?: string;

          order_quantity_limit?: number;

          point_good?: boolean;

          points_amount?: number;

          price_without_vat?: number;

          price_without_vat_sale?: number;

          priceinfo?: string;

          product_id?: number;

          product_name?: string;

          product_note?: string;

          product_type?: number;

          purchaseprice?: number;

          qtyDiscounts?: {};

          sale?: boolean;

          sale_percentage?: number;

          sale_valid?: string;

          unit_price_sale?: number;

          unitprice?: number;

          unitprice_sale?: number;

          units?: string;

          valid_from?: string;

          valid_to?: string;

          vat_rate?: number;

          web_attribs?: Array<{
            attrib_code?: string;

            attrib_grid?: number;

            attrib_id?: number;

            attrib_ismulti?: number;

            attrib_list?: number;

            attrib_name?: string;

            attrib_pbl?: number;

            attrib_sort?: number;

            attrib_type?: string;

            attrib_uid?: string;

            attrib_unit?: string;

            attrib_value?: string;

            custom_value?: string;

            group_id?: number;

            group_name?: string;

            product_id?: number;

            translations?: Array<{
              attrib_id?: number;

              attrib_name?: string;

              attrib_unit?: string;

              lang_id?: string;
            }>;

            type_id?: number;

            value_groups?: Array<{
              attrib_id?: number;

              group_id?: number;

              group_name?: string;

              group_sort?: number;
            }>;

            value_id?: number;

            values?: Array<{
              amount?: number;

              attrib_id?: number;

              attrib_pict?: string;

              attrib_value?: string;

              group_id?: number;

              istop?: boolean;

              searchfilter?: number;

              translations?: Array<{
                attrib_url?: string;

                attrib_value?: string;

                lang_id?: string;

                secondary_name?: string;

                value_id?: number;
              }>;

              value_id?: number;

              value_sort?: number;
            }>;
          }>;

          web_order_attribs?: Array<{
            attrib_code?: string;

            attrib_grid?: number;

            attrib_id?: number;

            attrib_ismulti?: number;

            attrib_list?: number;

            attrib_name?: string;

            attrib_pbl?: number;

            attrib_sort?: number;

            attrib_type?: string;

            attrib_uid?: string;

            attrib_unit?: string;

            attrib_value?: string;

            custom_value?: string;

            group_id?: number;

            group_name?: string;

            product_id?: number;

            translations?: Array<{
              attrib_id?: number;

              attrib_name?: string;

              attrib_unit?: string;

              lang_id?: string;
            }>;

            type_id?: number;

            value_groups?: Array<{
              attrib_id?: number;

              group_id?: number;

              group_name?: string;

              group_sort?: number;
            }>;

            value_id?: number;

            values?: Array<{
              amount?: number;

              attrib_id?: number;

              attrib_pict?: string;

              attrib_value?: string;

              group_id?: number;

              istop?: boolean;

              searchfilter?: number;

              translations?: Array<{
                attrib_url?: string;

                attrib_value?: string;

                lang_id?: string;

                secondary_name?: string;

                value_id?: number;
              }>;

              value_id?: number;

              value_sort?: number;
            }>;
          }>;

          weight?: number;
        };

        max_price?: number;

        min_delivery_priority?: number;

        min_payment_priority?: number;

        min_price?: number;

        name?: string;

        name_url_encoded?: string;

        notshow?: number;

        ordered_count?: number;

        picture?: string;

        picture_hash?: string;

        picture_media_script?: string;

        plu?: string;

        product_fts?: string;

        product_id?: number;

        product_weight?: number;

        rating?: number;

        rating_users?: number;

        slogan?: string;

        type?: {
          catalog_id?: number;

          is_hidden?: number;

          store_minqty?: string;

          type_descr?: string;

          type_id?: number;

          type_inherits?: number;

          type_name?: string;
        };

        url?: string;

        valid_from?: string;

        valid_to?: string;
      }>;

      publish?: number;

      rss?: boolean;

      sf_form?: string;

      sf_stylesheet?: string;

      sf_xmlcontent?: string;

      sitemap_item_id?: number;

      tags?: Array<{
        id?: number;

        name?: string;
      }>;

      url?: string;

      videos?: Array<{
        content_id?: number;

        created_date?: string;

        created_user?: number;

        ordering?: number;

        video_description?: string;

        video_height?: number;

        video_id?: number;

        video_name?: string;

        video_url?: string;

        video_width?: number;

        webshow?: number;
      }>;

      canonical_url?: string;

      lang_versions?: Array<{
        lang_id?: string;

        url?: string;
      }>;
    }>;
  }> {
    let path = '/impressions/most-visited/articles';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Exports product to Karat
   * @method
   * @name API#exportProduct
   */
  exportProduct(
    productId: number,
    parameters: {} = {},
  ): Promise<{
    errorCode?: string;

    error?: string;

    idProduct?: string;
  }> {
    let path = '/karat/export-product/{product_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Exports variant to Karat
   * @method
   * @name API#exportVariant
   */
  exportVariant(
    productId: number,
    goodId: number,
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    errorCode?: string;

    error?: string;
  }> {
    let path = '/karat/export-variant/{product_id}/{good_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{product_id}', productId.toString());

    path = path.replace('{good_id}', goodId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Exports product to Karat
   * @method
   * @name API#exportProductWithVariants
   */
  exportProductWithVariants(
    productId: number,
    parameters: {} = {},
  ): Promise<{
    errorCode?: string;

    error?: string;

    idProduct?: string;
  }> {
    let path = '/karat/export-product-with-variants/{product_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Exports category to Karat
   * @method
   * @name API#exportCategory
   */
  exportCategory(
    categoryId: number,
    parameters: {} = {},
  ): Promise<{
    errorCode?: string;

    error?: string;

    idGroup?: string;
  }> {
    let path = '/karat/export-category/{category_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{category_id}', categoryId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Exports order to Karat
   * @method
   * @name API#exportOrder
   */
  exportOrder(
    orderId: string,
    parameters: {} = {},
  ): Promise<{
    errorCode?: string;

    error?: string;

    idOrder?: string;
  }> {
    let path = '/karat/export-order/{order_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{order_id}', orderId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Exports user to Karat
   * @method
   * @name API#exportUser
   */
  exportUser(
    userId: number,
    parameters: {} = {},
  ): Promise<{
    errorCode?: string;

    error?: string;

    idUser?: string;
  }> {
    let path = '/karat/export-user/{user_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{user_id}', userId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Imports stock count from Karat
   * @method
   * @name API#importStocks
   */
  importStocks(productId: number, parameters: {} = {}): Promise<{}> {
    let path = '/karat/import-stocks/{product_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Imports invoice from Karat
   * @method
   * @name API#importOrderInvoice
   */
  importOrderInvoice(
    orderId: string,
    parameters: {} = {},
  ): Promise<{
    errorCode?: string;

    error?: string;
  }> {
    let path = '/karat/import-invoice/{order_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{order_id}', orderId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Loads all available languages
   * @method
   * @name API#loadLangList
   */
  loadLangList(parameters: {} = {}): Promise<{
    languages?: Array<{
      lang_default?: '0' | '1';

      lang_id?: string;

      lang_name?: string;

      lang_sort?: number;
    }>;
  }> {
    let path = '/langs';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Loads translations for concrete language
   * @method
   * @name API#loadLangTranslation
   */
  loadLangTranslation(langId: string, parameters: {} = {}): Promise<{}> {
    let path = '/langs/{lang_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{lang_id}', langId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update lang translations
   * @method
   * @name API#updateLangTranslation
   */
  updateLangTranslation(
    langId: string,
    parameters: {} = {},
    body: {},
  ): Promise<void> {
    let path = '/langs/{lang_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');

    path = path.replace('{lang_id}', langId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Returns last minutes including array of products
   * @method
   * @name API#searchLastMinutes
   */
  searchLastMinutes(
    parameters: {
      onlyValid?: '0' | '1';
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
      xCurrency?: string;
    },
  ): Promise<{
    lastMinute?: {
      id: number;

      name: string;

      validFrom: string;

      validTo: string;

      products: Array<{
        availability_date: string | null;

        is_in_wishlist: boolean;

        product_id: number;

        name: string;

        slogan: string | null;

        is_sale: 0 | 1;

        issaleout: 0 | 1;

        is_new: 0 | 1;

        is_top: 0 | 1;

        plu: string | null;

        ean: string | null;

        url: string;

        description: string;

        created_at: string;

        rating: string;

        rating_users: number;

        unpacking_cost?: number;

        picture: string | null;

        brand: {};

        name_url_encoded: string;

        qtyDiscounts?: {
          discount_pcn?: number;

          qty_from?: number;

          product_id?: number;

          lang?: string;

          final_price?: number;
        };

        goods: Array<{
          priceinfo: string;

          valid_from?: string;

          valid_to?: string;

          imagePath: string;

          final_price: number | null;

          final_price_without_vat?: number | null;

          customer_discount?: number | null;

          b2b_discount?: number | null;

          unitprice_sale: number | null;

          sale: boolean | null;

          good_id: number;

          product_id: number;

          currency_id: string;

          unitprice: number;

          vat_rate: number;

          availability_id: number | null;

          oldprice: number | null;

          units: string | null;

          order_number: string | null;

          ean: string | null;

          internal_code: string | null;

          on_stock_count: number;

          is_on_stock: 0 | 1;

          point_good: 0 | 1;

          points_amount: number | null;

          sale_percentage: number | null;

          price_without_vat: number | null;

          price_without_vat_sale: number | null;

          currency: {
            id: string;

            name: string;
          };

          availability: {
            avail_id?: number;

            avail_uniqid?: string;

            order_available?: number;

            translations?: Array<{
              avail_id: number;

              lang: string;

              avail_name: string;
            }>;
          };

          sum_price?: number | null;

          sum_price_without_vat?: number | null;

          sum_unpacking_cost?: number | null;

          group_price?: {
            unitprice: number;

            oldprice: number;

            sale_percentage: number;
          };

          discount_pcn?: number;

          color?: Array<{}>;

          length?: number;

          width?: number;

          height?: number;
        }>;

        main_good: {
          priceinfo: string;

          valid_from?: string;

          valid_to?: string;

          imagePath: string;

          final_price: number | null;

          final_price_without_vat?: number | null;

          customer_discount?: number | null;

          b2b_discount?: number | null;

          unitprice_sale: number | null;

          sale: boolean | null;

          good_id: number;

          product_id: number;

          currency_id: string;

          unitprice: number;

          vat_rate: number;

          availability_id: number | null;

          oldprice: number | null;

          units: string | null;

          order_number: string | null;

          ean: string | null;

          internal_code: string | null;

          on_stock_count: number;

          is_on_stock: 0 | 1;

          point_good: 0 | 1;

          points_amount: number | null;

          sale_percentage: number | null;

          price_without_vat: number | null;

          price_without_vat_sale: number | null;

          currency: {
            id: string;

            name: string;
          };

          availability: {
            avail_id?: number;

            avail_uniqid?: string;

            order_available?: number;

            translations?: Array<{
              avail_id: number;

              lang: string;

              avail_name: string;
            }>;
          };

          sum_price?: number | null;

          sum_price_without_vat?: number | null;

          sum_unpacking_cost?: number | null;

          group_price?: {
            unitprice: number;

            oldprice: number;

            sale_percentage: number;
          };

          discount_pcn?: number;

          color?: Array<{}>;

          length?: number;

          width?: number;

          height?: number;
        };

        orderWithoutStock?: boolean;

        products_in_set?: Array<{
          good_id?: number;

          package_good_id?: number;

          package_quantity?: number;

          package_price?: number;

          product_id?: number;

          package_product_id?: number;

          color?: string;

          pages?: string;

          capacity?: string;
        }>;

        street?: string;

        city?: string;

        zip?: string;

        parent_categories: Array<{}>;

        assembling?: boolean;

        prelisting?: boolean;

        productPackages?: Array<{
          id?: number;

          productId?: number;

          storeQuantity?: number;

          fullQuantity?: number;

          type?: number;
        }>;

        lang_versions?: Array<{
          lang_id?: string;

          url?: string;
        }>;
      }>;
    };
  }> {
    let path = '/last-minutes';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (extraHeaders && typeof extraHeaders['xCurrency'] !== 'undefined') {
      headers.append('X-Currency', extraHeaders['xCurrency']!);
    }

    queryParameters['only_valid'] = 1;

    if (typeof parameters['onlyValid'] !== 'undefined') {
      queryParameters['only_valid'] = parameters['onlyValid'];
    }

    queryParameters['only_valid'] = this.transformParameter(
      queryParameters['only_valid'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns information about last minute including array of products
   * @method
   * @name API#getLastMinute
   */
  getLastMinute(
    lastminuteName: string,
    parameters: {} = {},
  ): Promise<{
    id: number;

    name: string;

    validFrom: string;

    validTo: string;

    products: Array<{
      availability_date: string | null;

      is_in_wishlist: boolean;

      product_id: number;

      name: string;

      slogan: string | null;

      is_sale: 0 | 1;

      issaleout: 0 | 1;

      is_new: 0 | 1;

      is_top: 0 | 1;

      plu: string | null;

      ean: string | null;

      url: string;

      description: string;

      created_at: string;

      rating: string;

      rating_users: number;

      unpacking_cost?: number;

      picture: string | null;

      brand: {};

      name_url_encoded: string;

      qtyDiscounts?: {
        discount_pcn?: number;

        qty_from?: number;

        product_id?: number;

        lang?: string;

        final_price?: number;
      };

      goods: Array<{
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        final_price_without_vat?: number | null;

        customer_discount?: number | null;

        b2b_discount?: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        sum_price?: number | null;

        sum_price_without_vat?: number | null;

        sum_unpacking_cost?: number | null;

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        discount_pcn?: number;

        color?: Array<{}>;

        length?: number;

        width?: number;

        height?: number;
      }>;

      main_good: {
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        final_price_without_vat?: number | null;

        customer_discount?: number | null;

        b2b_discount?: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        sum_price?: number | null;

        sum_price_without_vat?: number | null;

        sum_unpacking_cost?: number | null;

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        discount_pcn?: number;

        color?: Array<{}>;

        length?: number;

        width?: number;

        height?: number;
      };

      orderWithoutStock?: boolean;

      products_in_set?: Array<{
        good_id?: number;

        package_good_id?: number;

        package_quantity?: number;

        package_price?: number;

        product_id?: number;

        package_product_id?: number;

        color?: string;

        pages?: string;

        capacity?: string;
      }>;

      street?: string;

      city?: string;

      zip?: string;

      parent_categories: Array<{}>;

      assembling?: boolean;

      prelisting?: boolean;

      productPackages?: Array<{
        id?: number;

        productId?: number;

        storeQuantity?: number;

        fullQuantity?: number;

        type?: number;
      }>;

      lang_versions?: Array<{
        lang_id?: string;

        url?: string;
      }>;
    }>;
  }> {
    let path = '/last-minutes/{lastminute_name}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{lastminute_name}', lastminuteName.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Check if member exists in list
   * @method
   * @name API#newsletterMemberExists
   */
  newsletterMemberExists(parameters: { email: string }): Promise<{
    exists: boolean;
  }> {
    let path = '/newsletter/member-exists';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['email'] !== 'undefined') {
      queryParameters['email'] = parameters['email'];
    }

    queryParameters['email'] = this.transformParameter(
      queryParameters['email'],
    );

    if (typeof parameters['email'] === 'undefined') {
      throw new Error('Missing required parameter: email');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Subscribe to nesletter
   * @method
   * @name API#subscribeNewsletter
   */
  subscribeNewsletter(
    parameters: {} = {},
    body: {
      email: string;

      first_name: string;

      last_name: string;

      source: string;

      status: string;
    },
  ): Promise<{}> {
    let path = '/newsletter/subscribe';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * some text
   * @method
   * @name API#prepareLabels
   */
  prepareLabels(
    parameters: {} = {},
    body: {
      listOfId?: {};
    },
  ): Promise<{}> {
    let path = '/mygls/prepare-labels';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Exchange code token for token
   * @method
   * @name API#changeCodeForToken
   */
  changeCodeForToken(
    parameters: {} = {},
    body: {
      code?: string;
    },
  ): Promise<{}> {
    let path = '/oauth/change-code';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Impersonate for user
   * @method
   * @name API#impersonate
   */
  impersonate(parameters: { user: string; redirectUri: string }): Promise<{}> {
    let path = '/oauth/impersonate';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['user'] !== 'undefined') {
      queryParameters['user'] = parameters['user'];
    }

    queryParameters['user'] = this.transformParameter(queryParameters['user']);

    if (typeof parameters['user'] === 'undefined') {
      throw new Error('Missing required parameter: user');
    }

    if (typeof parameters['redirectUri'] !== 'undefined') {
      queryParameters['redirect_uri'] = parameters['redirectUri'];
    }

    queryParameters['redirect_uri'] = this.transformParameter(
      queryParameters['redirect_uri'],
    );

    if (typeof parameters['redirectUri'] === 'undefined') {
      throw new Error('Missing required parameter: redirectUri');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Impersonate for user
   * @method
   * @name API#impersonateUser
   */
  impersonateUser(parameters: {
    user: string;
    redirectUri: string;
  }): Promise<{}> {
    let path = '/oauth/impersonate-user';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['user'] !== 'undefined') {
      queryParameters['user'] = parameters['user'];
    }

    queryParameters['user'] = this.transformParameter(queryParameters['user']);

    if (typeof parameters['user'] === 'undefined') {
      throw new Error('Missing required parameter: user');
    }

    if (typeof parameters['redirectUri'] !== 'undefined') {
      queryParameters['redirect_uri'] = parameters['redirectUri'];
    }

    queryParameters['redirect_uri'] = this.transformParameter(
      queryParameters['redirect_uri'],
    );

    if (typeof parameters['redirectUri'] === 'undefined') {
      throw new Error('Missing required parameter: redirectUri');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * Prihlasenie pouzivatela - ziskanie tokenu


Tato metoda bud vyhlasi neznamu chybu, alebo redirectne spat na
referrera, alebo redirectne na redirect uri s tokenom alebo inou chybou.


V pripade redirectu na referrera moze nastat nasledovne chyby:


- INVALID_REQUEST (XHR nie je povolene)

- INVALID_CLIENT (client_id je nezname)

- INVALID_REFERER (poziadavka bola odoslana zo blbej URL)

- INVALID_CREDENTIALS (login a heslo nie su vporiadku)

- INVALID_REDIRECT_URI (redirect uri nie je medzi zadanymi v zozname
aplikacii)

- INVALID_RESPONSE_TYPE (napriklad implicit metoda nemusi byt povolena
vsetkym apkam)


Referer je default /auth/authorize pokial nie
je uvedeny iny referrer ktory sa zhoduje s tymi nastavenymi v aplikacii.


Zaroven je vrateny status 302 a nastavena location hlavicka.

    * @method
    * @name API#oauthAuthorize
    */
  oauthAuthorize(parameters: {
    clientId: string;
    cartId?: string;
    redirectUri: string;
    responseType?: string;
    scope: string;
    state?: string;
    appSpace?: string;
    lang?: string;
    domainId?: number;
  }): Promise<{}> {
    let path = '/oauth/authorize';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Accept', 'application/json');

    if (typeof parameters['clientId'] !== 'undefined') {
      queryParameters['client_id'] = parameters['clientId'];
    }

    queryParameters['client_id'] = this.transformParameter(
      queryParameters['client_id'],
    );

    if (typeof parameters['clientId'] === 'undefined') {
      throw new Error('Missing required parameter: clientId');
    }

    if (typeof parameters['cartId'] !== 'undefined') {
      queryParameters['cart_id'] = parameters['cartId'];
    }

    queryParameters['cart_id'] = this.transformParameter(
      queryParameters['cart_id'],
    );

    if (typeof parameters['redirectUri'] !== 'undefined') {
      queryParameters['redirect_uri'] = parameters['redirectUri'];
    }

    queryParameters['redirect_uri'] = this.transformParameter(
      queryParameters['redirect_uri'],
    );

    if (typeof parameters['redirectUri'] === 'undefined') {
      throw new Error('Missing required parameter: redirectUri');
    }

    queryParameters['response_type'] = 'token';

    if (typeof parameters['responseType'] !== 'undefined') {
      queryParameters['response_type'] = parameters['responseType'];
    }

    queryParameters['response_type'] = this.transformParameter(
      queryParameters['response_type'],
    );

    if (typeof parameters['scope'] !== 'undefined') {
      queryParameters['scope'] = parameters['scope'];
    }

    queryParameters['scope'] = this.transformParameter(
      queryParameters['scope'],
    );

    if (typeof parameters['scope'] === 'undefined') {
      throw new Error('Missing required parameter: scope');
    }

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    if (typeof parameters['appSpace'] !== 'undefined') {
      queryParameters['appSpace'] = parameters['appSpace'];
    }

    queryParameters['appSpace'] = this.transformParameter(
      queryParameters['appSpace'],
    );

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    if (typeof parameters['domainId'] !== 'undefined') {
      queryParameters['domain_id'] = parameters['domainId'];
    }

    queryParameters['domain_id'] = this.transformParameter(
      queryParameters['domain_id'],
    );

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Facebook authentication - Redirects to Facebook server
   * @method
   * @name API#facebook
   */
  facebook(
    parameters: {
      state?: string;
    } = {},
  ): Promise<{}> {
    let path = '/oauth/facebook';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Facebook callback URL called when login form from FB has been confirmed
   * @method
   * @name API#facebookHandler
   */
  facebookHandler(parameters: {
    code?: string;
    state?: string;
    domainId: number;
  }): Promise<{}> {
    let path = '/oauth/facebook-callback';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['code'] !== 'undefined') {
      queryParameters['code'] = parameters['code'];
    }

    queryParameters['code'] = this.transformParameter(queryParameters['code']);

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    if (typeof parameters['domainId'] !== 'undefined') {
      queryParameters['domain_id'] = parameters['domainId'];
    }

    queryParameters['domain_id'] = this.transformParameter(
      queryParameters['domain_id'],
    );

    if (typeof parameters['domainId'] === 'undefined') {
      throw new Error('Missing required parameter: domainId');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Google authentication - Redirects to Google server
   * @method
   * @name API#google
   */
  google(
    parameters: {
      state?: string;
    } = {},
  ): Promise<{}> {
    let path = '/oauth/google';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * Google callback URL called when login form from Google has been
confirmed

    * @method
    * @name API#googleHandler
    */
  googleHandler(parameters: {
    code?: string;
    state?: string;
    domainId: number;
  }): Promise<{}> {
    let path = '/oauth/google-callback';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['code'] !== 'undefined') {
      queryParameters['code'] = parameters['code'];
    }

    queryParameters['code'] = this.transformParameter(queryParameters['code']);

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    if (typeof parameters['domainId'] !== 'undefined') {
      queryParameters['domain_id'] = parameters['domainId'];
    }

    queryParameters['domain_id'] = this.transformParameter(
      queryParameters['domain_id'],
    );

    if (typeof parameters['domainId'] === 'undefined') {
      throw new Error('Missing required parameter: domainId');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Fow now used to remove user ID from his cart
   * @method
   * @name API#oauthLogout
   */
  oauthLogout(
    parameters: {
      cartId?: string;
    } = {},
  ): Promise<{}> {
    let path = '/oauth/logout';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['cartId'] !== 'undefined') {
      queryParameters['cart_id'] = parameters['cartId'];
    }

    queryParameters['cart_id'] = this.transformParameter(
      queryParameters['cart_id'],
    );

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * After registration customer recieves activation email
   * @method
   * @name API#registerUser
   */
  registerUser(
    parameters: {} = {},
    body: {
      email: string;

      lang?: string;

      password: string;

      password_again: string;

      domain_id: string;

      newsletter_accept?: boolean;

      terms_accept: boolean;

      scope?: string;

      app_id?: string;
    },
  ): Promise<{
    access_token?: string;
  }> {
    let path = '/oauth/register';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * After registration customer recieves intial email
   * @method
   * @name API#registerB2B
   */
  registerB2B(
    parameters: {} = {},
    body: {
      additionalProperties?: {};

      name?: string;

      surname?: string;

      email?: string;

      phone?: string;

      login: string;

      bank_name: string;

      iban: string;

      bussiness_desc?: string;

      web?: string;

      note?: string;

      company: {
        name: string;

        ico: string;

        dic: string;

        ic_dph?: string;

        vat_payer?: '0' | '1';
      };

      billing_address: {
        name: string;

        surname: string;

        street: string;

        number: string;

        city: string;

        zip: string;

        country: {
          name: string;

          value: string;
        };
      };

      delivery_address?: {
        company_name: string;

        street: string;

        number: string;

        city: string;

        zip: string;

        country: {
          name: string;

          value: string;
        };
      };

      lang: string;

      domain_id: number;
    },
  ): Promise<void> {
    let path = '/oauth/register-b2b';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Returns information about token
   * @method
   * @name API#tokeninfo
   */
  tokeninfo(parameters: {
    accessToken: string;
    withGroups?: '0' | '1';
    lang?: string;
  }): Promise<{
    account_verified?: boolean;

    email?: number;

    id?: number;

    lang?: number;

    name?: number;

    surname?: number;

    token_info?: {};

    b2b?: number;

    updating_prices?: string;

    pricesUpdatedAt?: string;

    cartId?: string;

    pendingImportId?: string;

    doneImportId?: string;

    doneNotImported?: Array<{}>;
  }> {
    let path = '/oauth/tokeninfo';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['accessToken'] !== 'undefined') {
      queryParameters['access_token'] = parameters['accessToken'];
    }

    queryParameters['access_token'] = this.transformParameter(
      queryParameters['access_token'],
    );

    if (typeof parameters['accessToken'] === 'undefined') {
      throw new Error('Missing required parameter: accessToken');
    }

    queryParameters['with_groups'] = '0';

    if (typeof parameters['withGroups'] !== 'undefined') {
      queryParameters['with_groups'] = parameters['withGroups'];
    }

    queryParameters['with_groups'] = this.transformParameter(
      queryParameters['with_groups'],
    );

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * List of orders
   * @method
   * @name API#searchOrders
   */
  searchOrders(
    parameters: {
      limit?: number;
      orderNr?: string;
      status?: string;
      customerName?: string;
      sort?: string;
      sortDir?: string;
      offset?: number;
    } = {},
  ): Promise<{
    orders: Array<{
      _id: number;

      order_id: number;

      order_nr: string;

      order_status: string;

      order_status_color: string;

      order_status_readable: string;

      order_type: string;

      order_tprice: number;

      order_tpricecurrency: string;

      created_datetime: string;

      order_cancelled_datetime: string | null;

      order_paid_datetime: string | null;

      order_ship_datetime: string | null;

      order_comments: string;

      shipnr: string | null;

      comments: string | null;

      order_lang: string;

      parent_order: number | null;

      vs: string;

      ac: string | null;

      credit_note: number;

      credit_note_number: number | null;

      credit_note_amount: number | null;

      credit_note_delivery: number;

      delivery_note_number: number | null;

      delivery_note_created_date: string | null;

      reservation_date: string | null;

      partner_id: number | null;

      oo_shop_id: number | null;

      oo_shop_name: string | null;

      oo_shop_address: string | null;

      consumed_points: number;

      assigned_points: number;

      giftcards: number;

      club_user_id: number | null;

      invoice_with_vat: number;

      invoice_number: number | null;

      invoice_created_date: string | null;

      invoice_maturity_date: string | null;

      invoice_delivery_date: string | null;

      public_id: string;

      error_message: string | null;

      person: {
        name: string | null;

        surname: string | null;

        phone: string | null;

        email: string | null;

        phonePrefix?: string | null;
      };

      address: {
        person?: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street: string;

        street_number?: string;

        zip: string;

        city: string;

        country: string;

        country_code: string;

        country_id: string;

        company?: string;

        delivery_address_id?: number;
      };

      payment: {
        payment_type: string;

        payment_typename: string;

        payment_price: number;

        payment_pricecurrency: string;

        delivery_price: number;

        delivery_pricecurrency: string;

        order_tprice: number;

        order_tpricecurrency: string;

        vat_rate: number;

        discount: number;

        individual_discount: number;

        group_discount: number;

        discount_amount: number;

        paid_amount: number | null;

        paid_amount_from_wallet: number | null;

        paid_amount_from_wallet_returned?: number | null;

        card_number: string | null;
      };

      delivery: {
        person: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        delivery_firm: string | null;

        delivery_addr_street: string;

        delivery_addr_street_number: string;

        delivery_addr_city: string;

        delivery_addr_zip: string;

        delivery_addr_country: string;

        delivery_addr_country_code: string | null;

        delivery_type: string;

        delivery_typename: string;

        delivery_price: number;

        delivery_pricecurrency: string;

        posta_id: number | null;

        posta_name: string | null;

        posta_address: string | null;
      };

      quatro: {
        quatro_status: string | null;

        quatro_kod: string | null;

        quatro_url: string | null;

        quatro_oz: string | null;
      };

      company: {
        name: string | null;

        ico: string | null;

        dic: string | null;

        ic_dph: string | null;
      };

      items?: Array<{
        order_id: number;

        item_idx: number;

        ordernr: number;

        product_name: string;

        units: string | null;

        unitprice: number;

        vat_rate: number;

        quantity: number;

        credit_note_quantity: number | null;

        totalpoints: number;

        totalprice: number;

        currency: string;

        product_id: number;

        good_id: number;

        skladom: number | null;

        date_delivery: string | null;

        from_store_id: string | null;

        product_note: string | null;

        ean: string | null;

        product_picture: string | null;

        product_url: string | null;

        good_details?: string;

        good_details_json: {
          productName: string;

          productNote: string | null;

          units: string | null;

          ordernr: number;

          ean: string | null;

          internalcode: string | null;

          unitprice: {
            price: number;

            currency: string;

            excludeVat: number;

            vatRate: string;
          };

          onstock: number;

          onstockCount: string;

          orderAvailable: number;

          points: number;

          id: string;

          productId: string;

          productTypeId: string;

          hasSales: boolean;

          isFromSale: boolean;

          isFromGroupSale: boolean;

          isPointGood: number;

          pointAmount: number;

          availabilityId: string;

          availability: {
            name: string;

            uniqueid: string;

            id: string;

            lang: string;

            order_available: string;
          };

          attribs: Array<{
            name: string;

            unit: string;

            htmlUnit: string;

            value: string | null;

            customValue: string;

            groupName: string | null;

            attribId: string;

            valueId: number | null;

            valueGroupId: number | null;

            groupId: number | null;

            orderDepend: number;

            hexadecimal: string | null;

            attrib_ordernr: number;

            default_for_cart: number | null;
          }>;

          attribsByAttribId: {
            groupId: number;

            groupName: string;

            attribs: Array<{
              name: string;

              unit: string;

              htmlUnit: string;

              value: string | null;

              customValue: string;

              groupName: string | null;

              attribId: string;

              valueId: number | null;

              valueGroupId: number | null;

              groupId: number | null;

              orderDepend: number;

              hexadecimal: string | null;

              attrib_ordernr: number;

              default_for_cart: number | null;
            }>;
          };
        };

        is_meta_item?: boolean;
      }>;

      documents?: {
        faktura?: string;

        predfaktura?: string;

        dobropis?: string;
      };

      showPayment?: boolean;

      use_delivery_address?: boolean;

      chosen_gift_name?: string;

      chosen_gift_id?: string;

      sale_explanation?: string;
    }>;
  }> {
    let path = '/orders';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['orderNr'] !== 'undefined') {
      queryParameters['order_nr'] = parameters['orderNr'];
    }

    queryParameters['order_nr'] = this.transformParameter(
      queryParameters['order_nr'],
    );

    if (typeof parameters['status'] !== 'undefined') {
      queryParameters['status'] = parameters['status'];
    }

    queryParameters['status'] = this.transformParameter(
      queryParameters['status'],
    );

    if (typeof parameters['customerName'] !== 'undefined') {
      queryParameters['customer_name'] = parameters['customerName'];
    }

    queryParameters['customer_name'] = this.transformParameter(
      queryParameters['customer_name'],
    );

    queryParameters['sort'] = 'date';

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    queryParameters['sort_dir'] = 'desc';

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Send email for order cancel
   * @method
   * @name API#orderCancelRequest
   */
  orderCancelRequest(publicId: string, parameters: {} = {}): Promise<{}> {
    let path = '/orders/cancel/request/{public_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{public_id}', publicId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Cancel order
   * @method
   * @name API#orderCancel
   */
  orderCancel(
    publicId: string,
    token: string,
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/orders/cancel/confirm/{public_id}/{token}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{public_id}', publicId.toString());

    path = path.replace('{token}', token.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create bulk invoice
   * @method
   * @name API#bulkInvoices
   */
  bulkInvoices(
    parameters: {} = {},
    body: {
      order_ids: Array<number>;
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{}> {
    let path = '/orders/bulk/invoices';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Create bulk simple print pdfs
   * @method
   * @name API#orderSimplePrint
   */
  orderSimplePrint(
    parameters: {} = {},
    body: {
      order_ids: Array<number>;
    },
  ): Promise<{}> {
    let path = '/orders/bulk/simple-print';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * List of orders' statuses
   * @method
   * @name API#getStatuses
   */
  getStatuses(parameters: {} = {}): Promise<{
    name?: {
      message?: string;

      step?: number;
    };
  }> {
    let path = '/orders/statuses';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get order html view
   * @method
   * @name API#getOrderBodyHtml
   */
  getOrderBodyHtml(
    id: string,
    parameters: {} = {},
  ): Promise<{
    body?: string;
  }> {
    let path = '/orders/body-html/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get order by order public id
   * @method
   * @name API#getOrder
   */
  getOrder(
    id: string,
    parameters: {} = {},
  ): Promise<{
    _id: number;

    order_id: number;

    order_nr: string;

    order_status: string;

    order_status_color: string;

    order_status_readable: string;

    order_type: string;

    order_tprice: number;

    order_tpricecurrency: string;

    created_datetime: string;

    order_cancelled_datetime: string | null;

    order_paid_datetime: string | null;

    order_ship_datetime: string | null;

    order_comments: string;

    shipnr: string | null;

    comments: string | null;

    order_lang: string;

    parent_order: number | null;

    vs: string;

    ac: string | null;

    credit_note: number;

    credit_note_number: number | null;

    credit_note_amount: number | null;

    credit_note_delivery: number;

    delivery_note_number: number | null;

    delivery_note_created_date: string | null;

    reservation_date: string | null;

    partner_id: number | null;

    oo_shop_id: number | null;

    oo_shop_name: string | null;

    oo_shop_address: string | null;

    consumed_points: number;

    assigned_points: number;

    giftcards: number;

    club_user_id: number | null;

    invoice_with_vat: number;

    invoice_number: number | null;

    invoice_created_date: string | null;

    invoice_maturity_date: string | null;

    invoice_delivery_date: string | null;

    public_id: string;

    error_message: string | null;

    person: {
      name: string | null;

      surname: string | null;

      phone: string | null;

      email: string | null;

      phonePrefix?: string | null;
    };

    address: {
      person?: {
        name: string | null;

        surname: string | null;

        phone: string | null;

        email: string | null;

        phonePrefix?: string | null;
      };

      street: string;

      street_number?: string;

      zip: string;

      city: string;

      country: string;

      country_code: string;

      country_id: string;

      company?: string;

      delivery_address_id?: number;
    };

    payment: {
      payment_type: string;

      payment_typename: string;

      payment_price: number;

      payment_pricecurrency: string;

      delivery_price: number;

      delivery_pricecurrency: string;

      order_tprice: number;

      order_tpricecurrency: string;

      vat_rate: number;

      discount: number;

      individual_discount: number;

      group_discount: number;

      discount_amount: number;

      paid_amount: number | null;

      paid_amount_from_wallet: number | null;

      paid_amount_from_wallet_returned?: number | null;

      card_number: string | null;
    };

    delivery: {
      person: {
        name: string | null;

        surname: string | null;

        phone: string | null;

        email: string | null;

        phonePrefix?: string | null;
      };

      delivery_firm: string | null;

      delivery_addr_street: string;

      delivery_addr_street_number: string;

      delivery_addr_city: string;

      delivery_addr_zip: string;

      delivery_addr_country: string;

      delivery_addr_country_code: string | null;

      delivery_type: string;

      delivery_typename: string;

      delivery_price: number;

      delivery_pricecurrency: string;

      posta_id: number | null;

      posta_name: string | null;

      posta_address: string | null;
    };

    quatro: {
      quatro_status: string | null;

      quatro_kod: string | null;

      quatro_url: string | null;

      quatro_oz: string | null;
    };

    company: {
      name: string | null;

      ico: string | null;

      dic: string | null;

      ic_dph: string | null;
    };

    items?: Array<{
      order_id: number;

      item_idx: number;

      ordernr: number;

      product_name: string;

      units: string | null;

      unitprice: number;

      vat_rate: number;

      quantity: number;

      credit_note_quantity: number | null;

      totalpoints: number;

      totalprice: number;

      currency: string;

      product_id: number;

      good_id: number;

      skladom: number | null;

      date_delivery: string | null;

      from_store_id: string | null;

      product_note: string | null;

      ean: string | null;

      product_picture: string | null;

      product_url: string | null;

      good_details?: string;

      good_details_json: {
        productName: string;

        productNote: string | null;

        units: string | null;

        ordernr: number;

        ean: string | null;

        internalcode: string | null;

        unitprice: {
          price: number;

          currency: string;

          excludeVat: number;

          vatRate: string;
        };

        onstock: number;

        onstockCount: string;

        orderAvailable: number;

        points: number;

        id: string;

        productId: string;

        productTypeId: string;

        hasSales: boolean;

        isFromSale: boolean;

        isFromGroupSale: boolean;

        isPointGood: number;

        pointAmount: number;

        availabilityId: string;

        availability: {
          name: string;

          uniqueid: string;

          id: string;

          lang: string;

          order_available: string;
        };

        attribs: Array<{
          name: string;

          unit: string;

          htmlUnit: string;

          value: string | null;

          customValue: string;

          groupName: string | null;

          attribId: string;

          valueId: number | null;

          valueGroupId: number | null;

          groupId: number | null;

          orderDepend: number;

          hexadecimal: string | null;

          attrib_ordernr: number;

          default_for_cart: number | null;
        }>;

        attribsByAttribId: {
          groupId: number;

          groupName: string;

          attribs: Array<{
            name: string;

            unit: string;

            htmlUnit: string;

            value: string | null;

            customValue: string;

            groupName: string | null;

            attribId: string;

            valueId: number | null;

            valueGroupId: number | null;

            groupId: number | null;

            orderDepend: number;

            hexadecimal: string | null;

            attrib_ordernr: number;

            default_for_cart: number | null;
          }>;
        };
      };

      is_meta_item?: boolean;
    }>;

    documents?: {
      faktura?: string;

      predfaktura?: string;

      dobropis?: string;
    };

    showPayment?: boolean;

    use_delivery_address?: boolean;

    chosen_gift_name?: string;

    chosen_gift_id?: string;

    sale_explanation?: string;
  }> {
    let path = '/orders/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create order
   * @method
   * @name API#createOrder
   */
  createOrder(
    id: string,
    parameters: {} = {},
    body: {
      delivery?: {};

      payment?: {};

      delivery_address?: {
        person: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street?: string;

        street_number?: string;

        zip?: string;

        city?: string;

        country?: string;

        country_code?: string;

        country_id?: string;

        company?: string;

        delivery_address_id?: number;
      };

      billing_address: {
        person: {
          name: string | null;

          surname: string | null;

          phone: string | null;

          email: string | null;

          phonePrefix?: string | null;
        };

        street?: string;

        street_number?: string;

        zip?: string;

        city?: string;

        country?: string;

        country_code?: string;

        country_id?: string;

        company?: string;

        delivery_address_id?: number;
      };

      company: {
        name: string | null;

        ico: string | null;

        dic: string | null;

        ic_dph: string | null;
      };

      billing_company: boolean;

      use_delivery_address: boolean;

      terms_accept: boolean;

      newsletter_accept: boolean;

      heureka: boolean;

      heureka_refuse?: boolean;

      note: string;

      cart_label?: string;

      step: number;

      zasielkovna_id?: string;

      dpd_id?: string;

      consumed_points: number;

      chosen_gift?: {};

      vat_payer?: boolean;
    },
  ): Promise<{
    orderPublicId: string;
  }> {
    let path = '/orders/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Update order
   * @method
   * @name API#updateOrder
   */
  updateOrder(
    id: string,
    parameters: {} = {},
    body: {
      packeta_id?: string;

      message?: string;

      step?: number;
    },
  ): Promise<void> {
    let path = '/orders/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Get order document
   * @method
   * @name API#orderDocument
   */
  orderDocument(
    publicId: string,
    document: 'faktura' | 'predfaktura' | 'dobropis',
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{}> {
    let path = '/orders/{public_id}/documents/{document}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{public_id}', publicId.toString());

    path = path.replace('{document}', document.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create order document
   * @method
   * @name API#generateAdminOrderDocument_1
   */
  generateAdminOrderDocument_1(
    publicId: string,
    document: 'faktura' | 'predfaktura' | 'dobropis' | 'karat_faktura',
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{}> {
    let path = '/orders/{public_id}/documents/{document}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{public_id}', publicId.toString());

    path = path.replace('{document}', document.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get list of order statuses
   * @method
   * @name API#statuses
   */
  statuses(
    publicId: string,
    parameters: {} = {},
  ): Promise<{
    name?: {
      message?: string;

      step?: number;
    };
  }> {
    let path = '/orders/{public_id}/statuses';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{public_id}', publicId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns object with information about other text and its content
   * @method
   * @name API#loadOtherTexts
   */
  loadOtherTexts(
    textId: string,
    parameters: {
      langId?: string;
      domainId?: number;
      fallbackDomainId?: number;
    } = {},
  ): Promise<{
    text_id: string;

    text_name: string;

    ordering: number;

    sfpform: string;

    text_group: string;

    content: {
      id: string | number;

      created_user_id: string | null;

      sitemap_item_id: string | null;

      name: string | null;

      created_date: string;

      ordering: number | null;

      sf_form: string;

      sf_stylesheet: string;

      sf_xmlcontent: string | null;

      draft: number;

      hint: string | null;

      url: string | null;

      last_update: string;

      rss: number;

      home: number;

      publish: number;

      json_content: string | null;

      documents?: Array<{
        document_id?: number;

        content_id?: number;

        document_name?: string | null;

        document_description?: string | null;

        document_path?: string | null;

        document_type?: string | null;

        created_date?: string | null;

        created_user?: number | null;

        privatedoc?: number | null;
      }>;

      downloads?: Array<{
        document_id?: number;

        content_id?: number;

        document_name?: string | null;

        document_description?: string | null;

        document_path?: string | null;

        document_type?: string | null;

        created_date?: string | null;

        created_user?: number | null;

        privatedoc?: number | null;
      }>;

      photogallery?: Array<{
        document_id?: number;

        content_id?: number;

        document_name?: string | null;

        document_description?: string | null;

        document_path?: string | null;

        document_type?: string | null;

        created_date?: string | null;

        created_user?: number | null;

        privatedoc?: number | null;
      }>;

      videos?: Array<{
        video_id?: number;

        content_id?: number;

        video_name?: string | null;

        video_description?: string | null;

        video_url?: string | null;

        ordering?: number | null;

        created_date?: string | null;

        created_user?: number | null;

        webshow?: number | null;

        video_width?: number | null;

        video_height?: number | null;
      }>;

      products?: Array<{
        availability_date: string | null;

        is_in_wishlist: boolean;

        product_id: number;

        name: string;

        slogan: string | null;

        is_sale: 0 | 1;

        issaleout: 0 | 1;

        is_new: 0 | 1;

        is_top: 0 | 1;

        plu: string | null;

        ean: string | null;

        url: string;

        description: string;

        created_at: string;

        rating: string;

        rating_users: number;

        unpacking_cost?: number;

        picture: string | null;

        brand: {};

        name_url_encoded: string;

        qtyDiscounts?: {
          discount_pcn?: number;

          qty_from?: number;

          product_id?: number;

          lang?: string;

          final_price?: number;
        };

        goods: Array<{
          priceinfo: string;

          valid_from?: string;

          valid_to?: string;

          imagePath: string;

          final_price: number | null;

          final_price_without_vat?: number | null;

          customer_discount?: number | null;

          b2b_discount?: number | null;

          unitprice_sale: number | null;

          sale: boolean | null;

          good_id: number;

          product_id: number;

          currency_id: string;

          unitprice: number;

          vat_rate: number;

          availability_id: number | null;

          oldprice: number | null;

          units: string | null;

          order_number: string | null;

          ean: string | null;

          internal_code: string | null;

          on_stock_count: number;

          is_on_stock: 0 | 1;

          point_good: 0 | 1;

          points_amount: number | null;

          sale_percentage: number | null;

          price_without_vat: number | null;

          price_without_vat_sale: number | null;

          currency: {
            id: string;

            name: string;
          };

          availability: {
            avail_id?: number;

            avail_uniqid?: string;

            order_available?: number;

            translations?: Array<{
              avail_id: number;

              lang: string;

              avail_name: string;
            }>;
          };

          sum_price?: number | null;

          sum_price_without_vat?: number | null;

          sum_unpacking_cost?: number | null;

          group_price?: {
            unitprice: number;

            oldprice: number;

            sale_percentage: number;
          };

          discount_pcn?: number;

          color?: Array<{}>;

          length?: number;

          width?: number;

          height?: number;
        }>;

        main_good: {
          priceinfo: string;

          valid_from?: string;

          valid_to?: string;

          imagePath: string;

          final_price: number | null;

          final_price_without_vat?: number | null;

          customer_discount?: number | null;

          b2b_discount?: number | null;

          unitprice_sale: number | null;

          sale: boolean | null;

          good_id: number;

          product_id: number;

          currency_id: string;

          unitprice: number;

          vat_rate: number;

          availability_id: number | null;

          oldprice: number | null;

          units: string | null;

          order_number: string | null;

          ean: string | null;

          internal_code: string | null;

          on_stock_count: number;

          is_on_stock: 0 | 1;

          point_good: 0 | 1;

          points_amount: number | null;

          sale_percentage: number | null;

          price_without_vat: number | null;

          price_without_vat_sale: number | null;

          currency: {
            id: string;

            name: string;
          };

          availability: {
            avail_id?: number;

            avail_uniqid?: string;

            order_available?: number;

            translations?: Array<{
              avail_id: number;

              lang: string;

              avail_name: string;
            }>;
          };

          sum_price?: number | null;

          sum_price_without_vat?: number | null;

          sum_unpacking_cost?: number | null;

          group_price?: {
            unitprice: number;

            oldprice: number;

            sale_percentage: number;
          };

          discount_pcn?: number;

          color?: Array<{}>;

          length?: number;

          width?: number;

          height?: number;
        };

        orderWithoutStock?: boolean;

        products_in_set?: Array<{
          good_id?: number;

          package_good_id?: number;

          package_quantity?: number;

          package_price?: number;

          product_id?: number;

          package_product_id?: number;

          color?: string;

          pages?: string;

          capacity?: string;
        }>;

        street?: string;

        city?: string;

        zip?: string;

        parent_categories: Array<{}>;

        assembling?: boolean;

        prelisting?: boolean;

        productPackages?: Array<{
          id?: number;

          productId?: number;

          storeQuantity?: number;

          fullQuantity?: number;

          type?: number;
        }>;

        lang_versions?: Array<{
          lang_id?: string;

          url?: string;
        }>;
      }>;

      next_article?: {
        name?: string;

        url?: string;

        image?: string;

        annotation?: string;

        id?: number;
      };

      tags?: Array<{
        tag_id: string;

        tag_name?: string;
      }>;

      lang_versions?: Array<{}>;
    };
  }> {
    let path = '/other-texts/{text_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{text_id}', textId.toString());

    if (typeof parameters['langId'] !== 'undefined') {
      queryParameters['lang_id'] = parameters['langId'];
    }

    queryParameters['lang_id'] = this.transformParameter(
      queryParameters['lang_id'],
    );

    if (typeof parameters['domainId'] !== 'undefined') {
      queryParameters['domain_id'] = parameters['domainId'];
    }

    queryParameters['domain_id'] = this.transformParameter(
      queryParameters['domain_id'],
    );

    if (typeof parameters['fallbackDomainId'] !== 'undefined') {
      queryParameters['fallback_domain_id'] = parameters['fallbackDomainId'];
    }

    queryParameters['fallback_domain_id'] = this.transformParameter(
      queryParameters['fallback_domain_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Set order analytics sended
   * @method
   * @name API#updateOrderAnalytics
   */
  updateOrderAnalytics(publicId: string, parameters: {} = {}): Promise<void> {
    let path = '/orders/analytics/{public_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{public_id}', publicId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * export Xml Order
   * @method
   * @name API#exportXmlOrder
   */
  exportXmlOrder(
    parameters: {} = {},
    body: {
      listOfId?: {};
    },
  ): Promise<void> {
    let path = '/orders/xml-order';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Samo Sepesi <sepesi@bart.sk>, Laci Perduk <perduk@bart.sk>

**Main usage**: Generates URL and redirects to VUB payment system

**DESCRIPTION**
Generates url from order_id and redirects user to this URL.

    * @method
    * @name API#vubPay
    */
  vubPay(
    orderHash: string,
    parameters: {
      returnUrl?: string;
    } = {},
  ): Promise<void> {
    let path = '/payment/vubpay/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    if (typeof parameters['returnUrl'] !== 'undefined') {
      queryParameters['return_url'] = parameters['returnUrl'];
    }

    queryParameters['return_url'] = this.transformParameter(
      queryParameters['return_url'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Samo Sepesi <sepesi@bart.sk>, Laci Perduk <perduk@bart.sk>

**Main usage**: Vub calls this url after successful payment. User is redirected to frontend.

**DESCRIPTION**
Vub calls this url after successful payment. User is redirected to frontend.

    * @method
    * @name API#vubPaySuccess
    */
  vubPaySuccess(
    parameters: {
      returnUrl?: string;
    } = {},
  ): Promise<void> {
    let path = '/payment/vubpay/success';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Accept', 'application/json');

    if (typeof parameters['returnUrl'] !== 'undefined') {
      queryParameters['return_url'] = parameters['returnUrl'];
    }

    queryParameters['return_url'] = this.transformParameter(
      queryParameters['return_url'],
    );

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Samo Sepesi <sepesi@bart.sk>, Laci Perduk <perduk@bart.sk>

**Main usage**: Vub calls this url after failed payment. User is redirected to frontend.

**DESCRIPTION**
 Vub calls this url after failed payment. User is redirected to frontend.

    * @method
    * @name API#vubPayFail
    */
  vubPayFail(
    parameters: {
      returnUrl?: string;
    } = {},
  ): Promise<void> {
    let path = '/payment/vubpay/fail';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Accept', 'application/json');

    if (typeof parameters['returnUrl'] !== 'undefined') {
      queryParameters['return_url'] = parameters['returnUrl'];
    }

    queryParameters['return_url'] = this.transformParameter(
      queryParameters['return_url'],
    );

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Redirects to cardpay
   * @method
   * @name API#cardPayNext
   */
  cardPayNext(orderHash: string, parameters: {} = {}): Promise<void> {
    let path = '/payment/cardpay-next/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Charge back order
   * @method
   * @name API#cardPayChargeback
   */
  cardPayChargeback(
    orderId: string,
    parameters: {
      chargebackSum?: string;
    } = {},
  ): Promise<void> {
    let path = '/payment/cardpay_chargeback/{orderId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{orderId}', orderId.toString());

    if (typeof parameters['chargebackSum'] !== 'undefined') {
      queryParameters['chargeback_sum'] = parameters['chargebackSum'];
    }

    queryParameters['chargeback_sum'] = this.transformParameter(
      queryParameters['chargeback_sum'],
    );

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Samo Sepesi <sepesi@bart.sk>, Laci Perduk <perduk@bart.sk>

**Main usage**: Generates URL and redirects to Ahoj system

**DESCRIPTION**
Generates url from order_id and redirects user to this URL.

    * @method
    * @name API#ahojLoan
    */
  ahojLoan(orderHash: string, parameters: {} = {}): Promise<void> {
    let path = '/payment/ahoj/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Samo Sepesi <sepesi@bart.sk>, Laci Perduk <perduk@bart.sk>

**Main usage**: Change order's paid_datetime when loan from Ahoj was signed

**DESCRIPTION**
This is called automatically by Ahoj when Ahoj loan was signed. If sign is correct
and `stav` is 4 - Podpísaná order_paid_datetime is changed in eshop_order database.
If `stav` is not 4, order_paid_datetime is set to NULL.

    * @method
    * @name API#ahojLoanNotif
    */
  ahojLoanNotif(parameters: {
    idobj: string;
    cislozml: string;
    stav: string;
    sign: string;
  }): Promise<void> {
    let path = '/payment/ahoj/notif';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['idobj'] !== 'undefined') {
      queryParameters['idobj'] = parameters['idobj'];
    }

    queryParameters['idobj'] = this.transformParameter(
      queryParameters['idobj'],
    );

    if (typeof parameters['idobj'] === 'undefined') {
      throw new Error('Missing required parameter: idobj');
    }

    if (typeof parameters['cislozml'] !== 'undefined') {
      queryParameters['cislozml'] = parameters['cislozml'];
    }

    queryParameters['cislozml'] = this.transformParameter(
      queryParameters['cislozml'],
    );

    if (typeof parameters['cislozml'] === 'undefined') {
      throw new Error('Missing required parameter: cislozml');
    }

    if (typeof parameters['stav'] !== 'undefined') {
      queryParameters['stav'] = parameters['stav'];
    }

    queryParameters['stav'] = this.transformParameter(queryParameters['stav']);

    if (typeof parameters['stav'] === 'undefined') {
      throw new Error('Missing required parameter: stav');
    }

    if (typeof parameters['sign'] !== 'undefined') {
      queryParameters['sign'] = parameters['sign'];
    }

    queryParameters['sign'] = this.transformParameter(queryParameters['sign']);

    if (typeof parameters['sign'] === 'undefined') {
      throw new Error('Missing required parameter: sign');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Samo Sepesi <sepesi@bart.sk>, Laci Perduk <perduk@bart.sk>

**Main usage**: Redirects user to finished order page on frontend after leaving Ahoj site

**DESCRIPTION**
This is called after user is finished on loan site and is being redirected back to frontend
Uses apiFrontendUrl from config file and 
redirects user to `<apiFrontendUrl>/finish-order-message/<order_public_id>`

    * @method
    * @name API#ahojLoanFinish
    */
  ahojLoanFinish(orderHash: string, parameters: {} = {}): Promise<void> {
    let path = '/payment/ahoj/finish/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Redirects to TrustPay
   * @method
   * @name API#trustPay
   */
  trustPay(
    orderHash: string,
    parameters: {
      type: string;
    },
  ): Promise<void> {
    let path = '/payment/trustpay/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['type'] === 'undefined') {
      throw new Error('Missing required parameter: type');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Redirects to success page
   * @method
   * @name API#trustPaySuccess
   */
  trustPaySuccess(
    orderHash: string,
    parameters: {
      res: string;
    },
  ): Promise<void> {
    let path = '/payment/trustpay/success/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    if (typeof parameters['res'] !== 'undefined') {
      queryParameters['RES'] = parameters['res'];
    }

    queryParameters['RES'] = this.transformParameter(queryParameters['RES']);

    if (typeof parameters['res'] === 'undefined') {
      throw new Error('Missing required parameter: res');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Redirects to error page
   * @method
   * @name API#trustPayError
   */
  trustPayError(orderHash: string, parameters: {} = {}): Promise<void> {
    let path = '/payment/trustpay/error/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Samo Sepesi <sepesi@bart.sk>, Laci Perduk <perduk@bart.sk>

**Main usage**: Generates URL and redirects to Ahoj system

**DESCRIPTION**
Validates order status in Ahoj

    * @method
    * @name API#ahojLoanValidate
    */
  ahojLoanValidate(orderHash: string, parameters: {} = {}): Promise<void> {
    let path = '/payment/ahoj/validate/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Checks if payment was ok
   * @method
   * @name API#trustPayNotif
   */
  trustPayNotif(
    orderHash: string,
    parameters: {
      aid: string;
      typ: string;
      amt: string;
      cur: string;
      ref: string;
      res: string;
      tid: string;
      oid: string;
      tss: string;
      sig: string;
      cardId?: string;
      cardMask?: string;
      cardExp?: string;
      authNumber?: string;
      cardRecTxSec?: string;
      acqResId?: string;
      sig2?: string;
    },
  ): Promise<void> {
    let path = '/payment/trustpay/notif/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    if (typeof parameters['aid'] !== 'undefined') {
      queryParameters['AID'] = parameters['aid'];
    }

    queryParameters['AID'] = this.transformParameter(queryParameters['AID']);

    if (typeof parameters['aid'] === 'undefined') {
      throw new Error('Missing required parameter: aid');
    }

    if (typeof parameters['typ'] !== 'undefined') {
      queryParameters['TYP'] = parameters['typ'];
    }

    queryParameters['TYP'] = this.transformParameter(queryParameters['TYP']);

    if (typeof parameters['typ'] === 'undefined') {
      throw new Error('Missing required parameter: typ');
    }

    if (typeof parameters['amt'] !== 'undefined') {
      queryParameters['AMT'] = parameters['amt'];
    }

    queryParameters['AMT'] = this.transformParameter(queryParameters['AMT']);

    if (typeof parameters['amt'] === 'undefined') {
      throw new Error('Missing required parameter: amt');
    }

    if (typeof parameters['cur'] !== 'undefined') {
      queryParameters['CUR'] = parameters['cur'];
    }

    queryParameters['CUR'] = this.transformParameter(queryParameters['CUR']);

    if (typeof parameters['cur'] === 'undefined') {
      throw new Error('Missing required parameter: cur');
    }

    if (typeof parameters['ref'] !== 'undefined') {
      queryParameters['REF'] = parameters['ref'];
    }

    queryParameters['REF'] = this.transformParameter(queryParameters['REF']);

    if (typeof parameters['ref'] === 'undefined') {
      throw new Error('Missing required parameter: ref');
    }

    if (typeof parameters['res'] !== 'undefined') {
      queryParameters['RES'] = parameters['res'];
    }

    queryParameters['RES'] = this.transformParameter(queryParameters['RES']);

    if (typeof parameters['res'] === 'undefined') {
      throw new Error('Missing required parameter: res');
    }

    if (typeof parameters['tid'] !== 'undefined') {
      queryParameters['TID'] = parameters['tid'];
    }

    queryParameters['TID'] = this.transformParameter(queryParameters['TID']);

    if (typeof parameters['tid'] === 'undefined') {
      throw new Error('Missing required parameter: tid');
    }

    if (typeof parameters['oid'] !== 'undefined') {
      queryParameters['OID'] = parameters['oid'];
    }

    queryParameters['OID'] = this.transformParameter(queryParameters['OID']);

    if (typeof parameters['oid'] === 'undefined') {
      throw new Error('Missing required parameter: oid');
    }

    if (typeof parameters['tss'] !== 'undefined') {
      queryParameters['TSS'] = parameters['tss'];
    }

    queryParameters['TSS'] = this.transformParameter(queryParameters['TSS']);

    if (typeof parameters['tss'] === 'undefined') {
      throw new Error('Missing required parameter: tss');
    }

    if (typeof parameters['sig'] !== 'undefined') {
      queryParameters['SIG'] = parameters['sig'];
    }

    queryParameters['SIG'] = this.transformParameter(queryParameters['SIG']);

    if (typeof parameters['sig'] === 'undefined') {
      throw new Error('Missing required parameter: sig');
    }

    if (typeof parameters['cardId'] !== 'undefined') {
      queryParameters['CardID'] = parameters['cardId'];
    }

    queryParameters['CardID'] = this.transformParameter(
      queryParameters['CardID'],
    );

    if (typeof parameters['cardMask'] !== 'undefined') {
      queryParameters['CardMask'] = parameters['cardMask'];
    }

    queryParameters['CardMask'] = this.transformParameter(
      queryParameters['CardMask'],
    );

    if (typeof parameters['cardExp'] !== 'undefined') {
      queryParameters['CardExp'] = parameters['cardExp'];
    }

    queryParameters['CardExp'] = this.transformParameter(
      queryParameters['CardExp'],
    );

    if (typeof parameters['authNumber'] !== 'undefined') {
      queryParameters['AuthNumber'] = parameters['authNumber'];
    }

    queryParameters['AuthNumber'] = this.transformParameter(
      queryParameters['AuthNumber'],
    );

    if (typeof parameters['cardRecTxSec'] !== 'undefined') {
      queryParameters['CardRecTxSec'] = parameters['cardRecTxSec'];
    }

    queryParameters['CardRecTxSec'] = this.transformParameter(
      queryParameters['CardRecTxSec'],
    );

    if (typeof parameters['acqResId'] !== 'undefined') {
      queryParameters['AcqResId'] = parameters['acqResId'];
    }

    queryParameters['AcqResId'] = this.transformParameter(
      queryParameters['AcqResId'],
    );

    if (typeof parameters['sig2'] !== 'undefined') {
      queryParameters['SIG2'] = parameters['sig2'];
    }

    queryParameters['SIG2'] = this.transformParameter(queryParameters['SIG2']);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Notif URL for GoPay Payments, This is the url, GoPay will call if status of the payment changes
   * @method
   * @name API#goPayNotif
   */
  goPayNotif(
    orderHash: string,
    parameters: {
      id?: string;
    } = {},
  ): Promise<void> {
    let path = '/payment/gopay/notif/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    if (typeof parameters['id'] !== 'undefined') {
      queryParameters['id'] = parameters['id'];
    }

    queryParameters['id'] = this.transformParameter(queryParameters['id']);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Return URL for GoPay Payments
   * @method
   * @name API#goPayRurl
   */
  goPayRurl(
    orderHash: string,
    parameters: {
      redirectUrl?: string;
      id?: string;
    } = {},
  ): Promise<void> {
    let path = '/payment/gopay/return/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    if (typeof parameters['redirectUrl'] !== 'undefined') {
      queryParameters['redirectUrl'] = parameters['redirectUrl'];
    }

    queryParameters['redirectUrl'] = this.transformParameter(
      queryParameters['redirectUrl'],
    );

    if (typeof parameters['id'] !== 'undefined') {
      queryParameters['id'] = parameters['id'];
    }

    queryParameters['id'] = this.transformParameter(queryParameters['id']);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Redirects to gopay
   * @method
   * @name API#goPay
   */
  goPay(
    orderHash: string,
    parameters: {
      returnUrl?: string;
    } = {},
  ): Promise<void> {
    let path = '/payment/gopay/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    if (typeof parameters['returnUrl'] !== 'undefined') {
      queryParameters['return_url'] = parameters['returnUrl'];
    }

    queryParameters['return_url'] = this.transformParameter(
      queryParameters['return_url'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Return URL for CSOB
   * @method
   * @name API#csobRurl
   */
  csobRurl(
    parameters: {
      payId?: string;
      dttm?: string;
      resultCode?: string;
      resultMessage?: string;
      paymentStatus?: string;
      authCode?: string;
      merchantData?: string;
      signature?: string;
      returnUrl?: string;
    } = {},
  ): Promise<void> {
    let path = '/payment/csob/return';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['payId'] !== 'undefined') {
      queryParameters['payId'] = parameters['payId'];
    }

    queryParameters['payId'] = this.transformParameter(
      queryParameters['payId'],
    );

    if (typeof parameters['dttm'] !== 'undefined') {
      queryParameters['dttm'] = parameters['dttm'];
    }

    queryParameters['dttm'] = this.transformParameter(queryParameters['dttm']);

    if (typeof parameters['resultCode'] !== 'undefined') {
      queryParameters['resultCode'] = parameters['resultCode'];
    }

    queryParameters['resultCode'] = this.transformParameter(
      queryParameters['resultCode'],
    );

    if (typeof parameters['resultMessage'] !== 'undefined') {
      queryParameters['resultMessage'] = parameters['resultMessage'];
    }

    queryParameters['resultMessage'] = this.transformParameter(
      queryParameters['resultMessage'],
    );

    if (typeof parameters['paymentStatus'] !== 'undefined') {
      queryParameters['paymentStatus'] = parameters['paymentStatus'];
    }

    queryParameters['paymentStatus'] = this.transformParameter(
      queryParameters['paymentStatus'],
    );

    if (typeof parameters['authCode'] !== 'undefined') {
      queryParameters['authCode'] = parameters['authCode'];
    }

    queryParameters['authCode'] = this.transformParameter(
      queryParameters['authCode'],
    );

    if (typeof parameters['merchantData'] !== 'undefined') {
      queryParameters['merchantData'] = parameters['merchantData'];
    }

    queryParameters['merchantData'] = this.transformParameter(
      queryParameters['merchantData'],
    );

    if (typeof parameters['signature'] !== 'undefined') {
      queryParameters['signature'] = parameters['signature'];
    }

    queryParameters['signature'] = this.transformParameter(
      queryParameters['signature'],
    );

    if (typeof parameters['returnUrl'] !== 'undefined') {
      queryParameters['return_url'] = parameters['returnUrl'];
    }

    queryParameters['return_url'] = this.transformParameter(
      queryParameters['return_url'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Redirects to csob
   * @method
   * @name API#csob
   */
  csob(
    orderHash: string,
    parameters: {
      returnUrl?: string;
    } = {},
  ): Promise<void> {
    let path = '/payment/csob/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    if (typeof parameters['returnUrl'] !== 'undefined') {
      queryParameters['return_url'] = parameters['returnUrl'];
    }

    queryParameters['return_url'] = this.transformParameter(
      queryParameters['return_url'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Samo Sepesi <sepesi@bart.sk>, Laci Perduk <perduk@bart.sk>

**Main usage**: Generates URL and redirects to Quatro payment system

**DESCRIPTION**
Generates URL and redirects to Quatro payment system.

    * @method
    * @name API#quatro
    */
  quatro(orderHash: string, parameters: {} = {}): Promise<void> {
    let path = '/payment/quatro/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Samo Sepesi <sepesi@bart.sk>, Laci Perduk <perduk@bart.sk>

**Main usage**: Redirects user to finished order page on frontend after leaving Quatro site

**DESCRIPTION**
This is called after user is finished on Quatro site and is being redirected back to frontend
Uses apiFrontendUrl from config file and 
redirects user to `<apiFrontendUrl>/finish-order-message/<order_public_id>`

    * @method
    * @name API#quatroFinish
    */
  quatroFinish(
    orderHash: string,
    secretToken: string,
    parameters: {
      id: string;
      cn: string;
      state: string;
    },
  ): Promise<void> {
    let path = '/payment/quatro/finish/{orderHash}/{secretToken}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    path = path.replace('{secretToken}', secretToken.toString());

    if (typeof parameters['id'] !== 'undefined') {
      queryParameters['id'] = parameters['id'];
    }

    queryParameters['id'] = this.transformParameter(queryParameters['id']);

    if (typeof parameters['id'] === 'undefined') {
      throw new Error('Missing required parameter: id');
    }

    if (typeof parameters['cn'] !== 'undefined') {
      queryParameters['cn'] = parameters['cn'];
    }

    queryParameters['cn'] = this.transformParameter(queryParameters['cn']);

    if (typeof parameters['cn'] === 'undefined') {
      throw new Error('Missing required parameter: cn');
    }

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    if (typeof parameters['state'] === 'undefined') {
      throw new Error('Missing required parameter: state');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Roland Kister <kister@bart.sk>

**Main usage**: Generates URL and redirects to Homecredit payment system

**DESCRIPTION**
Generates URL and redirects to Homecredit payment system.

    * @method
    * @name API#homecredit
    */
  homecredit(orderHash: string, parameters: {} = {}): Promise<void> {
    let path = '/payment/homecredit/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Roland Kister <kister@bart.sk>

**Main usage**: This endpoint is for Homecredit API for changing the state of an application

**DESCRIPTION**
When application state changes for an order, this endpoint is called by Homecredit API.
Parameters defined by the schema are the only ones required for validation and state change.
However, more parameters are in requested URL, which could be used for more precise processing. 

    * @method
    * @name API#homecreditFinish
    */
  homecreditFinish(
    status: 'approved' | 'rejected',
    parameters: {
      orderNumber: string;
      stateReason: string;
      checkSum: string;
    },
  ): Promise<void> {
    let path = '/payment/homecredit/finish/{status}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{status}', status.toString());

    if (typeof parameters['orderNumber'] !== 'undefined') {
      queryParameters['orderNumber'] = parameters['orderNumber'];
    }

    queryParameters['orderNumber'] = this.transformParameter(
      queryParameters['orderNumber'],
    );

    if (typeof parameters['orderNumber'] === 'undefined') {
      throw new Error('Missing required parameter: orderNumber');
    }

    if (typeof parameters['stateReason'] !== 'undefined') {
      queryParameters['stateReason'] = parameters['stateReason'];
    }

    queryParameters['stateReason'] = this.transformParameter(
      queryParameters['stateReason'],
    );

    if (typeof parameters['stateReason'] === 'undefined') {
      throw new Error('Missing required parameter: stateReason');
    }

    if (typeof parameters['checkSum'] !== 'undefined') {
      queryParameters['checkSum'] = parameters['checkSum'];
    }

    queryParameters['checkSum'] = this.transformParameter(
      queryParameters['checkSum'],
    );

    if (typeof parameters['checkSum'] === 'undefined') {
      throw new Error('Missing required parameter: checkSum');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Roland Kister <kister@bart.sk>

**Main usage**: This endpoint is for Homecredit API for changing the state of an application

**DESCRIPTION**
When application state changes for an order, this endpoint is called by Homecredit API.
Parameters defined by the schema are the only ones required for validation and state change.
However, more parameters are in requested URL, which could be used for more precise processing. 

    * @method
    * @name API#homecreditCallback
    */
  homecreditCallback(parameters: {} = {}): Promise<void> {
    let path = '/payment/homecredit/callback';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Roland Kister <kister@bart.sk>

**Main usage**: Redirects user to finished order page on frontend after leaving Homecredit site

**DESCRIPTION**
This is called every time an application's state is changed by Homecredit

    * @method
    * @name API#homecreditTest
    */
  homecreditTest(
    orderHash: string,
    parameters: {
      state: string;
    },
  ): Promise<void> {
    let path = '/payment/homecredit/test/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    if (typeof parameters['state'] === 'undefined') {
      throw new Error('Missing required parameter: state');
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Redirects to GPWebPay
   * @method
   * @name API#gpWebPayPayment
   */
  gpWebPayPayment(
    orderHash: string,
    parameters: {
      type?: string;
    } = {},
  ): Promise<void> {
    let path = '/payment/gpwebpay/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Redirects to success page
   * @method
   * @name API#gpWebPayResponse
   */
  gpWebPayResponse(
    orderHash: string,
    parameters: {
      operation: string;
      ordernumber: number;
      merordernum?: number;
      md?: string;
      prcode: number;
      srcode: number;
      resulttext?: string;
      userparam1?: string;
      addinfo?: string;
      token?: string;
      expiry?: string;
      acsres?: string;
      accode?: string;
      panpattern?: string;
      daytocapture?: string;
      tokenregstatus?: string;
      acrc?: string;
      rrn?: string;
      par?: string;
      traceid?: string;
      digest: string;
      digest1: string;
    },
  ): Promise<void> {
    let path = '/payment/gpwebpay/response/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    if (typeof parameters['operation'] !== 'undefined') {
      queryParameters['OPERATION'] = parameters['operation'];
    }

    queryParameters['OPERATION'] = this.transformParameter(
      queryParameters['OPERATION'],
    );

    if (typeof parameters['operation'] === 'undefined') {
      throw new Error('Missing required parameter: operation');
    }

    if (typeof parameters['ordernumber'] !== 'undefined') {
      queryParameters['ORDERNUMBER'] = parameters['ordernumber'];
    }

    queryParameters['ORDERNUMBER'] = this.transformParameter(
      queryParameters['ORDERNUMBER'],
    );

    if (typeof parameters['ordernumber'] === 'undefined') {
      throw new Error('Missing required parameter: ordernumber');
    }

    if (typeof parameters['merordernum'] !== 'undefined') {
      queryParameters['MERORDERNUM'] = parameters['merordernum'];
    }

    queryParameters['MERORDERNUM'] = this.transformParameter(
      queryParameters['MERORDERNUM'],
    );

    if (typeof parameters['md'] !== 'undefined') {
      queryParameters['MD'] = parameters['md'];
    }

    queryParameters['MD'] = this.transformParameter(queryParameters['MD']);

    if (typeof parameters['prcode'] !== 'undefined') {
      queryParameters['PRCODE'] = parameters['prcode'];
    }

    queryParameters['PRCODE'] = this.transformParameter(
      queryParameters['PRCODE'],
    );

    if (typeof parameters['prcode'] === 'undefined') {
      throw new Error('Missing required parameter: prcode');
    }

    if (typeof parameters['srcode'] !== 'undefined') {
      queryParameters['SRCODE'] = parameters['srcode'];
    }

    queryParameters['SRCODE'] = this.transformParameter(
      queryParameters['SRCODE'],
    );

    if (typeof parameters['srcode'] === 'undefined') {
      throw new Error('Missing required parameter: srcode');
    }

    if (typeof parameters['resulttext'] !== 'undefined') {
      queryParameters['RESULTTEXT'] = parameters['resulttext'];
    }

    queryParameters['RESULTTEXT'] = this.transformParameter(
      queryParameters['RESULTTEXT'],
    );

    if (typeof parameters['userparam1'] !== 'undefined') {
      queryParameters['USERPARAM1'] = parameters['userparam1'];
    }

    queryParameters['USERPARAM1'] = this.transformParameter(
      queryParameters['USERPARAM1'],
    );

    if (typeof parameters['addinfo'] !== 'undefined') {
      queryParameters['ADDINFO'] = parameters['addinfo'];
    }

    queryParameters['ADDINFO'] = this.transformParameter(
      queryParameters['ADDINFO'],
    );

    if (typeof parameters['token'] !== 'undefined') {
      queryParameters['TOKEN'] = parameters['token'];
    }

    queryParameters['TOKEN'] = this.transformParameter(
      queryParameters['TOKEN'],
    );

    if (typeof parameters['expiry'] !== 'undefined') {
      queryParameters['EXPIRY'] = parameters['expiry'];
    }

    queryParameters['EXPIRY'] = this.transformParameter(
      queryParameters['EXPIRY'],
    );

    if (typeof parameters['acsres'] !== 'undefined') {
      queryParameters['ACSRES'] = parameters['acsres'];
    }

    queryParameters['ACSRES'] = this.transformParameter(
      queryParameters['ACSRES'],
    );

    if (typeof parameters['accode'] !== 'undefined') {
      queryParameters['ACCODE'] = parameters['accode'];
    }

    queryParameters['ACCODE'] = this.transformParameter(
      queryParameters['ACCODE'],
    );

    if (typeof parameters['panpattern'] !== 'undefined') {
      queryParameters['PANPATTERN'] = parameters['panpattern'];
    }

    queryParameters['PANPATTERN'] = this.transformParameter(
      queryParameters['PANPATTERN'],
    );

    if (typeof parameters['daytocapture'] !== 'undefined') {
      queryParameters['DAYTOCAPTURE'] = parameters['daytocapture'];
    }

    queryParameters['DAYTOCAPTURE'] = this.transformParameter(
      queryParameters['DAYTOCAPTURE'],
    );

    if (typeof parameters['tokenregstatus'] !== 'undefined') {
      queryParameters['TOKENREGSTATUS'] = parameters['tokenregstatus'];
    }

    queryParameters['TOKENREGSTATUS'] = this.transformParameter(
      queryParameters['TOKENREGSTATUS'],
    );

    if (typeof parameters['acrc'] !== 'undefined') {
      queryParameters['ACRC'] = parameters['acrc'];
    }

    queryParameters['ACRC'] = this.transformParameter(queryParameters['ACRC']);

    if (typeof parameters['rrn'] !== 'undefined') {
      queryParameters['RRN'] = parameters['rrn'];
    }

    queryParameters['RRN'] = this.transformParameter(queryParameters['RRN']);

    if (typeof parameters['par'] !== 'undefined') {
      queryParameters['PAR'] = parameters['par'];
    }

    queryParameters['PAR'] = this.transformParameter(queryParameters['PAR']);

    if (typeof parameters['traceid'] !== 'undefined') {
      queryParameters['TRACEID'] = parameters['traceid'];
    }

    queryParameters['TRACEID'] = this.transformParameter(
      queryParameters['TRACEID'],
    );

    if (typeof parameters['digest'] !== 'undefined') {
      queryParameters['DIGEST'] = parameters['digest'];
    }

    queryParameters['DIGEST'] = this.transformParameter(
      queryParameters['DIGEST'],
    );

    if (typeof parameters['digest'] === 'undefined') {
      throw new Error('Missing required parameter: digest');
    }

    if (typeof parameters['digest1'] !== 'undefined') {
      queryParameters['DIGEST1'] = parameters['digest1'];
    }

    queryParameters['DIGEST1'] = this.transformParameter(
      queryParameters['DIGEST1'],
    );

    if (typeof parameters['digest1'] === 'undefined') {
      throw new Error('Missing required parameter: digest1');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Redirect URL for Besteron Payments
   * @method
   * @name API#besteronRedirect
   */
  besteronRedirect(publicOrderId: string, parameters: {} = {}): Promise<void> {
    let path = '/payment/besteron/{publicOrderId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{publicOrderId}', publicOrderId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Return URL for Besteron Payments
   * @method
   * @name API#besteronRurl
   */
  besteronRurl(
    parameters: {
      cid?: string;
      type?: string;
      amnt?: number;
      curr?: number;
      vs?: string;
      result?: string;
      sign?: string;
    } = {},
  ): Promise<void> {
    let path = '/payment/besteron/return';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['cid'] !== 'undefined') {
      queryParameters['CID'] = parameters['cid'];
    }

    queryParameters['CID'] = this.transformParameter(queryParameters['CID']);

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['TYPE'] = parameters['type'];
    }

    queryParameters['TYPE'] = this.transformParameter(queryParameters['TYPE']);

    if (typeof parameters['amnt'] !== 'undefined') {
      queryParameters['AMNT'] = parameters['amnt'];
    }

    queryParameters['AMNT'] = this.transformParameter(queryParameters['AMNT']);

    if (typeof parameters['curr'] !== 'undefined') {
      queryParameters['CURR'] = parameters['curr'];
    }

    queryParameters['CURR'] = this.transformParameter(queryParameters['CURR']);

    if (typeof parameters['vs'] !== 'undefined') {
      queryParameters['VS'] = parameters['vs'];
    }

    queryParameters['VS'] = this.transformParameter(queryParameters['VS']);

    if (typeof parameters['result'] !== 'undefined') {
      queryParameters['RESULT'] = parameters['result'];
    }

    queryParameters['RESULT'] = this.transformParameter(
      queryParameters['RESULT'],
    );

    if (typeof parameters['sign'] !== 'undefined') {
      queryParameters['SIGN'] = parameters['sign'];
    }

    queryParameters['SIGN'] = this.transformParameter(queryParameters['SIGN']);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>, Samo Sepesi <sepesi@bart.sk>

**Main usage**: Returns list of documents from input ids

**DESCRIPTION**
If provided with document ids, returns array of these documents.

    * @method
    * @name API#listPhotogalleryImages
    */
  listPhotogalleryImages(
    parameters: {} = {},
    body: {
      images_ids: Array<string>;
    },
  ): Promise<{}> {
    let path = '/photogallery-images';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
    * You can specify category ID to get parameters only for products in
specific category

    * @method
    * @name API#loadProductAttribs
    */
  loadProductAttribs(
    parameters: {
      categoryId?: string;
      attribUniqueId?: string;
      brandId?: string;
      productType?: string;
      parameters?: string;
      sorter?: 'NAME' | 'LANG_ORDER_NUMBER_SORT' | 'VALUE_SORT';
      withoutHidden?: '0' | '1';
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    product_attribs?: Array<{
      attrib_id: number;

      type_id: number;

      attrib_uid: string | null;

      attrib_type: string | null;

      group_id: number | null;

      attrib_name: string;

      attrib_ismulti: number;

      attrib_sort: number;

      attrib_pbl: number;

      attrib_unit: string;

      attrib_list: number;

      attrib_code: string | null;

      attrib_grid: number;

      min?: number;

      max?: number;

      translations: Array<{
        attrib_id: number;

        lang_id: string;

        attrib_name: string;

        attrib_unit: string | null;
      }>;

      values: Array<{
        amount: number;

        value_id: number;

        attrib_id: number;

        attrib_value: string;

        attrib_pict: string | null;

        group_id: number | null;

        istop: boolean;

        searchfilter: number;

        value_sort: number | null;

        translations: Array<{
          value_id: number;

          lang_id: string;

          attrib_value: string;

          attrib_link: string;
        }>;
      }>;
    }>;
  }> {
    let path = '/product-attribs';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['categoryId'] !== 'undefined') {
      queryParameters['category_id'] = parameters['categoryId'];
    }

    queryParameters['category_id'] = this.transformParameter(
      queryParameters['category_id'],
    );

    if (typeof parameters['attribUniqueId'] !== 'undefined') {
      queryParameters['attrib_unique_id'] = parameters['attribUniqueId'];
    }

    queryParameters['attrib_unique_id'] = this.transformParameter(
      queryParameters['attrib_unique_id'],
    );

    if (typeof parameters['brandId'] !== 'undefined') {
      queryParameters['brand_id'] = parameters['brandId'];
    }

    queryParameters['brand_id'] = this.transformParameter(
      queryParameters['brand_id'],
    );

    if (typeof parameters['productType'] !== 'undefined') {
      queryParameters['product_type'] = parameters['productType'];
    }

    queryParameters['product_type'] = this.transformParameter(
      queryParameters['product_type'],
    );

    if (typeof parameters['parameters'] !== 'undefined') {
      queryParameters['parameters'] = parameters['parameters'];
    }

    queryParameters['parameters'] = this.transformParameter(
      queryParameters['parameters'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['withoutHidden'] !== 'undefined') {
      queryParameters['without_hidden'] = parameters['withoutHidden'];
    }

    queryParameters['without_hidden'] = this.transformParameter(
      queryParameters['without_hidden'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * returns product list info
   * @method
   * @name API#loadProductList
   */
  loadProductList(
    listId: string,
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
      xCurrency?: string;
    },
  ): Promise<{
    list_id: string;

    list_name: string;

    grupa: string | null;

    ordernr: number;

    products: Array<{
      availability_date: string | null;

      is_in_wishlist: boolean;

      product_id: number;

      name: string;

      slogan: string | null;

      is_sale: 0 | 1;

      issaleout: 0 | 1;

      is_new: 0 | 1;

      is_top: 0 | 1;

      plu: string | null;

      ean: string | null;

      url: string;

      description: string;

      created_at: string;

      rating: string;

      rating_users: number;

      unpacking_cost?: number;

      picture: string | null;

      brand: {};

      name_url_encoded: string;

      qtyDiscounts?: {
        discount_pcn?: number;

        qty_from?: number;

        product_id?: number;

        lang?: string;

        final_price?: number;
      };

      goods: Array<{
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        final_price_without_vat?: number | null;

        customer_discount?: number | null;

        b2b_discount?: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        sum_price?: number | null;

        sum_price_without_vat?: number | null;

        sum_unpacking_cost?: number | null;

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        discount_pcn?: number;

        color?: Array<{}>;

        length?: number;

        width?: number;

        height?: number;
      }>;

      main_good: {
        priceinfo: string;

        valid_from?: string;

        valid_to?: string;

        imagePath: string;

        final_price: number | null;

        final_price_without_vat?: number | null;

        customer_discount?: number | null;

        b2b_discount?: number | null;

        unitprice_sale: number | null;

        sale: boolean | null;

        good_id: number;

        product_id: number;

        currency_id: string;

        unitprice: number;

        vat_rate: number;

        availability_id: number | null;

        oldprice: number | null;

        units: string | null;

        order_number: string | null;

        ean: string | null;

        internal_code: string | null;

        on_stock_count: number;

        is_on_stock: 0 | 1;

        point_good: 0 | 1;

        points_amount: number | null;

        sale_percentage: number | null;

        price_without_vat: number | null;

        price_without_vat_sale: number | null;

        currency: {
          id: string;

          name: string;
        };

        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            lang: string;

            avail_name: string;
          }>;
        };

        sum_price?: number | null;

        sum_price_without_vat?: number | null;

        sum_unpacking_cost?: number | null;

        group_price?: {
          unitprice: number;

          oldprice: number;

          sale_percentage: number;
        };

        discount_pcn?: number;

        color?: Array<{}>;

        length?: number;

        width?: number;

        height?: number;
      };

      orderWithoutStock?: boolean;

      products_in_set?: Array<{
        good_id?: number;

        package_good_id?: number;

        package_quantity?: number;

        package_price?: number;

        product_id?: number;

        package_product_id?: number;

        color?: string;

        pages?: string;

        capacity?: string;
      }>;

      street?: string;

      city?: string;

      zip?: string;

      parent_categories: Array<{}>;

      assembling?: boolean;

      prelisting?: boolean;

      productPackages?: Array<{
        id?: number;

        productId?: number;

        storeQuantity?: number;

        fullQuantity?: number;

        type?: number;
      }>;

      lang_versions?: Array<{
        lang_id?: string;

        url?: string;
      }>;
    }>;
  }> {
    let path = '/product-list/{list_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (extraHeaders && typeof extraHeaders['xCurrency'] !== 'undefined') {
      headers.append('X-Currency', extraHeaders['xCurrency']!);
    }

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{list_id}', listId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * You can filter list by currency, category and language
   * @method
   * @name API#searchProducts
   */
  searchProducts(
    parameters: {
      columns?: string;
      withoutCurrency?: '0' | '1';
      withGoods?: '0' | '1';
      withGifts?: '0' | '1';
      withAttribs?: '0' | '1';
      withBrand?: '0' | '1';
      withPublish?: '0' | '1';
      validFrom?: string;
      validTo?: string;
      onlyValidGoods?: '0' | '1';
      categoryId?: number;
      limit?: number;
      offset?: number;
      isSale?: '0' | '1';
      isSaleout?: '0' | '1';
      onStockSort?: number;
      isTop?: number;
      availabilityId?: number;
      brandId?: number;
      priceMin?: number;
      priceMax?: number;
      price?: number;
      productListId?: string;
      sort?: string;
      sortDir?: string;
      parameters?: Array<string>;
      ean?: string;
      plu?: string;
      internalCode?: string;
      q?: string;
      ageFilter?: string;
      street?: string;
      city?: string;
      zip?: string;
      onlyAvailableGoods?: string;
      onlyOnInternalStock?: '0' | '1';
      onlyOnExternalStock?: '0' | '1';
      productSystem?: string;
      isNew?: '0' | '1';
      withCustomOrderNr?: '0' | '1';
      withProductPackages?: '0' | '1';
      goodsWithStores?: '0' | '1';
      withParentCategories?: '0' | '1';
      withFreeDelivery?: '0' | '1';
      withColors?: '0' | '1';
      withSize?: '0' | '1';
      tagIds?: string;
      withAllLangs?: '0' | '1';
      domainId?: string;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
      xCurrency?: string;
    },
  ): Promise<{
    total: number;

    price_min: number;

    price_max: number;

    offset: number;

    limit: number;

    products: Array<
      Array<{
        availability_date: string | null;

        is_in_wishlist: boolean;

        product_id: number;

        name: string;

        slogan: string | null;

        is_sale: 0 | 1;

        issaleout: 0 | 1;

        is_new: 0 | 1;

        is_top: 0 | 1;

        plu: string | null;

        ean: string | null;

        url: string;

        description: string;

        created_at: string;

        rating: string;

        rating_users: number;

        unpacking_cost?: number;

        picture: string | null;

        brand: {};

        name_url_encoded: string;

        qtyDiscounts?: {
          discount_pcn?: number;

          qty_from?: number;

          product_id?: number;

          lang?: string;

          final_price?: number;
        };

        goods: Array<{
          priceinfo: string;

          valid_from?: string;

          valid_to?: string;

          imagePath: string;

          final_price: number | null;

          final_price_without_vat?: number | null;

          customer_discount?: number | null;

          b2b_discount?: number | null;

          unitprice_sale: number | null;

          sale: boolean | null;

          good_id: number;

          product_id: number;

          currency_id: string;

          unitprice: number;

          vat_rate: number;

          availability_id: number | null;

          oldprice: number | null;

          units: string | null;

          order_number: string | null;

          ean: string | null;

          internal_code: string | null;

          on_stock_count: number;

          is_on_stock: 0 | 1;

          point_good: 0 | 1;

          points_amount: number | null;

          sale_percentage: number | null;

          price_without_vat: number | null;

          price_without_vat_sale: number | null;

          currency: {
            id: string;

            name: string;
          };

          availability: {
            avail_id?: number;

            avail_uniqid?: string;

            order_available?: number;

            translations?: Array<{
              avail_id: number;

              lang: string;

              avail_name: string;
            }>;
          };

          sum_price?: number | null;

          sum_price_without_vat?: number | null;

          sum_unpacking_cost?: number | null;

          group_price?: {
            unitprice: number;

            oldprice: number;

            sale_percentage: number;
          };

          discount_pcn?: number;

          color?: Array<{}>;

          length?: number;

          width?: number;

          height?: number;
        }>;

        main_good: {
          priceinfo: string;

          valid_from?: string;

          valid_to?: string;

          imagePath: string;

          final_price: number | null;

          final_price_without_vat?: number | null;

          customer_discount?: number | null;

          b2b_discount?: number | null;

          unitprice_sale: number | null;

          sale: boolean | null;

          good_id: number;

          product_id: number;

          currency_id: string;

          unitprice: number;

          vat_rate: number;

          availability_id: number | null;

          oldprice: number | null;

          units: string | null;

          order_number: string | null;

          ean: string | null;

          internal_code: string | null;

          on_stock_count: number;

          is_on_stock: 0 | 1;

          point_good: 0 | 1;

          points_amount: number | null;

          sale_percentage: number | null;

          price_without_vat: number | null;

          price_without_vat_sale: number | null;

          currency: {
            id: string;

            name: string;
          };

          availability: {
            avail_id?: number;

            avail_uniqid?: string;

            order_available?: number;

            translations?: Array<{
              avail_id: number;

              lang: string;

              avail_name: string;
            }>;
          };

          sum_price?: number | null;

          sum_price_without_vat?: number | null;

          sum_unpacking_cost?: number | null;

          group_price?: {
            unitprice: number;

            oldprice: number;

            sale_percentage: number;
          };

          discount_pcn?: number;

          color?: Array<{}>;

          length?: number;

          width?: number;

          height?: number;
        };

        orderWithoutStock?: boolean;

        products_in_set?: Array<{
          good_id?: number;

          package_good_id?: number;

          package_quantity?: number;

          package_price?: number;

          product_id?: number;

          package_product_id?: number;

          color?: string;

          pages?: string;

          capacity?: string;
        }>;

        street?: string;

        city?: string;

        zip?: string;

        parent_categories: Array<{}>;

        assembling?: boolean;

        prelisting?: boolean;

        productPackages?: Array<{
          id?: number;

          productId?: number;

          storeQuantity?: number;

          fullQuantity?: number;

          type?: number;
        }>;

        lang_versions?: Array<{
          lang_id?: string;

          url?: string;
        }>;
      }>
    >;
  }> {
    let path = '/products';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (extraHeaders && typeof extraHeaders['xCurrency'] !== 'undefined') {
      headers.append('X-Currency', extraHeaders['xCurrency']!);
    }

    if (typeof parameters['columns'] !== 'undefined') {
      queryParameters['columns'] = parameters['columns'];
    }

    queryParameters['columns'] = this.transformParameter(
      queryParameters['columns'],
    );

    queryParameters['without_currency'] = '0';

    if (typeof parameters['withoutCurrency'] !== 'undefined') {
      queryParameters['without_currency'] = parameters['withoutCurrency'];
    }

    queryParameters['without_currency'] = this.transformParameter(
      queryParameters['without_currency'],
    );

    queryParameters['with_goods'] = '1';

    if (typeof parameters['withGoods'] !== 'undefined') {
      queryParameters['with_goods'] = parameters['withGoods'];
    }

    queryParameters['with_goods'] = this.transformParameter(
      queryParameters['with_goods'],
    );

    queryParameters['with_gifts'] = '1';

    if (typeof parameters['withGifts'] !== 'undefined') {
      queryParameters['with_gifts'] = parameters['withGifts'];
    }

    queryParameters['with_gifts'] = this.transformParameter(
      queryParameters['with_gifts'],
    );

    queryParameters['with_attribs'] = '1';

    if (typeof parameters['withAttribs'] !== 'undefined') {
      queryParameters['with_attribs'] = parameters['withAttribs'];
    }

    queryParameters['with_attribs'] = this.transformParameter(
      queryParameters['with_attribs'],
    );

    queryParameters['with_brand'] = '1';

    if (typeof parameters['withBrand'] !== 'undefined') {
      queryParameters['with_brand'] = parameters['withBrand'];
    }

    queryParameters['with_brand'] = this.transformParameter(
      queryParameters['with_brand'],
    );

    if (typeof parameters['withPublish'] !== 'undefined') {
      queryParameters['with_publish'] = parameters['withPublish'];
    }

    queryParameters['with_publish'] = this.transformParameter(
      queryParameters['with_publish'],
    );

    if (typeof parameters['validFrom'] !== 'undefined') {
      queryParameters['valid_from'] = parameters['validFrom'];
    }

    queryParameters['valid_from'] = this.transformParameter(
      queryParameters['valid_from'],
    );

    if (typeof parameters['validTo'] !== 'undefined') {
      queryParameters['valid_to'] = parameters['validTo'];
    }

    queryParameters['valid_to'] = this.transformParameter(
      queryParameters['valid_to'],
    );

    if (typeof parameters['onlyValidGoods'] !== 'undefined') {
      queryParameters['only_valid_goods'] = parameters['onlyValidGoods'];
    }

    queryParameters['only_valid_goods'] = this.transformParameter(
      queryParameters['only_valid_goods'],
    );

    if (typeof parameters['categoryId'] !== 'undefined') {
      queryParameters['category_id'] = parameters['categoryId'];
    }

    queryParameters['category_id'] = this.transformParameter(
      queryParameters['category_id'],
    );

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['is_sale'] = '0';

    if (typeof parameters['isSale'] !== 'undefined') {
      queryParameters['is_sale'] = parameters['isSale'];
    }

    queryParameters['is_sale'] = this.transformParameter(
      queryParameters['is_sale'],
    );

    queryParameters['is_saleout'] = '0';

    if (typeof parameters['isSaleout'] !== 'undefined') {
      queryParameters['is_saleout'] = parameters['isSaleout'];
    }

    queryParameters['is_saleout'] = this.transformParameter(
      queryParameters['is_saleout'],
    );

    if (typeof parameters['onStockSort'] !== 'undefined') {
      queryParameters['on_stock_sort'] = parameters['onStockSort'];
    }

    queryParameters['on_stock_sort'] = this.transformParameter(
      queryParameters['on_stock_sort'],
    );

    if (typeof parameters['isTop'] !== 'undefined') {
      queryParameters['is_top'] = parameters['isTop'];
    }

    queryParameters['is_top'] = this.transformParameter(
      queryParameters['is_top'],
    );

    if (typeof parameters['availabilityId'] !== 'undefined') {
      queryParameters['availability_id'] = parameters['availabilityId'];
    }

    queryParameters['availability_id'] = this.transformParameter(
      queryParameters['availability_id'],
    );

    if (typeof parameters['brandId'] !== 'undefined') {
      queryParameters['brand_id'] = parameters['brandId'];
    }

    queryParameters['brand_id'] = this.transformParameter(
      queryParameters['brand_id'],
    );

    if (typeof parameters['priceMin'] !== 'undefined') {
      queryParameters['price_min'] = parameters['priceMin'];
    }

    queryParameters['price_min'] = this.transformParameter(
      queryParameters['price_min'],
    );

    if (typeof parameters['priceMax'] !== 'undefined') {
      queryParameters['price_max'] = parameters['priceMax'];
    }

    queryParameters['price_max'] = this.transformParameter(
      queryParameters['price_max'],
    );

    if (typeof parameters['price'] !== 'undefined') {
      queryParameters['price'] = parameters['price'];
    }

    queryParameters['price'] = this.transformParameter(
      queryParameters['price'],
    );

    if (typeof parameters['productListId'] !== 'undefined') {
      queryParameters['product_list_id'] = parameters['productListId'];
    }

    queryParameters['product_list_id'] = this.transformParameter(
      queryParameters['product_list_id'],
    );

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    if (typeof parameters['parameters'] !== 'undefined') {
      queryParameters['parameters'] = parameters['parameters'];
    }

    queryParameters['parameters'] = this.transformParameter(
      queryParameters['parameters'],
    );

    if (typeof parameters['ean'] !== 'undefined') {
      queryParameters['ean'] = parameters['ean'];
    }

    queryParameters['ean'] = this.transformParameter(queryParameters['ean']);

    if (typeof parameters['plu'] !== 'undefined') {
      queryParameters['plu'] = parameters['plu'];
    }

    queryParameters['plu'] = this.transformParameter(queryParameters['plu']);

    if (typeof parameters['internalCode'] !== 'undefined') {
      queryParameters['internal_code'] = parameters['internalCode'];
    }

    queryParameters['internal_code'] = this.transformParameter(
      queryParameters['internal_code'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['ageFilter'] !== 'undefined') {
      queryParameters['age_filter'] = parameters['ageFilter'];
    }

    queryParameters['age_filter'] = this.transformParameter(
      queryParameters['age_filter'],
    );

    if (typeof parameters['street'] !== 'undefined') {
      queryParameters['street'] = parameters['street'];
    }

    queryParameters['street'] = this.transformParameter(
      queryParameters['street'],
    );

    if (typeof parameters['city'] !== 'undefined') {
      queryParameters['city'] = parameters['city'];
    }

    queryParameters['city'] = this.transformParameter(queryParameters['city']);

    if (typeof parameters['zip'] !== 'undefined') {
      queryParameters['zip'] = parameters['zip'];
    }

    queryParameters['zip'] = this.transformParameter(queryParameters['zip']);

    if (typeof parameters['onlyAvailableGoods'] !== 'undefined') {
      queryParameters['only_available_goods'] =
        parameters['onlyAvailableGoods'];
    }

    queryParameters['only_available_goods'] = this.transformParameter(
      queryParameters['only_available_goods'],
    );

    queryParameters['only_on_internal_stock'] = '0';

    if (typeof parameters['onlyOnInternalStock'] !== 'undefined') {
      queryParameters['only_on_internal_stock'] =
        parameters['onlyOnInternalStock'];
    }

    queryParameters['only_on_internal_stock'] = this.transformParameter(
      queryParameters['only_on_internal_stock'],
    );

    queryParameters['only_on_external_stock'] = '0';

    if (typeof parameters['onlyOnExternalStock'] !== 'undefined') {
      queryParameters['only_on_external_stock'] =
        parameters['onlyOnExternalStock'];
    }

    queryParameters['only_on_external_stock'] = this.transformParameter(
      queryParameters['only_on_external_stock'],
    );

    if (typeof parameters['productSystem'] !== 'undefined') {
      queryParameters['product_system'] = parameters['productSystem'];
    }

    queryParameters['product_system'] = this.transformParameter(
      queryParameters['product_system'],
    );

    queryParameters['is_new'] = '0';

    if (typeof parameters['isNew'] !== 'undefined') {
      queryParameters['is_new'] = parameters['isNew'];
    }

    queryParameters['is_new'] = this.transformParameter(
      queryParameters['is_new'],
    );

    queryParameters['with_custom_order_nr'] = '0';

    if (typeof parameters['withCustomOrderNr'] !== 'undefined') {
      queryParameters['with_custom_order_nr'] = parameters['withCustomOrderNr'];
    }

    queryParameters['with_custom_order_nr'] = this.transformParameter(
      queryParameters['with_custom_order_nr'],
    );

    queryParameters['with_product_packages'] = '0';

    if (typeof parameters['withProductPackages'] !== 'undefined') {
      queryParameters['with_product_packages'] =
        parameters['withProductPackages'];
    }

    queryParameters['with_product_packages'] = this.transformParameter(
      queryParameters['with_product_packages'],
    );

    if (typeof parameters['goodsWithStores'] !== 'undefined') {
      queryParameters['goods_with_stores'] = parameters['goodsWithStores'];
    }

    queryParameters['goods_with_stores'] = this.transformParameter(
      queryParameters['goods_with_stores'],
    );

    if (typeof parameters['withParentCategories'] !== 'undefined') {
      queryParameters['with_parent_categories'] =
        parameters['withParentCategories'];
    }

    queryParameters['with_parent_categories'] = this.transformParameter(
      queryParameters['with_parent_categories'],
    );

    if (typeof parameters['withFreeDelivery'] !== 'undefined') {
      queryParameters['with_free_delivery'] = parameters['withFreeDelivery'];
    }

    queryParameters['with_free_delivery'] = this.transformParameter(
      queryParameters['with_free_delivery'],
    );

    if (typeof parameters['withColors'] !== 'undefined') {
      queryParameters['with_colors'] = parameters['withColors'];
    }

    queryParameters['with_colors'] = this.transformParameter(
      queryParameters['with_colors'],
    );

    if (typeof parameters['withSize'] !== 'undefined') {
      queryParameters['with_size'] = parameters['withSize'];
    }

    queryParameters['with_size'] = this.transformParameter(
      queryParameters['with_size'],
    );

    if (typeof parameters['tagIds'] !== 'undefined') {
      queryParameters['tag_ids'] = parameters['tagIds'];
    }

    queryParameters['tag_ids'] = this.transformParameter(
      queryParameters['tag_ids'],
    );

    if (typeof parameters['withAllLangs'] !== 'undefined') {
      queryParameters['with_all_langs'] = parameters['withAllLangs'];
    }

    queryParameters['with_all_langs'] = this.transformParameter(
      queryParameters['with_all_langs'],
    );

    if (typeof parameters['domainId'] !== 'undefined') {
      queryParameters['domain_id'] = parameters['domainId'];
    }

    queryParameters['domain_id'] = this.transformParameter(
      queryParameters['domain_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Export product attribs
   * @method
   * @name API#exportDsiProductsParameters
   */
  exportDsiProductsParameters(parameters: {} = {}): Promise<void> {
    let path = '/products/attribs/export';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Loads all product types
   * @method
   * @name API#loadProductTypes
   */
  loadProductTypes(parameters: {} = {}): Promise<void> {
    let path = '/product/types';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * returns product questions
   * @method
   * @name API#loadQuestions
   */
  loadQuestions(
    parameters: {
      productId?: number;
      categoryId?: string;
      sort?: string;
      sortDir?: string;
      published?: '0' | '1';
      answered?: '0' | '1';
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    questions: Array<{
      answer: string;

      answer_date: string;

      categories: {
        categories: Array<{
          category_id: number;

          category_name: string;

          lang_id: string;
        }>;
      };

      category_id: string;

      email: string;

      id: number;

      ip: string;

      lang_id: string;

      phone: string;

      product_id: number;

      published: number;

      question: string;

      question_date: string;

      review?: number;

      username: string;
    }>;
  }> {
    let path = '/products/questions';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['productId'] !== 'undefined') {
      queryParameters['product_id'] = parameters['productId'];
    }

    queryParameters['product_id'] = this.transformParameter(
      queryParameters['product_id'],
    );

    if (typeof parameters['categoryId'] !== 'undefined') {
      queryParameters['category_id'] = parameters['categoryId'];
    }

    queryParameters['category_id'] = this.transformParameter(
      queryParameters['category_id'],
    );

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    queryParameters['sort_dir'] = 'asc';

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    if (typeof parameters['published'] !== 'undefined') {
      queryParameters['published'] = parameters['published'];
    }

    queryParameters['published'] = this.transformParameter(
      queryParameters['published'],
    );

    if (typeof parameters['answered'] !== 'undefined') {
      queryParameters['answered'] = parameters['answered'];
    }

    queryParameters['answered'] = this.transformParameter(
      queryParameters['answered'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * sends a product questions to the database
   * @method
   * @name API#insertQuestions
   */
  insertQuestions(
    parameters: {} = {},
    body: {
      category_id?: Array<number>;

      email: string;

      lang_id: string;

      phone: string;

      product_id: number;

      question: string;

      username: string;

      review?: number;
    },
  ): Promise<{
    status?: string;
  }> {
    let path = '/products/questions';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * returns product question categories
   * @method
   * @name API#loadQuestionCategories
   */
  loadQuestionCategories(
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    categories: Array<{
      category_id: number;

      category_name: string;

      lang_id: string;
    }>;
  }> {
    let path = '/products/questions/categories';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Creates a new question category in the database
   * @method
   * @name API#createCategory
   */
  createCategory(
    parameters: {} = {},
    body: {
      category_name: string;

      lang_id: string;
    },
  ): Promise<{
    status?: string;
  }> {
    let path = '/products/questions/categories';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Returns product question category detail
   * @method
   * @name API#loadCategoryDetail
   */
  loadCategoryDetail(
    categoryId: number,
    parameters: {} = {},
  ): Promise<{
    category_id: number;

    category_name: string;

    lang_id: string;
  }> {
    let path = '/products/questions/categories/{category_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{category_id}', categoryId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Deletes category in the database
   * @method
   * @name API#deleteCategory
   */
  deleteCategory(
    categoryId: number,
    parameters: {} = {},
  ): Promise<{
    status?: string;
  }> {
    let path = '/products/questions/categories/{category_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{category_id}', categoryId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Updates category in the database
   * @method
   * @name API#updateCategory
   */
  updateCategory(
    categoryId: number,
    parameters: {} = {},
    body: {
      category_name?: string;

      lang_id?: string;
    },
  ): Promise<{
    status?: {
      category_id: number;

      category_name: string;

      lang_id: string;
    };
  }> {
    let path = '/products/questions/categories/{category_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{category_id}', categoryId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * returns category info
   * @method
   * @name API#loadProduct
   */
  loadProduct(
    productId: number,
    parameters: {
      onlyValidGoods?: '0' | '1';
      published?: '0' | '1';
      goodsWithStores?: '0' | '1';
      goodsWithSets?: '0' | '1';
      withParentCategories?: '0' | '1';
      withCustomOrderNr?: '0' | '1';
      withProductPackages?: '0' | '1';
      count?: number;
      withFreeDelivery?: '0' | '1';
      withContents?: '0' | '1';
      withAllLangs?: '0' | '1';
      withProductVariants?: '0' | '1';
      topCategoryId?: string;
      domainId?: string;
      cartId?: string;
      withLangVersions?: '0' | '1';
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
      xCurrency?: string;
    },
  ): Promise<{
    availability_date: string | null;

    is_in_wishlist: boolean;

    product_id: number;

    name: string;

    slogan: string | null;

    is_sale: 0 | 1;

    issaleout: 0 | 1;

    is_new: 0 | 1;

    is_top: 0 | 1;

    plu: string | null;

    ean: string | null;

    url: string;

    description: string;

    created_at: string;

    rating: string;

    rating_users: number;

    unpacking_cost?: number;

    picture: string | null;

    brand: {};

    name_url_encoded: string;

    qtyDiscounts?: {
      discount_pcn?: number;

      qty_from?: number;

      product_id?: number;

      lang?: string;

      final_price?: number;
    };

    goods: Array<{
      priceinfo: string;

      valid_from?: string;

      valid_to?: string;

      imagePath: string;

      final_price: number | null;

      final_price_without_vat?: number | null;

      customer_discount?: number | null;

      b2b_discount?: number | null;

      unitprice_sale: number | null;

      sale: boolean | null;

      good_id: number;

      product_id: number;

      currency_id: string;

      unitprice: number;

      vat_rate: number;

      availability_id: number | null;

      oldprice: number | null;

      units: string | null;

      order_number: string | null;

      ean: string | null;

      internal_code: string | null;

      on_stock_count: number;

      is_on_stock: 0 | 1;

      point_good: 0 | 1;

      points_amount: number | null;

      sale_percentage: number | null;

      price_without_vat: number | null;

      price_without_vat_sale: number | null;

      currency: {
        id: string;

        name: string;
      };

      availability: {
        avail_id?: number;

        avail_uniqid?: string;

        order_available?: number;

        translations?: Array<{
          avail_id: number;

          lang: string;

          avail_name: string;
        }>;
      };

      sum_price?: number | null;

      sum_price_without_vat?: number | null;

      sum_unpacking_cost?: number | null;

      group_price?: {
        unitprice: number;

        oldprice: number;

        sale_percentage: number;
      };

      discount_pcn?: number;

      color?: Array<{}>;

      length?: number;

      width?: number;

      height?: number;
    }>;

    main_good: {
      priceinfo: string;

      valid_from?: string;

      valid_to?: string;

      imagePath: string;

      final_price: number | null;

      final_price_without_vat?: number | null;

      customer_discount?: number | null;

      b2b_discount?: number | null;

      unitprice_sale: number | null;

      sale: boolean | null;

      good_id: number;

      product_id: number;

      currency_id: string;

      unitprice: number;

      vat_rate: number;

      availability_id: number | null;

      oldprice: number | null;

      units: string | null;

      order_number: string | null;

      ean: string | null;

      internal_code: string | null;

      on_stock_count: number;

      is_on_stock: 0 | 1;

      point_good: 0 | 1;

      points_amount: number | null;

      sale_percentage: number | null;

      price_without_vat: number | null;

      price_without_vat_sale: number | null;

      currency: {
        id: string;

        name: string;
      };

      availability: {
        avail_id?: number;

        avail_uniqid?: string;

        order_available?: number;

        translations?: Array<{
          avail_id: number;

          lang: string;

          avail_name: string;
        }>;
      };

      sum_price?: number | null;

      sum_price_without_vat?: number | null;

      sum_unpacking_cost?: number | null;

      group_price?: {
        unitprice: number;

        oldprice: number;

        sale_percentage: number;
      };

      discount_pcn?: number;

      color?: Array<{}>;

      length?: number;

      width?: number;

      height?: number;
    };

    orderWithoutStock?: boolean;

    products_in_set?: Array<{
      good_id?: number;

      package_good_id?: number;

      package_quantity?: number;

      package_price?: number;

      product_id?: number;

      package_product_id?: number;

      color?: string;

      pages?: string;

      capacity?: string;
    }>;

    street?: string;

    city?: string;

    zip?: string;

    parent_categories: Array<{}>;

    assembling?: boolean;

    prelisting?: boolean;

    productPackages?: Array<{
      id?: number;

      productId?: number;

      storeQuantity?: number;

      fullQuantity?: number;

      type?: number;
    }>;

    lang_versions?: Array<{
      lang_id?: string;

      url?: string;
    }>;
  }> {
    let path = '/products/{product_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{product_id}', productId.toString());

    if (extraHeaders && typeof extraHeaders['xCurrency'] !== 'undefined') {
      headers.append('X-Currency', extraHeaders['xCurrency']!);
    }

    if (typeof parameters['onlyValidGoods'] !== 'undefined') {
      queryParameters['only_valid_goods'] = parameters['onlyValidGoods'];
    }

    queryParameters['only_valid_goods'] = this.transformParameter(
      queryParameters['only_valid_goods'],
    );

    queryParameters['published'] = '1';

    if (typeof parameters['published'] !== 'undefined') {
      queryParameters['published'] = parameters['published'];
    }

    queryParameters['published'] = this.transformParameter(
      queryParameters['published'],
    );

    queryParameters['goods_with_stores'] = '0';

    if (typeof parameters['goodsWithStores'] !== 'undefined') {
      queryParameters['goods_with_stores'] = parameters['goodsWithStores'];
    }

    queryParameters['goods_with_stores'] = this.transformParameter(
      queryParameters['goods_with_stores'],
    );

    queryParameters['goods_with_sets'] = '0';

    if (typeof parameters['goodsWithSets'] !== 'undefined') {
      queryParameters['goods_with_sets'] = parameters['goodsWithSets'];
    }

    queryParameters['goods_with_sets'] = this.transformParameter(
      queryParameters['goods_with_sets'],
    );

    queryParameters['with_parent_categories'] = '0';

    if (typeof parameters['withParentCategories'] !== 'undefined') {
      queryParameters['with_parent_categories'] =
        parameters['withParentCategories'];
    }

    queryParameters['with_parent_categories'] = this.transformParameter(
      queryParameters['with_parent_categories'],
    );

    queryParameters['with_custom_order_nr'] = '0';

    if (typeof parameters['withCustomOrderNr'] !== 'undefined') {
      queryParameters['with_custom_order_nr'] = parameters['withCustomOrderNr'];
    }

    queryParameters['with_custom_order_nr'] = this.transformParameter(
      queryParameters['with_custom_order_nr'],
    );

    queryParameters['with_product_packages'] = '0';

    if (typeof parameters['withProductPackages'] !== 'undefined') {
      queryParameters['with_product_packages'] =
        parameters['withProductPackages'];
    }

    queryParameters['with_product_packages'] = this.transformParameter(
      queryParameters['with_product_packages'],
    );

    if (typeof parameters['count'] !== 'undefined') {
      queryParameters['count'] = parameters['count'];
    }

    queryParameters['count'] = this.transformParameter(
      queryParameters['count'],
    );

    if (typeof parameters['withFreeDelivery'] !== 'undefined') {
      queryParameters['with_free_delivery'] = parameters['withFreeDelivery'];
    }

    queryParameters['with_free_delivery'] = this.transformParameter(
      queryParameters['with_free_delivery'],
    );

    if (typeof parameters['withContents'] !== 'undefined') {
      queryParameters['with_contents'] = parameters['withContents'];
    }

    queryParameters['with_contents'] = this.transformParameter(
      queryParameters['with_contents'],
    );

    if (typeof parameters['withAllLangs'] !== 'undefined') {
      queryParameters['with_all_langs'] = parameters['withAllLangs'];
    }

    queryParameters['with_all_langs'] = this.transformParameter(
      queryParameters['with_all_langs'],
    );

    if (typeof parameters['withProductVariants'] !== 'undefined') {
      queryParameters['with_product_variants'] =
        parameters['withProductVariants'];
    }

    queryParameters['with_product_variants'] = this.transformParameter(
      queryParameters['with_product_variants'],
    );

    if (typeof parameters['topCategoryId'] !== 'undefined') {
      queryParameters['top_category_id'] = parameters['topCategoryId'];
    }

    queryParameters['top_category_id'] = this.transformParameter(
      queryParameters['top_category_id'],
    );

    if (typeof parameters['domainId'] !== 'undefined') {
      queryParameters['domain_id'] = parameters['domainId'];
    }

    queryParameters['domain_id'] = this.transformParameter(
      queryParameters['domain_id'],
    );

    if (typeof parameters['cartId'] !== 'undefined') {
      queryParameters['cart_id'] = parameters['cartId'];
    }

    queryParameters['cart_id'] = this.transformParameter(
      queryParameters['cart_id'],
    );

    if (typeof parameters['withLangVersions'] !== 'undefined') {
      queryParameters['with_lang_versions'] = parameters['withLangVersions'];
    }

    queryParameters['with_lang_versions'] = this.transformParameter(
      queryParameters['with_lang_versions'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * returns product good price tag
   * @method
   * @name API#exportPriceTagPdf
   */
  exportPriceTagPdf(
    productId: number,
    goodId: number,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/price-tag/{product_id}/{good_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    path = path.replace('{good_id}', goodId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * returns object of all connected products
   * @method
   * @name API#loadProductConnections
   */
  loadProductConnections(
    productId: number,
    parameters: {
      connectionType?: string;
      limit?: number;
      offset?: number;
      sort?: string;
      sortDir?: string;
      withCustomOrderNr?: '0' | '1';
      goodsWithStores?: '0' | '1';
      categoryId?: string;
      domainId?: string;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
      xCurrency?: string;
    },
  ): Promise<{
    total: number;

    price_min: number;

    price_max: number;

    offset: number;

    limit: number;

    products: Array<{
      brand: {};

      created_at: string;

      description: string;

      ean: string;

      goods: Array<{
        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            avail_name: string;

            lang: string;
          }>;
        };

        availability_id: number;

        currency: {
          id: string;

          name: string;
        };

        currency_id: string;

        discount_pcn?: number;

        ean: string;

        final_price: number;

        good_id: number;

        group_price?: {
          oldprice: number;

          sale_percentage: number;

          unitprice: number;
        };

        imagePath: string;

        internal_code: string;

        is_on_stock: '0' | '1';

        oldprice: number;

        on_stock_count: number;

        order_number: string;

        point_good: '0' | '1';

        points_amount: number;

        price_without_vat: number;

        price_without_vat_sale: number;

        product_id: number;

        sale: boolean;

        sale_percentage: number;

        unitprice: number;

        unitprice_sale: number;

        units: string;

        valid_from?: string;

        valid_to?: string;

        vat_rate: number;
      }>;

      is_new: '0' | '1';

      is_sale: '0' | '1';

      is_top: '0' | '1';

      issaleout: '0' | '1';

      main_good: {
        availability: {
          avail_id?: number;

          avail_uniqid?: string;

          order_available?: number;

          translations?: Array<{
            avail_id: number;

            avail_name: string;

            lang: string;
          }>;
        };

        availability_id: number;

        currency: {
          id: string;

          name: string;
        };

        currency_id: string;

        discount_pcn?: number;

        ean: string;

        final_price: number;

        good_id: number;

        group_price?: {
          oldprice: number;

          sale_percentage: number;

          unitprice: number;
        };

        imagePath: string;

        internal_code: string;

        is_on_stock: '0' | '1';

        oldprice: number;

        on_stock_count: number;

        order_number: string;

        point_good: '0' | '1';

        points_amount: number;

        price_without_vat: number;

        price_without_vat_sale: number;

        product_id: number;

        sale: boolean;

        sale_percentage: number;

        unitprice: number;

        unitprice_sale: number;

        units: string;

        valid_from?: string;

        valid_to?: string;

        vat_rate: number;
      };

      name: string;

      name_url_encoded: string;

      picture: string;

      plu: string;

      product_id: number;

      rating: string;

      rating_users: number;

      richness?: string;

      slogan: string;

      url: string;
    }>;
  }> {
    let path = '/products/{product_id}/connections';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (extraHeaders && typeof extraHeaders['xCurrency'] !== 'undefined') {
      headers.append('X-Currency', extraHeaders['xCurrency']!);
    }

    path = path.replace('{product_id}', productId.toString());

    if (typeof parameters['connectionType'] !== 'undefined') {
      queryParameters['connection_type'] = parameters['connectionType'];
    }

    queryParameters['connection_type'] = this.transformParameter(
      queryParameters['connection_type'],
    );

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    queryParameters['with_custom_order_nr'] = '0';

    if (typeof parameters['withCustomOrderNr'] !== 'undefined') {
      queryParameters['with_custom_order_nr'] = parameters['withCustomOrderNr'];
    }

    queryParameters['with_custom_order_nr'] = this.transformParameter(
      queryParameters['with_custom_order_nr'],
    );

    if (typeof parameters['goodsWithStores'] !== 'undefined') {
      queryParameters['goods_with_stores'] = parameters['goodsWithStores'];
    }

    queryParameters['goods_with_stores'] = this.transformParameter(
      queryParameters['goods_with_stores'],
    );

    if (typeof parameters['categoryId'] !== 'undefined') {
      queryParameters['category_id'] = parameters['categoryId'];
    }

    queryParameters['category_id'] = this.transformParameter(
      queryParameters['category_id'],
    );

    if (typeof parameters['domainId'] !== 'undefined') {
      queryParameters['domain_id'] = parameters['domainId'];
    }

    queryParameters['domain_id'] = this.transformParameter(
      queryParameters['domain_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns average rating and all user ratings
   * @method
   * @name API#loadProductRatings
   */
  loadProductRatings(
    productId: number,
    parameters: {
      dateStart?: string;
      dateEnd?: string;
      limit?: number;
      offset?: number;
      sort?: string;
      sortDir?: 'asc' | 'desc';
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    average_rating: string;

    rating_users: number;

    ratings: Array<{
      rate?: string;

      user_id?: number;
    }>;
  }> {
    let path = '/products/{product_id}/rating';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{product_id}', productId.toString());

    if (typeof parameters['dateStart'] !== 'undefined') {
      queryParameters['date_start'] = parameters['dateStart'];
    }

    queryParameters['date_start'] = this.transformParameter(
      queryParameters['date_start'],
    );

    if (typeof parameters['dateEnd'] !== 'undefined') {
      queryParameters['date_end'] = parameters['dateEnd'];
    }

    queryParameters['date_end'] = this.transformParameter(
      queryParameters['date_end'],
    );

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['sort'] !== 'undefined') {
      queryParameters['sort'] = parameters['sort'];
    }

    queryParameters['sort'] = this.transformParameter(queryParameters['sort']);

    if (typeof parameters['sortDir'] !== 'undefined') {
      queryParameters['sort_dir'] = parameters['sortDir'];
    }

    queryParameters['sort_dir'] = this.transformParameter(
      queryParameters['sort_dir'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * User must be logged in to submit rating
   * @method
   * @name API#rateProduct
   */
  rateProduct(
    productId: number,
    parameters: {} = {},
    body: {
      rating: number;

      user_id: number;
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<void> {
    let path = '/products/{product_id}/rating';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{product_id}', productId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Loads data about prices of good for conrete customer
   * @method
   * @name API#loadProductGoodCustomersPrices
   */
  loadProductGoodCustomersPrices(
    productId: number,
    goodId: number,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/products/{product_id}/{good_id}/customer-prices';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    path = path.replace('{good_id}', goodId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Deletes all customer prices for concrete good
   * @method
   * @name API#deleteAllProductGoodCustomerPrice
   */
  deleteAllProductGoodCustomerPrice(
    productId: number,
    goodId: number,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/products/{product_id}/{good_id}/customer-prices';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    path = path.replace('{good_id}', goodId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#createsProductGoodCustomerPrices
   */
  createsProductGoodCustomerPrices(
    productId: number,
    goodId: number,
    parameters: {} = {},
    body: {
      user_prices: Array<{
        oldprice: number;

        unitprice: number;

        user_id: number;
      }>;
    },
  ): Promise<void> {
    let path = '/products/{product_id}/{good_id}/customer-prices';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    path = path.replace('{good_id}', goodId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Loads data about prices of good for conrete customer
   * @method
   * @name API#loadProductGoodCustomerPrices
   */
  loadProductGoodCustomerPrices(
    productId: number,
    goodId: number,
    customerId: number,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/products/{product_id}/{good_id}/customer-prices/{customer_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    path = path.replace('{good_id}', goodId.toString());

    path = path.replace('{customer_id}', customerId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Deletes all customer prices for concrete good
   * @method
   * @name API#deleteProductGoodCustomerPrice
   */
  deleteProductGoodCustomerPrice(
    productId: number,
    goodId: number,
    customerId: number,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/products/{product_id}/{good_id}/customer-prices/{customer_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    path = path.replace('{good_id}', goodId.toString());

    path = path.replace('{customer_id}', customerId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * You must be registered user to do this
   * @method
   * @name API#watchAvailability
   */
  watchAvailability(
    productId: number,
    goodId: number,
    parameters: {} = {},
    body: {
      user_id: number;
    },
  ): Promise<void> {
    let path = '/products/{product_id}/{good_id}/watch-availability';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    path = path.replace('{good_id}', goodId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * You must be registered user to do this
   * @method
   * @name API#watchPrice
   */
  watchPrice(
    productId: number,
    goodId: number,
    parameters: {} = {},
    body: {
      user_id: number;
    },
  ): Promise<void> {
    let path = '/products/{product_id}/{good_id}/watch-price';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    path = path.replace('{good_id}', goodId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * You can filter list by currency, category and language
   * @method
   * @name API#updateStock
   */
  updateStock(
    productId: number,
    goodId: number,
    parameters: {} = {},
    body: {
      stock_id?: string;

      quantity: number;
    },
  ): Promise<{}> {
    let path = '/products/{product_id}/{good_id}/stock';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{product_id}', productId.toString());

    path = path.replace('{good_id}', goodId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
    * You can specify category ID to get availabilities on stock only for products in
specific category

    * @method
    * @name API#stateAvailabilitiesStock
    */
  stateAvailabilitiesStock(
    goodId: number,
    productId: number,
    parameters: {
      quantity: number;
    },
  ): Promise<{}> {
    let path = '/products/{product_id}/{good_id}/on-stock';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{good_id}', goodId.toString());

    path = path.replace('{product_id}', productId.toString());

    if (typeof parameters['quantity'] !== 'undefined') {
      queryParameters['quantity'] = parameters['quantity'];
    }

    queryParameters['quantity'] = this.transformParameter(
      queryParameters['quantity'],
    );

    if (typeof parameters['quantity'] === 'undefined') {
      throw new Error('Missing required parameter: quantity');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns rss
   * @method
   * @name API#loadRss
   */
  loadRss(
    parameters: {
      limit?: number;
      offset?: number;
      sitemapId?: string;
      langId?: string;
    } = {},
  ): Promise<
    Array<{
      tag_id: string;

      tag_name?: string;
    }>
  > {
    let path = '/rss';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['sitemapId'] !== 'undefined') {
      queryParameters['sitemap_id'] = parameters['sitemapId'];
    }

    queryParameters['sitemap_id'] = this.transformParameter(
      queryParameters['sitemap_id'],
    );

    if (typeof parameters['langId'] !== 'undefined') {
      queryParameters['lang_id'] = parameters['langId'];
    }

    queryParameters['lang_id'] = this.transformParameter(
      queryParameters['lang_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns array of tags
   * @method
   * @name API#loadTags
   */
  loadTags(
    parameters: {
      limit?: number;
      offset?: number;
      withIsHidden?: number;
      identificator?: string;
      query?: string;
    } = {},
  ): Promise<
    Array<{
      tag_id: string;

      tag_name?: string;
    }>
  > {
    let path = '/tags';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['withIsHidden'] !== 'undefined') {
      queryParameters['with_is_hidden'] = parameters['withIsHidden'];
    }

    queryParameters['with_is_hidden'] = this.transformParameter(
      queryParameters['with_is_hidden'],
    );

    if (typeof parameters['identificator'] !== 'undefined') {
      queryParameters['identificator'] = parameters['identificator'];
    }

    queryParameters['identificator'] = this.transformParameter(
      queryParameters['identificator'],
    );

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create new tag
   * @method
   * @name API#createTag
   */
  createTag(
    parameters: {} = {},
    body: {
      tag_name: string;

      is_top: boolean;
    },
  ): Promise<{
    status?: string;
  }> {
    let path = '/tags';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Returns information about concrete tag by ID
   * @method
   * @name API#loadTagDetail
   */
  loadTagDetail(
    tagId: number,
    parameters: {} = {},
  ): Promise<{
    tag_id: string;

    tag_name?: string;
  }> {
    let path = '/tags/{tag_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{tag_id}', tagId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update tag's details
   * @method
   * @name API#updateTag
   */
  updateTag(
    tagId: number,
    parameters: {} = {},
    body: {
      tag_name?: string;

      is_top?: boolean;
    },
  ): Promise<void> {
    let path = '/tags/{tag_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{tag_id}', tagId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Delete tag
   * @method
   * @name API#deleteTag
   */
  deleteTag(tagId: number, parameters: {} = {}): Promise<void> {
    let path = '/tags/{tag_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{tag_id}', tagId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns array of key value pairs
   * @method
   * @name API#getSettings
   */
  getSettings(
    parameters: {
      domainId?: string;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<
    Array<{
      id: number;

      name: string;

      value: string;

      public: number;

      admin_name: string;
    }>
  > {
    let path = '/settings';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['domainId'] !== 'undefined') {
      queryParameters['domain_id'] = parameters['domainId'];
    }

    queryParameters['domain_id'] = this.transformParameter(
      queryParameters['domain_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns object with key value pair
   * @method
   * @name API#getSettingByName
   */
  getSettingByName(
    settingName: string,
    parameters: {
      domainId?: string;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<
    Array<{
      id: number;

      name: string;

      value: string;

      public: number;

      admin_name: string;
    }>
  > {
    let path = '/settings/{setting_name}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{setting_name}', settingName.toString());

    if (typeof parameters['domainId'] !== 'undefined') {
      queryParameters['domain_id'] = parameters['domainId'];
    }

    queryParameters['domain_id'] = this.transformParameter(
      queryParameters['domain_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Return HTML sitemap for current language
   * @method
   * @name API#getHtmlSitemap
   */
  getHtmlSitemap(lang: string, parameters: {} = {}): Promise<{}> {
    let path = '/sitemap/{lang}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{lang}', lang.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns sitemap with its content
   * @method
   * @name API#loadSitemaps
   */
  loadSitemaps(
    parameters: {
      sitemapUids: string;
      withLangVersions?: '0' | '1';
      withArticles?: '0' | '1';
      articlesLimit?: number;
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    web_sitemap: Array<{
      access?: string;

      content?: {
        author?: {
          author_id?: number;

          image?: string;

          name?: string;

          url?: string;
        };

        created_date?: string;

        created_user_id?: number;

        documents?: Array<{
          content_id?: number;

          created_date?: string;

          created_user?: number;

          document_description?: string;

          document_id?: number;

          document_name?: string;

          document_path?: string;

          document_type?: string;

          privatedoc?: boolean;
        }>;

        downloads?: Array<{
          content_id?: number;

          created_date?: string;

          created_user?: number;

          document_description?: string;

          document_id?: number;

          document_name?: string;

          document_path?: string;

          document_type?: string;

          privatedoc?: boolean;
        }>;

        draft?: boolean;

        hint?: string;

        home?: boolean;

        id?: number;

        is_top?: boolean;

        json_content?: {};

        lang_id?: string;

        lang_version_id?: number;

        last_update?: string;

        name?: string;

        next_article?: {
          annotation?: string;

          id?: string;

          image?: string;

          name?: string;

          url?: string;
        };

        ordering?: number;

        original_id?: number;

        photogallery?: Array<{
          content_id?: number;

          created_date?: string;

          created_user?: number;

          document_description?: string;

          document_id?: number;

          document_name?: string;

          document_path?: string;

          document_type?: string;

          privatedoc?: boolean;
        }>;

        products?: Array<{
          attribs?: Array<{
            attrib_code?: string;

            attrib_grid?: number;

            attrib_id?: number;

            attrib_ismulti?: number;

            attrib_list?: number;

            attrib_name?: string;

            attrib_pbl?: number;

            attrib_sort?: number;

            attrib_type?: string;

            attrib_uid?: string;

            attrib_unit?: string;

            attrib_value?: string;

            custom_value?: string;

            group_id?: number;

            group_name?: string;

            product_id?: number;

            translations?: Array<{
              attrib_id?: number;

              attrib_name?: string;

              attrib_unit?: string;

              lang_id?: string;
            }>;

            type_id?: number;

            value_groups?: Array<{
              attrib_id?: number;

              group_id?: number;

              group_name?: string;

              group_sort?: number;
            }>;

            value_id?: number;

            values?: Array<{
              amount?: number;

              attrib_id?: number;

              attrib_pict?: string;

              attrib_value?: string;

              group_id?: number;

              istop?: boolean;

              searchfilter?: number;

              translations?: Array<{
                attrib_url?: string;

                attrib_value?: string;

                lang_id?: string;

                secondary_name?: string;

                value_id?: number;
              }>;

              value_id?: number;

              value_sort?: number;
            }>;
          }>;

          brand?: {
            brand_descr?: string;

            brand_id?: number;

            brand_image?: string;

            brand_name?: string;

            brand_provider?: string;

            brand_top?: boolean;

            brand_weburl?: string;

            catalog_id?: number;

            translations?: Array<{
              brand_descr?: string;

              brand_id?: number;

              brand_name?: string;

              brand_weburl?: string;

              lang_id?: string;
            }>;

            url?: string;
          };

          brand_id?: number;

          category_text?: string;

          created_at?: string;

          created_datetime?: string;

          description?: string;

          disable_discount?: boolean;

          disable_solo_order?: boolean;

          ean?: string;

          goods?: Array<{
            availability?: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id?: number;

                avail_name?: string;

                lang?: string;
              }>;
            };

            availability_date?: string;

            availability_id?: number;

            buy_price?: number;

            currency?: {
              id?: string;

              name?: string;
            };

            currency_id?: string;

            customer_prices?: {};

            discount_pcn?: number;

            ean?: string;

            final_price?: number;

            from_group_sale?: boolean;

            from_sale?: boolean;

            giftcard?: {
              apply_per_unit?: boolean;

              card_number?: string;

              card_status?: string;

              club_user_id?: number;

              created_date?: string;

              created_user_id?: number;

              date_used?: string;

              date_validity?: string;

              discount?: number;

              discount_currency?: string;

              freeshipping?: boolean;

              freeshippingeu?: boolean;

              id?: number;

              idx?: number;

              is_strict_discount?: boolean;

              lang?: string;

              multicard?: boolean;

              order_id?: number;

              price?: number;

              price_currency?: string;

              price_validity?: number;

              sale?: number;

              sale_count?: number;

              valid_product_ids?: Array<number>;

              valid_products_count?: number;
            };

            good_id?: number;

            group_price?: {
              oldprice?: number;

              sale_percentage?: number;

              unitprice?: number;
            };

            group_prices?: Array<{}>;

            idoklad_id?: string;

            imagePath?: string;

            internal_code?: string;

            is_on_stock?: boolean;

            karat_id?: string;

            lang?: string;

            oldprice?: number;

            on_stock_count?: number;

            order_number?: string;

            order_quantity_limit?: number;

            point_good?: boolean;

            points_amount?: number;

            price_without_vat?: number;

            price_without_vat_sale?: number;

            priceinfo?: string;

            product_id?: number;

            product_name?: string;

            product_note?: string;

            product_type?: number;

            purchaseprice?: number;

            qtyDiscounts?: {};

            sale?: boolean;

            sale_percentage?: number;

            sale_valid?: string;

            unit_price_sale?: number;

            unitprice?: number;

            unitprice_sale?: number;

            units?: string;

            valid_from?: string;

            valid_to?: string;

            vat_rate?: number;

            web_attribs?: Array<{
              attrib_code?: string;

              attrib_grid?: number;

              attrib_id?: number;

              attrib_ismulti?: number;

              attrib_list?: number;

              attrib_name?: string;

              attrib_pbl?: number;

              attrib_sort?: number;

              attrib_type?: string;

              attrib_uid?: string;

              attrib_unit?: string;

              attrib_value?: string;

              custom_value?: string;

              group_id?: number;

              group_name?: string;

              product_id?: number;

              translations?: Array<{
                attrib_id?: number;

                attrib_name?: string;

                attrib_unit?: string;

                lang_id?: string;
              }>;

              type_id?: number;

              value_groups?: Array<{
                attrib_id?: number;

                group_id?: number;

                group_name?: string;

                group_sort?: number;
              }>;

              value_id?: number;

              values?: Array<{
                amount?: number;

                attrib_id?: number;

                attrib_pict?: string;

                attrib_value?: string;

                group_id?: number;

                istop?: boolean;

                searchfilter?: number;

                translations?: Array<{
                  attrib_url?: string;

                  attrib_value?: string;

                  lang_id?: string;

                  secondary_name?: string;

                  value_id?: number;
                }>;

                value_id?: number;

                value_sort?: number;
              }>;
            }>;

            web_order_attribs?: Array<{
              attrib_code?: string;

              attrib_grid?: number;

              attrib_id?: number;

              attrib_ismulti?: number;

              attrib_list?: number;

              attrib_name?: string;

              attrib_pbl?: number;

              attrib_sort?: number;

              attrib_type?: string;

              attrib_uid?: string;

              attrib_unit?: string;

              attrib_value?: string;

              custom_value?: string;

              group_id?: number;

              group_name?: string;

              product_id?: number;

              translations?: Array<{
                attrib_id?: number;

                attrib_name?: string;

                attrib_unit?: string;

                lang_id?: string;
              }>;

              type_id?: number;

              value_groups?: Array<{
                attrib_id?: number;

                group_id?: number;

                group_name?: string;

                group_sort?: number;
              }>;

              value_id?: number;

              values?: Array<{
                amount?: number;

                attrib_id?: number;

                attrib_pict?: string;

                attrib_value?: string;

                group_id?: number;

                istop?: boolean;

                searchfilter?: number;

                translations?: Array<{
                  attrib_url?: string;

                  attrib_value?: string;

                  lang_id?: string;

                  secondary_name?: string;

                  value_id?: number;
                }>;

                value_id?: number;

                value_sort?: number;
              }>;
            }>;

            weight?: number;
          }>;

          heureka?: number;

          heureka_id?: number;

          internal_code?: number;

          is_favorite?: boolean;

          is_new?: boolean;

          is_popular?: boolean;

          is_recommended?: boolean;

          is_sale?: boolean;

          isactive?: number;

          isnew_valid?: string;

          issale_buy3_pay2?: number;

          issaleout?: boolean;

          issaleout_since?: string;

          issaleout_valid?: string;

          istop?: boolean;

          locked?: number;

          main_good?: {
            availability?: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id?: number;

                avail_name?: string;

                lang?: string;
              }>;
            };

            availability_date?: string;

            availability_id?: number;

            buy_price?: number;

            currency?: {
              id?: string;

              name?: string;
            };

            currency_id?: string;

            customer_prices?: {};

            discount_pcn?: number;

            ean?: string;

            final_price?: number;

            from_group_sale?: boolean;

            from_sale?: boolean;

            giftcard?: {
              apply_per_unit?: boolean;

              card_number?: string;

              card_status?: string;

              club_user_id?: number;

              created_date?: string;

              created_user_id?: number;

              date_used?: string;

              date_validity?: string;

              discount?: number;

              discount_currency?: string;

              freeshipping?: boolean;

              freeshippingeu?: boolean;

              id?: number;

              idx?: number;

              is_strict_discount?: boolean;

              lang?: string;

              multicard?: boolean;

              order_id?: number;

              price?: number;

              price_currency?: string;

              price_validity?: number;

              sale?: number;

              sale_count?: number;

              valid_product_ids?: Array<number>;

              valid_products_count?: number;
            };

            good_id?: number;

            group_price?: {
              oldprice?: number;

              sale_percentage?: number;

              unitprice?: number;
            };

            group_prices?: Array<{}>;

            idoklad_id?: string;

            imagePath?: string;

            internal_code?: string;

            is_on_stock?: boolean;

            karat_id?: string;

            lang?: string;

            oldprice?: number;

            on_stock_count?: number;

            order_number?: string;

            order_quantity_limit?: number;

            point_good?: boolean;

            points_amount?: number;

            price_without_vat?: number;

            price_without_vat_sale?: number;

            priceinfo?: string;

            product_id?: number;

            product_name?: string;

            product_note?: string;

            product_type?: number;

            purchaseprice?: number;

            qtyDiscounts?: {};

            sale?: boolean;

            sale_percentage?: number;

            sale_valid?: string;

            unit_price_sale?: number;

            unitprice?: number;

            unitprice_sale?: number;

            units?: string;

            valid_from?: string;

            valid_to?: string;

            vat_rate?: number;

            web_attribs?: Array<{
              attrib_code?: string;

              attrib_grid?: number;

              attrib_id?: number;

              attrib_ismulti?: number;

              attrib_list?: number;

              attrib_name?: string;

              attrib_pbl?: number;

              attrib_sort?: number;

              attrib_type?: string;

              attrib_uid?: string;

              attrib_unit?: string;

              attrib_value?: string;

              custom_value?: string;

              group_id?: number;

              group_name?: string;

              product_id?: number;

              translations?: Array<{
                attrib_id?: number;

                attrib_name?: string;

                attrib_unit?: string;

                lang_id?: string;
              }>;

              type_id?: number;

              value_groups?: Array<{
                attrib_id?: number;

                group_id?: number;

                group_name?: string;

                group_sort?: number;
              }>;

              value_id?: number;

              values?: Array<{
                amount?: number;

                attrib_id?: number;

                attrib_pict?: string;

                attrib_value?: string;

                group_id?: number;

                istop?: boolean;

                searchfilter?: number;

                translations?: Array<{
                  attrib_url?: string;

                  attrib_value?: string;

                  lang_id?: string;

                  secondary_name?: string;

                  value_id?: number;
                }>;

                value_id?: number;

                value_sort?: number;
              }>;
            }>;

            web_order_attribs?: Array<{
              attrib_code?: string;

              attrib_grid?: number;

              attrib_id?: number;

              attrib_ismulti?: number;

              attrib_list?: number;

              attrib_name?: string;

              attrib_pbl?: number;

              attrib_sort?: number;

              attrib_type?: string;

              attrib_uid?: string;

              attrib_unit?: string;

              attrib_value?: string;

              custom_value?: string;

              group_id?: number;

              group_name?: string;

              product_id?: number;

              translations?: Array<{
                attrib_id?: number;

                attrib_name?: string;

                attrib_unit?: string;

                lang_id?: string;
              }>;

              type_id?: number;

              value_groups?: Array<{
                attrib_id?: number;

                group_id?: number;

                group_name?: string;

                group_sort?: number;
              }>;

              value_id?: number;

              values?: Array<{
                amount?: number;

                attrib_id?: number;

                attrib_pict?: string;

                attrib_value?: string;

                group_id?: number;

                istop?: boolean;

                searchfilter?: number;

                translations?: Array<{
                  attrib_url?: string;

                  attrib_value?: string;

                  lang_id?: string;

                  secondary_name?: string;

                  value_id?: number;
                }>;

                value_id?: number;

                value_sort?: number;
              }>;
            }>;

            weight?: number;
          };

          max_price?: number;

          min_delivery_priority?: number;

          min_payment_priority?: number;

          min_price?: number;

          name?: string;

          name_url_encoded?: string;

          notshow?: number;

          ordered_count?: number;

          picture?: string;

          picture_hash?: string;

          picture_media_script?: string;

          plu?: string;

          product_fts?: string;

          product_id?: number;

          product_weight?: number;

          rating?: number;

          rating_users?: number;

          slogan?: string;

          type?: {
            catalog_id?: number;

            is_hidden?: number;

            store_minqty?: string;

            type_descr?: string;

            type_id?: number;

            type_inherits?: number;

            type_name?: string;
          };

          url?: string;

          valid_from?: string;

          valid_to?: string;
        }>;

        publish?: number;

        rss?: boolean;

        sf_form?: string;

        sf_stylesheet?: string;

        sf_xmlcontent?: string;

        sitemap_item_id?: number;

        tags?: Array<{
          id?: number;

          name?: string;
        }>;

        url?: string;

        videos?: Array<{
          content_id?: number;

          created_date?: string;

          created_user?: number;

          ordering?: number;

          video_description?: string;

          video_height?: number;

          video_id?: number;

          video_name?: string;

          video_url?: string;

          video_width?: number;

          webshow?: number;
        }>;
      };

      created_date?: string;

      created_user_id?: number;

      descr_content_id?: number;

      display?: string;

      id?: number;

      image?: {
        content_id?: number;

        created_date?: string;

        created_user?: number;

        document_description?: string;

        document_id?: number;

        document_name?: string;

        document_path?: string;

        document_type?: string;

        privatedoc?: boolean;
      };

      lang?: string;

      lang_version_id?: number;

      last_update?: string;

      layout_id?: string;

      link?: string;

      link_newwin?: number;

      locked?: number;

      name?: string;

      ordering?: number;

      parent_id?: number;

      private?: number;

      slogan?: string;

      title?: string;

      uniqid?: string;

      url?: string;

      webshow?: number;
    }>;
  }> {
    let path = '/sitemaps';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['sitemapUids'] !== 'undefined') {
      queryParameters['sitemap_uids'] = parameters['sitemapUids'];
    }

    queryParameters['sitemap_uids'] = this.transformParameter(
      queryParameters['sitemap_uids'],
    );

    if (typeof parameters['sitemapUids'] === 'undefined') {
      throw new Error('Missing required parameter: sitemapUids');
    }

    if (typeof parameters['withLangVersions'] !== 'undefined') {
      queryParameters['with_lang_versions'] = parameters['withLangVersions'];
    }

    queryParameters['with_lang_versions'] = this.transformParameter(
      queryParameters['with_lang_versions'],
    );

    if (typeof parameters['withArticles'] !== 'undefined') {
      queryParameters['with_articles'] = parameters['withArticles'];
    }

    queryParameters['with_articles'] = this.transformParameter(
      queryParameters['with_articles'],
    );

    queryParameters['articles_limit'] = 10;

    if (typeof parameters['articlesLimit'] !== 'undefined') {
      queryParameters['articles_limit'] = parameters['articlesLimit'];
    }

    queryParameters['articles_limit'] = this.transformParameter(
      queryParameters['articles_limit'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns sitemap tree with its content
   * @method
   * @name API#loadSitemapTree
   */
  loadSitemapTree(
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    sitemap_tree: Array<{
      access?: string;

      content?: {
        author?: {
          author_id?: number;

          image?: string;

          name?: string;

          url?: string;
        };

        created_date?: string;

        created_user_id?: number;

        documents?: Array<{
          content_id?: number;

          created_date?: string;

          created_user?: number;

          document_description?: string;

          document_id?: number;

          document_name?: string;

          document_path?: string;

          document_type?: string;

          privatedoc?: boolean;
        }>;

        downloads?: Array<{
          content_id?: number;

          created_date?: string;

          created_user?: number;

          document_description?: string;

          document_id?: number;

          document_name?: string;

          document_path?: string;

          document_type?: string;

          privatedoc?: boolean;
        }>;

        draft?: boolean;

        hint?: string;

        home?: boolean;

        id?: number;

        is_top?: boolean;

        json_content?: {};

        lang_id?: string;

        lang_version_id?: number;

        last_update?: string;

        name?: string;

        next_article?: {
          annotation?: string;

          id?: string;

          image?: string;

          name?: string;

          url?: string;
        };

        ordering?: number;

        original_id?: number;

        photogallery?: Array<{
          content_id?: number;

          created_date?: string;

          created_user?: number;

          document_description?: string;

          document_id?: number;

          document_name?: string;

          document_path?: string;

          document_type?: string;

          privatedoc?: boolean;
        }>;

        products?: Array<{
          attribs?: Array<{
            attrib_code?: string;

            attrib_grid?: number;

            attrib_id?: number;

            attrib_ismulti?: number;

            attrib_list?: number;

            attrib_name?: string;

            attrib_pbl?: number;

            attrib_sort?: number;

            attrib_type?: string;

            attrib_uid?: string;

            attrib_unit?: string;

            attrib_value?: string;

            custom_value?: string;

            group_id?: number;

            group_name?: string;

            product_id?: number;

            translations?: Array<{
              attrib_id?: number;

              attrib_name?: string;

              attrib_unit?: string;

              lang_id?: string;
            }>;

            type_id?: number;

            value_groups?: Array<{
              attrib_id?: number;

              group_id?: number;

              group_name?: string;

              group_sort?: number;
            }>;

            value_id?: number;

            values?: Array<{
              amount?: number;

              attrib_id?: number;

              attrib_pict?: string;

              attrib_value?: string;

              group_id?: number;

              istop?: boolean;

              searchfilter?: number;

              translations?: Array<{
                attrib_url?: string;

                attrib_value?: string;

                lang_id?: string;

                secondary_name?: string;

                value_id?: number;
              }>;

              value_id?: number;

              value_sort?: number;
            }>;
          }>;

          brand?: {
            brand_descr?: string;

            brand_id?: number;

            brand_image?: string;

            brand_name?: string;

            brand_provider?: string;

            brand_top?: boolean;

            brand_weburl?: string;

            catalog_id?: number;

            translations?: Array<{
              brand_descr?: string;

              brand_id?: number;

              brand_name?: string;

              brand_weburl?: string;

              lang_id?: string;
            }>;

            url?: string;
          };

          brand_id?: number;

          category_text?: string;

          created_at?: string;

          created_datetime?: string;

          description?: string;

          disable_discount?: boolean;

          disable_solo_order?: boolean;

          ean?: string;

          goods?: Array<{
            availability?: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id?: number;

                avail_name?: string;

                lang?: string;
              }>;
            };

            availability_date?: string;

            availability_id?: number;

            buy_price?: number;

            currency?: {
              id?: string;

              name?: string;
            };

            currency_id?: string;

            customer_prices?: {};

            discount_pcn?: number;

            ean?: string;

            final_price?: number;

            from_group_sale?: boolean;

            from_sale?: boolean;

            giftcard?: {
              apply_per_unit?: boolean;

              card_number?: string;

              card_status?: string;

              club_user_id?: number;

              created_date?: string;

              created_user_id?: number;

              date_used?: string;

              date_validity?: string;

              discount?: number;

              discount_currency?: string;

              freeshipping?: boolean;

              freeshippingeu?: boolean;

              id?: number;

              idx?: number;

              is_strict_discount?: boolean;

              lang?: string;

              multicard?: boolean;

              order_id?: number;

              price?: number;

              price_currency?: string;

              price_validity?: number;

              sale?: number;

              sale_count?: number;

              valid_product_ids?: Array<number>;

              valid_products_count?: number;
            };

            good_id?: number;

            group_price?: {
              oldprice?: number;

              sale_percentage?: number;

              unitprice?: number;
            };

            group_prices?: Array<{}>;

            idoklad_id?: string;

            imagePath?: string;

            internal_code?: string;

            is_on_stock?: boolean;

            karat_id?: string;

            lang?: string;

            oldprice?: number;

            on_stock_count?: number;

            order_number?: string;

            order_quantity_limit?: number;

            point_good?: boolean;

            points_amount?: number;

            price_without_vat?: number;

            price_without_vat_sale?: number;

            priceinfo?: string;

            product_id?: number;

            product_name?: string;

            product_note?: string;

            product_type?: number;

            purchaseprice?: number;

            qtyDiscounts?: {};

            sale?: boolean;

            sale_percentage?: number;

            sale_valid?: string;

            unit_price_sale?: number;

            unitprice?: number;

            unitprice_sale?: number;

            units?: string;

            valid_from?: string;

            valid_to?: string;

            vat_rate?: number;

            web_attribs?: Array<{
              attrib_code?: string;

              attrib_grid?: number;

              attrib_id?: number;

              attrib_ismulti?: number;

              attrib_list?: number;

              attrib_name?: string;

              attrib_pbl?: number;

              attrib_sort?: number;

              attrib_type?: string;

              attrib_uid?: string;

              attrib_unit?: string;

              attrib_value?: string;

              custom_value?: string;

              group_id?: number;

              group_name?: string;

              product_id?: number;

              translations?: Array<{
                attrib_id?: number;

                attrib_name?: string;

                attrib_unit?: string;

                lang_id?: string;
              }>;

              type_id?: number;

              value_groups?: Array<{
                attrib_id?: number;

                group_id?: number;

                group_name?: string;

                group_sort?: number;
              }>;

              value_id?: number;

              values?: Array<{
                amount?: number;

                attrib_id?: number;

                attrib_pict?: string;

                attrib_value?: string;

                group_id?: number;

                istop?: boolean;

                searchfilter?: number;

                translations?: Array<{
                  attrib_url?: string;

                  attrib_value?: string;

                  lang_id?: string;

                  secondary_name?: string;

                  value_id?: number;
                }>;

                value_id?: number;

                value_sort?: number;
              }>;
            }>;

            web_order_attribs?: Array<{
              attrib_code?: string;

              attrib_grid?: number;

              attrib_id?: number;

              attrib_ismulti?: number;

              attrib_list?: number;

              attrib_name?: string;

              attrib_pbl?: number;

              attrib_sort?: number;

              attrib_type?: string;

              attrib_uid?: string;

              attrib_unit?: string;

              attrib_value?: string;

              custom_value?: string;

              group_id?: number;

              group_name?: string;

              product_id?: number;

              translations?: Array<{
                attrib_id?: number;

                attrib_name?: string;

                attrib_unit?: string;

                lang_id?: string;
              }>;

              type_id?: number;

              value_groups?: Array<{
                attrib_id?: number;

                group_id?: number;

                group_name?: string;

                group_sort?: number;
              }>;

              value_id?: number;

              values?: Array<{
                amount?: number;

                attrib_id?: number;

                attrib_pict?: string;

                attrib_value?: string;

                group_id?: number;

                istop?: boolean;

                searchfilter?: number;

                translations?: Array<{
                  attrib_url?: string;

                  attrib_value?: string;

                  lang_id?: string;

                  secondary_name?: string;

                  value_id?: number;
                }>;

                value_id?: number;

                value_sort?: number;
              }>;
            }>;

            weight?: number;
          }>;

          heureka?: number;

          heureka_id?: number;

          internal_code?: number;

          is_favorite?: boolean;

          is_new?: boolean;

          is_popular?: boolean;

          is_recommended?: boolean;

          is_sale?: boolean;

          isactive?: number;

          isnew_valid?: string;

          issale_buy3_pay2?: number;

          issaleout?: boolean;

          issaleout_since?: string;

          issaleout_valid?: string;

          istop?: boolean;

          locked?: number;

          main_good?: {
            availability?: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id?: number;

                avail_name?: string;

                lang?: string;
              }>;
            };

            availability_date?: string;

            availability_id?: number;

            buy_price?: number;

            currency?: {
              id?: string;

              name?: string;
            };

            currency_id?: string;

            customer_prices?: {};

            discount_pcn?: number;

            ean?: string;

            final_price?: number;

            from_group_sale?: boolean;

            from_sale?: boolean;

            giftcard?: {
              apply_per_unit?: boolean;

              card_number?: string;

              card_status?: string;

              club_user_id?: number;

              created_date?: string;

              created_user_id?: number;

              date_used?: string;

              date_validity?: string;

              discount?: number;

              discount_currency?: string;

              freeshipping?: boolean;

              freeshippingeu?: boolean;

              id?: number;

              idx?: number;

              is_strict_discount?: boolean;

              lang?: string;

              multicard?: boolean;

              order_id?: number;

              price?: number;

              price_currency?: string;

              price_validity?: number;

              sale?: number;

              sale_count?: number;

              valid_product_ids?: Array<number>;

              valid_products_count?: number;
            };

            good_id?: number;

            group_price?: {
              oldprice?: number;

              sale_percentage?: number;

              unitprice?: number;
            };

            group_prices?: Array<{}>;

            idoklad_id?: string;

            imagePath?: string;

            internal_code?: string;

            is_on_stock?: boolean;

            karat_id?: string;

            lang?: string;

            oldprice?: number;

            on_stock_count?: number;

            order_number?: string;

            order_quantity_limit?: number;

            point_good?: boolean;

            points_amount?: number;

            price_without_vat?: number;

            price_without_vat_sale?: number;

            priceinfo?: string;

            product_id?: number;

            product_name?: string;

            product_note?: string;

            product_type?: number;

            purchaseprice?: number;

            qtyDiscounts?: {};

            sale?: boolean;

            sale_percentage?: number;

            sale_valid?: string;

            unit_price_sale?: number;

            unitprice?: number;

            unitprice_sale?: number;

            units?: string;

            valid_from?: string;

            valid_to?: string;

            vat_rate?: number;

            web_attribs?: Array<{
              attrib_code?: string;

              attrib_grid?: number;

              attrib_id?: number;

              attrib_ismulti?: number;

              attrib_list?: number;

              attrib_name?: string;

              attrib_pbl?: number;

              attrib_sort?: number;

              attrib_type?: string;

              attrib_uid?: string;

              attrib_unit?: string;

              attrib_value?: string;

              custom_value?: string;

              group_id?: number;

              group_name?: string;

              product_id?: number;

              translations?: Array<{
                attrib_id?: number;

                attrib_name?: string;

                attrib_unit?: string;

                lang_id?: string;
              }>;

              type_id?: number;

              value_groups?: Array<{
                attrib_id?: number;

                group_id?: number;

                group_name?: string;

                group_sort?: number;
              }>;

              value_id?: number;

              values?: Array<{
                amount?: number;

                attrib_id?: number;

                attrib_pict?: string;

                attrib_value?: string;

                group_id?: number;

                istop?: boolean;

                searchfilter?: number;

                translations?: Array<{
                  attrib_url?: string;

                  attrib_value?: string;

                  lang_id?: string;

                  secondary_name?: string;

                  value_id?: number;
                }>;

                value_id?: number;

                value_sort?: number;
              }>;
            }>;

            web_order_attribs?: Array<{
              attrib_code?: string;

              attrib_grid?: number;

              attrib_id?: number;

              attrib_ismulti?: number;

              attrib_list?: number;

              attrib_name?: string;

              attrib_pbl?: number;

              attrib_sort?: number;

              attrib_type?: string;

              attrib_uid?: string;

              attrib_unit?: string;

              attrib_value?: string;

              custom_value?: string;

              group_id?: number;

              group_name?: string;

              product_id?: number;

              translations?: Array<{
                attrib_id?: number;

                attrib_name?: string;

                attrib_unit?: string;

                lang_id?: string;
              }>;

              type_id?: number;

              value_groups?: Array<{
                attrib_id?: number;

                group_id?: number;

                group_name?: string;

                group_sort?: number;
              }>;

              value_id?: number;

              values?: Array<{
                amount?: number;

                attrib_id?: number;

                attrib_pict?: string;

                attrib_value?: string;

                group_id?: number;

                istop?: boolean;

                searchfilter?: number;

                translations?: Array<{
                  attrib_url?: string;

                  attrib_value?: string;

                  lang_id?: string;

                  secondary_name?: string;

                  value_id?: number;
                }>;

                value_id?: number;

                value_sort?: number;
              }>;
            }>;

            weight?: number;
          };

          max_price?: number;

          min_delivery_priority?: number;

          min_payment_priority?: number;

          min_price?: number;

          name?: string;

          name_url_encoded?: string;

          notshow?: number;

          ordered_count?: number;

          picture?: string;

          picture_hash?: string;

          picture_media_script?: string;

          plu?: string;

          product_fts?: string;

          product_id?: number;

          product_weight?: number;

          rating?: number;

          rating_users?: number;

          slogan?: string;

          type?: {
            catalog_id?: number;

            is_hidden?: number;

            store_minqty?: string;

            type_descr?: string;

            type_id?: number;

            type_inherits?: number;

            type_name?: string;
          };

          url?: string;

          valid_from?: string;

          valid_to?: string;
        }>;

        publish?: number;

        rss?: boolean;

        sf_form?: string;

        sf_stylesheet?: string;

        sf_xmlcontent?: string;

        sitemap_item_id?: number;

        tags?: Array<{
          id?: number;

          name?: string;
        }>;

        url?: string;

        videos?: Array<{
          content_id?: number;

          created_date?: string;

          created_user?: number;

          ordering?: number;

          video_description?: string;

          video_height?: number;

          video_id?: number;

          video_name?: string;

          video_url?: string;

          video_width?: number;

          webshow?: number;
        }>;
      };

      created_date?: string;

      created_user_id?: number;

      descr_content_id?: number;

      display?: string;

      id?: number;

      image?: {
        content_id?: number;

        created_date?: string;

        created_user?: number;

        document_description?: string;

        document_id?: number;

        document_name?: string;

        document_path?: string;

        document_type?: string;

        privatedoc?: boolean;
      };

      lang?: string;

      lang_version_id?: number;

      last_update?: string;

      layout_id?: string;

      link?: string;

      link_newwin?: number;

      locked?: number;

      name?: string;

      ordering?: number;

      parent_id?: number;

      private?: number;

      slogan?: string;

      title?: string;

      uniqid?: string;

      url?: string;

      webshow?: number;
    }>;
  }> {
    let path = '/sitemaps/tree';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns sitemap with its content
   * @method
   * @name API#loadSitemap
   */
  loadSitemap(
    sitemapUid: string,
    parameters: {
      withLangVersions?: '0' | '1';
      withSubtree?: '0' | '1';
      withArticles?: '0' | '1';
      articlesLimit?: number;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    id?: number;

    parent_id?: number | null;

    created_user_id?: number | null;

    created_date?: string;

    lang?: string;

    display?: string | null;

    name?: string;

    link?: string;

    ordering?: number;

    descr_content_id?: number | null;

    link_newwin?: number;

    access?: string;

    url?: string;

    webshow?: number;

    locked?: number;

    title?: string | null;

    uniqid?: string | null;

    last_update?: string;

    slogan?: string | null;

    layout_id?: string | null;

    private?: number;

    content?: object | null;

    lang_versions?: Array<{}>;
  }> {
    let path = '/sitemaps/{sitemap_uid}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{sitemap_uid}', sitemapUid.toString());

    if (typeof parameters['withLangVersions'] !== 'undefined') {
      queryParameters['with_lang_versions'] = parameters['withLangVersions'];
    }

    queryParameters['with_lang_versions'] = this.transformParameter(
      queryParameters['with_lang_versions'],
    );

    if (typeof parameters['withSubtree'] !== 'undefined') {
      queryParameters['with_subtree'] = parameters['withSubtree'];
    }

    queryParameters['with_subtree'] = this.transformParameter(
      queryParameters['with_subtree'],
    );

    if (typeof parameters['withArticles'] !== 'undefined') {
      queryParameters['with_articles'] = parameters['withArticles'];
    }

    queryParameters['with_articles'] = this.transformParameter(
      queryParameters['with_articles'],
    );

    queryParameters['articles_limit'] = 10;

    if (typeof parameters['articlesLimit'] !== 'undefined') {
      queryParameters['articles_limit'] = parameters['articlesLimit'];
    }

    queryParameters['articles_limit'] = this.transformParameter(
      queryParameters['articles_limit'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns sitemap tree with its content
   * @method
   * @name API#loadSitemapSubtree
   */
  loadSitemapSubtree(
    sitemapUid: string,
    parameters: {
      withoutContent?: '0' | '1';
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    sitemap_tree: Array<{
      access?: string;

      content?: {
        author?: {
          author_id?: number;

          image?: string;

          name?: string;

          url?: string;
        };

        created_date?: string;

        created_user_id?: number;

        documents?: Array<{
          content_id?: number;

          created_date?: string;

          created_user?: number;

          document_description?: string;

          document_id?: number;

          document_name?: string;

          document_path?: string;

          document_type?: string;

          privatedoc?: boolean;
        }>;

        downloads?: Array<{
          content_id?: number;

          created_date?: string;

          created_user?: number;

          document_description?: string;

          document_id?: number;

          document_name?: string;

          document_path?: string;

          document_type?: string;

          privatedoc?: boolean;
        }>;

        draft?: boolean;

        hint?: string;

        home?: boolean;

        id?: number;

        is_top?: boolean;

        json_content?: {};

        lang_id?: string;

        lang_version_id?: number;

        last_update?: string;

        name?: string;

        next_article?: {
          annotation?: string;

          id?: string;

          image?: string;

          name?: string;

          url?: string;
        };

        ordering?: number;

        original_id?: number;

        photogallery?: Array<{
          content_id?: number;

          created_date?: string;

          created_user?: number;

          document_description?: string;

          document_id?: number;

          document_name?: string;

          document_path?: string;

          document_type?: string;

          privatedoc?: boolean;
        }>;

        products?: Array<{
          attribs?: Array<{
            attrib_code?: string;

            attrib_grid?: number;

            attrib_id?: number;

            attrib_ismulti?: number;

            attrib_list?: number;

            attrib_name?: string;

            attrib_pbl?: number;

            attrib_sort?: number;

            attrib_type?: string;

            attrib_uid?: string;

            attrib_unit?: string;

            attrib_value?: string;

            custom_value?: string;

            group_id?: number;

            group_name?: string;

            product_id?: number;

            translations?: Array<{
              attrib_id?: number;

              attrib_name?: string;

              attrib_unit?: string;

              lang_id?: string;
            }>;

            type_id?: number;

            value_groups?: Array<{
              attrib_id?: number;

              group_id?: number;

              group_name?: string;

              group_sort?: number;
            }>;

            value_id?: number;

            values?: Array<{
              amount?: number;

              attrib_id?: number;

              attrib_pict?: string;

              attrib_value?: string;

              group_id?: number;

              istop?: boolean;

              searchfilter?: number;

              translations?: Array<{
                attrib_url?: string;

                attrib_value?: string;

                lang_id?: string;

                secondary_name?: string;

                value_id?: number;
              }>;

              value_id?: number;

              value_sort?: number;
            }>;
          }>;

          brand?: {
            brand_descr?: string;

            brand_id?: number;

            brand_image?: string;

            brand_name?: string;

            brand_provider?: string;

            brand_top?: boolean;

            brand_weburl?: string;

            catalog_id?: number;

            translations?: Array<{
              brand_descr?: string;

              brand_id?: number;

              brand_name?: string;

              brand_weburl?: string;

              lang_id?: string;
            }>;

            url?: string;
          };

          brand_id?: number;

          category_text?: string;

          created_at?: string;

          created_datetime?: string;

          description?: string;

          disable_discount?: boolean;

          disable_solo_order?: boolean;

          ean?: string;

          goods?: Array<{
            availability?: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id?: number;

                avail_name?: string;

                lang?: string;
              }>;
            };

            availability_date?: string;

            availability_id?: number;

            buy_price?: number;

            currency?: {
              id?: string;

              name?: string;
            };

            currency_id?: string;

            customer_prices?: {};

            discount_pcn?: number;

            ean?: string;

            final_price?: number;

            from_group_sale?: boolean;

            from_sale?: boolean;

            giftcard?: {
              apply_per_unit?: boolean;

              card_number?: string;

              card_status?: string;

              club_user_id?: number;

              created_date?: string;

              created_user_id?: number;

              date_used?: string;

              date_validity?: string;

              discount?: number;

              discount_currency?: string;

              freeshipping?: boolean;

              freeshippingeu?: boolean;

              id?: number;

              idx?: number;

              is_strict_discount?: boolean;

              lang?: string;

              multicard?: boolean;

              order_id?: number;

              price?: number;

              price_currency?: string;

              price_validity?: number;

              sale?: number;

              sale_count?: number;

              valid_product_ids?: Array<number>;

              valid_products_count?: number;
            };

            good_id?: number;

            group_price?: {
              oldprice?: number;

              sale_percentage?: number;

              unitprice?: number;
            };

            group_prices?: Array<{}>;

            idoklad_id?: string;

            imagePath?: string;

            internal_code?: string;

            is_on_stock?: boolean;

            karat_id?: string;

            lang?: string;

            oldprice?: number;

            on_stock_count?: number;

            order_number?: string;

            order_quantity_limit?: number;

            point_good?: boolean;

            points_amount?: number;

            price_without_vat?: number;

            price_without_vat_sale?: number;

            priceinfo?: string;

            product_id?: number;

            product_name?: string;

            product_note?: string;

            product_type?: number;

            purchaseprice?: number;

            qtyDiscounts?: {};

            sale?: boolean;

            sale_percentage?: number;

            sale_valid?: string;

            unit_price_sale?: number;

            unitprice?: number;

            unitprice_sale?: number;

            units?: string;

            valid_from?: string;

            valid_to?: string;

            vat_rate?: number;

            web_attribs?: Array<{
              attrib_code?: string;

              attrib_grid?: number;

              attrib_id?: number;

              attrib_ismulti?: number;

              attrib_list?: number;

              attrib_name?: string;

              attrib_pbl?: number;

              attrib_sort?: number;

              attrib_type?: string;

              attrib_uid?: string;

              attrib_unit?: string;

              attrib_value?: string;

              custom_value?: string;

              group_id?: number;

              group_name?: string;

              product_id?: number;

              translations?: Array<{
                attrib_id?: number;

                attrib_name?: string;

                attrib_unit?: string;

                lang_id?: string;
              }>;

              type_id?: number;

              value_groups?: Array<{
                attrib_id?: number;

                group_id?: number;

                group_name?: string;

                group_sort?: number;
              }>;

              value_id?: number;

              values?: Array<{
                amount?: number;

                attrib_id?: number;

                attrib_pict?: string;

                attrib_value?: string;

                group_id?: number;

                istop?: boolean;

                searchfilter?: number;

                translations?: Array<{
                  attrib_url?: string;

                  attrib_value?: string;

                  lang_id?: string;

                  secondary_name?: string;

                  value_id?: number;
                }>;

                value_id?: number;

                value_sort?: number;
              }>;
            }>;

            web_order_attribs?: Array<{
              attrib_code?: string;

              attrib_grid?: number;

              attrib_id?: number;

              attrib_ismulti?: number;

              attrib_list?: number;

              attrib_name?: string;

              attrib_pbl?: number;

              attrib_sort?: number;

              attrib_type?: string;

              attrib_uid?: string;

              attrib_unit?: string;

              attrib_value?: string;

              custom_value?: string;

              group_id?: number;

              group_name?: string;

              product_id?: number;

              translations?: Array<{
                attrib_id?: number;

                attrib_name?: string;

                attrib_unit?: string;

                lang_id?: string;
              }>;

              type_id?: number;

              value_groups?: Array<{
                attrib_id?: number;

                group_id?: number;

                group_name?: string;

                group_sort?: number;
              }>;

              value_id?: number;

              values?: Array<{
                amount?: number;

                attrib_id?: number;

                attrib_pict?: string;

                attrib_value?: string;

                group_id?: number;

                istop?: boolean;

                searchfilter?: number;

                translations?: Array<{
                  attrib_url?: string;

                  attrib_value?: string;

                  lang_id?: string;

                  secondary_name?: string;

                  value_id?: number;
                }>;

                value_id?: number;

                value_sort?: number;
              }>;
            }>;

            weight?: number;
          }>;

          heureka?: number;

          heureka_id?: number;

          internal_code?: number;

          is_favorite?: boolean;

          is_new?: boolean;

          is_popular?: boolean;

          is_recommended?: boolean;

          is_sale?: boolean;

          isactive?: number;

          isnew_valid?: string;

          issale_buy3_pay2?: number;

          issaleout?: boolean;

          issaleout_since?: string;

          issaleout_valid?: string;

          istop?: boolean;

          locked?: number;

          main_good?: {
            availability?: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id?: number;

                avail_name?: string;

                lang?: string;
              }>;
            };

            availability_date?: string;

            availability_id?: number;

            buy_price?: number;

            currency?: {
              id?: string;

              name?: string;
            };

            currency_id?: string;

            customer_prices?: {};

            discount_pcn?: number;

            ean?: string;

            final_price?: number;

            from_group_sale?: boolean;

            from_sale?: boolean;

            giftcard?: {
              apply_per_unit?: boolean;

              card_number?: string;

              card_status?: string;

              club_user_id?: number;

              created_date?: string;

              created_user_id?: number;

              date_used?: string;

              date_validity?: string;

              discount?: number;

              discount_currency?: string;

              freeshipping?: boolean;

              freeshippingeu?: boolean;

              id?: number;

              idx?: number;

              is_strict_discount?: boolean;

              lang?: string;

              multicard?: boolean;

              order_id?: number;

              price?: number;

              price_currency?: string;

              price_validity?: number;

              sale?: number;

              sale_count?: number;

              valid_product_ids?: Array<number>;

              valid_products_count?: number;
            };

            good_id?: number;

            group_price?: {
              oldprice?: number;

              sale_percentage?: number;

              unitprice?: number;
            };

            group_prices?: Array<{}>;

            idoklad_id?: string;

            imagePath?: string;

            internal_code?: string;

            is_on_stock?: boolean;

            karat_id?: string;

            lang?: string;

            oldprice?: number;

            on_stock_count?: number;

            order_number?: string;

            order_quantity_limit?: number;

            point_good?: boolean;

            points_amount?: number;

            price_without_vat?: number;

            price_without_vat_sale?: number;

            priceinfo?: string;

            product_id?: number;

            product_name?: string;

            product_note?: string;

            product_type?: number;

            purchaseprice?: number;

            qtyDiscounts?: {};

            sale?: boolean;

            sale_percentage?: number;

            sale_valid?: string;

            unit_price_sale?: number;

            unitprice?: number;

            unitprice_sale?: number;

            units?: string;

            valid_from?: string;

            valid_to?: string;

            vat_rate?: number;

            web_attribs?: Array<{
              attrib_code?: string;

              attrib_grid?: number;

              attrib_id?: number;

              attrib_ismulti?: number;

              attrib_list?: number;

              attrib_name?: string;

              attrib_pbl?: number;

              attrib_sort?: number;

              attrib_type?: string;

              attrib_uid?: string;

              attrib_unit?: string;

              attrib_value?: string;

              custom_value?: string;

              group_id?: number;

              group_name?: string;

              product_id?: number;

              translations?: Array<{
                attrib_id?: number;

                attrib_name?: string;

                attrib_unit?: string;

                lang_id?: string;
              }>;

              type_id?: number;

              value_groups?: Array<{
                attrib_id?: number;

                group_id?: number;

                group_name?: string;

                group_sort?: number;
              }>;

              value_id?: number;

              values?: Array<{
                amount?: number;

                attrib_id?: number;

                attrib_pict?: string;

                attrib_value?: string;

                group_id?: number;

                istop?: boolean;

                searchfilter?: number;

                translations?: Array<{
                  attrib_url?: string;

                  attrib_value?: string;

                  lang_id?: string;

                  secondary_name?: string;

                  value_id?: number;
                }>;

                value_id?: number;

                value_sort?: number;
              }>;
            }>;

            web_order_attribs?: Array<{
              attrib_code?: string;

              attrib_grid?: number;

              attrib_id?: number;

              attrib_ismulti?: number;

              attrib_list?: number;

              attrib_name?: string;

              attrib_pbl?: number;

              attrib_sort?: number;

              attrib_type?: string;

              attrib_uid?: string;

              attrib_unit?: string;

              attrib_value?: string;

              custom_value?: string;

              group_id?: number;

              group_name?: string;

              product_id?: number;

              translations?: Array<{
                attrib_id?: number;

                attrib_name?: string;

                attrib_unit?: string;

                lang_id?: string;
              }>;

              type_id?: number;

              value_groups?: Array<{
                attrib_id?: number;

                group_id?: number;

                group_name?: string;

                group_sort?: number;
              }>;

              value_id?: number;

              values?: Array<{
                amount?: number;

                attrib_id?: number;

                attrib_pict?: string;

                attrib_value?: string;

                group_id?: number;

                istop?: boolean;

                searchfilter?: number;

                translations?: Array<{
                  attrib_url?: string;

                  attrib_value?: string;

                  lang_id?: string;

                  secondary_name?: string;

                  value_id?: number;
                }>;

                value_id?: number;

                value_sort?: number;
              }>;
            }>;

            weight?: number;
          };

          max_price?: number;

          min_delivery_priority?: number;

          min_payment_priority?: number;

          min_price?: number;

          name?: string;

          name_url_encoded?: string;

          notshow?: number;

          ordered_count?: number;

          picture?: string;

          picture_hash?: string;

          picture_media_script?: string;

          plu?: string;

          product_fts?: string;

          product_id?: number;

          product_weight?: number;

          rating?: number;

          rating_users?: number;

          slogan?: string;

          type?: {
            catalog_id?: number;

            is_hidden?: number;

            store_minqty?: string;

            type_descr?: string;

            type_id?: number;

            type_inherits?: number;

            type_name?: string;
          };

          url?: string;

          valid_from?: string;

          valid_to?: string;
        }>;

        publish?: number;

        rss?: boolean;

        sf_form?: string;

        sf_stylesheet?: string;

        sf_xmlcontent?: string;

        sitemap_item_id?: number;

        tags?: Array<{
          id?: number;

          name?: string;
        }>;

        url?: string;

        videos?: Array<{
          content_id?: number;

          created_date?: string;

          created_user?: number;

          ordering?: number;

          video_description?: string;

          video_height?: number;

          video_id?: number;

          video_name?: string;

          video_url?: string;

          video_width?: number;

          webshow?: number;
        }>;
      };

      created_date?: string;

      created_user_id?: number;

      descr_content_id?: number;

      display?: string;

      id?: number;

      image?: {
        content_id?: number;

        created_date?: string;

        created_user?: number;

        document_description?: string;

        document_id?: number;

        document_name?: string;

        document_path?: string;

        document_type?: string;

        privatedoc?: boolean;
      };

      lang?: string;

      lang_version_id?: number;

      last_update?: string;

      layout_id?: string;

      link?: string;

      link_newwin?: number;

      locked?: number;

      name?: string;

      ordering?: number;

      parent_id?: number;

      private?: number;

      slogan?: string;

      title?: string;

      uniqid?: string;

      url?: string;

      webshow?: number;
    }>;
  }> {
    let path = '/sitemaps/{sitemap_uid}/tree';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{sitemap_uid}', sitemapUid.toString());

    if (typeof parameters['withoutContent'] !== 'undefined') {
      queryParameters['without_content'] = parameters['withoutContent'];
    }

    queryParameters['without_content'] = this.transformParameter(
      queryParameters['without_content'],
    );

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Creates new language version of sitemap available for translation

**DESCRIPTION**

Copy sitemap between two language versions. Source langugage version is determined by ID of sitemap.
Language version of sitemap is full copy of source sitemap. Connections between language version is maintained by lang_version_id.
**!!!WARNING!!! If dest. version already exists it will be first deleted than created again.**

    * @method
    * @name API#copySitemap
    */
  copySitemap(
    sitemapUid: number,
    langId: string,
    parameters: {} = {},
    body: {
      parent_id: number;

      name: string;

      url: string;
    },
  ): Promise<{}> {
    let path = '/sitemaps/{sitemap_uid}/copy/{lang_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{sitemap_uid}', sitemapUid.toString());

    path = path.replace('{lang_id}', langId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#loadCategoryStatistics
   */
  loadCategoryStatistics(type: 'sales', parameters: {} = {}): Promise<void> {
    let path = '/statistics/categories/{type}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{type}', type.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Statistics about customers from specified period
   * @method
   * @name API#loadCustomerStatistics
   */
  loadCustomerStatistics(
    type: 'monthCount' | 'yearCount' | 'monthlyCount' | 'ratioOfCustomersType',
    parameters: {
      from?: string;
      to?: string;
    } = {},
  ): Promise<{}> {
    let path = '/statistics/customers/{type}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{type}', type.toString());

    if (typeof parameters['from'] !== 'undefined') {
      queryParameters['from'] = parameters['from'];
    }

    queryParameters['from'] = this.transformParameter(queryParameters['from']);

    if (typeof parameters['to'] !== 'undefined') {
      queryParameters['to'] = parameters['to'];
    }

    queryParameters['to'] = this.transformParameter(queryParameters['to']);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#loadOrdersStatistics
   */
  loadOrdersStatistics(
    type: 'monthCount' | 'yearCount' | 'monthlyCount',
    parameters: {} = {},
  ): Promise<void> {
    let path = '/statistics/orders/{type}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{type}', type.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Best selling brands
   * @method
   * @name API#loadTopBrands
   */
  loadTopBrands(
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    top_brands: Array<{
      brand_id: number;

      brand_name: string;

      total: number;
    }>;
  }> {
    let path = '/statistics/top/brands';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Best selling categories
   * @method
   * @name API#loadTopCategories
   */
  loadTopCategories(
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    top_categories: Array<{
      category_id: number;

      category_name: string;

      total: number;
    }>;
  }> {
    let path = '/statistics/top/categories';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Best selling products
   * @method
   * @name API#loadTopProducts
   */
  loadTopProducts(
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    top_products: Array<{
      product_id: number;

      product_name: string;

      number_of_orders: number;

      product_picture: string;
    }>;
  }> {
    let path = '/statistics/top/products';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns information about all shops
   * @method
   * @name API#loadShops
   */
  loadShops(parameters: { langId: string; domainId?: string }): Promise<{
    shops: Array<{
      id: number;

      url: string;

      lang: string;

      company: string;

      boss: string;

      dic: string;

      ico: string;

      phone: string;

      email: string;

      name: string;

      descr_content_id: number;

      address: string;

      city: string;

      zip: string;

      state: string;

      gps_latitude: number;

      gps_longitude: number;

      opening_hours: Array<{}>;

      content?: {
        id: string | number;

        created_user_id: string | null;

        sitemap_item_id: string | null;

        name: string | null;

        created_date: string;

        ordering: number | null;

        sf_form: string;

        sf_stylesheet: string;

        sf_xmlcontent: string | null;

        draft: number;

        hint: string | null;

        url: string | null;

        last_update: string;

        rss: number;

        home: number;

        publish: number;

        json_content: string | null;

        documents?: Array<{
          document_id?: number;

          content_id?: number;

          document_name?: string | null;

          document_description?: string | null;

          document_path?: string | null;

          document_type?: string | null;

          created_date?: string | null;

          created_user?: number | null;

          privatedoc?: number | null;
        }>;

        downloads?: Array<{
          document_id?: number;

          content_id?: number;

          document_name?: string | null;

          document_description?: string | null;

          document_path?: string | null;

          document_type?: string | null;

          created_date?: string | null;

          created_user?: number | null;

          privatedoc?: number | null;
        }>;

        photogallery?: Array<{
          document_id?: number;

          content_id?: number;

          document_name?: string | null;

          document_description?: string | null;

          document_path?: string | null;

          document_type?: string | null;

          created_date?: string | null;

          created_user?: number | null;

          privatedoc?: number | null;
        }>;

        videos?: Array<{
          video_id?: number;

          content_id?: number;

          video_name?: string | null;

          video_description?: string | null;

          video_url?: string | null;

          ordering?: number | null;

          created_date?: string | null;

          created_user?: number | null;

          webshow?: number | null;

          video_width?: number | null;

          video_height?: number | null;
        }>;

        products?: Array<{
          availability_date: string | null;

          is_in_wishlist: boolean;

          product_id: number;

          name: string;

          slogan: string | null;

          is_sale: 0 | 1;

          issaleout: 0 | 1;

          is_new: 0 | 1;

          is_top: 0 | 1;

          plu: string | null;

          ean: string | null;

          url: string;

          description: string;

          created_at: string;

          rating: string;

          rating_users: number;

          unpacking_cost?: number;

          picture: string | null;

          brand: {};

          name_url_encoded: string;

          qtyDiscounts?: {
            discount_pcn?: number;

            qty_from?: number;

            product_id?: number;

            lang?: string;

            final_price?: number;
          };

          goods: Array<{
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            final_price_without_vat?: number | null;

            customer_discount?: number | null;

            b2b_discount?: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            sum_price?: number | null;

            sum_price_without_vat?: number | null;

            sum_unpacking_cost?: number | null;

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            discount_pcn?: number;

            color?: Array<{}>;

            length?: number;

            width?: number;

            height?: number;
          }>;

          main_good: {
            priceinfo: string;

            valid_from?: string;

            valid_to?: string;

            imagePath: string;

            final_price: number | null;

            final_price_without_vat?: number | null;

            customer_discount?: number | null;

            b2b_discount?: number | null;

            unitprice_sale: number | null;

            sale: boolean | null;

            good_id: number;

            product_id: number;

            currency_id: string;

            unitprice: number;

            vat_rate: number;

            availability_id: number | null;

            oldprice: number | null;

            units: string | null;

            order_number: string | null;

            ean: string | null;

            internal_code: string | null;

            on_stock_count: number;

            is_on_stock: 0 | 1;

            point_good: 0 | 1;

            points_amount: number | null;

            sale_percentage: number | null;

            price_without_vat: number | null;

            price_without_vat_sale: number | null;

            currency: {
              id: string;

              name: string;
            };

            availability: {
              avail_id?: number;

              avail_uniqid?: string;

              order_available?: number;

              translations?: Array<{
                avail_id: number;

                lang: string;

                avail_name: string;
              }>;
            };

            sum_price?: number | null;

            sum_price_without_vat?: number | null;

            sum_unpacking_cost?: number | null;

            group_price?: {
              unitprice: number;

              oldprice: number;

              sale_percentage: number;
            };

            discount_pcn?: number;

            color?: Array<{}>;

            length?: number;

            width?: number;

            height?: number;
          };

          orderWithoutStock?: boolean;

          products_in_set?: Array<{
            good_id?: number;

            package_good_id?: number;

            package_quantity?: number;

            package_price?: number;

            product_id?: number;

            package_product_id?: number;

            color?: string;

            pages?: string;

            capacity?: string;
          }>;

          street?: string;

          city?: string;

          zip?: string;

          parent_categories: Array<{}>;

          assembling?: boolean;

          prelisting?: boolean;

          productPackages?: Array<{
            id?: number;

            productId?: number;

            storeQuantity?: number;

            fullQuantity?: number;

            type?: number;
          }>;

          lang_versions?: Array<{
            lang_id?: string;

            url?: string;
          }>;
        }>;

        next_article?: {
          name?: string;

          url?: string;

          image?: string;

          annotation?: string;

          id?: number;
        };

        tags?: Array<{
          tag_id: string;

          tag_name?: string;
        }>;

        lang_versions?: Array<{}>;
      };
    }>;
  }> {
    let path = '/shops';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['langId'] !== 'undefined') {
      queryParameters['langId'] = parameters['langId'];
    }

    queryParameters['langId'] = this.transformParameter(
      queryParameters['langId'],
    );

    if (typeof parameters['langId'] === 'undefined') {
      throw new Error('Missing required parameter: langId');
    }

    if (typeof parameters['domainId'] !== 'undefined') {
      queryParameters['domainId'] = parameters['domainId'];
    }

    queryParameters['domainId'] = this.transformParameter(
      queryParameters['domainId'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns information about concrete shop by ID
   * @method
   * @name API#loadShopDetail
   */
  loadShopDetail(id: number, parameters: {} = {}): Promise<{}> {
    let path = '/shops/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update order status
   * @method
   * @name API#changeOrderStatus
   */
  changeOrderStatus(
    publicId: string,
    status: string,
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/orders/{public_id}/status/{status}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{public_id}', publicId.toString());

    path = path.replace('{status}', status.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Send automatic email for current status
   * @method
   * @name API#sendMailForOrderStatus
   */
  sendMailForOrderStatus(
    publicId: string,
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{}> {
    let path = '/orders/{public_id}/send-mail-for-status';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{public_id}', publicId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Send automatic email for current status
   * @method
   * @name API#sendMailForOrderPaymentStatus
   */
  sendMailForOrderPaymentStatus(
    publicId: string,
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{}> {
    let path = '/orders/{public_id}/send-mail-for-payment-status';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{public_id}', publicId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Send email from eshop admin
   * @method
   * @name API#sendMailForOrder
   */
  sendMailForOrder(
    publicId: string,
    parameters: {} = {},
    body: {
      emailBody: string;

      subject: string;

      fromName: string;

      fromEmail: string;

      bcc: string;

      withDobropis: boolean;

      withPreInvoice: boolean;

      withInvoice: boolean;
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{}> {
    let path = '/orders/{public_id}/send-mail';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    path = path.replace('{public_id}', publicId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Redirects to cardpay
   * @method
   * @name API#cardPay
   */
  cardPay(orderHash: string, parameters: {} = {}): Promise<void> {
    let path = '/payment/cardpay/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * CardPay response url
   * @method
   * @name API#cardPayResponse
   */
  cardPayResponse(parameters: {
    amt: string;
    curr: number;
    vs: string;
    txn?: string;
    res: string;
    ac?: string;
    tres?: string;
    cid?: string;
    cc?: string;
    rc?: string;
    tid?: string;
    timestamp: string;
    hmac: string;
    ecdsaKey: string;
    ecdsa: string;
  }): Promise<void> {
    let path = '/payment/cardpay_response';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['amt'] !== 'undefined') {
      queryParameters['AMT'] = parameters['amt'];
    }

    queryParameters['AMT'] = this.transformParameter(queryParameters['AMT']);

    if (typeof parameters['amt'] === 'undefined') {
      throw new Error('Missing required parameter: amt');
    }

    if (typeof parameters['curr'] !== 'undefined') {
      queryParameters['CURR'] = parameters['curr'];
    }

    queryParameters['CURR'] = this.transformParameter(queryParameters['CURR']);

    if (typeof parameters['curr'] === 'undefined') {
      throw new Error('Missing required parameter: curr');
    }

    if (typeof parameters['vs'] !== 'undefined') {
      queryParameters['VS'] = parameters['vs'];
    }

    queryParameters['VS'] = this.transformParameter(queryParameters['VS']);

    if (typeof parameters['vs'] === 'undefined') {
      throw new Error('Missing required parameter: vs');
    }

    if (typeof parameters['txn'] !== 'undefined') {
      queryParameters['TXN'] = parameters['txn'];
    }

    queryParameters['TXN'] = this.transformParameter(queryParameters['TXN']);

    if (typeof parameters['res'] !== 'undefined') {
      queryParameters['RES'] = parameters['res'];
    }

    queryParameters['RES'] = this.transformParameter(queryParameters['RES']);

    if (typeof parameters['res'] === 'undefined') {
      throw new Error('Missing required parameter: res');
    }

    if (typeof parameters['ac'] !== 'undefined') {
      queryParameters['AC'] = parameters['ac'];
    }

    queryParameters['AC'] = this.transformParameter(queryParameters['AC']);

    if (typeof parameters['tres'] !== 'undefined') {
      queryParameters['TRES'] = parameters['tres'];
    }

    queryParameters['TRES'] = this.transformParameter(queryParameters['TRES']);

    if (typeof parameters['cid'] !== 'undefined') {
      queryParameters['CID'] = parameters['cid'];
    }

    queryParameters['CID'] = this.transformParameter(queryParameters['CID']);

    if (typeof parameters['cc'] !== 'undefined') {
      queryParameters['CC'] = parameters['cc'];
    }

    queryParameters['CC'] = this.transformParameter(queryParameters['CC']);

    if (typeof parameters['rc'] !== 'undefined') {
      queryParameters['RC'] = parameters['rc'];
    }

    queryParameters['RC'] = this.transformParameter(queryParameters['RC']);

    if (typeof parameters['tid'] !== 'undefined') {
      queryParameters['TID'] = parameters['tid'];
    }

    queryParameters['TID'] = this.transformParameter(queryParameters['TID']);

    if (typeof parameters['timestamp'] !== 'undefined') {
      queryParameters['TIMESTAMP'] = parameters['timestamp'];
    }

    queryParameters['TIMESTAMP'] = this.transformParameter(
      queryParameters['TIMESTAMP'],
    );

    if (typeof parameters['timestamp'] === 'undefined') {
      throw new Error('Missing required parameter: timestamp');
    }

    if (typeof parameters['hmac'] !== 'undefined') {
      queryParameters['HMAC'] = parameters['hmac'];
    }

    queryParameters['HMAC'] = this.transformParameter(queryParameters['HMAC']);

    if (typeof parameters['hmac'] === 'undefined') {
      throw new Error('Missing required parameter: hmac');
    }

    if (typeof parameters['ecdsaKey'] !== 'undefined') {
      queryParameters['ECDSA_KEY'] = parameters['ecdsaKey'];
    }

    queryParameters['ECDSA_KEY'] = this.transformParameter(
      queryParameters['ECDSA_KEY'],
    );

    if (typeof parameters['ecdsaKey'] === 'undefined') {
      throw new Error('Missing required parameter: ecdsaKey');
    }

    if (typeof parameters['ecdsa'] !== 'undefined') {
      queryParameters['ECDSA'] = parameters['ecdsa'];
    }

    queryParameters['ECDSA'] = this.transformParameter(
      queryParameters['ECDSA'],
    );

    if (typeof parameters['ecdsa'] === 'undefined') {
      throw new Error('Missing required parameter: ecdsa');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Redirects to tatrapay
   * @method
   * @name API#tatraPay
   */
  tatraPay(orderHash: string, parameters: {} = {}): Promise<void> {
    let path = '/payment/tatrapay/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * CardPay response url
   * @method
   * @name API#cardPayResponse_1
   */
  cardPayResponse_1(parameters: {
    amt: string;
    curr: number;
    vs: string;
    txn?: string;
    res: string;
    ac?: string;
    tres?: string;
    cid?: string;
    cc?: string;
    rc?: string;
    tid?: string;
    timestamp: string;
    hmac: string;
    ecdsaKey: string;
    ecdsa: string;
  }): Promise<void> {
    let path = '/payment/tatrapay_response';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['amt'] !== 'undefined') {
      queryParameters['AMT'] = parameters['amt'];
    }

    queryParameters['AMT'] = this.transformParameter(queryParameters['AMT']);

    if (typeof parameters['amt'] === 'undefined') {
      throw new Error('Missing required parameter: amt');
    }

    if (typeof parameters['curr'] !== 'undefined') {
      queryParameters['CURR'] = parameters['curr'];
    }

    queryParameters['CURR'] = this.transformParameter(queryParameters['CURR']);

    if (typeof parameters['curr'] === 'undefined') {
      throw new Error('Missing required parameter: curr');
    }

    if (typeof parameters['vs'] !== 'undefined') {
      queryParameters['VS'] = parameters['vs'];
    }

    queryParameters['VS'] = this.transformParameter(queryParameters['VS']);

    if (typeof parameters['vs'] === 'undefined') {
      throw new Error('Missing required parameter: vs');
    }

    if (typeof parameters['txn'] !== 'undefined') {
      queryParameters['TXN'] = parameters['txn'];
    }

    queryParameters['TXN'] = this.transformParameter(queryParameters['TXN']);

    if (typeof parameters['res'] !== 'undefined') {
      queryParameters['RES'] = parameters['res'];
    }

    queryParameters['RES'] = this.transformParameter(queryParameters['RES']);

    if (typeof parameters['res'] === 'undefined') {
      throw new Error('Missing required parameter: res');
    }

    if (typeof parameters['ac'] !== 'undefined') {
      queryParameters['AC'] = parameters['ac'];
    }

    queryParameters['AC'] = this.transformParameter(queryParameters['AC']);

    if (typeof parameters['tres'] !== 'undefined') {
      queryParameters['TRES'] = parameters['tres'];
    }

    queryParameters['TRES'] = this.transformParameter(queryParameters['TRES']);

    if (typeof parameters['cid'] !== 'undefined') {
      queryParameters['CID'] = parameters['cid'];
    }

    queryParameters['CID'] = this.transformParameter(queryParameters['CID']);

    if (typeof parameters['cc'] !== 'undefined') {
      queryParameters['CC'] = parameters['cc'];
    }

    queryParameters['CC'] = this.transformParameter(queryParameters['CC']);

    if (typeof parameters['rc'] !== 'undefined') {
      queryParameters['RC'] = parameters['rc'];
    }

    queryParameters['RC'] = this.transformParameter(queryParameters['RC']);

    if (typeof parameters['tid'] !== 'undefined') {
      queryParameters['TID'] = parameters['tid'];
    }

    queryParameters['TID'] = this.transformParameter(queryParameters['TID']);

    if (typeof parameters['timestamp'] !== 'undefined') {
      queryParameters['TIMESTAMP'] = parameters['timestamp'];
    }

    queryParameters['TIMESTAMP'] = this.transformParameter(
      queryParameters['TIMESTAMP'],
    );

    if (typeof parameters['timestamp'] === 'undefined') {
      throw new Error('Missing required parameter: timestamp');
    }

    if (typeof parameters['hmac'] !== 'undefined') {
      queryParameters['HMAC'] = parameters['hmac'];
    }

    queryParameters['HMAC'] = this.transformParameter(queryParameters['HMAC']);

    if (typeof parameters['hmac'] === 'undefined') {
      throw new Error('Missing required parameter: hmac');
    }

    if (typeof parameters['ecdsaKey'] !== 'undefined') {
      queryParameters['ECDSA_KEY'] = parameters['ecdsaKey'];
    }

    queryParameters['ECDSA_KEY'] = this.transformParameter(
      queryParameters['ECDSA_KEY'],
    );

    if (typeof parameters['ecdsaKey'] === 'undefined') {
      throw new Error('Missing required parameter: ecdsaKey');
    }

    if (typeof parameters['ecdsa'] !== 'undefined') {
      queryParameters['ECDSA'] = parameters['ecdsa'];
    }

    queryParameters['ECDSA'] = this.transformParameter(
      queryParameters['ECDSA'],
    );

    if (typeof parameters['ecdsa'] === 'undefined') {
      throw new Error('Missing required parameter: ecdsa');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Redirects to Comgate
   * @method
   * @name API#comgate
   */
  comgate(orderHash: string, parameters: {} = {}): Promise<void> {
    let path = '/payment/comgate/{orderHash}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{orderHash}', orderHash.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Redirects to success page
   * @method
   * @name API#comgateResponse
   */
  comgateResponse(parameters: {
    refId: string;
    id: string;
    status: string;
  }): Promise<void> {
    let path = '/payment/comgate_response';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['refId'] !== 'undefined') {
      queryParameters['refId'] = parameters['refId'];
    }

    queryParameters['refId'] = this.transformParameter(
      queryParameters['refId'],
    );

    if (typeof parameters['refId'] === 'undefined') {
      throw new Error('Missing required parameter: refId');
    }

    if (typeof parameters['id'] !== 'undefined') {
      queryParameters['id'] = parameters['id'];
    }

    queryParameters['id'] = this.transformParameter(queryParameters['id']);

    if (typeof parameters['id'] === 'undefined') {
      throw new Error('Missing required parameter: id');
    }

    if (typeof parameters['status'] !== 'undefined') {
      queryParameters['status'] = parameters['status'];
    }

    queryParameters['status'] = this.transformParameter(
      queryParameters['status'],
    );

    if (typeof parameters['status'] === 'undefined') {
      throw new Error('Missing required parameter: status');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update stock data from helios
   * @method
   * @name API#updateStock_1
   */
  updateStock_1(
    parameters: {} = {},
    body: Array<{
      ProductId: number;

      MJEvidencna: string;

      MnozstvoSklad: number;

      MnozstvoSkladExterny: number;

      MnozstvoObjednane: number;

      MnozstvoVPrijme: number;

      MnozstvoRezervovane: number;

      Balenia?: Array<{
        MnozstvovBaleniCelkom: number;

        PocetBaleniSkladom: number;

        TypBalenia: number;
      }>;
    }>,
  ): Promise<void> {
    let path = '/helios/stock/update';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Export order to helios
   * @method
   * @name API#exportOrder_1
   */
  exportOrder_1(orderId: string, parameters: {} = {}): Promise<void> {
    let path = '/helios/order/export{order_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{order_id}', orderId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update order status from helios
   * @method
   * @name API#updateOrderStatus
   */
  updateOrderStatus(
    parameters: {} = {},
    body: Array<{
      EshopNumber: number;

      status: string;
    }>,
  ): Promise<void> {
    let path = '/helios/order/status';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Update demand data from helios
   * @method
   * @name API#updateDemand
   */
  updateDemand(
    parameters: {} = {},
    body: Array<{
      HeliosNumber: string;

      EshopNumber: string;

      Note?: string;

      Type: string;

      totalPrice: number;

      Items: Array<{
        idproduct: number;

        OrderLineNumber?: number;

        code: string;

        ItemDescription: string;

        quantity: number;

        MeasUnit: string;

        pricePerUnit: number;

        price: number;

        taxRate: number;

        finalPricePerUnit: number;

        finalPrice: number;

        ItemNote?: string;
      }>;
    }>,
  ): Promise<void> {
    let path = '/helios/demand/update';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Get customer documents from helios
   * @method
   * @name API#loadCustomerDocuments
   */
  loadCustomerDocuments(
    customerId: number,
    parameters: {} = {},
  ): Promise<
    Array<{
      name?: 'Invoice' | 'DeliveryNote';

      IdDokladHelios?: number;

      HeliosNumber?: string;

      CreatedAt?: string;

      EshopOrderNumber?: string;

      DruhDokladu?: string;

      CenaCelkom?: number;

      Dokumenty?: Array<{
        DokumentID?: number;

        Popis?: string;

        Typ?: string;

        Pripona?: string;
      }>;
    }>
  > {
    let path = '/helios/documents/customer/{customer_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{customer_id}', customerId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get order documents from helios
   * @method
   * @name API#loadOrderDocuments
   */
  loadOrderDocuments(
    orderId: string,
    parameters: {} = {},
  ): Promise<
    Array<{
      name?: 'Invoice' | 'DeliveryNote';

      IdDokladHelios?: number;

      HeliosNumber?: string;

      CreatedAt?: string;

      EshopOrderNumber?: string;

      DruhDokladu?: string;

      CenaCelkom?: number;

      Dokumenty?: Array<{
        DokumentID?: number;

        Popis?: string;

        Typ?: string;

        Pripona?: string;
      }>;
    }>
  > {
    let path = '/helios/documents/order/{order_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{order_id}', orderId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get helios document
   * @method
   * @name API#loadDocument
   */
  loadDocument(
    documentId: string,
    parameters: {
      documentName: string;
    },
  ): Promise<void> {
    let path = '/helios/document/{document_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{document_id}', documentId.toString());

    if (typeof parameters['documentName'] !== 'undefined') {
      queryParameters['document_name'] = parameters['documentName'];
    }

    queryParameters['document_name'] = this.transformParameter(
      queryParameters['document_name'],
    );

    if (typeof parameters['documentName'] === 'undefined') {
      throw new Error('Missing required parameter: documentName');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Process noris order action
   * @method
   * @name API#orderAction
   */
  orderAction(
    orderId: string,
    actionId: string,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/noris/order/{order_id}/{action_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{order_id}', orderId.toString());

    path = path.replace('{action_id}', actionId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Insert new import request
   * @method
   * @name API#insertImport
   */
  insertImport(
    parameters: {} = {},
    body: {
      export_type: string;

      item_id: string;
    },
  ): Promise<{
    status?: string;
  }> {
    let path = '/external-system/import-queue';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Get order documents from ANS
   * @method
   * @name API#getDocumentPdf
   */
  getDocumentPdf(
    orderId: string,
    document: 'faktura' | 'predfaktura' | 'dobropis',
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/ans/documents/order/{order_id}/generate-pdf/{document}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{order_id}', orderId.toString());

    path = path.replace('{document}', document.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Process noris order action
   * @method
   * @name API#orderAction_1
   */
  orderAction_1(
    orderId: string,
    actionId: string,
    parameters: {} = {},
  ): Promise<void> {
    let path = '/cybersoft/order/{order_id}/{action_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{order_id}', orderId.toString());

    path = path.replace('{action_id}', actionId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Process list logs action
   * @method
   * @name API#loadLogs
   */
  loadLogs(
    parameters: {
      limit?: number;
      offset?: number;
      type?: string;
      query?: string;
      category?: string;
      payloadLang?: string;
      payloadId?: string;
    } = {},
  ): Promise<void> {
    let path = '/logger/search';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    if (typeof parameters['category'] !== 'undefined') {
      queryParameters['category'] = parameters['category'];
    }

    queryParameters['category'] = this.transformParameter(
      queryParameters['category'],
    );

    if (typeof parameters['payloadLang'] !== 'undefined') {
      queryParameters['payload_lang'] = parameters['payloadLang'];
    }

    queryParameters['payload_lang'] = this.transformParameter(
      queryParameters['payload_lang'],
    );

    if (typeof parameters['payloadId'] !== 'undefined') {
      queryParameters['payload_id'] = parameters['payloadId'];
    }

    queryParameters['payload_id'] = this.transformParameter(
      queryParameters['payload_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Creates log in the database
   * @method
   * @name API#createRestLog
   */
  createRestLog(
    parameters: {} = {},
    body: {
      type: string;

      category?: string;

      admin_id?: number;

      user_name?: string;

      payload: {};
    },
  ): Promise<{}> {
    let path = '/logger/create';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#testNew
   */
  testNew(
    parameters: {
      email?: number;
    } = {},
  ): Promise<{}> {
    let path = '/logger/monitor';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['email'] !== 'undefined') {
      queryParameters['email'] = parameters['email'];
    }

    queryParameters['email'] = this.transformParameter(
      queryParameters['email'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns products export for particular service according path parameter in appropriate format
   * @method
   * @name API#loadExport
   */
  loadExport(
    exportName: string,
    parameters: {
      lang?: string;
      ext?: string;
      brandId?: string;
      domainId?: number;
    } = {},
  ): Promise<{}> {
    let path = '/export/{export_name}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    queryParameters['ext'] = 'xml';

    if (typeof parameters['ext'] !== 'undefined') {
      queryParameters['ext'] = parameters['ext'];
    }

    queryParameters['ext'] = this.transformParameter(queryParameters['ext']);

    path = path.replace('{export_name}', exportName.toString());

    if (typeof parameters['brandId'] !== 'undefined') {
      queryParameters['brand_id'] = parameters['brandId'];
    }

    queryParameters['brand_id'] = this.transformParameter(
      queryParameters['brand_id'],
    );

    if (typeof parameters['domainId'] !== 'undefined') {
      queryParameters['domain_id'] = parameters['domainId'];
    }

    queryParameters['domain_id'] = this.transformParameter(
      queryParameters['domain_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns headers information of file
   * @method
   * @name API#loadExportHead
   */
  loadExportHead(
    exportName: string,
    parameters: {
      lang?: string;
      ext?: string;
    } = {},
  ): Promise<void> {
    let path = '/export/{export_name}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    queryParameters['ext'] = 'xml';

    if (typeof parameters['ext'] !== 'undefined') {
      queryParameters['ext'] = parameters['ext'];
    }

    queryParameters['ext'] = this.transformParameter(queryParameters['ext']);

    path = path.replace('{export_name}', exportName.toString());

    return this.request(
      'HEAD',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns possible updates info
   * @method
   * @name API#updateInfo
   */
  updateInfo(adminVersion: string, parameters: {} = {}): Promise<{}> {
    let path = '/update-info/{admin_version}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{admin_version}', adminVersion.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Returns list of landing pages

    * @method
    * @name API#loadLandingPages
    */
  loadLandingPages(parameters: {
    limit?: number;
    offset?: number;
    columns?: string;
    id?: number;
    withContent?: number;
    lang: string;
    productLimit?: number;
    productOffset?: number;
  }): Promise<{
    product_materials?: Array<{
      content: {
        annot: string;

        author: {
          author_id: number;

          image: string;

          name: string;

          url: string;
        };

        created_date: string;

        created_user_id: number;

        documents: Array<{
          content_id: number;

          created_date: string;

          created_user: number;

          document_description: string;

          document_id: number;

          document_name: string;

          document_path: string;

          document_type: string;

          privatedoc: boolean;
        }>;

        downloads: Array<{
          content_id: number;

          created_date: string;

          created_user: number;

          document_description: string;

          document_id: number;

          document_name: string;

          document_path: string;

          document_type: string;

          privatedoc: boolean;
        }>;

        draft: boolean;

        hint: string;

        home: boolean;

        id: number;

        image: string;

        is_top: boolean;

        json_content: {};

        lang_id: string;

        lang_version_id: number;

        last_update: string;

        name: string;

        next_article: {
          annotation: string;

          id: string;

          image: string;

          name: string;

          url: string;
        };

        ordering: number;

        original_id: number;

        photogallery: Array<{
          content_id: number;

          created_date: string;

          created_user: number;

          document_description: string;

          document_id: number;

          document_name: string;

          document_path: string;

          document_type: string;

          privatedoc: boolean;
        }>;

        products: Array<{
          attribs: Array<{
            attrib_code: string;

            attrib_grid: number;

            attrib_id: number;

            attrib_ismulti: number;

            attrib_list: number;

            attrib_name: string;

            attrib_pbl: number;

            attrib_sort: number;

            attrib_type: string;

            attrib_uid: string;

            attrib_unit: string;

            attrib_value: string;

            custom_value: string;

            group_id: number;

            group_name: string;

            max: number;

            min: number;

            product_id: number;

            translations: Array<{
              attrib_id: number;

              attrib_name: string;

              attrib_unit: string;

              lang_id: string;
            }>;

            type_id: number;

            value_groups: Array<{
              attrib_id: number;

              group_id: number;

              group_name: string;

              group_sort: number;
            }>;

            value_id: number;

            values: Array<{
              amount: number;

              attrib_id: number;

              attrib_pict: string;

              attrib_value: string;

              group_id: number;

              istop: boolean;

              searchfilter: number;

              translations: Array<{
                attrib_url: string;

                attrib_value: string;

                lang_id: string;

                secondary_name: string;

                value_id: number;
              }>;

              value_id: number;

              value_sort: number;
            }>;
          }>;

          b2bOrderAvailable: boolean;

          b2cOrderAvailable: boolean;

          brand: {
            brand_descr: string;

            brand_id: number;

            brand_image: string;

            brand_name: string;

            brand_provider: string;

            brand_top: boolean;

            brand_weburl: string;

            catalog_id: number;

            translations: Array<{
              brand_descr: string;

              brand_id: number;

              brand_name: string;

              brand_weburl: string;

              lang_id: string;
            }>;

            url: string;
          };

          brand_id: number;

          category_text: string;

          created_at: string;

          created_datetime: string;

          description: string;

          disable_discount: boolean;

          disable_solo_order: boolean;

          ean: string;

          goods: Array<{
            availability: {
              avail_id: number;

              avail_uniqid: string;

              order_available: number;

              translations: Array<{
                avail_id: number;

                avail_name: string;

                lang: string;
              }>;
            };

            availability_date: string;

            availability_id: number;

            buy_price: number;

            currency: {
              id: string;

              name: string;
            };

            currency_id: string;

            customer_prices: {};

            discount_pcn: number;

            ean: string;

            final_price: number;

            from_group_sale: boolean;

            from_sale: boolean;

            giftcard: {
              apply_per_unit: boolean;

              card_number: string;

              card_status: string;

              club_user_id: number;

              created_date: string;

              created_user_id: number;

              date_used: string;

              date_validity: string;

              discount: number;

              discount_currency: string;

              freeshipping: boolean;

              freeshippingeu: boolean;

              id: number;

              idx: number;

              is_strict_discount: boolean;

              lang: string;

              multicard: boolean;

              order_id: number;

              price: number;

              price_currency: string;

              price_validity: number;

              sale: number;

              sale_count: number;

              valid_product_ids: Array<number>;

              valid_products_count: number;
            };

            good_id: number;

            goods_in_set: {
              good_id: number;

              package_good_id: number;

              package_name: string;

              package_price: number;

              package_quantity: number;
            };

            group_price: {
              oldprice: number;

              sale_percentage: number;

              unitprice: number;
            };

            group_prices: Array<{}>;

            idoklad_id: string;

            imagePath: string;

            internal_code: string;

            is_on_stock: boolean;

            karat_id: string;

            lang: string;

            oldprice: number;

            on_stock_count: number;

            order_number: string;

            order_quantity_limit: number;

            point_good: boolean;

            points_amount: number;

            price_without_vat: number;

            price_without_vat_sale: number;

            priceinfo: string;

            product_id: number;

            product_name: string;

            product_note: string;

            product_type: number;

            purchaseprice: number;

            qtyDiscounts: {};

            sale: boolean;

            sale_percentage: number;

            sale_valid: string;

            stores_info: Array<{
              availability: {
                avail_id: number;

                avail_uniqid: string;

                order_available: number;

                translations: Array<{
                  avail_id: number;

                  avail_name: string;

                  lang: string;
                }>;
              };

              quanity: number;

              store_id: string;

              store_name: string;
            }>;

            unit_price_sale: number;

            unitprice: number;

            unitprice_sale: number;

            units: string;

            valid_from: string;

            valid_to: string;

            vat_rate: number;

            warranty: string;

            warranty_cz: string;

            web_attribs: Array<{
              attrib_code: string;

              attrib_grid: number;

              attrib_id: number;

              attrib_ismulti: number;

              attrib_list: number;

              attrib_name: string;

              attrib_pbl: number;

              attrib_sort: number;

              attrib_type: string;

              attrib_uid: string;

              attrib_unit: string;

              attrib_value: string;

              custom_value: string;

              group_id: number;

              group_name: string;

              max: number;

              min: number;

              product_id: number;

              translations: Array<{
                attrib_id: number;

                attrib_name: string;

                attrib_unit: string;

                lang_id: string;
              }>;

              type_id: number;

              value_groups: Array<{
                attrib_id: number;

                group_id: number;

                group_name: string;

                group_sort: number;
              }>;

              value_id: number;

              values: Array<{
                amount: number;

                attrib_id: number;

                attrib_pict: string;

                attrib_value: string;

                group_id: number;

                istop: boolean;

                searchfilter: number;

                translations: Array<{
                  attrib_url: string;

                  attrib_value: string;

                  lang_id: string;

                  secondary_name: string;

                  value_id: number;
                }>;

                value_id: number;

                value_sort: number;
              }>;
            }>;

            web_order_attribs: Array<{
              attrib_code: string;

              attrib_grid: number;

              attrib_id: number;

              attrib_ismulti: number;

              attrib_list: number;

              attrib_name: string;

              attrib_pbl: number;

              attrib_sort: number;

              attrib_type: string;

              attrib_uid: string;

              attrib_unit: string;

              attrib_value: string;

              custom_value: string;

              group_id: number;

              group_name: string;

              max: number;

              min: number;

              product_id: number;

              translations: Array<{
                attrib_id: number;

                attrib_name: string;

                attrib_unit: string;

                lang_id: string;
              }>;

              type_id: number;

              value_groups: Array<{
                attrib_id: number;

                group_id: number;

                group_name: string;

                group_sort: number;
              }>;

              value_id: number;

              values: Array<{
                amount: number;

                attrib_id: number;

                attrib_pict: string;

                attrib_value: string;

                group_id: number;

                istop: boolean;

                searchfilter: number;

                translations: Array<{
                  attrib_url: string;

                  attrib_value: string;

                  lang_id: string;

                  secondary_name: string;

                  value_id: number;
                }>;

                value_id: number;

                value_sort: number;
              }>;
            }>;

            weight: number;
          }>;

          heureka: number;

          heureka_id: number;

          internal_code: number;

          is_favorite: boolean;

          is_new: boolean;

          is_popular: boolean;

          is_recommended: boolean;

          is_sale: boolean;

          isactive: boolean;

          isnew_valid: string;

          issale_buy3_pay2: number;

          issaleout: boolean;

          issaleout_since: string;

          issaleout_valid: string;

          istop: boolean;

          locked: number;

          main_good: {
            availability: {
              avail_id: number;

              avail_uniqid: string;

              order_available: number;

              translations: Array<{
                avail_id: number;

                avail_name: string;

                lang: string;
              }>;
            };

            availability_date: string;

            availability_id: number;

            buy_price: number;

            currency: {
              id: string;

              name: string;
            };

            currency_id: string;

            customer_prices: {};

            discount_pcn: number;

            ean: string;

            final_price: number;

            from_group_sale: boolean;

            from_sale: boolean;

            giftcard: {
              apply_per_unit: boolean;

              card_number: string;

              card_status: string;

              club_user_id: number;

              created_date: string;

              created_user_id: number;

              date_used: string;

              date_validity: string;

              discount: number;

              discount_currency: string;

              freeshipping: boolean;

              freeshippingeu: boolean;

              id: number;

              idx: number;

              is_strict_discount: boolean;

              lang: string;

              multicard: boolean;

              order_id: number;

              price: number;

              price_currency: string;

              price_validity: number;

              sale: number;

              sale_count: number;

              valid_product_ids: Array<number>;

              valid_products_count: number;
            };

            good_id: number;

            goods_in_set: {
              good_id: number;

              package_good_id: number;

              package_name: string;

              package_price: number;

              package_quantity: number;
            };

            group_price: {
              oldprice: number;

              sale_percentage: number;

              unitprice: number;
            };

            group_prices: Array<{}>;

            idoklad_id: string;

            imagePath: string;

            internal_code: string;

            is_on_stock: boolean;

            karat_id: string;

            lang: string;

            oldprice: number;

            on_stock_count: number;

            order_number: string;

            order_quantity_limit: number;

            point_good: boolean;

            points_amount: number;

            price_without_vat: number;

            price_without_vat_sale: number;

            priceinfo: string;

            product_id: number;

            product_name: string;

            product_note: string;

            product_type: number;

            purchaseprice: number;

            qtyDiscounts: {};

            sale: boolean;

            sale_percentage: number;

            sale_valid: string;

            stores_info: Array<{
              availability: {
                avail_id: number;

                avail_uniqid: string;

                order_available: number;

                translations: Array<{
                  avail_id: number;

                  avail_name: string;

                  lang: string;
                }>;
              };

              quanity: number;

              store_id: string;

              store_name: string;
            }>;

            unit_price_sale: number;

            unitprice: number;

            unitprice_sale: number;

            units: string;

            valid_from: string;

            valid_to: string;

            vat_rate: number;

            warranty: string;

            warranty_cz: string;

            web_attribs: Array<{
              attrib_code: string;

              attrib_grid: number;

              attrib_id: number;

              attrib_ismulti: number;

              attrib_list: number;

              attrib_name: string;

              attrib_pbl: number;

              attrib_sort: number;

              attrib_type: string;

              attrib_uid: string;

              attrib_unit: string;

              attrib_value: string;

              custom_value: string;

              group_id: number;

              group_name: string;

              max: number;

              min: number;

              product_id: number;

              translations: Array<{
                attrib_id: number;

                attrib_name: string;

                attrib_unit: string;

                lang_id: string;
              }>;

              type_id: number;

              value_groups: Array<{
                attrib_id: number;

                group_id: number;

                group_name: string;

                group_sort: number;
              }>;

              value_id: number;

              values: Array<{
                amount: number;

                attrib_id: number;

                attrib_pict: string;

                attrib_value: string;

                group_id: number;

                istop: boolean;

                searchfilter: number;

                translations: Array<{
                  attrib_url: string;

                  attrib_value: string;

                  lang_id: string;

                  secondary_name: string;

                  value_id: number;
                }>;

                value_id: number;

                value_sort: number;
              }>;
            }>;

            web_order_attribs: Array<{
              attrib_code: string;

              attrib_grid: number;

              attrib_id: number;

              attrib_ismulti: number;

              attrib_list: number;

              attrib_name: string;

              attrib_pbl: number;

              attrib_sort: number;

              attrib_type: string;

              attrib_uid: string;

              attrib_unit: string;

              attrib_value: string;

              custom_value: string;

              group_id: number;

              group_name: string;

              max: number;

              min: number;

              product_id: number;

              translations: Array<{
                attrib_id: number;

                attrib_name: string;

                attrib_unit: string;

                lang_id: string;
              }>;

              type_id: number;

              value_groups: Array<{
                attrib_id: number;

                group_id: number;

                group_name: string;

                group_sort: number;
              }>;

              value_id: number;

              values: Array<{
                amount: number;

                attrib_id: number;

                attrib_pict: string;

                attrib_value: string;

                group_id: number;

                istop: boolean;

                searchfilter: number;

                translations: Array<{
                  attrib_url: string;

                  attrib_value: string;

                  lang_id: string;

                  secondary_name: string;

                  value_id: number;
                }>;

                value_id: number;

                value_sort: number;
              }>;
            }>;

            weight: number;
          };

          max_price: number;

          min_delivery_priority: number;

          min_payment_priority: number;

          min_price: number;

          name: string;

          name_url_encoded: string;

          nautilus_id: number;

          notshow: number;

          ordered_count: number;

          picture: string;

          picture_hash: string;

          picture_media_script: string;

          plu: string;

          product_fts: string;

          product_id: number;

          product_weight: number;

          rating: number;

          rating_users: number;

          slogan: string;

          type: {
            catalog_id: number;

            is_hidden: number;

            store_minqty: string;

            type_descr: string;

            type_id: number;

            type_inherits: number;

            type_name: string;
          };

          url: string;

          valid_from: string;

          valid_to: string;
        }>;

        publish: number;

        rss: boolean;

        sf_form: string;

        sf_stylesheet: string;

        sf_xmlcontent: string;

        sitemapIds: Array<number>;

        sitemap_item_id: number;

        tags: Array<{
          id: number;

          identificator: string;

          is_hidden: boolean;

          name: string;
        }>;

        url: string;

        videos: Array<{
          content_id: number;

          created_date: string;

          created_user: number;

          ordering: number;

          video_description: string;

          video_height: number;

          video_id: number;

          video_name: string;

          video_url: string;

          video_width: number;

          webshow: number;
        }>;
      };

      createdDate: string;

      dateFrom: string;

      dateTo: string;

      descrContentId: number;

      id: number;

      langId: string;

      lastUpdate: string;

      name: string;

      url: string;
    }>;
  }> {
    let path = '/landing-pages';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['columns'] !== 'undefined') {
      queryParameters['columns'] = parameters['columns'];
    }

    queryParameters['columns'] = this.transformParameter(
      queryParameters['columns'],
    );

    if (typeof parameters['id'] !== 'undefined') {
      queryParameters['id'] = parameters['id'];
    }

    queryParameters['id'] = this.transformParameter(queryParameters['id']);

    if (typeof parameters['withContent'] !== 'undefined') {
      queryParameters['with_content'] = parameters['withContent'];
    }

    queryParameters['with_content'] = this.transformParameter(
      queryParameters['with_content'],
    );

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    if (typeof parameters['lang'] === 'undefined') {
      throw new Error('Missing required parameter: lang');
    }

    if (typeof parameters['productLimit'] !== 'undefined') {
      queryParameters['product_limit'] = parameters['productLimit'];
    }

    queryParameters['product_limit'] = this.transformParameter(
      queryParameters['product_limit'],
    );

    if (typeof parameters['productOffset'] !== 'undefined') {
      queryParameters['product_offset'] = parameters['productOffset'];
    }

    queryParameters['product_offset'] = this.transformParameter(
      queryParameters['product_offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Creates or update landing page

    * @method
    * @name API#upsertLandingPage
    */
  upsertLandingPage(
    parameters: {} = {},
    body: {
      descrContentId: number;

      id: number;

      langId: string;

      name: string;

      url: string;

      user_type: string;

      createdDate?: string;

      dateFrom?: string;

      dateTo?: string;
    },
  ): Promise<{
    content: {
      annot: string;

      author: {
        author_id: number;

        image: string;

        name: string;

        url: string;
      };

      created_date: string;

      created_user_id: number;

      documents: Array<{
        content_id: number;

        created_date: string;

        created_user: number;

        document_description: string;

        document_id: number;

        document_name: string;

        document_path: string;

        document_type: string;

        privatedoc: boolean;
      }>;

      downloads: Array<{
        content_id: number;

        created_date: string;

        created_user: number;

        document_description: string;

        document_id: number;

        document_name: string;

        document_path: string;

        document_type: string;

        privatedoc: boolean;
      }>;

      draft: boolean;

      hint: string;

      home: boolean;

      id: number;

      image: string;

      is_top: boolean;

      json_content: {};

      lang_id: string;

      lang_version_id: number;

      last_update: string;

      name: string;

      next_article: {
        annotation: string;

        id: string;

        image: string;

        name: string;

        url: string;
      };

      ordering: number;

      original_id: number;

      photogallery: Array<{
        content_id: number;

        created_date: string;

        created_user: number;

        document_description: string;

        document_id: number;

        document_name: string;

        document_path: string;

        document_type: string;

        privatedoc: boolean;
      }>;

      products: Array<{
        attribs: Array<{
          attrib_code: string;

          attrib_grid: number;

          attrib_id: number;

          attrib_ismulti: number;

          attrib_list: number;

          attrib_name: string;

          attrib_pbl: number;

          attrib_sort: number;

          attrib_type: string;

          attrib_uid: string;

          attrib_unit: string;

          attrib_value: string;

          custom_value: string;

          group_id: number;

          group_name: string;

          max: number;

          min: number;

          product_id: number;

          translations: Array<{
            attrib_id: number;

            attrib_name: string;

            attrib_unit: string;

            lang_id: string;
          }>;

          type_id: number;

          value_groups: Array<{
            attrib_id: number;

            group_id: number;

            group_name: string;

            group_sort: number;
          }>;

          value_id: number;

          values: Array<{
            amount: number;

            attrib_id: number;

            attrib_pict: string;

            attrib_value: string;

            group_id: number;

            istop: boolean;

            searchfilter: number;

            translations: Array<{
              attrib_url: string;

              attrib_value: string;

              lang_id: string;

              secondary_name: string;

              value_id: number;
            }>;

            value_id: number;

            value_sort: number;
          }>;
        }>;

        b2bOrderAvailable: boolean;

        b2cOrderAvailable: boolean;

        brand: {
          brand_descr: string;

          brand_id: number;

          brand_image: string;

          brand_name: string;

          brand_provider: string;

          brand_top: boolean;

          brand_weburl: string;

          catalog_id: number;

          translations: Array<{
            brand_descr: string;

            brand_id: number;

            brand_name: string;

            brand_weburl: string;

            lang_id: string;
          }>;

          url: string;
        };

        brand_id: number;

        category_text: string;

        created_at: string;

        created_datetime: string;

        description: string;

        disable_discount: boolean;

        disable_solo_order: boolean;

        ean: string;

        goods: Array<{
          availability: {
            avail_id: number;

            avail_uniqid: string;

            order_available: number;

            translations: Array<{
              avail_id: number;

              avail_name: string;

              lang: string;
            }>;
          };

          availability_date: string;

          availability_id: number;

          buy_price: number;

          currency: {
            id: string;

            name: string;
          };

          currency_id: string;

          customer_prices: {};

          discount_pcn: number;

          ean: string;

          final_price: number;

          from_group_sale: boolean;

          from_sale: boolean;

          giftcard: {
            apply_per_unit: boolean;

            card_number: string;

            card_status: string;

            club_user_id: number;

            created_date: string;

            created_user_id: number;

            date_used: string;

            date_validity: string;

            discount: number;

            discount_currency: string;

            freeshipping: boolean;

            freeshippingeu: boolean;

            id: number;

            idx: number;

            is_strict_discount: boolean;

            lang: string;

            multicard: boolean;

            order_id: number;

            price: number;

            price_currency: string;

            price_validity: number;

            sale: number;

            sale_count: number;

            valid_product_ids: Array<number>;

            valid_products_count: number;
          };

          good_id: number;

          goods_in_set: {
            good_id: number;

            package_good_id: number;

            package_name: string;

            package_price: number;

            package_quantity: number;
          };

          group_price: {
            oldprice: number;

            sale_percentage: number;

            unitprice: number;
          };

          group_prices: Array<{}>;

          idoklad_id: string;

          imagePath: string;

          internal_code: string;

          is_on_stock: boolean;

          karat_id: string;

          lang: string;

          oldprice: number;

          on_stock_count: number;

          order_number: string;

          order_quantity_limit: number;

          point_good: boolean;

          points_amount: number;

          price_without_vat: number;

          price_without_vat_sale: number;

          priceinfo: string;

          product_id: number;

          product_name: string;

          product_note: string;

          product_type: number;

          purchaseprice: number;

          qtyDiscounts: {};

          sale: boolean;

          sale_percentage: number;

          sale_valid: string;

          stores_info: Array<{
            availability: {
              avail_id: number;

              avail_uniqid: string;

              order_available: number;

              translations: Array<{
                avail_id: number;

                avail_name: string;

                lang: string;
              }>;
            };

            quanity: number;

            store_id: string;

            store_name: string;
          }>;

          unit_price_sale: number;

          unitprice: number;

          unitprice_sale: number;

          units: string;

          valid_from: string;

          valid_to: string;

          vat_rate: number;

          warranty: string;

          warranty_cz: string;

          web_attribs: Array<{
            attrib_code: string;

            attrib_grid: number;

            attrib_id: number;

            attrib_ismulti: number;

            attrib_list: number;

            attrib_name: string;

            attrib_pbl: number;

            attrib_sort: number;

            attrib_type: string;

            attrib_uid: string;

            attrib_unit: string;

            attrib_value: string;

            custom_value: string;

            group_id: number;

            group_name: string;

            max: number;

            min: number;

            product_id: number;

            translations: Array<{
              attrib_id: number;

              attrib_name: string;

              attrib_unit: string;

              lang_id: string;
            }>;

            type_id: number;

            value_groups: Array<{
              attrib_id: number;

              group_id: number;

              group_name: string;

              group_sort: number;
            }>;

            value_id: number;

            values: Array<{
              amount: number;

              attrib_id: number;

              attrib_pict: string;

              attrib_value: string;

              group_id: number;

              istop: boolean;

              searchfilter: number;

              translations: Array<{
                attrib_url: string;

                attrib_value: string;

                lang_id: string;

                secondary_name: string;

                value_id: number;
              }>;

              value_id: number;

              value_sort: number;
            }>;
          }>;

          web_order_attribs: Array<{
            attrib_code: string;

            attrib_grid: number;

            attrib_id: number;

            attrib_ismulti: number;

            attrib_list: number;

            attrib_name: string;

            attrib_pbl: number;

            attrib_sort: number;

            attrib_type: string;

            attrib_uid: string;

            attrib_unit: string;

            attrib_value: string;

            custom_value: string;

            group_id: number;

            group_name: string;

            max: number;

            min: number;

            product_id: number;

            translations: Array<{
              attrib_id: number;

              attrib_name: string;

              attrib_unit: string;

              lang_id: string;
            }>;

            type_id: number;

            value_groups: Array<{
              attrib_id: number;

              group_id: number;

              group_name: string;

              group_sort: number;
            }>;

            value_id: number;

            values: Array<{
              amount: number;

              attrib_id: number;

              attrib_pict: string;

              attrib_value: string;

              group_id: number;

              istop: boolean;

              searchfilter: number;

              translations: Array<{
                attrib_url: string;

                attrib_value: string;

                lang_id: string;

                secondary_name: string;

                value_id: number;
              }>;

              value_id: number;

              value_sort: number;
            }>;
          }>;

          weight: number;
        }>;

        heureka: number;

        heureka_id: number;

        internal_code: number;

        is_favorite: boolean;

        is_new: boolean;

        is_popular: boolean;

        is_recommended: boolean;

        is_sale: boolean;

        isactive: boolean;

        isnew_valid: string;

        issale_buy3_pay2: number;

        issaleout: boolean;

        issaleout_since: string;

        issaleout_valid: string;

        istop: boolean;

        locked: number;

        main_good: {
          availability: {
            avail_id: number;

            avail_uniqid: string;

            order_available: number;

            translations: Array<{
              avail_id: number;

              avail_name: string;

              lang: string;
            }>;
          };

          availability_date: string;

          availability_id: number;

          buy_price: number;

          currency: {
            id: string;

            name: string;
          };

          currency_id: string;

          customer_prices: {};

          discount_pcn: number;

          ean: string;

          final_price: number;

          from_group_sale: boolean;

          from_sale: boolean;

          giftcard: {
            apply_per_unit: boolean;

            card_number: string;

            card_status: string;

            club_user_id: number;

            created_date: string;

            created_user_id: number;

            date_used: string;

            date_validity: string;

            discount: number;

            discount_currency: string;

            freeshipping: boolean;

            freeshippingeu: boolean;

            id: number;

            idx: number;

            is_strict_discount: boolean;

            lang: string;

            multicard: boolean;

            order_id: number;

            price: number;

            price_currency: string;

            price_validity: number;

            sale: number;

            sale_count: number;

            valid_product_ids: Array<number>;

            valid_products_count: number;
          };

          good_id: number;

          goods_in_set: {
            good_id: number;

            package_good_id: number;

            package_name: string;

            package_price: number;

            package_quantity: number;
          };

          group_price: {
            oldprice: number;

            sale_percentage: number;

            unitprice: number;
          };

          group_prices: Array<{}>;

          idoklad_id: string;

          imagePath: string;

          internal_code: string;

          is_on_stock: boolean;

          karat_id: string;

          lang: string;

          oldprice: number;

          on_stock_count: number;

          order_number: string;

          order_quantity_limit: number;

          point_good: boolean;

          points_amount: number;

          price_without_vat: number;

          price_without_vat_sale: number;

          priceinfo: string;

          product_id: number;

          product_name: string;

          product_note: string;

          product_type: number;

          purchaseprice: number;

          qtyDiscounts: {};

          sale: boolean;

          sale_percentage: number;

          sale_valid: string;

          stores_info: Array<{
            availability: {
              avail_id: number;

              avail_uniqid: string;

              order_available: number;

              translations: Array<{
                avail_id: number;

                avail_name: string;

                lang: string;
              }>;
            };

            quanity: number;

            store_id: string;

            store_name: string;
          }>;

          unit_price_sale: number;

          unitprice: number;

          unitprice_sale: number;

          units: string;

          valid_from: string;

          valid_to: string;

          vat_rate: number;

          warranty: string;

          warranty_cz: string;

          web_attribs: Array<{
            attrib_code: string;

            attrib_grid: number;

            attrib_id: number;

            attrib_ismulti: number;

            attrib_list: number;

            attrib_name: string;

            attrib_pbl: number;

            attrib_sort: number;

            attrib_type: string;

            attrib_uid: string;

            attrib_unit: string;

            attrib_value: string;

            custom_value: string;

            group_id: number;

            group_name: string;

            max: number;

            min: number;

            product_id: number;

            translations: Array<{
              attrib_id: number;

              attrib_name: string;

              attrib_unit: string;

              lang_id: string;
            }>;

            type_id: number;

            value_groups: Array<{
              attrib_id: number;

              group_id: number;

              group_name: string;

              group_sort: number;
            }>;

            value_id: number;

            values: Array<{
              amount: number;

              attrib_id: number;

              attrib_pict: string;

              attrib_value: string;

              group_id: number;

              istop: boolean;

              searchfilter: number;

              translations: Array<{
                attrib_url: string;

                attrib_value: string;

                lang_id: string;

                secondary_name: string;

                value_id: number;
              }>;

              value_id: number;

              value_sort: number;
            }>;
          }>;

          web_order_attribs: Array<{
            attrib_code: string;

            attrib_grid: number;

            attrib_id: number;

            attrib_ismulti: number;

            attrib_list: number;

            attrib_name: string;

            attrib_pbl: number;

            attrib_sort: number;

            attrib_type: string;

            attrib_uid: string;

            attrib_unit: string;

            attrib_value: string;

            custom_value: string;

            group_id: number;

            group_name: string;

            max: number;

            min: number;

            product_id: number;

            translations: Array<{
              attrib_id: number;

              attrib_name: string;

              attrib_unit: string;

              lang_id: string;
            }>;

            type_id: number;

            value_groups: Array<{
              attrib_id: number;

              group_id: number;

              group_name: string;

              group_sort: number;
            }>;

            value_id: number;

            values: Array<{
              amount: number;

              attrib_id: number;

              attrib_pict: string;

              attrib_value: string;

              group_id: number;

              istop: boolean;

              searchfilter: number;

              translations: Array<{
                attrib_url: string;

                attrib_value: string;

                lang_id: string;

                secondary_name: string;

                value_id: number;
              }>;

              value_id: number;

              value_sort: number;
            }>;
          }>;

          weight: number;
        };

        max_price: number;

        min_delivery_priority: number;

        min_payment_priority: number;

        min_price: number;

        name: string;

        name_url_encoded: string;

        nautilus_id: number;

        notshow: number;

        ordered_count: number;

        picture: string;

        picture_hash: string;

        picture_media_script: string;

        plu: string;

        product_fts: string;

        product_id: number;

        product_weight: number;

        rating: number;

        rating_users: number;

        slogan: string;

        type: {
          catalog_id: number;

          is_hidden: number;

          store_minqty: string;

          type_descr: string;

          type_id: number;

          type_inherits: number;

          type_name: string;
        };

        url: string;

        valid_from: string;

        valid_to: string;
      }>;

      publish: number;

      rss: boolean;

      sf_form: string;

      sf_stylesheet: string;

      sf_xmlcontent: string;

      sitemapIds: Array<number>;

      sitemap_item_id: number;

      tags: Array<{
        id: number;

        identificator: string;

        is_hidden: boolean;

        name: string;
      }>;

      url: string;

      videos: Array<{
        content_id: number;

        created_date: string;

        created_user: number;

        ordering: number;

        video_description: string;

        video_height: number;

        video_id: number;

        video_name: string;

        video_url: string;

        video_width: number;

        webshow: number;
      }>;
    };

    createdDate: string;

    dateFrom: string;

    dateTo: string;

    descrContentId: number;

    id: number;

    langId: string;

    lastUpdate: string;

    name: string;

    url: string;
  }> {
    let path = '/landing-pages';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Returns list of landing pages

    * @method
    * @name API#loadLandingPages_1
    */
  loadLandingPages_1(parameters: {
    limit?: number;
    offset?: number;
    columns?: string;
    id?: number;
    withContent?: number;
    lang: string;
  }): Promise<{
    product_materials?: Array<{
      content: {
        annot: string;

        author: {
          author_id: number;

          image: string;

          name: string;

          url: string;
        };

        created_date: string;

        created_user_id: number;

        documents: Array<{
          content_id: number;

          created_date: string;

          created_user: number;

          document_description: string;

          document_id: number;

          document_name: string;

          document_path: string;

          document_type: string;

          privatedoc: boolean;
        }>;

        downloads: Array<{
          content_id: number;

          created_date: string;

          created_user: number;

          document_description: string;

          document_id: number;

          document_name: string;

          document_path: string;

          document_type: string;

          privatedoc: boolean;
        }>;

        draft: boolean;

        hint: string;

        home: boolean;

        id: number;

        image: string;

        is_top: boolean;

        json_content: {};

        lang_id: string;

        lang_version_id: number;

        last_update: string;

        name: string;

        next_article: {
          annotation: string;

          id: string;

          image: string;

          name: string;

          url: string;
        };

        ordering: number;

        original_id: number;

        photogallery: Array<{
          content_id: number;

          created_date: string;

          created_user: number;

          document_description: string;

          document_id: number;

          document_name: string;

          document_path: string;

          document_type: string;

          privatedoc: boolean;
        }>;

        products: Array<{
          attribs: Array<{
            attrib_code: string;

            attrib_grid: number;

            attrib_id: number;

            attrib_ismulti: number;

            attrib_list: number;

            attrib_name: string;

            attrib_pbl: number;

            attrib_sort: number;

            attrib_type: string;

            attrib_uid: string;

            attrib_unit: string;

            attrib_value: string;

            custom_value: string;

            group_id: number;

            group_name: string;

            max: number;

            min: number;

            product_id: number;

            translations: Array<{
              attrib_id: number;

              attrib_name: string;

              attrib_unit: string;

              lang_id: string;
            }>;

            type_id: number;

            value_groups: Array<{
              attrib_id: number;

              group_id: number;

              group_name: string;

              group_sort: number;
            }>;

            value_id: number;

            values: Array<{
              amount: number;

              attrib_id: number;

              attrib_pict: string;

              attrib_value: string;

              group_id: number;

              istop: boolean;

              searchfilter: number;

              translations: Array<{
                attrib_url: string;

                attrib_value: string;

                lang_id: string;

                secondary_name: string;

                value_id: number;
              }>;

              value_id: number;

              value_sort: number;
            }>;
          }>;

          b2bOrderAvailable: boolean;

          b2cOrderAvailable: boolean;

          brand: {
            brand_descr: string;

            brand_id: number;

            brand_image: string;

            brand_name: string;

            brand_provider: string;

            brand_top: boolean;

            brand_weburl: string;

            catalog_id: number;

            translations: Array<{
              brand_descr: string;

              brand_id: number;

              brand_name: string;

              brand_weburl: string;

              lang_id: string;
            }>;

            url: string;
          };

          brand_id: number;

          category_text: string;

          created_at: string;

          created_datetime: string;

          description: string;

          disable_discount: boolean;

          disable_solo_order: boolean;

          ean: string;

          goods: Array<{
            availability: {
              avail_id: number;

              avail_uniqid: string;

              order_available: number;

              translations: Array<{
                avail_id: number;

                avail_name: string;

                lang: string;
              }>;
            };

            availability_date: string;

            availability_id: number;

            buy_price: number;

            currency: {
              id: string;

              name: string;
            };

            currency_id: string;

            customer_prices: {};

            discount_pcn: number;

            ean: string;

            final_price: number;

            from_group_sale: boolean;

            from_sale: boolean;

            giftcard: {
              apply_per_unit: boolean;

              card_number: string;

              card_status: string;

              club_user_id: number;

              created_date: string;

              created_user_id: number;

              date_used: string;

              date_validity: string;

              discount: number;

              discount_currency: string;

              freeshipping: boolean;

              freeshippingeu: boolean;

              id: number;

              idx: number;

              is_strict_discount: boolean;

              lang: string;

              multicard: boolean;

              order_id: number;

              price: number;

              price_currency: string;

              price_validity: number;

              sale: number;

              sale_count: number;

              valid_product_ids: Array<number>;

              valid_products_count: number;
            };

            good_id: number;

            goods_in_set: {
              good_id: number;

              package_good_id: number;

              package_name: string;

              package_price: number;

              package_quantity: number;
            };

            group_price: {
              oldprice: number;

              sale_percentage: number;

              unitprice: number;
            };

            group_prices: Array<{}>;

            idoklad_id: string;

            imagePath: string;

            internal_code: string;

            is_on_stock: boolean;

            karat_id: string;

            lang: string;

            oldprice: number;

            on_stock_count: number;

            order_number: string;

            order_quantity_limit: number;

            point_good: boolean;

            points_amount: number;

            price_without_vat: number;

            price_without_vat_sale: number;

            priceinfo: string;

            product_id: number;

            product_name: string;

            product_note: string;

            product_type: number;

            purchaseprice: number;

            qtyDiscounts: {};

            sale: boolean;

            sale_percentage: number;

            sale_valid: string;

            stores_info: Array<{
              availability: {
                avail_id: number;

                avail_uniqid: string;

                order_available: number;

                translations: Array<{
                  avail_id: number;

                  avail_name: string;

                  lang: string;
                }>;
              };

              quanity: number;

              store_id: string;

              store_name: string;
            }>;

            unit_price_sale: number;

            unitprice: number;

            unitprice_sale: number;

            units: string;

            valid_from: string;

            valid_to: string;

            vat_rate: number;

            warranty: string;

            warranty_cz: string;

            web_attribs: Array<{
              attrib_code: string;

              attrib_grid: number;

              attrib_id: number;

              attrib_ismulti: number;

              attrib_list: number;

              attrib_name: string;

              attrib_pbl: number;

              attrib_sort: number;

              attrib_type: string;

              attrib_uid: string;

              attrib_unit: string;

              attrib_value: string;

              custom_value: string;

              group_id: number;

              group_name: string;

              max: number;

              min: number;

              product_id: number;

              translations: Array<{
                attrib_id: number;

                attrib_name: string;

                attrib_unit: string;

                lang_id: string;
              }>;

              type_id: number;

              value_groups: Array<{
                attrib_id: number;

                group_id: number;

                group_name: string;

                group_sort: number;
              }>;

              value_id: number;

              values: Array<{
                amount: number;

                attrib_id: number;

                attrib_pict: string;

                attrib_value: string;

                group_id: number;

                istop: boolean;

                searchfilter: number;

                translations: Array<{
                  attrib_url: string;

                  attrib_value: string;

                  lang_id: string;

                  secondary_name: string;

                  value_id: number;
                }>;

                value_id: number;

                value_sort: number;
              }>;
            }>;

            web_order_attribs: Array<{
              attrib_code: string;

              attrib_grid: number;

              attrib_id: number;

              attrib_ismulti: number;

              attrib_list: number;

              attrib_name: string;

              attrib_pbl: number;

              attrib_sort: number;

              attrib_type: string;

              attrib_uid: string;

              attrib_unit: string;

              attrib_value: string;

              custom_value: string;

              group_id: number;

              group_name: string;

              max: number;

              min: number;

              product_id: number;

              translations: Array<{
                attrib_id: number;

                attrib_name: string;

                attrib_unit: string;

                lang_id: string;
              }>;

              type_id: number;

              value_groups: Array<{
                attrib_id: number;

                group_id: number;

                group_name: string;

                group_sort: number;
              }>;

              value_id: number;

              values: Array<{
                amount: number;

                attrib_id: number;

                attrib_pict: string;

                attrib_value: string;

                group_id: number;

                istop: boolean;

                searchfilter: number;

                translations: Array<{
                  attrib_url: string;

                  attrib_value: string;

                  lang_id: string;

                  secondary_name: string;

                  value_id: number;
                }>;

                value_id: number;

                value_sort: number;
              }>;
            }>;

            weight: number;
          }>;

          heureka: number;

          heureka_id: number;

          internal_code: number;

          is_favorite: boolean;

          is_new: boolean;

          is_popular: boolean;

          is_recommended: boolean;

          is_sale: boolean;

          isactive: boolean;

          isnew_valid: string;

          issale_buy3_pay2: number;

          issaleout: boolean;

          issaleout_since: string;

          issaleout_valid: string;

          istop: boolean;

          locked: number;

          main_good: {
            availability: {
              avail_id: number;

              avail_uniqid: string;

              order_available: number;

              translations: Array<{
                avail_id: number;

                avail_name: string;

                lang: string;
              }>;
            };

            availability_date: string;

            availability_id: number;

            buy_price: number;

            currency: {
              id: string;

              name: string;
            };

            currency_id: string;

            customer_prices: {};

            discount_pcn: number;

            ean: string;

            final_price: number;

            from_group_sale: boolean;

            from_sale: boolean;

            giftcard: {
              apply_per_unit: boolean;

              card_number: string;

              card_status: string;

              club_user_id: number;

              created_date: string;

              created_user_id: number;

              date_used: string;

              date_validity: string;

              discount: number;

              discount_currency: string;

              freeshipping: boolean;

              freeshippingeu: boolean;

              id: number;

              idx: number;

              is_strict_discount: boolean;

              lang: string;

              multicard: boolean;

              order_id: number;

              price: number;

              price_currency: string;

              price_validity: number;

              sale: number;

              sale_count: number;

              valid_product_ids: Array<number>;

              valid_products_count: number;
            };

            good_id: number;

            goods_in_set: {
              good_id: number;

              package_good_id: number;

              package_name: string;

              package_price: number;

              package_quantity: number;
            };

            group_price: {
              oldprice: number;

              sale_percentage: number;

              unitprice: number;
            };

            group_prices: Array<{}>;

            idoklad_id: string;

            imagePath: string;

            internal_code: string;

            is_on_stock: boolean;

            karat_id: string;

            lang: string;

            oldprice: number;

            on_stock_count: number;

            order_number: string;

            order_quantity_limit: number;

            point_good: boolean;

            points_amount: number;

            price_without_vat: number;

            price_without_vat_sale: number;

            priceinfo: string;

            product_id: number;

            product_name: string;

            product_note: string;

            product_type: number;

            purchaseprice: number;

            qtyDiscounts: {};

            sale: boolean;

            sale_percentage: number;

            sale_valid: string;

            stores_info: Array<{
              availability: {
                avail_id: number;

                avail_uniqid: string;

                order_available: number;

                translations: Array<{
                  avail_id: number;

                  avail_name: string;

                  lang: string;
                }>;
              };

              quanity: number;

              store_id: string;

              store_name: string;
            }>;

            unit_price_sale: number;

            unitprice: number;

            unitprice_sale: number;

            units: string;

            valid_from: string;

            valid_to: string;

            vat_rate: number;

            warranty: string;

            warranty_cz: string;

            web_attribs: Array<{
              attrib_code: string;

              attrib_grid: number;

              attrib_id: number;

              attrib_ismulti: number;

              attrib_list: number;

              attrib_name: string;

              attrib_pbl: number;

              attrib_sort: number;

              attrib_type: string;

              attrib_uid: string;

              attrib_unit: string;

              attrib_value: string;

              custom_value: string;

              group_id: number;

              group_name: string;

              max: number;

              min: number;

              product_id: number;

              translations: Array<{
                attrib_id: number;

                attrib_name: string;

                attrib_unit: string;

                lang_id: string;
              }>;

              type_id: number;

              value_groups: Array<{
                attrib_id: number;

                group_id: number;

                group_name: string;

                group_sort: number;
              }>;

              value_id: number;

              values: Array<{
                amount: number;

                attrib_id: number;

                attrib_pict: string;

                attrib_value: string;

                group_id: number;

                istop: boolean;

                searchfilter: number;

                translations: Array<{
                  attrib_url: string;

                  attrib_value: string;

                  lang_id: string;

                  secondary_name: string;

                  value_id: number;
                }>;

                value_id: number;

                value_sort: number;
              }>;
            }>;

            web_order_attribs: Array<{
              attrib_code: string;

              attrib_grid: number;

              attrib_id: number;

              attrib_ismulti: number;

              attrib_list: number;

              attrib_name: string;

              attrib_pbl: number;

              attrib_sort: number;

              attrib_type: string;

              attrib_uid: string;

              attrib_unit: string;

              attrib_value: string;

              custom_value: string;

              group_id: number;

              group_name: string;

              max: number;

              min: number;

              product_id: number;

              translations: Array<{
                attrib_id: number;

                attrib_name: string;

                attrib_unit: string;

                lang_id: string;
              }>;

              type_id: number;

              value_groups: Array<{
                attrib_id: number;

                group_id: number;

                group_name: string;

                group_sort: number;
              }>;

              value_id: number;

              values: Array<{
                amount: number;

                attrib_id: number;

                attrib_pict: string;

                attrib_value: string;

                group_id: number;

                istop: boolean;

                searchfilter: number;

                translations: Array<{
                  attrib_url: string;

                  attrib_value: string;

                  lang_id: string;

                  secondary_name: string;

                  value_id: number;
                }>;

                value_id: number;

                value_sort: number;
              }>;
            }>;

            weight: number;
          };

          max_price: number;

          min_delivery_priority: number;

          min_payment_priority: number;

          min_price: number;

          name: string;

          name_url_encoded: string;

          nautilus_id: number;

          notshow: number;

          ordered_count: number;

          picture: string;

          picture_hash: string;

          picture_media_script: string;

          plu: string;

          product_fts: string;

          product_id: number;

          product_weight: number;

          rating: number;

          rating_users: number;

          slogan: string;

          type: {
            catalog_id: number;

            is_hidden: number;

            store_minqty: string;

            type_descr: string;

            type_id: number;

            type_inherits: number;

            type_name: string;
          };

          url: string;

          valid_from: string;

          valid_to: string;
        }>;

        publish: number;

        rss: boolean;

        sf_form: string;

        sf_stylesheet: string;

        sf_xmlcontent: string;

        sitemapIds: Array<number>;

        sitemap_item_id: number;

        tags: Array<{
          id: number;

          identificator: string;

          is_hidden: boolean;

          name: string;
        }>;

        url: string;

        videos: Array<{
          content_id: number;

          created_date: string;

          created_user: number;

          ordering: number;

          video_description: string;

          video_height: number;

          video_id: number;

          video_name: string;

          video_url: string;

          video_width: number;

          webshow: number;
        }>;
      };

      createdDate: string;

      dateFrom: string;

      dateTo: string;

      descrContentId: number;

      id: number;

      langId: string;

      lastUpdate: string;

      name: string;

      url: string;
    }>;
  }> {
    let path = '/admin/landing-pages';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['columns'] !== 'undefined') {
      queryParameters['columns'] = parameters['columns'];
    }

    queryParameters['columns'] = this.transformParameter(
      queryParameters['columns'],
    );

    if (typeof parameters['id'] !== 'undefined') {
      queryParameters['id'] = parameters['id'];
    }

    queryParameters['id'] = this.transformParameter(queryParameters['id']);

    if (typeof parameters['withContent'] !== 'undefined') {
      queryParameters['with_content'] = parameters['withContent'];
    }

    queryParameters['with_content'] = this.transformParameter(
      queryParameters['with_content'],
    );

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    if (typeof parameters['lang'] === 'undefined') {
      throw new Error('Missing required parameter: lang');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Deletes landing page

    * @method
    * @name API#deleteLandingPage
    */
  deleteLandingPage(id: number, parameters: {} = {}): Promise<{}> {
    let path = '/landing-pages/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Returns list of domain

    * @method
    * @name API#loadCatalogDomains
    */
  loadCatalogDomains(
    parameters: {
      limit?: number;
      offset?: number;
      columns?: string;
      id?: number;
      q?: string;
    } = {},
  ): Promise<{
    product_materials?: Array<{
      content: {
        annot: string;

        author: {
          author_id: number;

          image: string;

          name: string;

          url: string;
        };

        created_date: string;

        created_user_id: number;

        documents: Array<{
          content_id: number;

          created_date: string;

          created_user: number;

          document_description: string;

          document_id: number;

          document_name: string;

          document_path: string;

          document_type: string;

          privatedoc: boolean;
        }>;

        downloads: Array<{
          content_id: number;

          created_date: string;

          created_user: number;

          document_description: string;

          document_id: number;

          document_name: string;

          document_path: string;

          document_type: string;

          privatedoc: boolean;
        }>;

        draft: boolean;

        hint: string;

        home: boolean;

        id: number;

        image: string;

        is_top: boolean;

        json_content: {};

        lang_id: string;

        lang_version_id: number;

        last_update: string;

        name: string;

        next_article: {
          annotation: string;

          id: string;

          image: string;

          name: string;

          url: string;
        };

        ordering: number;

        original_id: number;

        photogallery: Array<{
          content_id: number;

          created_date: string;

          created_user: number;

          document_description: string;

          document_id: number;

          document_name: string;

          document_path: string;

          document_type: string;

          privatedoc: boolean;
        }>;

        products: Array<{
          attribs: Array<{
            attrib_code: string;

            attrib_grid: number;

            attrib_id: number;

            attrib_ismulti: number;

            attrib_list: number;

            attrib_name: string;

            attrib_pbl: number;

            attrib_sort: number;

            attrib_type: string;

            attrib_uid: string;

            attrib_unit: string;

            attrib_value: string;

            custom_value: string;

            group_id: number;

            group_name: string;

            max: number;

            min: number;

            product_id: number;

            translations: Array<{
              attrib_id: number;

              attrib_name: string;

              attrib_unit: string;

              lang_id: string;
            }>;

            type_id: number;

            value_groups: Array<{
              attrib_id: number;

              group_id: number;

              group_name: string;

              group_sort: number;
            }>;

            value_id: number;

            values: Array<{
              amount: number;

              attrib_id: number;

              attrib_pict: string;

              attrib_value: string;

              group_id: number;

              istop: boolean;

              searchfilter: number;

              translations: Array<{
                attrib_url: string;

                attrib_value: string;

                lang_id: string;

                secondary_name: string;

                value_id: number;
              }>;

              value_id: number;

              value_sort: number;
            }>;
          }>;

          b2bOrderAvailable: boolean;

          b2cOrderAvailable: boolean;

          brand: {
            brand_descr: string;

            brand_id: number;

            brand_image: string;

            brand_name: string;

            brand_provider: string;

            brand_top: boolean;

            brand_weburl: string;

            catalog_id: number;

            translations: Array<{
              brand_descr: string;

              brand_id: number;

              brand_name: string;

              brand_weburl: string;

              lang_id: string;
            }>;

            url: string;
          };

          brand_id: number;

          category_text: string;

          created_at: string;

          created_datetime: string;

          description: string;

          disable_discount: boolean;

          disable_solo_order: boolean;

          ean: string;

          goods: Array<{
            availability: {
              avail_id: number;

              avail_uniqid: string;

              order_available: number;

              translations: Array<{
                avail_id: number;

                avail_name: string;

                lang: string;
              }>;
            };

            availability_date: string;

            availability_id: number;

            buy_price: number;

            currency: {
              id: string;

              name: string;
            };

            currency_id: string;

            customer_prices: {};

            discount_pcn: number;

            ean: string;

            final_price: number;

            from_group_sale: boolean;

            from_sale: boolean;

            giftcard: {
              apply_per_unit: boolean;

              card_number: string;

              card_status: string;

              club_user_id: number;

              created_date: string;

              created_user_id: number;

              date_used: string;

              date_validity: string;

              discount: number;

              discount_currency: string;

              freeshipping: boolean;

              freeshippingeu: boolean;

              id: number;

              idx: number;

              is_strict_discount: boolean;

              lang: string;

              multicard: boolean;

              order_id: number;

              price: number;

              price_currency: string;

              price_validity: number;

              sale: number;

              sale_count: number;

              valid_product_ids: Array<number>;

              valid_products_count: number;
            };

            good_id: number;

            goods_in_set: {
              good_id: number;

              package_good_id: number;

              package_name: string;

              package_price: number;

              package_quantity: number;
            };

            group_price: {
              oldprice: number;

              sale_percentage: number;

              unitprice: number;
            };

            group_prices: Array<{}>;

            idoklad_id: string;

            imagePath: string;

            internal_code: string;

            is_on_stock: boolean;

            karat_id: string;

            lang: string;

            oldprice: number;

            on_stock_count: number;

            order_number: string;

            order_quantity_limit: number;

            point_good: boolean;

            points_amount: number;

            price_without_vat: number;

            price_without_vat_sale: number;

            priceinfo: string;

            product_id: number;

            product_name: string;

            product_note: string;

            product_type: number;

            purchaseprice: number;

            qtyDiscounts: {};

            sale: boolean;

            sale_percentage: number;

            sale_valid: string;

            stores_info: Array<{
              availability: {
                avail_id: number;

                avail_uniqid: string;

                order_available: number;

                translations: Array<{
                  avail_id: number;

                  avail_name: string;

                  lang: string;
                }>;
              };

              quanity: number;

              store_id: string;

              store_name: string;
            }>;

            unit_price_sale: number;

            unitprice: number;

            unitprice_sale: number;

            units: string;

            valid_from: string;

            valid_to: string;

            vat_rate: number;

            warranty: string;

            warranty_cz: string;

            web_attribs: Array<{
              attrib_code: string;

              attrib_grid: number;

              attrib_id: number;

              attrib_ismulti: number;

              attrib_list: number;

              attrib_name: string;

              attrib_pbl: number;

              attrib_sort: number;

              attrib_type: string;

              attrib_uid: string;

              attrib_unit: string;

              attrib_value: string;

              custom_value: string;

              group_id: number;

              group_name: string;

              max: number;

              min: number;

              product_id: number;

              translations: Array<{
                attrib_id: number;

                attrib_name: string;

                attrib_unit: string;

                lang_id: string;
              }>;

              type_id: number;

              value_groups: Array<{
                attrib_id: number;

                group_id: number;

                group_name: string;

                group_sort: number;
              }>;

              value_id: number;

              values: Array<{
                amount: number;

                attrib_id: number;

                attrib_pict: string;

                attrib_value: string;

                group_id: number;

                istop: boolean;

                searchfilter: number;

                translations: Array<{
                  attrib_url: string;

                  attrib_value: string;

                  lang_id: string;

                  secondary_name: string;

                  value_id: number;
                }>;

                value_id: number;

                value_sort: number;
              }>;
            }>;

            web_order_attribs: Array<{
              attrib_code: string;

              attrib_grid: number;

              attrib_id: number;

              attrib_ismulti: number;

              attrib_list: number;

              attrib_name: string;

              attrib_pbl: number;

              attrib_sort: number;

              attrib_type: string;

              attrib_uid: string;

              attrib_unit: string;

              attrib_value: string;

              custom_value: string;

              group_id: number;

              group_name: string;

              max: number;

              min: number;

              product_id: number;

              translations: Array<{
                attrib_id: number;

                attrib_name: string;

                attrib_unit: string;

                lang_id: string;
              }>;

              type_id: number;

              value_groups: Array<{
                attrib_id: number;

                group_id: number;

                group_name: string;

                group_sort: number;
              }>;

              value_id: number;

              values: Array<{
                amount: number;

                attrib_id: number;

                attrib_pict: string;

                attrib_value: string;

                group_id: number;

                istop: boolean;

                searchfilter: number;

                translations: Array<{
                  attrib_url: string;

                  attrib_value: string;

                  lang_id: string;

                  secondary_name: string;

                  value_id: number;
                }>;

                value_id: number;

                value_sort: number;
              }>;
            }>;

            weight: number;
          }>;

          heureka: number;

          heureka_id: number;

          internal_code: number;

          is_favorite: boolean;

          is_new: boolean;

          is_popular: boolean;

          is_recommended: boolean;

          is_sale: boolean;

          isactive: boolean;

          isnew_valid: string;

          issale_buy3_pay2: number;

          issaleout: boolean;

          issaleout_since: string;

          issaleout_valid: string;

          istop: boolean;

          locked: number;

          main_good: {
            availability: {
              avail_id: number;

              avail_uniqid: string;

              order_available: number;

              translations: Array<{
                avail_id: number;

                avail_name: string;

                lang: string;
              }>;
            };

            availability_date: string;

            availability_id: number;

            buy_price: number;

            currency: {
              id: string;

              name: string;
            };

            currency_id: string;

            customer_prices: {};

            discount_pcn: number;

            ean: string;

            final_price: number;

            from_group_sale: boolean;

            from_sale: boolean;

            giftcard: {
              apply_per_unit: boolean;

              card_number: string;

              card_status: string;

              club_user_id: number;

              created_date: string;

              created_user_id: number;

              date_used: string;

              date_validity: string;

              discount: number;

              discount_currency: string;

              freeshipping: boolean;

              freeshippingeu: boolean;

              id: number;

              idx: number;

              is_strict_discount: boolean;

              lang: string;

              multicard: boolean;

              order_id: number;

              price: number;

              price_currency: string;

              price_validity: number;

              sale: number;

              sale_count: number;

              valid_product_ids: Array<number>;

              valid_products_count: number;
            };

            good_id: number;

            goods_in_set: {
              good_id: number;

              package_good_id: number;

              package_name: string;

              package_price: number;

              package_quantity: number;
            };

            group_price: {
              oldprice: number;

              sale_percentage: number;

              unitprice: number;
            };

            group_prices: Array<{}>;

            idoklad_id: string;

            imagePath: string;

            internal_code: string;

            is_on_stock: boolean;

            karat_id: string;

            lang: string;

            oldprice: number;

            on_stock_count: number;

            order_number: string;

            order_quantity_limit: number;

            point_good: boolean;

            points_amount: number;

            price_without_vat: number;

            price_without_vat_sale: number;

            priceinfo: string;

            product_id: number;

            product_name: string;

            product_note: string;

            product_type: number;

            purchaseprice: number;

            qtyDiscounts: {};

            sale: boolean;

            sale_percentage: number;

            sale_valid: string;

            stores_info: Array<{
              availability: {
                avail_id: number;

                avail_uniqid: string;

                order_available: number;

                translations: Array<{
                  avail_id: number;

                  avail_name: string;

                  lang: string;
                }>;
              };

              quanity: number;

              store_id: string;

              store_name: string;
            }>;

            unit_price_sale: number;

            unitprice: number;

            unitprice_sale: number;

            units: string;

            valid_from: string;

            valid_to: string;

            vat_rate: number;

            warranty: string;

            warranty_cz: string;

            web_attribs: Array<{
              attrib_code: string;

              attrib_grid: number;

              attrib_id: number;

              attrib_ismulti: number;

              attrib_list: number;

              attrib_name: string;

              attrib_pbl: number;

              attrib_sort: number;

              attrib_type: string;

              attrib_uid: string;

              attrib_unit: string;

              attrib_value: string;

              custom_value: string;

              group_id: number;

              group_name: string;

              max: number;

              min: number;

              product_id: number;

              translations: Array<{
                attrib_id: number;

                attrib_name: string;

                attrib_unit: string;

                lang_id: string;
              }>;

              type_id: number;

              value_groups: Array<{
                attrib_id: number;

                group_id: number;

                group_name: string;

                group_sort: number;
              }>;

              value_id: number;

              values: Array<{
                amount: number;

                attrib_id: number;

                attrib_pict: string;

                attrib_value: string;

                group_id: number;

                istop: boolean;

                searchfilter: number;

                translations: Array<{
                  attrib_url: string;

                  attrib_value: string;

                  lang_id: string;

                  secondary_name: string;

                  value_id: number;
                }>;

                value_id: number;

                value_sort: number;
              }>;
            }>;

            web_order_attribs: Array<{
              attrib_code: string;

              attrib_grid: number;

              attrib_id: number;

              attrib_ismulti: number;

              attrib_list: number;

              attrib_name: string;

              attrib_pbl: number;

              attrib_sort: number;

              attrib_type: string;

              attrib_uid: string;

              attrib_unit: string;

              attrib_value: string;

              custom_value: string;

              group_id: number;

              group_name: string;

              max: number;

              min: number;

              product_id: number;

              translations: Array<{
                attrib_id: number;

                attrib_name: string;

                attrib_unit: string;

                lang_id: string;
              }>;

              type_id: number;

              value_groups: Array<{
                attrib_id: number;

                group_id: number;

                group_name: string;

                group_sort: number;
              }>;

              value_id: number;

              values: Array<{
                amount: number;

                attrib_id: number;

                attrib_pict: string;

                attrib_value: string;

                group_id: number;

                istop: boolean;

                searchfilter: number;

                translations: Array<{
                  attrib_url: string;

                  attrib_value: string;

                  lang_id: string;

                  secondary_name: string;

                  value_id: number;
                }>;

                value_id: number;

                value_sort: number;
              }>;
            }>;

            weight: number;
          };

          max_price: number;

          min_delivery_priority: number;

          min_payment_priority: number;

          min_price: number;

          name: string;

          name_url_encoded: string;

          nautilus_id: number;

          notshow: number;

          ordered_count: number;

          picture: string;

          picture_hash: string;

          picture_media_script: string;

          plu: string;

          product_fts: string;

          product_id: number;

          product_weight: number;

          rating: number;

          rating_users: number;

          slogan: string;

          type: {
            catalog_id: number;

            is_hidden: number;

            store_minqty: string;

            type_descr: string;

            type_id: number;

            type_inherits: number;

            type_name: string;
          };

          url: string;

          valid_from: string;

          valid_to: string;
        }>;

        publish: number;

        rss: boolean;

        sf_form: string;

        sf_stylesheet: string;

        sf_xmlcontent: string;

        sitemapIds: Array<number>;

        sitemap_item_id: number;

        tags: Array<{
          id: number;

          identificator: string;

          is_hidden: boolean;

          name: string;
        }>;

        url: string;

        videos: Array<{
          content_id: number;

          created_date: string;

          created_user: number;

          ordering: number;

          video_description: string;

          video_height: number;

          video_id: number;

          video_name: string;

          video_url: string;

          video_width: number;

          webshow: number;
        }>;
      };

      createdDate: string;

      dateFrom: string;

      dateTo: string;

      descrContentId: number;

      id: number;

      langId: string;

      lastUpdate: string;

      name: string;

      url: string;
    }>;
  }> {
    let path = '/domains';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['columns'] !== 'undefined') {
      queryParameters['columns'] = parameters['columns'];
    }

    queryParameters['columns'] = this.transformParameter(
      queryParameters['columns'],
    );

    if (typeof parameters['id'] !== 'undefined') {
      queryParameters['id'] = parameters['id'];
    }

    queryParameters['id'] = this.transformParameter(queryParameters['id']);

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Creates or update domain

    * @method
    * @name API#upsertCatalogDomain
    */
  upsertCatalogDomain(
    parameters: {} = {},
    body: {
      id: number;

      domainName: string;

      brands?: Array<string>;
    },
  ): Promise<{}> {
    let path = '/domains';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Shows information about custom urls
   * @method
   * @name API#getUrlMap
   */
  getUrlMap(
    parameters: {
      domainId?: string;
      url: string;
      noTag?: '0' | '1';
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    type: string;

    data: {
      brand_id?: number;

      product_id?: number;

      category_id?: number;

      sitemap_id?: number;

      content_id?: number;

      new_url?: string;
    };
  }> {
    let path = '/url-map';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['domainId'] !== 'undefined') {
      queryParameters['domain_id'] = parameters['domainId'];
    }

    queryParameters['domain_id'] = this.transformParameter(
      queryParameters['domain_id'],
    );

    if (typeof parameters['url'] !== 'undefined') {
      queryParameters['url'] = parameters['url'];
    }

    queryParameters['url'] = this.transformParameter(queryParameters['url']);

    if (typeof parameters['url'] === 'undefined') {
      throw new Error('Missing required parameter: url');
    }

    queryParameters['no_tag'] = '0';

    if (typeof parameters['noTag'] !== 'undefined') {
      queryParameters['no_tag'] = parameters['noTag'];
    }

    queryParameters['no_tag'] = this.transformParameter(
      queryParameters['no_tag'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Shows information about custom urls
   * @method
   * @name API#pairingImages
   */
  pairingImages(parameters: {} = {}): Promise<{
    type: string;

    data: {
      brand_id?: number;

      product_id?: number;

      category_id?: number;

      sitemap_id?: number;

      content_id?: number;

      new_url?: string;
    };
  }> {
    let path = '/pair-images';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Loads info about URL redirection. If NOT 404 -> than redirects URL
   * @method
   * @name API#checkRedirect
   */
  checkRedirect(parameters: { url: string }): Promise<{
    createdDatetime?: string;

    id?: number;

    lang?: string;

    newUrl?: string;

    oldUrl?: string;

    redirectCode?: number;
  }> {
    let path = '/redirects';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['url'] !== 'undefined') {
      queryParameters['url'] = parameters['url'];
    }

    queryParameters['url'] = this.transformParameter(queryParameters['url']);

    if (typeof parameters['url'] === 'undefined') {
      throw new Error('Missing required parameter: url');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns sets that contain this product
   * @method
   * @name API#loadsProductsSets
   */
  loadsProductsSets(
    productId: number,
    parameters: {} = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
      xCurrency?: string;
    },
  ): Promise<{
    type: string;

    data: {
      brand_id?: number;

      product_id?: number;

      category_id?: number;

      sitemap_id?: number;

      content_id?: number;

      new_url?: string;
    };
  }> {
    let path = '/products/{product_id}/sets';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (extraHeaders && typeof extraHeaders['xCurrency'] !== 'undefined') {
      headers.append('X-Currency', extraHeaders['xCurrency']!);
    }

    path = path.replace('{product_id}', productId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Load orders that are has status INPROGRESS and are not older than 14 days
   * @method
   * @name API#loadOrders
   */
  loadOrders(parameters: {} = {}): Promise<
    Array<{
      order_id: number;

      weight?: number;

      carrier:
        | 'cpost'
        | 'ppl'
        | 'dpd'
        | 'geis'
        | 'gibbon'
        | 'gls'
        | 'ulozenka'
        | 'ups'
        | 'zasilkovna'
        | 'intime'
        | 'toptrans'
        | 'dhl-sk'
        | 'sk-post'
        | 'personal - osobní odběr';

      carrier_product: string;

      carrier_branch_id?: string;

      priority?: number;

      recipient_name: string;

      recipient_contact: string;

      recipient_street: string;

      recipient_city: string;

      recipient_zip: string;

      recipient_country_code: string;

      recipient_phone: string;

      recipient_email: string;

      recipient_state?: string;

      ic?: string;

      dic?: string;

      note?: string;

      cod_amount: number;

      cod_currency: string;

      cod_vs: string;

      cod_card_payment: boolean;

      ins_amount?: number;

      ins_currency?: string;

      date_source: string;

      barcode: string;

      label: string;

      ref: string;

      attachments?: Array<{
        name?: string;

        href: string;
      }>;

      products: Array<{
        item_id: number;

        sku: string;

        ean: string;

        name: string;

        qty: number;

        image_url: string;

        weight: number;

        location?: string;

        note?: string;

        unit: string;
      }>;
    }>
  > {
    let path = '/touchstore/orders';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Touchstore via this endpoint notifies system about orders that was shipped recently.
   * @method
   * @name API#sendConsignments
   */
  sendConsignments(
    parameters: {} = {},
    body: {
      list_id: number;

      carrier: string;

      consignments: Array<{
        order_id: string;

        product: string;

        track_ids: Array<string>;

        email: string;

        phone: string;

        branch_id: string;

        ref: string;

        label: string;

        barcode: string;
      }>;
    },
  ): Promise<{
    message: string;
  }> {
    let path = '/touchstore/send';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Receive error notification about order from Touchstore
   * @method
   * @name API#noticeConsignmentError
   */
  noticeConsignmentError(
    parameters: {} = {},
    body: {
      order_id: string;

      error_id: string;

      error_name: string;

      error_note: string;
    },
  ): Promise<{
    message: string;
  }> {
    let path = '/touchstore/error';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Loads Touchstore consignment by its id
   * @method
   * @name API#loadTouchstoreConsignment
   */
  loadTouchstoreConsignment(
    publicOrderId: string,
    parameters: {} = {},
  ): Promise<{
    id: number;

    list_id: number;

    carrier: string;

    product: string;

    email: string;

    phone: string;

    branch_id: string;

    ref: string;

    label: string;

    barcode: string;

    track_ids: Array<string>;

    error_id: string;

    error_name: string;

    error_note: string;
  }> {
    let path = '/touchstore/touchstore-consignment/{public_order_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{public_order_id}', publicOrderId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Returns list of product materials groups

    * @method
    * @name API#loadProductMaterialsGroups
    */
  loadProductMaterialsGroups(
    parameters: {
      limit?: number;
      offset?: number;
      columns?: string;
      id?: number;
    } = {},
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    product_materials_groups?: Array<{
      description: string;

      id: number;

      lastUpdate: string;

      name: string;

      lang?: string;
    }>;
  }> {
    let path = '/product-materials-groups';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['columns'] !== 'undefined') {
      queryParameters['columns'] = parameters['columns'];
    }

    queryParameters['columns'] = this.transformParameter(
      queryParameters['columns'],
    );

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['id'] !== 'undefined') {
      queryParameters['id'] = parameters['id'];
    }

    queryParameters['id'] = this.transformParameter(queryParameters['id']);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Returns list of product materials groups

    * @method
    * @name API#upsertProductMaterialsGroups
    */
  upsertProductMaterialsGroups(
    parameters: {
      limit?: number;
      offset?: number;
      columns?: string;
    } = {},
    body: {
      description: string;

      id: number;

      lastUpdate: string;

      name: string;

      lang?: string;
    },
  ): Promise<{
    product_materials_groups?: Array<{
      description: string;

      id: number;

      lastUpdate: string;

      name: string;

      lang?: string;
    }>;
  }> {
    let path = '/product-materials-groups';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['columns'] !== 'undefined') {
      queryParameters['columns'] = parameters['columns'];
    }

    queryParameters['columns'] = this.transformParameter(
      queryParameters['columns'],
    );

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Returns list of product materials groups

    * @method
    * @name API#deleteProductMaterialsGroups
    */
  deleteProductMaterialsGroups(
    id: number,
    parameters: {} = {},
  ): Promise<{
    product_materials_groups?: Array<{
      description: string;

      id: number;

      lastUpdate: string;

      name: string;

      lang?: string;
    }>;
  }> {
    let path = '/product-materials-groups/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Returns list of product materials

    * @method
    * @name API#loadProductMaterials
    */
  loadProductMaterials(
    parameters: {
      limit?: number;
      offset?: number;
      columns?: string;
      id?: number;
      query?: string;
      withAllTranslations?: '0' | '1';
    } = {},
    extraHeaders?: {
      xCurrency?: string;
    },
  ): Promise<{
    product_materials?: Array<{
      brandId: number;

      groupId: number;

      id: number;

      lastUpdate: string;

      name: string;

      priceGroup: number;

      productPartType: number;

      translations?: {};
    }>;
  }> {
    let path = '/product-materials';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['columns'] !== 'undefined') {
      queryParameters['columns'] = parameters['columns'];
    }

    queryParameters['columns'] = this.transformParameter(
      queryParameters['columns'],
    );

    if (extraHeaders && typeof extraHeaders['xCurrency'] !== 'undefined') {
      headers.append('X-Currency', extraHeaders['xCurrency']!);
    }

    if (typeof parameters['id'] !== 'undefined') {
      queryParameters['id'] = parameters['id'];
    }

    queryParameters['id'] = this.transformParameter(queryParameters['id']);

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    queryParameters['with_all_translations'] = '1';

    if (typeof parameters['withAllTranslations'] !== 'undefined') {
      queryParameters['with_all_translations'] =
        parameters['withAllTranslations'];
    }

    queryParameters['with_all_translations'] = this.transformParameter(
      queryParameters['with_all_translations'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Returns list of product materials groups

    * @method
    * @name API#upsertProductMaterial
    */
  upsertProductMaterial(
    parameters: {} = {},
    body: {
      brandId: number;

      groupId: number;

      id: number;

      lastUpdate: string;

      name: string;

      priceGroup: number;

      productPartType: number;

      translations?: {};
    },
  ): Promise<{}> {
    let path = '/product-materials';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Deletes product material

    * @method
    * @name API#deleteProductMaterial
    */
  deleteProductMaterial(id: number, parameters: {} = {}): Promise<{}> {
    let path = '/product-materials/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Returns list of product parts

    * @method
    * @name API#loadProductParts
    */
  loadProductParts(
    parameters: {
      limit?: number;
      offset?: number;
      columns?: string;
      id?: number;
      brandId?: number;
    } = {},
  ): Promise<{
    product_materials?: Array<{
      affectPrice: boolean;

      brandIds: Array<number>;

      id: number;

      isActive: boolean;

      isCompact: boolean;

      name: string;

      productIds: Array<number>;

      productPartType: number;

      productType: number;

      products: Array<{
        id: number;

        name: string;
      }>;
    }>;
  }> {
    let path = '/product-parts';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['columns'] !== 'undefined') {
      queryParameters['columns'] = parameters['columns'];
    }

    queryParameters['columns'] = this.transformParameter(
      queryParameters['columns'],
    );

    if (typeof parameters['id'] !== 'undefined') {
      queryParameters['id'] = parameters['id'];
    }

    queryParameters['id'] = this.transformParameter(queryParameters['id']);

    if (typeof parameters['brandId'] !== 'undefined') {
      queryParameters['brand_id'] = parameters['brandId'];
    }

    queryParameters['brand_id'] = this.transformParameter(
      queryParameters['brand_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Creates or update product part

    * @method
    * @name API#upsertProductPart
    */
  upsertProductPart(
    parameters: {} = {},
    body: {
      affectPrice: boolean;

      brandIds: Array<number>;

      id: number;

      isActive: boolean;

      isCompact: boolean;

      name: string;

      productIds: Array<number>;

      productPartType: number;

      productType: number;

      products?: Array<{
        id: number;

        name: string;
      }>;
    },
  ): Promise<{}> {
    let path = '/product-parts';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Returns list of product part types

    * @method
    * @name API#loadProductPartTypes
    */
  loadProductPartTypes(parameters: {} = {}): Promise<{}> {
    let path = '/product-part/types';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Deletes product part

    * @method
    * @name API#deleteProductPart
    */
  deleteProductPart(id: number, parameters: {} = {}): Promise<{}> {
    let path = '/product-parts/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Returns list of product parts

    * @method
    * @name API#bulkBrandProductParts
    */
  bulkBrandProductParts(
    brandId: number,
    parameters: {} = {},
    body: {},
  ): Promise<{
    product_materials?: Array<{
      affectPrice: boolean;

      brandIds: Array<number>;

      id: number;

      isActive: boolean;

      isCompact: boolean;

      name: string;

      productIds: Array<number>;

      productPartType: number;

      productType: number;

      products: Array<{
        id: number;

        name: string;
      }>;
    }>;
  }> {
    let path = '/brands/{brand_id}/product-parts/bulk';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{brand_id}', brandId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Returns list of product materials

    * @method
    * @name API#loadProductConfiguraion
    */
  loadProductConfiguraion(
    goodId: number,
    productId: number,
    parameters: {
      langId?: string;
    } = {},
  ): Promise<{
    product_materials?: Array<{
      brandId: number;

      groupId: number;

      id: number;

      lastUpdate: string;

      name: string;

      priceGroup: number;

      productPartType: number;

      translations?: {};
    }>;
  }> {
    let path = '/products/{product_id}/goods/{good_id}/configurator';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{good_id}', goodId.toString());

    path = path.replace('{product_id}', productId.toString());

    queryParameters['lang_id'] = 'sk';

    if (typeof parameters['langId'] !== 'undefined') {
      queryParameters['lang_id'] = parameters['langId'];
    }

    queryParameters['lang_id'] = this.transformParameter(
      queryParameters['lang_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Returns price for concrete product configuration

    * @method
    * @name API#loadProductConfiguraionPrice
    */
  loadProductConfiguraionPrice(
    goodId: number,
    parameters: {
      langId?: string;
    } = {},
    body: {},
  ): Promise<{
    price?: number;
  }> {
    let path = '/product/goods/{good_id}/configurator/price';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{good_id}', goodId.toString());

    queryParameters['lang_id'] = 'sk';

    if (typeof parameters['langId'] !== 'undefined') {
      queryParameters['lang_id'] = parameters['langId'];
    }

    queryParameters['lang_id'] = this.transformParameter(
      queryParameters['lang_id'],
    );

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Returns price for concrete product configuration

    * @method
    * @name API#loadGoodPriceGroups
    */
  loadGoodPriceGroups(
    parameters: {
      goodId?: number;
    } = {},
  ): Promise<{
    product_goods_price_groups?: Array<{
      goodId: number;

      id: number;

      price: number;

      priceCurrency: number;

      priceGroupId: number;

      priceWithoutVat: number;

      vat: number;
    }>;
  }> {
    let path = '/product/goods/price-groups';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['goodId'] !== 'undefined') {
      queryParameters['good_id'] = parameters['goodId'];
    }

    queryParameters['good_id'] = this.transformParameter(
      queryParameters['good_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * **Authors**: Laci Perduk <perduk@bart.sk>

**Main usage**: Returns price for concrete product configuration

    * @method
    * @name API#bulkProductGoodPriceGroups
    */
  bulkProductGoodPriceGroups(
    parameters: {} = {},
    body: {
      product_goods_price_groups?: Array<{
        goodId: number;

        id: number;

        price: number;

        priceCurrency: number;

        priceGroupId: number;

        priceWithoutVat: number;

        vat: number;
      }>;
    },
  ): Promise<{
    product_goods_price_groups?: Array<{
      goodId: number;

      id: number;

      price: number;

      priceCurrency: number;

      priceGroupId: number;

      priceWithoutVat: number;

      vat: number;
    }>;
  }> {
    let path = '/product/goods/price-groups/bulk';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Returns array of system tasks
   * @method
   * @name API#loadSystemTasks
   */
  loadSystemTasks(
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    items?: Array<{
      id?: number;

      name?: string;

      script_path?: string;

      state?: string;

      created_at?: string;

      started_at?: string;

      finished_at?: string;

      error_message?: string;

      auto_finish?: number;

      auto_start?: number;
    }>;

    total?: number;

    offset?: number;

    limit?: number;
  }> {
    let path = '/system-tasks';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create new system task
   * @method
   * @name API#createSystemTask
   */
  createSystemTask(
    parameters: {} = {},
    body: {
      name: string;

      script_path: string;

      auto_finish?: boolean;

      auto_start?: boolean;
    },
  ): Promise<{
    status?: string;
  }> {
    let path = '/system-tasks';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Load equivalent urls from other language versions
   * @method
   * @name API#loadUrlTranslations
   */
  loadUrlTranslations(
    parameters: {
      url: string;
      type: 'product' | 'category' | 'sitemap';
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{
    url_translations: Array<{
      lang_id: string;

      url: string;
    }>;
  }> {
    let path = '/url-translations';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    if (typeof parameters['url'] !== 'undefined') {
      queryParameters['url'] = parameters['url'];
    }

    queryParameters['url'] = this.transformParameter(queryParameters['url']);

    if (typeof parameters['url'] === 'undefined') {
      throw new Error('Missing required parameter: url');
    }

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['type'] === 'undefined') {
      throw new Error('Missing required parameter: type');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#openapiJson
   */
  openapiJson(parameters: {} = {}): Promise<{}> {
    let path = '/openapi.json';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#test
   */
  test(
    parameters: {
      email?: number;
    } = {},
  ): Promise<{}> {
    let path = '/test';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['email'] !== 'undefined') {
      queryParameters['email'] = parameters['email'];
    }

    queryParameters['email'] = this.transformParameter(
      queryParameters['email'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#testNew_1
   */
  testNew_1(
    parameters: {
      email?: number;
    } = {},
  ): Promise<{}> {
    let path = '/test-new';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['email'] !== 'undefined') {
      queryParameters['email'] = parameters['email'];
    }

    queryParameters['email'] = this.transformParameter(
      queryParameters['email'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#testError
   */
  testError(parameters: {} = {}): Promise<{}> {
    let path = '/test/error';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Submits contact form
   * @method
   * @name API#contactForm
   */
  contactForm(
    parameters: {} = {},
    body: {
      email?: string;

      message?: string;

      name?: string;

      token?: string;
    },
    extraHeaders?: {
      xAcceptLanguage?: string;
    },
  ): Promise<{}> {
    let path = '/contact-form';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xAcceptLanguage'] !== 'undefined'
    ) {
      headers.append('X-Accept-Language', extraHeaders['xAcceptLanguage']!);
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Shows actual memory usage of API process
   * @method
   * @name API#heap
   */
  heap(parameters: {} = {}): Promise<{
    description?: string;

    name?: string;

    payload?: {};

    statusCode?: number;

    userinfo?: string;
  }> {
    let path = '/status/heap';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Admin search
   * @method
   * @name API#adminSearch
   */
  adminSearch(parameters: { query: string }): Promise<{}> {
    let path = '/admin/search';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    if (typeof parameters['query'] === 'undefined') {
      throw new Error('Missing required parameter: query');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
}

export default new API();
export { API };
